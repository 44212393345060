import { Injectable, NgZone } from '@angular/core';
import {
    EventSourceMessage,
    fetchEventSource,
} from '@microsoft/fetch-event-source';
import _ from 'lodash';
import {
    finalize, lastValueFrom,
    Observable,
    Subscriber,
} from 'rxjs';
import { filter, map, share, shareReplay } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from '../../error-handler/app-error-handler';

@Injectable({
    providedIn: 'root',
})
@StaticMembers<DowngradedService>()
export class NotificationsService {
    static downgrade: ServiceDowngradeMappings = {
        serviceName: 'notificationsService',
        moduleName: 'sis-components.notifications.notifications.downgraded',
    };

    private eventsObservable$: Observable<any>;
    private messageCountObservable$: Observable<any>;
    private abortController: AbortController;

    constructor(private authService: AuthService,
                private appErrorHandler: AppErrorHandler,
                private environmentService: EnvironmentService,
                private configService: ConfigService,
                private ngZone: NgZone) {
        this.eventsObservable$ = this.createEventsObservable().pipe(share({ resetOnRefCountZero: false }));
        this.messageCountObservable$ = this.eventsObservable$.pipe(
            filter((message: EventSourceMessage) => message.event === 'messageCount'),
            shareReplay({ bufferSize: 1, refCount: false }));
    }

    getMessageCountObservable(): Observable<number> {
        return this.messageCountObservable$.pipe(map((message: EventSourceMessage) => JSON.parse(message.data)));
    }

    getEventsObservable<T>(eventType: string): Observable<T> {
        return this.eventsObservable$.pipe(
            filter((message: EventSourceMessage) => eventType === message.event),
            map((message: EventSourceMessage) => JSON.parse(message.data)),
        );
    }

    private createEventsObservable(): Observable<EventSourceMessage> {
        this.abortController = new AbortController();
        return new Observable<EventSourceMessage>(observer => this.createEventSource(observer))
            .pipe(
                this.appErrorHandler.defaultErrorHandler(),
                finalize(() => {
                    console.debug('Finalize SSE-observable');
                    this.abortController.abort();
                }));
    }

    private createEventSource(observer: Subscriber<EventSourceMessage>) {
        let retryTimeout: number;
        fetchEventSource(this.constructUrl(), {
            method: 'GET',
            fetch: async (input: RequestInfo, init?: RequestInit) => {
                /* If the connection needs to be established again, use the last event id if exists, to continue where left off */
                const newToken = await lastValueFrom(this.refreshAuthTokenAndNotificationsEndpoint());
                init.headers = { ...init.headers, Authorization: `Bearer ${newToken}` };
                return window.fetch(input, init);
            },
            signal: this.abortController.signal,
            async onopen(response: Response) {
                console.debug('Opening SSE connection, response:', response);
                if (response.ok) {
                    retryTimeout = undefined;
                    return;
                }
                if (response.status === 419) throw new AuthorizationError('Token has expired');
                throw new ServerError();
            },
            onmessage: message => this.handleEvent(message, observer),
            onclose: () => {
                console.debug('SSE connection closed by server');
                throw new ConnectionClosedError('Connection closed by the server');
            },
            /* Retry with timeout, depending on the error */
            onerror: error => {
                console.debug('Error encountered in SSE: ', error);
                if (error instanceof AuthorizationError) {
                    retryTimeout = this.getRetryTimeout(2000, retryTimeout);
                } else if (error instanceof ConnectionClosedError) {
                    retryTimeout = this.getRetryTimeout(1000, retryTimeout);
                } else {
                    retryTimeout = this.getRetryTimeout(30000, retryTimeout);
                }
                return retryTimeout;
            },
        }).catch(err => { observer.error(err); });
    }

    private getRetryTimeout = (defaultTimeout: number, retryTimeOut: number) => {
        if (!retryTimeOut) return defaultTimeout;
        return _.min([retryTimeOut * 2, 120000]);
    };

    private refreshAuthTokenAndNotificationsEndpoint() {
        return this.authService.refreshAuthToken().pipe(map(token => {
            if (token === null) throw new AuthorizationError('Failed to retrieve token');
            return token;
        }));
    }

    private handleEvent(message: EventSourceMessage, observer: Subscriber<EventSourceMessage>) {
        console.debug('Received SSE event:', message);
        /* This is necessary to bring the sse-events to angular zone, otherwise change detection is not working as intended */
        this.ngZone.run(() => observer.next(message));
    }

    private constructUrl() {
        const { notificationsBackendUrl } = this.configService.get();
        return `${notificationsBackendUrl}/osuva/api/notifications/${this.environmentService.frontendName.toLowerCase()}/${this.authService.personId()}`;
    }
}

class ServerError extends Error { }
class ConnectionClosedError extends Error { }
class AuthorizationError extends Error { }
