<ng-container *ngFor="let attachments of  externalAttachments$ | async">
    <ng-container *ngFor="let document of  attachments?.documents">
      <div class="sis-mt-sm" *ngFor="let lang of getAttachmentLanguages(document)">
        <ng-container *ngIf="document.attachments[lang] as attachment">
          <a fudisLink *ngIf="showLink"
             [title]="(document.title | localizedString: lang) + ' (' + readableFileSizeString(attachment.size) + ')' +  ' (EMREX:' +  document.externalIdentifier + ')'"
             [href]="attachment.preSignedGetUrl"
             [external]="true"
             data-cy="attachment"></a>
          <div class="object file-preview" *ngIf="!showLink">
            <span class="file-name">{{document.title | localizedString: lang}} ({{readableFileSizeString(attachment.size)}}) (EMREX: {{document.externalIdentifier}})</span>
          </div>
          <p>{{getDescriptionForLang(document, lang)}}</p>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
