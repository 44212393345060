import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    effect,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit, signal,
    Signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TransitionService } from '@uirouter/angular';
import { RawParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { debounceTime, fromEvent } from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { EnvironmentService } from 'sis-common/environmentService/environment.service';

import { navUrls, NavUtilsService } from '../nav-utils/nav-utils.service';

export interface MainNavigationItem {
    /** Unique id of the link */
    linkId: string;
    /** Visible label of the link */
    translationKey: string;
    /** If true, the link will only be visible for authenticated users */
    loginRequired?: boolean;
    /** The name of the state where the link points to. Alternative to `toUrl`. */
    toState?: string;
    /** Additional state parameters for `toState` */
    toParams?: any;
    /** A URL the link points to. Alternative to `toState`. */
    toUrl?: string;
}

@Component({
    selector: 'sis-top-navigation',
    templateUrl: './top-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationComponent implements OnDestroy, OnInit, AfterViewInit {

    /** The target router state the frontpage link. Alternative to {@link homeUrl}. */
    @Input() homeState: string;
    /** The target URL the frontpage link. Alternative to {@link homeState}. */
    @Input() homeUrl: string;

    @Input() mainNavigationItems: MainNavigationItem[];

    /** View child for the component's left navigation wrapper div */
    @ViewChild('leftNavigation') leftNavigation: ElementRef;
    @ViewChild('rightNavigation') rightNavigation: ElementRef;
    @ViewChild('rightNavigationMobile') rightNavigationMobile: ElementRef;
    @ViewChild('navList') navList: ElementRef;
    @ViewChild('exitLinkFirst') exitLinkFirst: ElementRef<HTMLAnchorElement>;
    @ViewChild('exitLinkSecond') exitLinkSecond: ElementRef<HTMLAnchorElement>;

    readonly authenticated: Signal<boolean>;
    activeIndex: number;
    mobileMenuOpen = false;
    mobileMenuToggled = false;
    frontendName: string;
    navCollapsed = signal<boolean>(true);
    navCollapseCalc = signal<boolean>(false);
    focusToShoppingCart: boolean = false;
    activeRoleNameKey: string;
    displayName: string;
    windowHeight: number;

    deregisterActiveUrlChangeHook: Function;

    constructor(authService: AuthService,
                private environmentService: EnvironmentService,
                private navUtilsService: NavUtilsService,
                private state: StateService,
                private transitionService: TransitionService,
                private changeDetectorRef: ChangeDetectorRef,
                private uiRouterGlobals: UIRouterGlobals,
    ) {
        this.authenticated = toSignal(authService.userLoggedIn$);
        this.displayName = authService.displayname();
        this.windowHeight = window.innerHeight;

        // When user starts resizing, after a brief delay check if TopNav requires collapsing
        fromEvent(window, 'resize')
            .pipe(takeUntilDestroyed(), debounceTime(500))
            .subscribe(() => {
                this.checkTopNavWidth();
            });

        // When user starts resizing, set calc observable as true, so required CSS classes are triggered in HTML
        fromEvent(window, 'resize')
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                // Prevents the event triggering on mobile screen scroll
                const height = window.innerHeight;

                if (height === this.windowHeight) {
                    if (!this.navCollapseCalc()) {
                        this.navCollapseCalc.set(true);

                        if (this.mobileMenuOpen) {
                            this.toggleMobileMenu();
                        }
                    }
                }
                this.windowHeight = height;
            });
        effect(() => {
            this.focusToExitLink(!!this.navUtilsService.focusMode());
        });
    }

    get focusMode() {
        return this.navUtilsService.focusMode;
    }

    ngOnInit() {
        this.frontendName = this.environmentService.frontendName;
        this.setActiveIndex(this.uiRouterGlobals.current.name);
        this.deregisterActiveUrlChangeHook = this.registerActiveUrlChangeHook();
        this.activeRoleNameKey = this.getActiveRoleNameKey(this.navUtilsService.getSelectedContextPath());

        // Set navCollapse  "calculation state" true before HTML is mounted to avoid HTML flickering.
        this.navCollapseCalc.set(true);
    }

    ngAfterViewInit() {
        // Run width check on HTML mounting
        this.checkTopNavWidth();
    }

    ngOnDestroy() {
        this.deregisterActiveUrlChangeHook();
    }

    focusToExitLink(focusModeOn: boolean) {
        if (focusModeOn) {
            const targetElement = this.exitLinkFirst?.nativeElement ? this.exitLinkFirst.nativeElement : this.exitLinkSecond?.nativeElement ? this.exitLinkSecond.nativeElement : null;
            if (targetElement) targetElement.focus();
        }
    }

    isValidState(state: string, params: RawParams = null) {
        return !!state && !!this.state.href(state, params);
    }

    registerActiveUrlChangeHook() {
        return this.transitionService.onSuccess({}, (transition) => {
            this.mobileMenuOpen = false;
            this.setActiveIndex(transition.to().name);
            this.changeDetectorRef.markForCheck();
        });
    }

    setActiveIndex(activePathName: string) {
        this.activeIndex = undefined;

        if (activePathName.includes('logged-in.frontpage') || activePathName.includes('.login')) {
            this.activeIndex = -1;
        } else if (activePathName.includes('student.logged-in.structure')) {
            // path changes from plan-select to structure before the transition updates
            this.activeIndex = 0;
        } else {
            this.mainNavigationItems?.forEach((item: any, index) => {
                if (activePathName.includes(item.toState)) {
                    this.activeIndex = index;
                }
            });
        }
    }

    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
        this.mobileMenuToggled = true;
    }

    /** Close mobile navigation when next targeted element is not inside the mobile nav */
    blurMobileMenu(event: any) {
        if (this.mobileMenuOpen && !this.leftNavigation?.nativeElement.contains(event.relatedTarget)) {
            this.mobileMenuOpen = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    checkTopNavWidth(): void {

        const widthInEm = window.innerWidth / parseFloat(
            getComputedStyle(
                document.querySelector('body'),
            ).fontSize,
        );

        // LG screen size trigger point, if smaller, set mobile mode on, otherwise check if links fit the available container
        if (widthInEm >= 85.71428571428571) {

            // List of links, which might not fit!
            const navList = (this.navList?.nativeElement as HTMLUListElement)?.getBoundingClientRect();

            // Wrapper of right side navigation
            const navRight = (this.rightNavigation?.nativeElement as HTMLDivElement)?.getBoundingClientRect();

            if (navList && navRight) {

                // When calc observable is on, the navigation is not collapsed and in "desktop mode" but hidden with opacity 0. Then if navlinks are too wide for the screen, their top position do not match with the main nav wrapper --> collapse and set to mobile mode
                if (navRight.height !== navList.height) {
                    // Mobile mode
                    this.navCollapsed.set(true);
                } else {
                    // Desktop mode
                    this.navCollapsed.set(false);
                }
                // Disable calc observable, and CSS is set to normal
                this.navCollapseCalc.set(false);
            } else {

                // Especially when loading components for the first time, DOM elements might not be available for width calculation, so let's try again.
                setTimeout(() => {
                    this.checkTopNavWidth();
                }, 200);
            }

        } else {
            this.navCollapsed.set(true);
            this.navCollapseCalc.set(false);
        }
    }

    moveFocusToCartButton() {
        this.focusToShoppingCart = true;
    }

    private getActiveRoleNameKey(context: string): string {
        switch (context) {
            case (navUrls.ADMIN_URL):
                return 'AUTH.APPROLE.ADMIN';
            case (navUrls.STUDENT_URL):
                return 'AUTH.APPROLE.STUDENT';
            case (navUrls.STAFF_URL):
                return 'AUTH.APPROLE.STAFF';
            case (navUrls.TEACHER_URL):
                return 'AUTH.APPROLE.TEACHER';
            case ('/staff'):
                return 'AUTH.APPROLE.STAFF';
        }
        return null;
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.mobileMenuOpen && event.code !== 'Tab') {
            const listItems = this.getAllNavItems() as HTMLInputElement[];

            if (listItems.length > 0) {
                const current = listItems.find(item => item.ariaCurrent === 'page') || listItems[0];
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    const focusElement = this.getFocusedElement();
                    const siblingElementsCount = listItems.length - 1;
                    event.preventDefault();

                    const focusIndex = listItems.indexOf(focusElement);

                    let elementToFocus = current;

                    if (event.key === 'ArrowUp') {
                        elementToFocus = listItems[focusIndex > 0 ? focusIndex - 1 : siblingElementsCount];
                    }
                    if (event.key === 'ArrowDown') {
                        elementToFocus = listItems[focusIndex < siblingElementsCount ? focusIndex + 1 : 0];
                    }
                    elementToFocus.focus();

                    // If elementToFocus is hidden behind display none
                    if (focusElement === this.getFocusedElement()) {
                        elementToFocus = listItems[event.key === 'ArrowUp' ? this.getMainNavItems().length - 1 : 0];
                        elementToFocus.focus();
                    }

                } else if (event.code === 'Space') {
                    const focusElement = this.getFocusedElement();
                    focusElement?.click();
                }
            }
        }
    }

    private getFocusedElement() {
        return this.navList?.nativeElement.querySelector(':focus') || this.rightNavigationMobile?.nativeElement.querySelector(':focus');
    }

    private getMainNavItems() {
        return this.navList?.nativeElement.querySelectorAll('a');
    }

    private getAllNavItems() {
        const rightNavMobileLinks = this.rightNavigationMobile?.nativeElement.querySelectorAll('a, button');
        return [...this.getMainNavItems(), ...rightNavMobileLinks];
    }

    /** Close open mobile navigation if targeted element is not inside the mobile nav, expect when the click event opens the nav */
    @HostListener('document:click', ['$event'])
    private offClickHandler(event: any) {
        if (this.mobileMenuOpen && !this.leftNavigation?.nativeElement.contains(event.target)) {
            if (!this.mobileMenuToggled) {
                this.mobileMenuOpen = false;
            }
            this.mobileMenuToggled = false;
            this.changeDetectorRef.markForCheck();
        }
    }
}
