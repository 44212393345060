import { Injectable } from '@angular/core';
import { EntityActions, EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    BatchOperationResult,
    ModuleResponsibilityInfoType,
    OtmId,
    PersonWithModuleResponsibilityInfoType,
    ResponsibilityInfo,
    ResponsiblePersonDeleteRequest,
    ResponsiblePersonsAddRequest,
    ResponsiblePersonValidityPeriodEndDates,
    ResponsiblePersonValidityPeriodsEndRequest,
    StudyModule,
} from 'common-typescript/types';
import { Observable, tap } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';
import { ModuleEntityService } from './module-entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        cancelApproval(studyModuleId: OtmId) {
            return `${this.backend}/study-modules/cancel-approval/${studyModuleId}`;
        },
        undoDelete(studyModuleId: OtmId) {
            return `${this.backend}/study-modules/undo-delete/${studyModuleId}`;
        },
        approve(studyModuleId: OtmId) {
            return `${this.backend}/study-modules/approve/${studyModuleId}`;
        },
        addResponsiblePersons() {
            return `${this.backend}/study-modules/add-responsible-persons/batch`;
        },
        endResponsiblePersonValidityPeriod() {
            return `${this.backend}/study-modules/end-responsible-person-validity-period/batch`;
        },
        deleteResponsiblePerson() {
            return `${this.backend}/study-modules/delete-responsible-person/batch`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'study-modules',
})
export class StudyModuleEntityService extends EntityService<StudyModuleState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.studyModuleEntityService',
        serviceName: 'studyModuleEntityService',
    };

    constructor(private moduleEntityService: ModuleEntityService) {
        super(StudyModuleStore, StudyModuleQuery);
        this.syncToModuleStore();
    }

    private syncToModuleStore() {
        this.query.selectEntityAction([EntityActions.Add, EntityActions.Set, EntityActions.Update])
            .pipe(
                tap((data) => {
                    data.ids.forEach((id) => {
                        const entity = this.query.getEntity(id);
                        if (!!entity) {
                            this.moduleEntityService.storeUpsert(entity);
                        }
                    });
                }),
            )
            .subscribe();
        this.query.selectEntityAction(EntityActions.Remove)
            .pipe(
                tap((ids) => {
                    ids.forEach((id) => {
                        this.moduleEntityService.storeRemove(id);
                    });
                }),
            )
            .subscribe();
    }

    undoApproval(id: OtmId): Observable<StudyModule> {
        return this.getHttp().put<StudyModule>(
            CONFIG.ENDPOINTS.cancelApproval(id),
            {},
        ).pipe(tap((studyModule) => this.store.upsert(studyModule.id, studyModule)));
    }

    undoDelete(id: OtmId, curriculumPeriodIds: OtmId[]): Observable<StudyModule> {
        return this.getHttp().put<StudyModule>(
            CONFIG.ENDPOINTS.undoDelete(id),
            curriculumPeriodIds,
        ).pipe(tap((studyModule) => this.store.upsert(studyModule.id, studyModule)));
    }

    approve(id: OtmId): Observable<StudyModule> {
        return this.getHttp().put<StudyModule>(
            CONFIG.ENDPOINTS.approve(id),
            {},
        ).pipe(tap((studyModule) => this.store.upsert(studyModule.id, studyModule)));
    }

    addResponsiblePersonsBatch(studyModuleIds: OtmId[], responsibilityInfos: ResponsibilityInfo[], dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.addResponsiblePersons(),
            { ids: studyModuleIds, responsibilityInfos } as ResponsiblePersonsAddRequest<PersonWithModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    endResponsiblePersonValidityPeriodBatch(studyModuleIds: OtmId[], responsiblePersonId: OtmId, endDates: ResponsiblePersonValidityPeriodEndDates, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const roleValidityPeriodEndDates = [
            { roleUrn: 'urn:code:module-responsibility-info-type:responsible-teacher', endDate: endDates.responsibleTeacherRoleEndDate },
            { roleUrn: 'urn:code:module-responsibility-info-type:administrative-person', endDate: endDates.adminRoleEndDate },
            { roleUrn: 'urn:code:module-responsibility-info-type:contact-info', endDate: endDates.contactInfoRoleEndDate },
        ];
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.endResponsiblePersonValidityPeriod(),
            { ids: studyModuleIds, responsiblePersonId, roleValidityPeriodEndDates } as ResponsiblePersonValidityPeriodsEndRequest<ModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    deleteResponsiblePersonBatch(studyModuleIds: OtmId[], responsiblePersonId: OtmId, rolesToDelete: Set<ModuleResponsibilityInfoType>, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.deleteResponsiblePerson(),
            { ids: studyModuleIds, responsiblePersonId, rolesToDelete: [...rolesToDelete] } as ResponsiblePersonDeleteRequest<ModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    updateAndStore(studyModule: StudyModule): Observable<StudyModule> {
        return super.update<StudyModule>(studyModule.id, studyModule, { skipWrite: true })
            .pipe(tap((sm) => this.store.upsert(sm.id, sm)));
    }

    storeUpsert(studyModule: StudyModule) {
        this.store.upsert(studyModule.id, studyModule);
    }
}

type StudyModuleState = EntityState<StudyModule, OtmId>;

@StoreConfig({ name: 'study-modules' })
class StudyModuleStore extends EntityStore<StudyModuleState> {}

class StudyModuleQuery extends QueryEntity<StudyModuleState> {
    constructor(protected store: StudyModuleStore) {
        super(store);
    }
}
