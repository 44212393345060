import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedString } from 'common-typescript/types';

interface IdentityProvider {
    name: LocalizedString;
    url: string;
}

@Component({
    selector: 'sis-login-dropdown',
    templateUrl: './login-dropdown.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDropdownComponent {

    @Input() options: IdentityProvider[] = [];

    /** View child for the component's wrapper div */
    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    /** Close login dropdown when next targeted element is not inside the menu */
    blurMenu(event: any) {
        if (this.dropdown?.isOpen() && !document.querySelector('sis-login-dropdown').contains(event.relatedTarget)) {
            this.dropdown.close();
        }
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.dropdown?.isOpen() && event.code !== 'Tab') {
            const listItems = this.getDropdownItems() as HTMLInputElement[];
            if (listItems.length > 0) { listItems[0].focus(); }
        }
    }

    private getDropdownItems(eventElement?: HTMLInputElement) {
        const dropdownMenu = document.getElementById('sis-login-dropdown');
        const listItems = dropdownMenu.querySelectorAll('a');
        return Array.prototype.filter.call(listItems, (item: any) => item.tabIndex >= '0');
    }
}
