<sis-carousel *ngIf="products?.length > 0"
              [control]="selectedProductIndexControl"
              [selectedSlideId]="initialSelection"
              [mobileView]="mobileView"
              [carouselHeaderId]="carouselHeaderId"
              mobileViewDescription="OPEN_UNIVERSITY.STUDY_OFFERING.BROWSE_COMPLETION_METHODS"
              prevAriaLabel="OPEN_UNIVERSITY.STUDY_OFFERING.BROWSE_COMPLETION_METHODS_BACKWARD"
              nextAriaLabel="OPEN_UNIVERSITY.STUDY_OFFERING.BROWSE_COMPLETION_METHODS_FORWARD"
              contentRole="radiogroup">
  <sis-carousel-selection-box-slide *ngFor="let product of products; trackBy: entityId; index as index"
                                    [control]="selectedProductIndexControl"
                                    [translatedTitle]="product.name | localizedString"
                                    [titleBadgeText]="product.isPurchased ? 'OPEN_UNIVERSITY.STUDY_OFFERING.PURCHASED' : undefined"
                                    [titleBadgeVariant]="product.isPurchased ? 'success' : undefined"
                                    [id]="product.id"
                                    [value]="index"
                                    [name]="name"
                                    [banners]="product | productSlideBanner"
                                    (selectedIdEvent)="onProductSelect($event)">
    <app-carousel-product-info [product]="product" [id]="product?.id + '-info'" sisDescribedByContent></app-carousel-product-info>
    <app-product-details *ngIf="mobileView"
                         [courseUnit]="courseUnit"
                         [product]="product"
                         [mobileView]="true"
                         class="mt-5">
    </app-product-details>
  </sis-carousel-selection-box-slide>
</sis-carousel>
