import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from 'sis-common/auth/auth-service';

@Component({
    selector: 'sis-top-navigation-icons',
    templateUrl: './top-navigation-icons.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavigationIconsComponent {

    @Input() mobileMode = false;
    @Input() frontendName: string;
    @Input() initialFocus: boolean = false;

    @Output() blurMobileMenuIcons = new EventEmitter<any>();
    @Output() keydownPressed = new EventEmitter<any>();

    readonly authenticated = toSignal(inject(AuthService).userLoggedIn$);
}
