<div *ngIf="userRoleSelections?.length > 1" aria-labelledby="user-role" role="group" class="user-role-menu"
     [class.user-role-menu--mobile]="mobileMode">
  <div class="user-role-menu__header" aria-hidden="true" id="user-role">{{'AUTH.USER_ROLE' | translate}}</div>
  <a ngbDropdownItem
     role="menuitem"
     *ngFor="let role of userRoleSelections"
     [href]="role.url"
     (click)="updateActiveRole(role)"
     (blur)="blurMobileUserRoleMenu.emit($event)"
     (keydown.escape)="blurMobileUserRoleMenu.emit($event)"
     (keydown)="keydownPressed.emit($event)"
     [attr.aria-current]="role.active"
     class="user-role-menu__item anchor-button"
     data-cy="user-role">
    {{role.nameKey | translate}}
    <fudis-icon [icon]="'check'" *ngIf="role.active" [color]="'primary'"></fudis-icon>
  </a>
</div>
