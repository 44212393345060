import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { numberUtils } from 'common-typescript';
import { ExternalAttainedStudyAttachments, ExternalAttainedStudyDocument } from 'common-typescript/types';
import { forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import { AppErrorHandler } from '../../error-handler/app-error-handler';
import {
    ExternalAttainedStudyAttachmentService,
} from '../../service/external-attained-study-attachment.service';

@Component({
    selector: 'sis-emrex-attachments',
    templateUrl: './emrex-attachments.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class EmrexAttachmentsComponent implements OnInit {

    @Input() externalAttainedStudyIds$: Observable<string[]>;
    @Input() showLink: boolean = false;
    @Output() fetchedExternalAttachments = new EventEmitter<ExternalAttainedStudyAttachments[]>();

    externalAttachments$: Observable<ExternalAttainedStudyAttachments[]>;

    constructor(private externalAttainedStudyAttachmentService: ExternalAttainedStudyAttachmentService,
                private appErrorHandler: AppErrorHandler) {}

    ngOnInit(): void {
        this.externalAttachments$ = this.externalAttainedStudyIds$?.pipe(
            switchMap(ids => {
                const observables = ids.map(id => this.externalAttainedStudyAttachmentService.getExternalAttainedStudyAttachments(id)
                    .pipe(this.appErrorHandler.defaultErrorHandler()),
                );
                if (observables.length === 0) {
                    return of([]);
                }
                return forkJoin(observables);
            }),
            map((attachments: ExternalAttainedStudyAttachments[]) => attachments.filter(attachment => attachment?.documents?.length > 0)),
            tap(attachments => {
                this.fetchedExternalAttachments.emit(attachments);
            }),
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    getAttachmentLanguages(document: ExternalAttainedStudyDocument): string[] {
        return Object.keys(document.attachments);
    }

    readableFileSizeString(number: any) {
        return numberUtils.readableFileSizeString(number);
    }

    getDescriptionForLang(document: ExternalAttainedStudyDocument, lang: string): string {
        return !!document.description ? document.description[lang] : '';
    }
}
