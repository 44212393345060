<ng-container *transloco="let t">
  <fudis-dialog [size]="'sm'">
    <fudis-heading [level]="2">{{ t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.TITLE') }}</fudis-heading>
    <fudis-form [errorSummaryHelpText]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.FORM_ERROR_SUMMARY_INFO')">
      <ng-template fudisActions [type]="'form'">
        <fudis-dialog-content>
          <section class="sis-mb-lg">
            <fudis-heading
              [level]="3">{{ t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.GRADUATION_INSTRUCTIONS_TITLE') }}
            </fudis-heading>
            <app-graduation-instructions-url [organisationId]="data.organisationId"></app-graduation-instructions-url>
          </section>
          <section>
            <fudis-heading [level]="3">{{ t(data.modalTitle) }}</fudis-heading>
            <fudis-body-text [variant]="'lg-light'">{{ t(data.infoText) }}</fudis-body-text>
            <fudis-body-text [variant]="'lg-light'">{{ t(data.infoQuestion) }}</fudis-body-text>
            <a fudisLink [title]="t(data.continueButtonText)" class="fudis-mt-sm" [external]="true" [href]="getLocalizedSurveyUrl()"></a>
            <div class="sis-mt-lg">
              <fudis-checkbox-group [formGroup]="surveyFormGroup"
                                    [label]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.CONFIRM_LABEL')">
                <fudis-checkbox [controlName]="'surveyCompleted'"
                                [label]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.CONFIRM_VALUE')"></fudis-checkbox>
              </fudis-checkbox-group>
            </div>
          </section>
          <section class="sis-mt-lg">
            <fudis-body-text [variant]="'lg-light'">{{ t('GRADUATION.APPLY_FOR_GRADUATION_MODAL.INFO_TEXT') }}
            </fudis-body-text>
          </section>
        </fudis-dialog-content>
        <fudis-dialog-actions>
          <fudis-button fudisDialogClose variant="secondary" [label]="t('CANCEL')"/>
          <fudis-button fudisFormSubmit [formValid]="surveyFormGroup.valid"
                        [label]="t('GRADUATION.MANDATORY_GRADUATION_SURVEY_MODAL.APPLY_FOR_GRADUATION_BUTTON')"
                        (handleClick)="applyForGraduation()"/>
        </fudis-dialog-actions>
      </ng-template>
    </fudis-form>
  </fudis-dialog>
</ng-container>
