import { registerLocaleData } from '@angular/common';
import fi from '@angular/common/locales/fi';
import sv from '@angular/common/locales/sv';
import { Injectable } from '@angular/core';
import { FudisTranslationService } from '@funidata/ngx-fudis';
import { FudisLanguageAbbr } from '@funidata/ngx-fudis/lib/types/miscellaneous';
import { TranslocoService } from '@ngneat/transloco';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LocalizedString } from 'common-typescript/types';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fi';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ConfigService } from '../config/config.service';

import { LocalStorageSelectedLanguageService } from './local-storage-selected-language.service';

export type LanguageCode = 'fi' | 'en' | 'sv';

@Injectable({ providedIn: 'root' })
export class LocaleService {
    private readonly supportedLanguages = ['fi', 'en', 'sv'];
    private readonly localizedLanguageNames$: Subject<{ [language: string]: LocalizedString }> = new ReplaySubject(1);

    constructor(
        private configService: ConfigService,
        private translate: TranslateService,
        private translocoService: TranslocoService,
        private fudisTranslationService: FudisTranslationService,
        private localStorageSelectedLanguage: LocalStorageSelectedLanguageService,
    ) {
        this.initLocalizedLanguageNames();
        this.initLanguageChangeListeners();
        this.initLocales();
    }

    getCurrentLanguage(): string {
        return this.translate.currentLang;
    }

    /**
     * Returns localized names of the given languages. If no language codes are given, returns the names of all supported languages.
     *
     * @param languages The two-letter language codes of the languages whose names to return (optional)
     */
    getLocalizedLanguageNames(languages?: string[]): Observable<{ [language: string]: LocalizedString }> {
        const langs = languages ?? this.supportedLanguages;
        return this.localizedLanguageNames$.pipe(
            take(1), // No need to keep the subscription open, the values won't change
            map(names => langs.reduce((result, lang) => ({ ...result, [lang]: names[lang] ?? {} }), {})),
        );
    }

    /**
     * Returns the two-letter language codes of the languages supported by SISU. In the vast majority of cases, you'll
     * want to use `getOfficialLanguages()` instead.
     */
    getSupportedLanguages(): string[] {
        return this.supportedLanguages;
    }

    /**
     * Returns the two-letter language codes of the official languages of the current university.
     */
    getOfficialLanguages(): string[] {
        return this.configService.get().officialLanguages;
    }

    /**
     * Alias for `localize()` (for backwards compatibility).
     *
     * @deprecated Use localize() instead
     */
    getLocalizedValue(value: LocalizedString, locale?: string): string {
        return this.localize(value, locale);
    }

    /**
     * Return a localized value from the given `LocalizedString` object. The value to return is resolved as follows:
     *
     * 1. If the `language` parameter is defined, and the localized string has a value for that language, return that value.
     * 2. If the localized string has a value for the current language, return that value.
     * 3. Iterate the `supportedLanguages` array and return the first matching non-empty value from the localized string.
     * 4. Iterate keys of the `value` parameter and return the first matching non-empty value. This is needed in cases where the LocalizedString
     * contains only languages that are not supported, but we want to show one of those anyway.
     * Otherwise return undefined.
     */
    localize(value: LocalizedString, language?: string): string {
        if (!value) return undefined;
        return [
            language ?? this.getCurrentLanguage(),
            ...this.supportedLanguages,
            ...Object.keys(value),
        ]
            .map(lang => value?.[lang])
            .find(entry => !!entry);
    }

    /**
     * Localize each value in the given array using the current language and combine them into a single string. Values that
     * can't be localized are filtered out. The last two values in the output are separated with the word "and", and all other
     * values are separated with a comma (e.g. "First, Second and Third").
     *
     * Each input value is ran through `localize()`, meaning that they all get separate locale fallback handling, and the
     * output can therefore have parts in different languages.
     */
    localizeArray(values: LocalizedString[]): string {
        const localizedValues = values?.map(value => _.pick(value, this.supportedLanguages)).map(value => this.localize(value)).filter(Boolean) ?? [];
        if (localizedValues.length === 0) {
            return undefined;
        }
        if (localizedValues.length === 1) {
            return localizedValues[0];
        }

        const lastValue = localizedValues.pop();
        return `${localizedValues.join(', ')} ${this.translate.instant('COMMON.AND')} ${lastValue}`;
    }

    /**
     * Combines the given localized strings into a single localized string, using the given separator between individual
     * parts of the merged result.
     *
     * The returned value will only contain content in the supported system languages, any content in other languages
     * contained in the inputs will be omitted from the result.
     *
     * @param values The localized strings to merge
     * @param separator The separator string to use when joining the individual parts of the result
     * @param useFallbacks Whether to use language fallback handling for missing values (see `localize()` for details).
     * If enabled, each value will get separate language fallback handling for missing localizations, so the result will
     * have content in mixed languages if the input values don't have content for all supported languages.
     */
    merge(values: LocalizedString[], separator = ', ', useFallbacks = false): LocalizedString {
        const localize = (value: LocalizedString, language: string) =>
            useFallbacks ? this.localize(value, language) : value?.[language];
        const resultEntries: [string, string][] = this.supportedLanguages.map(language => [
            language,
            (values || []).filter(Boolean).map(value => localize(value, language)).filter(Boolean).join(separator),
        ]);
        return Object.fromEntries(resultEntries);
    }

    private initLocalizedLanguageNames(): void {
        combineLatest(
            this.supportedLanguages.map(language => this.translate.getTranslation(language)
                .pipe(map(translations => [language, translations?.LANGUAGES ?? {}]))),
        )
            .pipe(map((languageNameEntries: [string, any][]) => Object.fromEntries(languageNameEntries)))
            .subscribe((languageNames: { [language: string]: any }) => {
                this.localizedLanguageNames$.next(Object.fromEntries(this.supportedLanguages.map(language => [
                    language,
                    Object.fromEntries(this.supportedLanguages.map(lang => [lang, languageNames?.[lang]?.[language]])),
                ])));
            });
    }

    /**
     * Get a complete `LocalizedString` object by translation key. Missing translations will be
     * replaced with their `key`.
     *
     * @param key Translation key.
     */
    getLocalizedString(key: string): LocalizedString {
        return {
            fi: this.getTranslationByKey(key, 'fi'),
            en: this.getTranslationByKey(key, 'en'),
            sv: this.getTranslationByKey(key, 'sv'),
        };
    }

    private getTranslationByKey(key: string, lang: LanguageCode): string {
        const path = [lang, key].join('.');
        return _.get(this.translate.translations, path, key);
    }

    /**
     * Set up Transloco and Fudis to mirror language configuration of `ngx-translate`.
     * @private
     */
    private initLanguageChangeListeners(): void {
        this.translocoService.setAvailableLangs(this.translate.getLangs());

        this.translate.onLangChange.subscribe(({ lang }: LangChangeEvent) => {
            this.fudisTranslationService.setLanguage(lang as FudisLanguageAbbr);
            this.translocoService.setActiveLang(lang);
            this.localStorageSelectedLanguage.set(lang);
        });

        this.translate.onDefaultLangChange.subscribe(({ lang }: DefaultLangChangeEvent) => {
            this.fudisTranslationService.setLanguage(lang as FudisLanguageAbbr);
            this.translocoService.setDefaultLang(lang);
        });
    }

    private initLocales(): void {
        moment.locale('fi');
        registerLocaleData(fi);
        registerLocaleData(sv);
    }
}
