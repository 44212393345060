import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import { DegreeProgramme, EntityWithRule, OtmId } from 'common-typescript/types';
import { filter } from 'rxjs/operators';
import { planSelectorModalOpener } from 'sis-components/plan/plan-selector-modal/plan-selector-modal.component';
import { isDegreeProgramme } from 'sis-components/service/module-entity.service';

import { PlanLoaderService } from '../../../../common/service/plan-loader.service';

export interface SelectedModule {
    module: EntityWithRule;
    /** The closest ancestor degree programme of the selected module in the plan structure */
    degreeProgramme: DegreeProgramme;
}

@Component({
    selector: 'app-plan-module-selector',
    templateUrl: './plan-module-selector.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanModuleSelectorComponent {

    @Input() set planId(planId: OtmId) {
        this.planLoader.createValidatablePlan(planId)
            .subscribe(validatablePlan => {
                this.validatablePlan = validatablePlan;
                if (this._moduleId) {
                    this.selectModule(this._moduleId);
                }
                this.ref.markForCheck();
            });
    }

    @Input() set moduleId(moduleId: OtmId) {
        this._moduleId = moduleId;
        this.selectModule(moduleId);
    }

    @Output() select = new EventEmitter<SelectedModule>();

    private readonly planLoader = inject(PlanLoaderService);
    private readonly ref = inject(ChangeDetectorRef);
    private readonly openPlanSelectorModal = planSelectorModalOpener();

    validatablePlan: ValidatablePlan;
    selectedModule: EntityWithRule;
    _moduleId: OtmId;

    openPlanSelector(): void {
        this.openPlanSelectorModal({
            title: 'PROFILE.APPLICATIONS.SELECT_LOCATION.MODAL_HEADER',
            description: 'PROFILE.APPLICATIONS.SELECT_LOCATION.MODAL_INFO',
            validatablePlan: this.validatablePlan,
            initialSelection: this._moduleId,
            config: {
                disableCourseUnitSelection: true,
                disableCustomStudyDraftSelection: true,
            },
        })
            .afterClosed()
            .pipe(filter(Boolean), filter(selection => 'module' in selection))
            .subscribe(({ module }) => this.selectModule(module.id));
    }

    findParentDegreeProgramme(moduleId: OtmId): DegreeProgramme {
        const module = this.validatablePlan?.modulesById?.[moduleId];
        const moduleSelection = this.validatablePlan?.moduleIdSelectionMap?.[moduleId];
        if (isDegreeProgramme(module)) {
            return module;
        }
        if (!moduleSelection?.parentModuleId) {
            return null;
        }
        return this.findParentDegreeProgramme(moduleSelection.parentModuleId);
    }

    selectModule(moduleId: OtmId): void {
        if (this.validatablePlan) {
            this.selectedModule = this.validatablePlan.modulesById?.[moduleId];
            this.select.emit({
                module: this.selectedModule ?? null,
                degreeProgramme: this.findParentDegreeProgramme(moduleId),
            });
        }
    }
}
