import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { HttpConfig, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    DeepPartial,
    Enrolment,
    EnrolmentAddRequest,
    EnrolmentStudySubGroupPriority,
    OtmId,
} from 'common-typescript/types';
import { Observable, throwError } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { EntityService } from './entity.service';

export interface EnrolmentCreationData {
    courseUnitId: OtmId;
    assessmentItemId: OtmId;
    courseUnitRealisationId: OtmId;
    personId: OtmId;
    studyRightId: OtmId;
    enrolmentRightId?: OtmId;
    targetStudySubGroupAmounts: { [studyGroupSetId: OtmId]: number };
    studySubGroupPriorities: { [studySubGroupId: OtmId]: EnrolmentStudySubGroupPriority };
}

/**
 * A service for handling enrolments by staff users. The STUDENT project contains a separate `EnrolmentStudentService`,
 * which is meant for student users.
 */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
@NgEntityServiceConfig({
    baseUrl: '/ilmo/api',
    resourceName: 'enrolments',
})
export class EnrolmentEntityService extends EntityService<EnrolmentState> {
    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.enrolmentEntityService',
        serviceName: 'enrolmentEntityService',
    };

    constructor(
        private uuidService: UuidService,
    ) {
        super(EnrolmentStore, EnrolmentQuery);
    }

    override getById(id: OtmId, bypassStore: boolean = false, config: HttpConfig = {}): Observable<Enrolment> {
        // Override method to use normal GET as data loader endpoint is not supported by ilmo/enrolment
        if (!bypassStore && this.query.hasEntity(id)) {
            return this.query.selectEntity(id);
        }
        return this.get(id);
    }

    addEnrolment(data: EnrolmentCreationData): Observable<Enrolment> {
        const body: DeepPartial<EnrolmentAddRequest> = { enrolment: this.createEnrolment(data) };
        const params = { courseUnitRealisationId: data.courseUnitRealisationId };
        return this.getHttp().post<Enrolment>(`${this.api}/add-enrolment`, body, { params })
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    retryCrossStudyEnrolments(enrolmentIds: OtmId[]): Observable<Enrolment[]> {
        return this.getHttp().post<Enrolment[]>(`${this.api}/retry-cross-study-enrolments`, enrolmentIds, {});
    }

    findForCourseUnitRealisation(courseUnitRealisationId: OtmId): Observable<Enrolment[]> {
        if (!courseUnitRealisationId) {
            return throwError(() => new Error('courseUnitRealisationId must be defined'));
        }

        return this.getHttp().get<Enrolment[]>(this.api, { params: { courseUnitRealisationId } })
            .pipe(
                this.upsertManyAndSwitchToStoreObservable({
                    filterBy: enrolment => enrolment.courseUnitRealisationId === courseUnitRealisationId,
                }),
            );
    }

    storeRemove(id: OtmId) {
        this.store.remove(id);
    }

    private createEnrolment(data: EnrolmentCreationData): Partial<Enrolment> {
        return {
            id: this.uuidService.randomOtmId(),
            courseUnitId: data.courseUnitId,
            assessmentItemId: data.assessmentItemId,
            courseUnitRealisationId: data.courseUnitRealisationId,
            personId: data.personId,
            studyRightId: data.studyRightId,
            enrolmentRightId: data.enrolmentRightId,
            studyGroupSets: Object.entries(data.targetStudySubGroupAmounts).map(([studyGroupSetId, targetStudySubGroupAmount]) => ({
                studyGroupSetId,
                targetStudySubGroupAmount,
            })),
            studySubGroups: Object.entries(data.studySubGroupPriorities).map(([studySubGroupId, enrolmentStudySubGroupPriority]) => ({
                studySubGroupId,
                enrolmentStudySubGroupPriority,
                isInCalendar: !!enrolmentStudySubGroupPriority && enrolmentStudySubGroupPriority !== 'NOT_SUITABLE',
            })),
            state: 'PROCESSING',
            processingState: 'NOT_PROCESSED',
            isInCalendar: true,
            colorIndex: 0,
        };
    }
}

type EnrolmentState = EntityState<Enrolment, OtmId>;

@StoreConfig({ name: 'enrolments' })
class EnrolmentStore extends EntityStore<EnrolmentState> {
}

class EnrolmentQuery extends QueryEntity<EnrolmentState> {
    constructor(protected store: EnrolmentStore) {
        super(store);
    }
}
