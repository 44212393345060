import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ELMOCredit,
    ExternalAttainedStudy,
    ExternalIssuer,
    LocalizedString, OtmId,
    PriorStudies,
} from 'common-typescript/types';
import { find, has, values } from 'lodash';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { ExternalIssuerEntityService } from './../service/external-issuer-entity.service';

@Injectable({ providedIn: 'root' })
export class ExternalAttainedStudyService {

    constructor(
        private http: HttpClient,
        private externalIssuerEntityService: ExternalIssuerEntityService,
        private authService: AuthService,
        private localeService: LocaleService,
    ) {}

    getExternalAttainedStudies(): Observable<ExternalAttainedStudy[]> {
        return this.http.get<ExternalAttainedStudy[]>(
            '/ori/api/external-attained-studies',
        );
    }

    getExternalAttainedStudyById(externalAttainedStudyId: OtmId, personId?: OtmId): Observable<ExternalAttainedStudy> {
        const id = personId ? personId : this.authService.personId();
        return this.http.get<ExternalAttainedStudy>(`/ori/api/external-attained-studies/${externalAttainedStudyId}?personId=${id}`);
    }

    getExternalAttainedStudyAsPriorStudies(externalAttainedStudy: ExternalAttainedStudy): Observable<Partial<PriorStudies>> {
        return this.externalIssuerEntityService.getById(externalAttainedStudy.externalIssuerId)
            .pipe(
                take(1),
                map(externalIssuer => this.mapExternalAttainedStudyPriorStudies(externalAttainedStudy, externalIssuer)),
            );
    }

    private mapExternalAttainedStudyPriorStudies(externalAttainedStudy: ExternalAttainedStudy, externalIssuer: ExternalIssuer) {
        const loi = externalAttainedStudy?.learningOpportunityInstance;
        return {
            externalAttainedStudyId: externalAttainedStudy.id,
            organisation: this.mapElmoLocalizedStringValue(externalIssuer?.title),
            attainmentDate: loi?.date ? loi.date : null,
            grade: loi?.resultLabel ? loi.resultLabel : null,
            code: externalAttainedStudy?.learningOpportunitySpecification?.iscedCode ? externalAttainedStudy.learningOpportunitySpecification.iscedCode : null,
            gradeScale: this.mapElmoLocalizedStringValue(loi?.gradingScheme),
            credits: this.mapElmoCredits(loi?.credit),
            description: this.mapElmoLocalizedStringValue(externalAttainedStudy?.learningOpportunitySpecification?.description),
            name: this.mapElmoLocalizedStringValue(externalAttainedStudy?.learningOpportunitySpecification?.title),
            attainmentLanguage: loi?.languageOfInstruction ? loi.languageOfInstruction : null,
        } as Partial<PriorStudies>;
    }

    private mapElmoLocalizedStringValue = (localizedString: LocalizedString) => {
        if (!localizedString) return null;
        let stringValue = localizedString[this.localeService.getCurrentLanguage()];
        if (!stringValue) {
            const primaryLanguages = this.localeService.getOfficialLanguages().filter(item => item !== this.localeService.getCurrentLanguage());
            stringValue = find(primaryLanguages, key => has(primaryLanguages, key) && localizedString[key] !== null && localizedString[key] !== undefined);
            if (!stringValue) stringValue = find(values(localizedString), val => val !== null && val !== undefined);
        }
        return stringValue;
    };

    private mapElmoCredits = (credits: ELMOCredit[]) => {
        if (!credits || credits.length === 0) return null;
        const concatenatedCredits = credits
            .map(credit => this.formatElmoCredit(credit))
            .join(', ');
        return concatenatedCredits;
    };

    private formatElmoCredit = (elmoCredit: ELMOCredit) => {
        if (elmoCredit.scheme) return `${elmoCredit.value} (${elmoCredit.scheme})`;
        return `${elmoCredit.value}`;
    };

}

