<ng-container *transloco="let t">
  <div>
    <div class="modal-header">
      <h2 class="modal-title" tabindex="0">{{t('MODULE_CONTENT_APPROVAL_MODAL.' + approvalRequestType + '.MODAL_TITLE')}}</h2>
      <button type="button"
              class="modal-close"
              (click)="modal.close()"
              [attr.aria-label]="('ARIA_LABEL.CLOSE')">
        <sis-icon icon="ring-close"></sis-icon>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12" data-cy="mca-app-description">
          <p class="guidance">{{ moduleContentApplicationDescriptionText | localizedString }}</p>
        </div>
      </div>
      <div class="row" *ngIf="moduleContentApplicationDescriptionUrl | localizedString">
        <div class="col-12" data-cy="mca-app-description-link">
          <a fudisLink [title]="(moduleContentApplicationDescriptionUrl | localizedString)" [href]="(moduleContentApplicationDescriptionUrl | localizedString)" [external]="true"></a>
        </div>
      </div>
      <div class="row top-spacing">
        <div class="col-12 situation-description">
          <p *ngIf="parentModule.type !== 'GroupingModule'">
            <transloco
              key="{{'MODULE_CONTENT_APPROVAL_MODAL.' + approvalRequestType + '.SITUATION_DESCRIPTION'}}"
              [params]="{modulename: parentModule.name | localizedString}"
            ></transloco>
          </p>
          <p *ngIf="parentModule.type === 'GroupingModule'">
            <transloco
              key="{{'MODULE_CONTENT_APPROVAL_MODAL.' + approvalRequestType + '.SITUATION_DESCRIPTION_FOR_GROUPING_MODULE'}}"
              [params]="{modulename: parentModule.name | localizedString}"
            ></transloco>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sis-module-content-workflow-structure
          [parentModule]="parentModule"
          [selectedCourseUnits]="selectedCourseUnits"
          [selectedModules]="selectedModules"
          [selectedCustomCourseUnitAttainments]="selectedCustomCourseUnitAttainments"
          [selectedCustomModuleAttainments]="selectedCustomModuleAttainments"
          [customStudyDrafts]="customStudyDrafts"
          [attainments]="attainments">
          </sis-module-content-workflow-structure>
          <sis-custom-study-draft-summary *ngFor="let customStudyDraft of customStudyDrafts; trackBy: entityId"
            [customStudyDraft]="customStudyDraft">
          </sis-custom-study-draft-summary>
        </div>
      </div>
      <div class="module-content-application-comment">
        <div class="row">
          <div class="form-group col-12">
            <sis-text-field [name]="'comment'"
                            [control]="moduleContentCommentFormControl"
                            [textarea]="true"
                            [id]="'module-content-application-comment'"
                            [label]="t('MODULE_CONTENT_APPROVAL_MODAL.' + approvalRequestType + '.APPLICATION_RATIONALE')"
                            data-cy="module-content-application-rationale-textarea">
            </sis-text-field>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <sis-button [size]="'lg'"
                  [hollow]="true"
                  (clicked)="dismiss()">{{t('CANCEL')}}
      </sis-button>
      <sis-button
        [dataCy]="'module-content-application-submit'"
        [size]="'lg'"
        (clicked)="sendApplication()">{{t('MODULE_CONTENT_APPROVAL_MODAL.' + approvalRequestType + '.SEND_APPLICATION')}}
      </sis-button>
    </div>
  </div>
</ng-container>
