import { Injectable } from '@angular/core';
import { AssessmentItem, CourseUnitRealisation, OtmId } from 'common-typescript/types';

import { AssessmentItemTeachingComponent } from './assessment-item-teaching.component';

/**
 * Used for handling the one-time initial focus in {@link AssessmentItemTeachingComponent}.
 * Initial focus will be handled once per service instance,
 * so it must be provided in a context, where initial focus needs to be done only once.
 */
@Injectable()
export class AssessmentItemTeachingInitialFocusService {
    private _initialFocusData: AssessmentItemTeachingInitialFocusData | null = null;
    private _isInitialFocusHandled: boolean = false;

    /**
     * Returns the initial focus data,
     * or null if not (yet) provided.
     */
    get initialFocusData(): AssessmentItemTeachingInitialFocusData | null {
        return this._initialFocusData;
    }

    /**
     * Sets {@link initialFocusData},
     * unless it's already been set (as not-null)
     * or {@link markInitialFocusAsHandled} has already been called.
     */
    set initialFocusData(value: AssessmentItemTeachingInitialFocusData | null) {
        if (!!this._initialFocusData || this._isInitialFocusHandled) {
            return;
        }

        this._initialFocusData = value;
    }

    /**
     * Tells if {@link markInitialFocusAsHandled} has been called on this service instance.
     */
    get isInitialFocusHandled(): boolean {
        return this._isInitialFocusHandled;
    }

    /**
     * Sets {@link isInitialFocusHandled} as true.
     */
    markInitialFocusAsHandled(): void {
        this._isInitialFocusHandled = true;
    }
}

/**
 * Holds the IDs to identify the initially focused {@link CourseUnitRealisation} under an {@link AssessmentItem}.
 */
export interface AssessmentItemTeachingInitialFocusData {
    readonly focusedAssessmentItemId: OtmId;
    readonly focusedCourseUnitRealisationId: OtmId;
}
