<div class="container row" *ngIf="ready">
  <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8" *transloco="let t">
    <sis-contextual-notification *ngIf="!studyRightExists" [icon]="'info-circle'" variant="warning">
      {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.STUDY_RIGHT_NOT_FOUND') }}
    </sis-contextual-notification>

    <div *ngIf="studyRightExists">
      <sis-contextual-notification *ngIf="applicationExists" [icon]="'info-circle'" variant="warning">
        <transloco key="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.APPLICATION_EXISTS"
                   [params]="{degreeProgrammeName: degreeProgramme.name | localizedString}"/>
        <a href="javascript:void(0);" (click)="navigateToExistingApplication()">
          {{ 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.NAVIGATE_TO_EXISTING_APPLICATION' | transloco }}
        </a>
      </sis-contextual-notification>

      <sis-contextual-notification *ngIf="attainmentExists" [icon]="'info-circle'" variant="warning">
        <transloco key="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ATTAINMENT_EXISTS"
                   [params]="{degreeProgrammeName: degreeProgramme.name | localizedString}"/>
      </sis-contextual-notification>

      <sis-contextual-notification *ngIf="!primaryPlan" [icon]="'info-circle'" variant="warning">
        <transloco key="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PRIMARY_PLAN_INVALID_FOR_SENDING"
                   [params]="{degreeProgrammeName: degreeProgramme.name | localizedString}"/>
      </sis-contextual-notification>

      <sis-contextual-notification *ngIf="!planStateValidForSending" [icon]="'info-circle'" variant="warning">
        <transloco key="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PLAN_STATE_INVALID_FOR_SENDING"
                   [params]="{degreeProgrammeName: degreeProgramme.name | localizedString, planState: t('PLAN.STATE.' + planState)}"/>
      </sis-contextual-notification>

      <div *ngIf="!applicationExists && !attainmentExists && primaryPlan && planStateValidForSending" class="sis-mt-md">
        <fudis-heading [level]="2" [variant]="'lg'">
          {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.TITLE') }}
        </fudis-heading>
        <sis-person-info-for-graduation [person]="profile"/>
        <ng-container *ngIf="!editMode">
          <div class="row">
            <div class="col-10">
              <fudis-heading [level]="2" [variant]="'lg'">
                {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.TITLE') }}
              </fudis-heading>
            </div>
            <div class="col-2">
              <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.EDIT')" (handleClick)="edit()"/>
            </div>
          </div>
          <sis-contact-info-for-graduation [person]="profile" [level]="3"/>
        </ng-container>
        <app-edit-contact-info-for-graduation *ngIf="editMode"
                                              [person]="profile"
                                              (cancelEdit)="cancelEdit()"
                                              (saveEdit)="saveEdit(profile)"/>

        <form [formGroup]="form">
          <fudis-heading [level]="2" [variant]="'lg'">
            {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.TITLE') }}
          </fudis-heading>
          <div class="form-group" *ngFor="let question of questions">
            <sis-text-field
              [id]="question.localId"
              [helpBlockHTML]="question.guidance | localizedString"
              [label]="question.question | localizedString"
              [control]="getQuestionControl(question.localId)"
              [textarea]="true"/>
          </div>
          <div class="form-group">
            <sis-text-field
              id="additional-info-input"
              [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.ADDITIONAL_INFO_LABEL')"
              [control]="additionalInfo"
              [helpBlockHTML]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.OTHER_INFO.ADDITIONAL_INFO_HELP_BLOCK')"
              [textarea]="true"/>
          </div>

          <div class="checkbox-group delivery" [class.form-group]="degreeDeliveryMethod.value !== 'MAIL'"
               *ngIf="!digitalDegreeCertificatePreferred">
            <fudis-heading [level]="2" [variant]="'lg'">
              {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.TITLE') }}
            </fudis-heading>
            <transloco key="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.TEXT"/>
            <p>
              {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.TEXT_NOTIFICATION_IN_SISU') }}
            </p>
            <label class="required">
              {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.LABEL_TITLE') }}
            </label>
            <sis-radio-button
              name="degreeDeliveryMethod"
              (click)="degreeDeliveryMethodChanged('PICK_UP')"
              [control]="degreeDeliveryMethod"
              [translatedLabel]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.PICK_UP')"
              [value]="'PICK_UP'"/>
            <sis-radio-button
              (click)="degreeDeliveryMethodChanged('MAIL')"
              name="degreeDeliveryMethod"
              [disabled]="!profile.primaryAddress"
              [control]="degreeDeliveryMethod"
              [translatedLabel]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.MAIL')"
              [value]="'MAIL'"/>
          </div>

          <div *ngIf="degreeDeliveryMethod.value === 'MAIL'" class="sis-mb-md">
            <div class="delivery-by-mail sis-pt-sm">
              <p class="sis-guidance">
                {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY.BY_MAIL_GUIDANCE') }}
              </p>
              <div class="checkbox-group">
                <sis-radio-button
                  name="deliveryAddress"
                  [control]="deliveryAddress"
                  [value]="profile.primaryAddress"
                  [translatedLabel]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.PRIMARY_ADDRESS')"/>
                <span>{{ profile.primaryAddress.streetAddress }}</span>
                <span>{{ profile.primaryAddress.postalCode }}</span>
                <span>{{ profile.primaryAddress.city }}</span>
                <span>{{ profile.primaryAddress.countryUrn | codeLocalizedName | async }}</span>
                <div *ngIf="profile.secondaryAddress" class="secondary-address">
                  <sis-radio-button
                    name="deliveryAddress"
                    [control]="deliveryAddress"
                    [value]="profile.secondaryAddress"
                    [translatedLabel]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_ADDRESS')"/>
                  <ng-container *ngIf="isSecondaryAddressInFinland()">
                    <span>{{ profile.secondaryAddress.streetAddress }}</span>
                    <span>{{ profile.secondaryAddress.postalCode }}</span>
                    <span>{{ profile.secondaryAddress.city }}</span>
                  </ng-container>
                  <span *ngIf="!isSecondaryAddressInFinland()" class="formatted-address">
                    {{ profile.secondaryAddress.address }}
                  </span>
                  <span>{{ profile.secondaryAddress.countryUrn | codeLocalizedName | async }}</span>
                </div>
              </div>
            </div>
            <sis-contextual-notification [icon]="'info-circle'" variant="warning">
              {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.DEGREE_DELIVERY_MAIL_NOTIFICATION') }}
            </sis-contextual-notification>
          </div>

          <div class="form-group" *ngIf="showAlumniAssociationInfo">
            <fudis-heading [level]="2" [variant]="'lg'">
              {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.TITLE') }}
            </fudis-heading>
            <div class="sis-mb-sm">
              <p>{{ getAlumniAssociationDescription() }}</p>
              <a fudisLink [title]="getAlumniAssociationUrl()" [href]="getAlumniAssociationUrl()" [external]="true"></a>
            </div>
            <sis-checkbox
              class="sis-mb-sm"
              name="alumni-association-checkbox"
              [checked]="this.application.joinsAlumniAssociation"
              (checkedChange)="toggleJoinsAlumniAssociation()"
              [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.CHECKBOX')"/>
            <p class="sis-guidance">
              {{ getAlumniAssociationInfoText()  || t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.ALUMNI_ASSOCIATION.INFO_TEXT_PLACEHOLDER')}}
            </p>
          </div>
        </form>

        <div class="button-row">
          <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.EXIT')" [icon]="'back'"
                        (handleClick)="exitApplication()" [variant]="'secondary'"/>
          <fudis-button [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.SEND')"
                        (handleClick)="send()"/>
        </div>
      </div>
    </div>
  </div>
</div>
