import { Inject, Injectable } from '@angular/core';
import { ValidatablePlan } from 'common-typescript/src/plan/validation/validatablePlan';
import {
    Attainment,
    AttainmentType,
    CourseUnit,
    CourseUnitAttainment,
    CustomCourseUnitAttainment,
    CustomModuleAttainment,
    Module,
    ModuleAttainment,
    OtmId,
    Plan,
    StudyRight,
} from 'common-typescript/types';
import _ from 'lodash';

import { isAttached } from '../attainment/AttainmentUtil';

@Injectable({ providedIn: 'root' })
export class RawPlanEditService {

    isCourseUnitInPlanByGroupId(courseUnitGroupId: OtmId, validatablePlan: ValidatablePlan): boolean {
        const courseUnitsInPlanMap = _.filter(_.values(validatablePlan.courseUnitsById), { groupId: courseUnitGroupId });
        if (_.isEmpty(courseUnitsInPlanMap)) {
            return false;
        }
        const courseUnitInPlan = _.find(courseUnitsInPlanMap, courseUnit =>
            validatablePlan.isCourseUnitInPlan(courseUnit) || validatablePlan.isCourseUnitInPlanAsSubstitute(courseUnit));
        return !!courseUnitInPlan;
    }

    isCustomCourseUnitAttainmentInPlan(id: OtmId, validatablePlan: ValidatablePlan): boolean {
        const customCourseUnitAttainment = _.get(validatablePlan.customCourseUnitAttainmentsById, id);
        if (customCourseUnitAttainment) {
            return validatablePlan.isCustomCourseUnitAttainmentInPlan(customCourseUnitAttainment);
        }
        return false;
    }

    isModuleInPlanByGroupId(moduleGroupId: OtmId, validatablePlan: ValidatablePlan): boolean {
        const modulesInPlanMap = _.filter(_.values(validatablePlan.modulesById), { groupId: moduleGroupId });
        if (_.isEmpty(modulesInPlanMap)) {
            return false;
        }

        const moduleInPlan = _.find(modulesInPlanMap, module => validatablePlan.isModuleInPlan(module.id));
        return !!moduleInPlan;
    }

    getSelectableAttainedCourseUnits(allUnattachedAttainments: Attainment[], validatablePlan: ValidatablePlan): CourseUnit[] {
        const selectableAttainments = _.chain(allUnattachedAttainments)
            .filter(attainment => attainment.type === AttainmentType.COURSE_UNIT_ATTAINMENT)
            .map(attainment => attainment as CourseUnitAttainment)
            .filter(cuAttainment => !this.isCourseUnitInPlanByGroupId(cuAttainment.courseUnitGroupId, validatablePlan))
            .value();
        return _.map(selectableAttainments, courseUnitAttainment =>
            _.get(validatablePlan.courseUnitsById, courseUnitAttainment.courseUnitId));
    }

    getSelectableCustomCourseUnitAttainments(
        allUnattachedAttainments: Attainment[],
        validatablePlan: ValidatablePlan,
        matchingStudyRight: StudyRight,
    ): CustomCourseUnitAttainment[] {

        const studyRightId = _.get(matchingStudyRight, 'id');

        return _.filter(allUnattachedAttainments, (attainment) => attainment.type === 'CustomCourseUnitAttainment' &&
                !this.isCustomCourseUnitAttainmentInPlan(attainment.id, validatablePlan) &&
                // Consider attainments without studyRightId as not bound to any study right and include them
                (!attainment.studyRightId || attainment.studyRightId === studyRightId)) as CustomCourseUnitAttainment[];
    }

    getSelectableAttainedModules(allUnattachedAttainments: Attainment[], validatablePlan: ValidatablePlan): Module[] {
        const selectableAttainments = _.chain(allUnattachedAttainments)
            .filter(attainment =>
                _.includes([AttainmentType.MODULE_ATTAINMENT, AttainmentType.DEGREE_PROGRAMME_ATTAINMENT], attainment.type))
            .map(attainment => attainment as ModuleAttainment)
            .filter(moduleAttainment => !this.isModuleInPlanByGroupId(moduleAttainment.moduleGroupId, validatablePlan))
            .value();
        return _.map(selectableAttainments, moduleAttainment => _.get(validatablePlan.modulesById, moduleAttainment.moduleId) as Module);
    }

    getSelectableCustomModuleAttainments(
        allUnattachedAttainments: Attainment[],
        validatablePlan: ValidatablePlan,
        matchingStudyRight: StudyRight,
    ): CustomModuleAttainment[] {

        const studyRightId = _.get(matchingStudyRight, 'id');
        const selectableAttainments = _.filter(allUnattachedAttainments, attainment =>
            attainment.type === 'CustomModuleAttainment' &&
            !validatablePlan.isModuleAttainmentInPlan(attainment.id) &&
            (!attainment.studyRightId || attainment.studyRightId === studyRightId),
        ) as CustomModuleAttainment[];

        return selectableAttainments;
    }

    getAllUnattachedAttainments(allAttainments: Attainment[]): Attainment[] {
        return _.filter(allAttainments, attainment =>
            !isAttached(attainment, allAttainments));
    }

    selectCourseUnit(courseUnitId: OtmId, parentModuleId: OtmId, rawPlan: Plan): Plan {
        const existingSelection = _.find(rawPlan.courseUnitSelections, { courseUnitId });
        if (existingSelection) {
            return rawPlan;
        }
        rawPlan.courseUnitSelections.push({
            courseUnitId,
            parentModuleId,
            plannedPeriods: [],
            completionMethodId: null,
            substituteFor: [],
            substitutedBy: [],
            gradeRaiseAttempt: null,
        });
        return rawPlan;
    }

    removeCourseUnit(courseUnitId: OtmId, rawPlan: Plan): Plan {
        _.remove(rawPlan.courseUnitSelections, { courseUnitId });
        const assessmentItemSelections = _.get(rawPlan, 'assessmentItemSelections');
        if (assessmentItemSelections) {
            _.remove(assessmentItemSelections, { courseUnitId });
        }
        return rawPlan;
    }

    selectModule(moduleId: OtmId, parentModuleId: OtmId, rawPlan: Plan): Plan {
        const existingSelection = _.find(rawPlan.moduleSelections, { moduleId });
        if (existingSelection) {
            return rawPlan;
        }
        rawPlan.moduleSelections.push({
            moduleId,
            parentModuleId,
        });
        return rawPlan;
    }

    removeModuleRecursively(moduleId: OtmId, rawPlan: Plan): Plan {
        const childModuleIds = this.getModuleIdsByParentModuleId(moduleId, rawPlan);
        _.forEach(childModuleIds, childModuleId => this.removeModuleRecursively(childModuleId, rawPlan));

        const childCourseUnitIds = this.getCourseUnitIdsByParentModuleId(moduleId, rawPlan);
        _.forEach(childCourseUnitIds, cuId => this.removeCourseUnit(cuId, rawPlan));

        _.remove(rawPlan.customCourseUnitAttainmentSelections, { parentModuleId: moduleId });
        _.remove(rawPlan.customModuleAttainmentSelections, { parentModuleId: moduleId });
        _.remove(rawPlan.customStudyDrafts, { parentModuleId: moduleId });

        _.remove(rawPlan.moduleSelections, { moduleId });

        return rawPlan;
    }

    selectCustomCourseUnitAttainment(customCourseUnitAttainmentId: OtmId, parentModuleId: OtmId, rawPlan: Plan): Plan {
        const existingSelection = _.find(rawPlan.customCourseUnitAttainmentSelections, { customCourseUnitAttainmentId });
        if (existingSelection) {
            return rawPlan;
        }
        rawPlan.customCourseUnitAttainmentSelections.push({
            customCourseUnitAttainmentId,
            parentModuleId,
        });
        return rawPlan;
    }

    selectCustomModuleAttainment(customModuleAttainmentId: OtmId, parentModuleId: OtmId, rawPlan: Plan): Plan {
        const existingSelection = _.find(rawPlan.customModuleAttainmentSelections, { customModuleAttainmentId });
        if (existingSelection) {
            return rawPlan;
        }
        rawPlan.customModuleAttainmentSelections.push({
            customModuleAttainmentId,
            parentModuleId,
        });
        return rawPlan;
    }

    removeCustomCourseUnitAttainment(customCourseUnitAttainmentId: OtmId, rawPlan: Plan): Plan {
        _.remove(rawPlan.customCourseUnitAttainmentSelections, { customCourseUnitAttainmentId });
        return rawPlan;
    }

    removeCustomModuleAttainment(customModuleAttainmentId: OtmId, rawPlan: Plan): Plan {
        _.remove(rawPlan.customModuleAttainmentSelections, { customModuleAttainmentId });
        return rawPlan;
    }

    getCourseUnitIdsByParentModuleId(parentModuleId: OtmId, rawPlan: Plan): OtmId[] {
        const courseUnitSelections = _.get(rawPlan, 'courseUnitSelections') || [];
        return _.chain(courseUnitSelections)
            .filter(cuSelection => cuSelection.parentModuleId === parentModuleId)
            .map(cuSelection => cuSelection.courseUnitId)
            .value();
    }

    getModuleIdsByParentModuleId(parentModuleId: OtmId, rawPlan: Plan): OtmId[] {
        const moduleSelections = _.get(rawPlan, 'moduleSelections') || [];
        return _.chain(moduleSelections)
            .filter(moduleSelection => moduleSelection.parentModuleId === parentModuleId)
            .map(moduleSelection => moduleSelection.moduleId)
            .value();
    }

    findAttainedVersionIdForCourseUnit(courseUnitGroupId: OtmId, allAttainments: Attainment[]): OtmId {
        const courseUnitAttainment = _.find(allAttainments, attainment =>
            attainment.type === AttainmentType.COURSE_UNIT_ATTAINMENT &&
            _.get(attainment, 'courseUnitGroupId') === courseUnitGroupId) as CourseUnitAttainment;
        return courseUnitAttainment ? courseUnitAttainment.courseUnitId : undefined;
    }

    findAttainedVersionIdForModule(moduleGroupId: OtmId, allAttainments: Attainment[]): OtmId {
        const moduleAttainment = _.find(allAttainments, attainment =>
            _.includes([AttainmentType.MODULE_ATTAINMENT, AttainmentType.DEGREE_PROGRAMME_ATTAINMENT], attainment.type) &&
            _.get(attainment, 'moduleGroupId') === moduleGroupId);
        return moduleAttainment ? _.get(moduleAttainment, 'moduleId') : undefined;
    }

}
