import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

type ID = string | number;

export interface RadiobuttonTreeNode<T> {
    label: string;
    /** The unique id of the node. Must be set **even for unselectable nodes** (it is used e.g. as a tracking id). */
    id: ID;
    /** The actual value of the node. This will be emitted when the node is selected. */
    value: T;
    children?: RadiobuttonTreeNode<T>[];
    /** If true, the node can't be selected. All nodes are selectable by default. */
    unselectable?: boolean;
}

@Component({
    selector: 'sis-radiobutton-tree',
    templateUrl: './radiobutton-tree.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadiobuttonTreeComponent<T> {

    @Input({ required: true }) rootNode: RadiobuttonTreeNode<T>;

    @Input()
    set selected(value: T) {
        this.control.setValue(value, { emitEvent: false });
    }

    @Output() selectedChange = new EventEmitter<T>();

    readonly control = new FormControl<T>(null);
    readonly collapsedStates = new Map<ID, boolean>();

    constructor() {
        this.control.valueChanges
            .pipe(takeUntilDestroyed())
            .subscribe(value => this.selectedChange.emit(value));
    }

    isCollapsed(id: ID) {
        return !!this.collapsedStates.get(id);
    }

    toggleCollapsed(id: ID) {
        this.collapsedStates.set(id, !this.collapsedStates.get(id));
    }
}
