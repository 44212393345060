import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItem, CourseUnitRealisation, CourseUnitRealisationSeats, OtmId } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { trackByEntityId } from 'sis-components/util/utils';

import { AssessmentItemTeachingInitialFocusService } from './assessment-item-teaching-initial-focus.service';
import { CourseUnitRealisationExpansionStatusService } from './course-unit-realisation-expansion-status.service';

/**
 * Expects a provision of {@link AssessmentItemTeachingInitialFocusService} from a DOM ancestor.
 */
@Component({
    selector: 'app-assessment-item-teaching',
    templateUrl: './assessment-item-teaching.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [CourseUnitRealisationExpansionStatusService],
})
export class AssessmentItemTeachingComponent implements AfterViewChecked {

    @Input() assessmentItem: AssessmentItem;
    @Input() courseUnitRealisations: CourseUnitRealisation[];
    @Input() seatCounts?: CourseUnitRealisationSeats[];
    @Input() hideGuidance?: boolean;

    readonly entityId = trackByEntityId;

    constructor(
        private readonly _changeDetectorRef: ChangeDetectorRef,
        private readonly _expansionStatusService: CourseUnitRealisationExpansionStatusService,
        private readonly _initialFocusService: AssessmentItemTeachingInitialFocusService,
        @Inject(DOCUMENT) private readonly _document: Document,
    ) {}

    isClosed(courseUnitRealisation: CourseUnitRealisation): Observable<boolean> {
        return this._expansionStatusService.isClosed(courseUnitRealisation.id);
    }

    closedChange(courseUnitRealisation: CourseUnitRealisation, closed: boolean): void {
        this._expansionStatusService.setClosedStatus(courseUnitRealisation.id, closed);
        this._changeDetectorRef.detectChanges();
    }

    curIsFull(cur: CourseUnitRealisation) {
        const curSeatCount = this.seatCounts?.length && this.seatCounts.find(seatCount => seatCount.curId === cur.id);
        return curSeatCount?.availableSeats === 0;
    }

    getCourseUnitRealisationElementId(cur: CourseUnitRealisation): string {
        return cur.id;
    }

    ngAfterViewChecked(): void {
        if (this._initialFocusService.isInitialFocusHandled
            || !this._initialFocusService.initialFocusData
            || !this.courseUnitRealisations
            || !this.assessmentItem
            || this._initialFocusService.initialFocusData.focusedAssessmentItemId !== this.assessmentItem.id) {
            return;
        }

        const initiallyFocusedCourseUnitRealisationId: OtmId = this._initialFocusService.initialFocusData.focusedCourseUnitRealisationId;
        const initiallyFocusedCourseUnitRealisation: CourseUnitRealisation | undefined = this.courseUnitRealisations
            .find(cur => cur.id === initiallyFocusedCourseUnitRealisationId);
        if (!initiallyFocusedCourseUnitRealisation) {
            return;
        }
        const elementId: string = this.getCourseUnitRealisationElementId(initiallyFocusedCourseUnitRealisation);

        const element: HTMLElement | null = this._document.getElementById(elementId);
        if (!element) {
            return;
        }

        // Already mark it as done, but we shouldn't make changes affecting the view in the same call stack,
        // so let's do them in the next one.
        this._initialFocusService.markInitialFocusAsHandled();
        setTimeout(() => {
            this._expansionStatusService.setClosedStatus(initiallyFocusedCourseUnitRealisationId, false);

            // let's add a small delay before scroll in case of competing focuses (there actually are some)
            setTimeout(() => {
                element.focus({ preventScroll: true });
                element.scrollIntoView({ behavior: 'smooth' });
            }, 100);
        });
    }
}
