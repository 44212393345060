import { Injectable } from '@angular/core';
import { Attainment } from 'common-typescript/types';
import { BehaviorSubject, Observable, shareReplay, switchMap } from 'rxjs';
import { StudentContextAttainmentService } from 'sis-components/service/student-context-attainment.service';

import { AttainmentStudentService } from './attainment-student.service';

@Injectable({ providedIn: 'root' })
export class MyAttainmentService extends StudentContextAttainmentService {
    /**
     * Emits all attainments of the logged-in student.
     */
    readonly allAttainments$: Observable<readonly Attainment[]>;

    /**
     * Emits those from {@link validAttainments$} (in original order) that
     * a) are themselves valid
     * or
     * b) are child/descendant attainments of other valid {@link validAttainments$}.
     */
    readonly validAttainments$: Observable<readonly Attainment[]>;

    constructor(
        attainmentStudentService: AttainmentStudentService,
    ) {
        super();

        // The subject is just a trick to prevent the result from being cached forever.
        // It also delays running any actual logic before the first subscription.
        this.allAttainments$ = new BehaviorSubject(undefined).pipe(
            switchMap(() => attainmentStudentService.getMyUnfilteredAttainments()),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        this.validAttainments$ = new BehaviorSubject(undefined).pipe(
            switchMap(() => attainmentStudentService.getMyValidAttainments()),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }
}
