<div id="student-application" class="container student-application prior-learning-inclusion-application">
  <div class="row">
    <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
      <div class="form-group-mimic">
        <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep"></sis-wizard-progress>
      </div>

      <app-custom-attainment-basic-info-edit [hidden]="!isFirstStep()"
                                             [planId]="plan?.id"
                                             [customStudyDraft]="customStudyDraft"
                                             (exit)="exit()"
                                             (continue)="continue($event)">
      </app-custom-attainment-basic-info-edit>
      <app-prior-learning-and-attachments-edit [hidden]="currentStep !== 1"
                                               type="INCLUDE"
                                               [customStudyDraft]="customStudyDraft"
                                               (attachmentsChange)="onAttachmentsChange($event)"
                                               (selectedExternalStudyIdsChange)="onExternalAttachmentsChange($event)"
                                               (exit)="exit()"
                                               (previous)="previous()"
                                               (continue)="continue($event)">
      </app-prior-learning-and-attachments-edit>
      <app-prior-learning-application-confirmation [hidden]="!isLastStep()"
                                                   [application]="workflowApplication"
                                                   [attachments]="attachments"
                                                   [externalAttainedStudyIds$]="externalAttainedStudyIds$"
                                                   [disableButtons]="uploading"
                                                   (exit)="exit()"
                                                   (previous)="previous()"
                                                   (submit)="submit($event)">
      </app-prior-learning-application-confirmation>
    </div>
  </div>
</div>
