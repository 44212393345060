import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { LocalizedString } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { LanguageChangeService } from '../../l10n/language-change.service';

interface LanguageSelection {
    language: string;
    name: LocalizedString | string;
}

@Component({
    selector: 'sis-language-selection-menu',
    templateUrl: './language-selection-menu.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LanguageSelectionMenuComponent implements OnInit {

    languageSelections: LanguageSelection[] = [];

    constructor(private localeService: LocaleService,
                private languageChangeService: LanguageChangeService,
    ) { }

    /** View child for the component's wrapper div */
    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    ngOnInit() {
        this.initLanguageSelections();
    }

    get currentLanguage(): string {
        return this.localeService.getCurrentLanguage();
    }

    get currentLanguageName(): string {
        return this.languageName(this.currentLanguage);
    }

    changeLanguage(language: string): void {
        this.languageChangeService.use(language);
    }

    /** Close language selection menu when next targeted element is not inside the menu */
    blurMenu(event: any) {
        if (this.dropdown?.isOpen() && !document.querySelector('sis-language-selection-menu').contains(event.relatedTarget)) {
            this.dropdown.close();
        }
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.dropdown.isOpen() && event.code !== 'Tab') {
            const listItems = this.getDropdownItems() as HTMLInputElement[];
            if (listItems.length > 0) { listItems[0].focus(); }
        }
    }

    private initLanguageSelections(): void {
        this.localeService.getLocalizedLanguageNames()
            .subscribe((languageNames) => {
                this.languageSelections = this.localeService.getOfficialLanguages()
                    .map(language => ({ language, name: languageNames[language] }));

                // Languages are hardcoded in language menu since language options should not be translated
                this.languageSelections.forEach((value) => {
                    value.name = this.languageName(value.language);
                });
            });
    }

    private languageName(language: string): string {
        if (language === 'fi') return 'Suomeksi (FI)';
        if (language === 'sv') return 'På svenska (SV)';
        if (language === 'en') return 'In English (EN)';
        return '';
    }

    private getDropdownItems(eventElement?: HTMLInputElement) {
        const dropdownMenu = document.getElementById('sis-language-selection-menu');
        const listItems = dropdownMenu.querySelectorAll('button');
        return Array.prototype.filter.call(listItems, (item: any) => item.tabIndex >= '0');
    }
}
