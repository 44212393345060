<div *ngIf="ready" aria-labelledby="student-profile-study-rights-tab" id="study-rights" role="tabpanel" tabindex="0" class="sis-pt-md">
  <div class="d-sm-flex justify-content-sm-between">
    <h2>{{ 'PROFILE.STUDY_RIGHTS' | translate }}</h2>
    <sis-button [size]="'sm'" [hollow]="true" [variant]="'secondary'" (clicked)="openCreateStudyCertificateModal()" class="sis-mb-sm d-block">
      {{'PROFILE.CREATE_STUDY_CERTIFICATE' | translate}}
    </sis-button>
  </div>
  <h3>{{ 'STUDY_RIGHTS.HEADINGS.DEGREE_RIGHTS' | translate }}</h3>
  <ng-container *ngIf="degreeStudyRights?.length > 0; else noStudyRights">
    <ng-container *ngFor="let degreeStudyRight of degreeStudyRights">
      <ng-template [ngTemplateOutlet]="studyRightExpandable" [ngTemplateOutletContext]="{studyRight: degreeStudyRight}"></ng-template>
    </ng-container>
  </ng-container>

  <h3>{{ 'STUDY_RIGHTS.HEADINGS.OPEN_UNIVERSITY_RIGHTS' | translate }}</h3>
  <ng-container *ngIf="openUniversityStudyRights?.length > 0 || oldOpenUniversityStudyRights?.length > 0; else noStudyRights">
    <app-enrolment-right-student-study-right
      *ngFor="let openUniversityStudyRight of openUniversityStudyRights"
      [studyRight]="openUniversityStudyRight">
    </app-enrolment-right-student-study-right>
    <ng-container *ngFor="let oldOpenUniversityStudyRight of oldOpenUniversityStudyRights">
      <ng-template [ngTemplateOutlet]="studyRightExpandable" [ngTemplateOutletContext]="{studyRight: oldOpenUniversityStudyRight}"></ng-template>
    </ng-container>
  </ng-container>

  <h3>{{ 'STUDY_RIGHTS.HEADINGS.OTHER_RIGHTS' | translate }}</h3>
  <ng-container *ngIf="otherStudyRights?.length > 0 || otherBasedOnEnrolmentRightStudyRights?.length > 0; else noStudyRights">
    <ng-container *ngFor="let otherStudyRight of otherStudyRights">
      <ng-template [ngTemplateOutlet]="studyRightExpandable" [ngTemplateOutletContext]="{studyRight: otherStudyRight}"></ng-template>
    </ng-container>
    <app-enrolment-right-student-study-right
            *ngFor="let otherBasedOnEnrolmentRightStudyRight of otherBasedOnEnrolmentRightStudyRights"
            [studyRight]="otherBasedOnEnrolmentRightStudyRight">
    </app-enrolment-right-student-study-right>
  </ng-container>
</div>

<ng-template #studyRightExpandable let-studyRight="studyRight">
  <sis-expandable *ngIf="studyRight.id|studyRightName|async|localizedString as srName"
                  data-cy="study-right-profile-element" [level]="4" [title]="srName">
    <ng-template sisExpandableCustomTitle>
      <sis-study-right-status-badge [studyRightId]="studyRight.id"/>
    </ng-template>
    <ng-template sisExpandableHeaderButtons>
      <sis-button size="sm" (clicked)="initStudyRightExtensionApplication(studyRight)"
                  *ngIf="studyRightExtensionApplicationEnabledByUniversity && studyRightExpirations[studyRight.id] &&
                  !studyRightExpirations[studyRight.id]?.pendingExtensionApplication">
        {{ 'STUDY_RIGHT_EXPIRATION.EXTENSION_APPLY' | translate }}
      </sis-button>
      <sis-button size="sm" (clicked)="openStudyRightExtensionApplication(studyRight.id)"
                  *ngIf="studyRightExpirations[studyRight.id]?.pendingExtensionApplication">
        {{ 'STUDY_RIGHT_EXPIRATION.EXTENSION_SHOW_APPLICATION' | translate }}
      </sis-button>
    </ng-template>
    <ng-template sisExpandableContent>
      <div class="sis-pb-md" *ngIf="studyRightExpirations[studyRight.id] as studyRightExpiration">
        <sis-contextual-notification variant="warning">
          {{('STUDY_RIGHT_EXPIRATION.' + (studyRightExpiration.isExpired ? 'EXPIRED_INFO' : 'EXPIRING_INFO')) | translate:
          { endDate: (studyRightExpiration.inclusiveEndDate | localDateFormat)}
          }} {{('STUDY_RIGHT_EXPIRATION.EXTENSION_INFO_' + (studyRightExpiration.isExpired ? 'EXPIRED' : 'EXPIRING')) | translate}}
        </sis-contextual-notification>
      </div>
      <sis-read-only-study-right [studyRight]="studyRight"/>
    </ng-template>
  </sis-expandable>
</ng-template>

<ng-template #noStudyRights>
  <p class="sis-guidance">{{ 'STUDY_RIGHTS.NO_STUDY_RIGHTS' | translate }}</p>
</ng-template>
