import angular from 'angular';
import { dateUtils } from 'common-typescript';
import _ from 'lodash';
import moment from 'moment';
import 'sis-common/l10n/localeService';
import 'sis-common/model/courseUnitRealisationName.service';
import { UuidService } from 'sis-common/uuid/uuid.service.ts';
export const courseUnitRealisationServiceModule = 'sis-components.service.courseUnitRealisationService';
(function () {
  courseUnitRealisationService.$inject = ["$http", "$log", "$q", "courseUnitRealisationJSDataModel", "uuidService", "courseUnitRealisationNameService", "courseUnitRealisationUri", "universityService", "localeService", "commonAssessmentItemService", "localizedStringUtils", "jsDataCacheHelper", "SearchByIds", "RESOLVED_FLOWS_STATE"];
  angular.module(courseUnitRealisationServiceModule, ['sis-common.university', 'sis-common.l10n.localeService', 'sis-common.model.courseUnitRealisationNameService', UuidService.downgrade.moduleName, 'sis-components.model.courseUnitRealisation', 'sis-components.search.searchParameterService', 'sis-components.service.assessmentItemService', 'sis-components.service.jsDataCacheHelper']).factory('commonCourseUnitRealisationService', courseUnitRealisationService).constant('courseUnitRealisationUri', {
    searchUri: '/kori/api/course-unit-realisations/search',
    authenticatedSearchUri: '/kori/api/authenticated/course-unit-realisations/search',
    cancelUri: '/kori/api/course-unit-realisations/cancel',
    archiveUri: '/kori/api/course-unit-realisations/archive',
    publishUri: '/kori/api/course-unit-realisations/publish',
    saveStudyGroupSetsUri: '/kori/api/course-unit-realisations/study-group-sets',
    cursForTeacherUri: '/kori/api/course-unit-realisations/teacher',
    findByAssessmentItemIdUri: '/kori/api/course-unit-realisations-by-assessment-item-id',
    findByCourseUnitIdAuthenticatedUri: '/kori/api/authenticated/course-unit-realisations-by-course-unit-id',
    findByMassExamSessionIdUri: '/kori/api/course-unit-realisations-by-mass-exam-session-id',
    findActiveCourseUnitRealisations: '/kori/api/course-unit-realisations/published'
  });

  /**
   * @ngInject
   */
  function courseUnitRealisationService($http, $log, $q, courseUnitRealisationJSDataModel, uuidService, courseUnitRealisationNameService, courseUnitRealisationUri, universityService, localeService, commonAssessmentItemService, localizedStringUtils, jsDataCacheHelper, SearchByIds, RESOLVED_FLOWS_STATE) {
    let clearFindAll = false;
    function findCourseUnitRealisationsForCurrentUser(queryParams, options) {
      function isActivityStatusValid(status) {
        switch (status) {
          case 'ALL':
          case 'PAST':
          case 'ONGOING':
          case 'FUTURE':
            return true;
          default:
            return false;
        }
      }
      const activityStatus = _.get(queryParams, 'activityStatus');
      if (!_.isString(activityStatus) || !isActivityStatusValid(activityStatus)) {
        throw new Error(`Invalid activity status : ${activityStatus}`);
      }
      options.endpoint = courseUnitRealisationUri.cursForTeacherUri;
      return courseUnitRealisationJSDataModel.findAll(queryParams, options);
    }
    var api = {
      findActiveCourseUnitRealisationsForAssessmentItemIds(assessmentItemIds, bypassCache) {
        if (_.isEmpty(assessmentItemIds)) {
          return $q.when([]);
        }
        const searchParams = new SearchByIds(courseUnitRealisationJSDataModel);
        searchParams.endpoint = courseUnitRealisationUri.findActiveCourseUnitRealisations;
        searchParams.ids = assessmentItemIds;
        searchParams.clear = !!bypassCache;
        searchParams.idRequestParameterName = 'assessmentItemId';
        return jsDataCacheHelper.searchByIds(searchParams);
      },
      revert(courseUnitRealisation) {
        courseUnitRealisationJSDataModel.revert(courseUnitRealisation.id);
        return courseUnitRealisation.loadRelations();
      },
      create(courseUnitRealisation) {
        return courseUnitRealisationJSDataModel.create(courseUnitRealisation).then(_courseUnitRealisation => {
          clearFindAll = true;
          return _courseUnitRealisation;
        });
      },
      save(courseUnitRealisation, options) {
        options = options || {};
        return courseUnitRealisationJSDataModel.save(courseUnitRealisation, options).then(_courseUnitRealisation => {
          clearFindAll = true;
          return _courseUnitRealisation.loadRelations();
        });
      },
      saveStudyGroupSets(courseUnitRealisation) {
        return api.save(courseUnitRealisation, {
          endpoint: courseUnitRealisationUri.saveStudyGroupSetsUri
        });
      },
      publish(courseUnitRealisation) {
        return courseUnitRealisationJSDataModel.save(courseUnitRealisation, {
          endpoint: courseUnitRealisationUri.publishUri,
          params: {
            publishDate: courseUnitRealisation.publishDate
          }
        });
      },
      cancel(courseUnitRealisation) {
        return courseUnitRealisationJSDataModel.save(courseUnitRealisation, {
          endpoint: courseUnitRealisationUri.cancelUri
        });
      },
      archive(courseUnitRealisation) {
        return courseUnitRealisationJSDataModel.save(courseUnitRealisation, {
          endpoint: courseUnitRealisationUri.archiveUri
        });
      },
      delete(courseUnitRealisation) {
        return courseUnitRealisationJSDataModel.destroy(courseUnitRealisation);
      },
      courseUnitRealisationTemplate() {
        const UUID = uuidService.randomOtmId();
        const cur = {
          id: UUID,
          name: {}
        };
        cur.documentState = 'DRAFT';
        cur.targetCredits = {
          min: 0
        };
        cur.activityPeriod = {};
        cur.code = '';
        cur.studyGroupSets = null;
        cur.responsibilityInfos = null;
        cur.organisations = null;
        cur.literature = null;
        cur.universityOrgIds = [universityService.getCurrentUniversityOrgId()];
        return cur;
      },
      findById(courseUnitRealisationId, bypassCache) {
        return courseUnitRealisationJSDataModel.find(courseUnitRealisationId, {
          bypassCache: !!bypassCache
        });
      },
      findByAssessmentItemId(assessmentItemId, activityPeriod) {
        let activityParams;
        if (activityPeriod) {
          activityParams = activityPeriod.endDate ? `${activityPeriod.startDate},${activityPeriod.endDate}` : `${activityPeriod.startDate}`;
        }
        return courseUnitRealisationJSDataModel.findAll({
          assessmentItemId,
          documentStates: 'DRAFT,ACTIVE',
          activityPeriods: activityParams
        }, {
          bypassCache: true,
          endpoint: courseUnitRealisationUri.findByAssessmentItemIdUri
        });
      },
      findByCourseUnitIdsAuthenticated(courseUnitIds) {
        return courseUnitRealisationJSDataModel.findAll({
          courseUnitId: courseUnitIds
        }, {
          bypassCache: true,
          endpoint: courseUnitRealisationUri.findByCourseUnitIdAuthenticatedUri
        });
      },
      findByMassExamSessionId(massExamSessionId) {
        return courseUnitRealisationJSDataModel.findAll({
          massExamSessionId
        }, {
          bypassCache: true,
          endpoint: `${courseUnitRealisationUri.findByMassExamSessionIdUri}/${massExamSessionId}`
        });
      },
      findByIds(courseUnitRealisationIds) {
        return jsDataCacheHelper.findByIds(courseUnitRealisationJSDataModel, courseUnitRealisationIds);
      },
      findCursForTeacher(activityStatus) {
        return findCourseUnitRealisationsForCurrentUser({
          activityStatus
        }, {
          bypassCache: clearFindAll,
          cacheResponse: true
        }).then(courseUnitRealisations => {
          clearFindAll = false;
          return courseUnitRealisations;
        });
      },
      search(searchParams) {
        return $http.get(courseUnitRealisationUri.searchUri, searchParams.build()).then(result => {
          const {
            data
          } = result;
          if (data.searchResults) {
            _.forEach(data.searchResults, resultItem => {
              resultItem.fullName = courseUnitRealisationNameService.generateFullName(resultItem);
            });
          }
          return data;
        });
      },
      searchAuthenticated(searchParams) {
        return $http.get(courseUnitRealisationUri.authenticatedSearchUri, searchParams.build()).then(result => {
          const {
            data
          } = result;
          if (data.searchResults) {
            _.forEach(data.searchResults, resultItem => {
              resultItem.fullName = courseUnitRealisationNameService.generateFullName(resultItem);
            });
          }
          return data;
        });
      },
      searchAllAuthenticated(searchParams) {
        return $http.get(courseUnitRealisationUri.authenticatedSearchUri, searchParams.build()).then(result => result.data.searchResults);
      },
      getAssessmentItems(courseUnitRealisation) {
        return commonAssessmentItemService.findAllByIds(courseUnitRealisation.assessmentItemIds);
      },
      hasDefaultName(courseUnitRealisation) {
        return localizedStringUtils.areEqual(courseUnitRealisation.name, courseUnitRealisation.getDefaultName());
      },
      hasDefaultNameSpecifier(courseUnitRealisation, courseUnit) {
        const courseUnitName = courseUnit ? courseUnit.name : {};
        return localizedStringUtils.areEqual(courseUnitRealisation.nameSpecifier, courseUnitName);
      },
      getContextName(courseUnitRealisation, courseUnit) {
        if (!api.hasDefaultNameSpecifier(courseUnitRealisation, courseUnit)) {
          return courseUnitRealisationNameService.generateFullName(courseUnitRealisation);
        }
        return courseUnitRealisationNameService.generateShortName(courseUnitRealisation);
      },
      findCourseUnitRealisationsForCurrentUser(activityStatus, options) {
        return findCourseUnitRealisationsForCurrentUser({
          activityStatus
        }, _.isNil(options) ? {} : options);
      },
      updateClassifications(classificationsUpdateRequest) {
        return $http.put(`/kori/api/course-unit-realisations/classifications/${classificationsUpdateRequest.courseUnitRealisationId}`, classificationsUpdateRequest).then(result => courseUnitRealisationJSDataModel.inject(result.data).loadRelations());
      },
      createExamsForMassExamSession(massExamSessionId, examKernels) {
        if (_.isNil(massExamSessionId) || _.isEmpty(examKernels)) {
          $q.when();
        }
        return $http.post(`/kori/api/course-unit-realisations/massExamSession/${massExamSessionId}`, examKernels).then(result => courseUnitRealisationJSDataModel.inject(result.data));
      },
      isActive(courseUnitRealisation) {
        if (!_.get(courseUnitRealisation, 'activityPeriod')) {
          return false;
        }
        return dateUtils.rangeContains(moment(), courseUnitRealisation.activityPeriod);
      },
      getResolvedFlowState(courseUnitRealisation) {
        const isActive = api.isActive(courseUnitRealisation);
        if (courseUnitRealisation.documentState === 'DELETED') {
          return RESOLVED_FLOWS_STATE.DELETED;
        }
        if (courseUnitRealisation.flowState === 'ARCHIVED') {
          return RESOLVED_FLOWS_STATE.ARCHIVED;
        }
        if (courseUnitRealisation.flowState === 'NOT_READY') {
          return RESOLVED_FLOWS_STATE.NOT_READY;
        }
        if (courseUnitRealisation.flowState === 'PUBLISHED') {
          if (courseUnitRealisation.publishDate && moment(courseUnitRealisation.publishDate).isAfter(moment(), 'day')) {
            return RESOLVED_FLOWS_STATE.PUBLISHED_FUTURE;
          }
          if (isActive) {
            return RESOLVED_FLOWS_STATE.PUBLISHED_ACTIVE;
          }
          return RESOLVED_FLOWS_STATE.PUBLISHED_EXPIRED;
        }
        if (courseUnitRealisation.flowState === 'CANCELLED') {
          if (isActive) {
            return RESOLVED_FLOWS_STATE.CANCELLED_ACTIVE;
          }
          return RESOLVED_FLOWS_STATE.CANCELLED_EXPIRED;
        }
        return null;
      }
    };
    return api;
  }
})();