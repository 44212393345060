import { Injectable } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import {
    MandatoryGraduationSurveyModalComponent,
    MandatoryGraduationSurveyValues,
} from './mandatory-graduation-survey-modal.component';

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
export class MandatoryGraduationSurveyModalService {
    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'student.graduation.mandatoryGraduationSurveyModalService',
        serviceName: 'mandatoryGraduationSurveyModalServiceNg',
    };

    constructor(private fudisDialogService: FudisDialogService) { }

    open(values: MandatoryGraduationSurveyValues): void {
        this.fudisDialogService.open(MandatoryGraduationSurveyModalComponent, { data: values });
    }
}
