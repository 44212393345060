import { Injectable } from '@angular/core';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { planSelectorModalOpener } from './plan-selector-modal.component';

/** A wrapper service which allows opening the new {@link PlanSelectorModalComponent} from AngularJS */
@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class PlanSelectorModalLegacyService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.plan.plan-selector-modal-legacy-service',
        serviceName: 'planSelectorModalLegacyService',
    };

    readonly open = planSelectorModalOpener();
}
