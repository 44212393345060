import {
    ChangeDetectionStrategy,
    Component, inject,
    Input, OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { Attainment, CourseUnitAttainment, StudyRightExtensionWorkflow } from 'common-typescript/types';
import _ from 'lodash';
import { from, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { COMMON_PLAN_SERVICE } from 'sis-components/ajs-upgraded-modules';
import {
    PlannedStudies,
} from 'sis-components/applications/planned-studies-by-study-term/planned-studies-by-study-term.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';
import { AttainedCreditsByStudyTerm, StudyTermService } from 'sis-components/service/study-term.service';

@Component({
    selector: 'app-study-right-extension-workflow-summary',
    templateUrl: './study-right-extension-workflow-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightExtensionWorkflowSummaryComponent implements OnInit {
    @Input({ required: true }) studentWorkflow!: StudyRightExtensionWorkflow;

    attainmentEntityService = inject(AttainmentEntityService);
    studyTermService = inject(StudyTermService);
    appErrorHandler = inject(AppErrorHandler);
    commonPlanService = inject(COMMON_PLAN_SERVICE); // TODO: use angular version of commonPlanService.groupPlannedStudiesByStudyTerm when it exists

    creditsPerTerm$: Observable<number>;
    attainedCreditsByStudyTerm$: Observable<AttainedCreditsByStudyTerm[]>;
    plannedStudies$: Observable<PlannedStudies>;
    private attainments$: Observable<Attainment[]>;

    ngOnInit(): void {
        this.initializeAttainments();
        this.initializeCreditsPerTerm();
        this.initializeAttainedCreditsByStudyTerm();
        this.initializePlannedStudies();
    }

    private initializeAttainments() {
        this.attainments$ = this.studentWorkflow.attainmentIds?.length > 0 ?
            this.attainmentEntityService.getByIds(this.studentWorkflow.attainmentIds).pipe(shareReplay({
                bufferSize: 1,
                refCount: false,
            }), this.appErrorHandler.defaultErrorHandler()) : of([]);
    }

    private initializeCreditsPerTerm() {
        this.creditsPerTerm$ = this.attainments$.pipe(map(attainments => this.calculateCreditsPerTerm(attainments)));
    }

    private initializeAttainedCreditsByStudyTerm() {
        this.attainedCreditsByStudyTerm$ = this.attainments$.pipe(switchMap(attainments =>
            this.studyTermService.groupAttainedCreditsByStudyTerm(
                attainments,
                this.studentWorkflow.studyRightValidity?.startDate,
                this.studentWorkflow.creationTime,
                this.studentWorkflow.termRegistrations),
        ));
    }

    private initializePlannedStudies() {
        this.plannedStudies$ = this.attainments$.pipe(
            switchMap(attainments =>
                from(this.commonPlanService.groupPlannedStudiesByStudyTerm(
                    this.studentWorkflow.planSnapshot,
                    this.studentWorkflow.creationTime,
                    attainments.filter(a => a.type === 'CourseUnitAttainment').map(a => (a as CourseUnitAttainment).courseUnitId),
                ) as Observable<PlannedStudies>),
            ),
        );
    }

    private calculateCreditsPerTerm(attainments: Attainment[]) {
        const totalCredits = attainments.reduce((sum, { credits }) => sum + (credits || 0), 0);
        return this.studentWorkflow?.usedAttendanceTerms > 0 ? _.round(totalCredits / this.studentWorkflow.usedAttendanceTerms, 1) : 0;
    }

}
