import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { authServiceProvider, universityServiceProvider } from 'sis-common/ajs-upgraded-modules';
import { SisCommonModule } from 'sis-common/sis-common.module';

import { AddCourseUnitRealisationDialogComponent } from './lib/addCourseUnitRealisationDialog/addCourseUnitRealisationDialog.component';
import { AddCourseUnitRealisationDialogDirective } from './lib/addCourseUnitRealisationDialog/addCourseUnitRealisationDialog.dir.upgraded';
import {
    codebookJSDataModelProvider,
    colorServiceProvider,
    commonAttainmentServiceProvider,
    commonEmployeeServiceProvider,
    commonEnrolmentQuestionnaireServiceProvider,
    commonGradeScaleServiceProvider,
    commonMessageServiceProvider,
    commonModuleServiceProvider,
    commonPersonGroupServiceProvider,
    commonPlanSelectionServiceProvider,
    commonPlanServiceProvider,
    commonStudentApplicationServiceProvider,
    commonStudyPeriodServiceProvider,
    commonTermRegistrationPeriodServiceProvider,
    courseUnitInfoModalProvider,
    courseUnitJSDataModelProvider,
    courseUnitRealisationServiceProvider,
    curriculumPeriodServiceProvider,
    customStudyDraftInfoModalServiceProvider,
    degreeProgrammeJSDataModelProvider,
    jsDataCacheHelperServiceProvider,
    jsDataStoreProvider,
    languageJSDataModelProvider,
    moduleJSDataModelProvider,
    personRuleServiceProvider,
    planStudyRightServiceProvider,
    planValidationResultHelperProvider,
    publicPersonServiceProvider,
    ruleServiceProvider,
    searchComponentUtilsServiceProvider,
    searchFilterTypesProvider,
    searchParametersProvider,
    searchParameterStorageServiceProvider,
    studyEventServiceProvider,
    studyEventSummaryServiceProvider,
    studyModuleJSDataModelProvider,
    validAttainmentFilterServiceProvider,
} from './lib/ajs-upgraded-modules';
import { CommonShowModuleContentApplicationModalComponent } from './lib/applications/commonShowModuleContentApplicationModal.component';
import { CommonShowModuleContentApplicationModalDirective } from './lib/applications/commonShowModuleContentApplicationModal.dir.upgraded';
import { EditWorkflowHandlerModalComponent } from './lib/applications/edit-workflow-handler-modal/edit-workflow-handler-modal.component';
import {
    AssessmentItemAttainmentDetailsComponent,
} from './lib/attainment/attainment-details/assessment-item-attainment-details/assessment-item-attainment-details.component';
import {
    AttainmentDetailsModalComponent,
} from './lib/attainment/attainment-details/attainment-details-modal/attainment-details-modal.component';
import {
    CourseUnitAttainmentDetailsComponent,
} from './lib/attainment/attainment-details/course-unit-attainment-details/course-unit-attainment-details.component';
import {
    CustomCourseUnitAttainmentDetailsComponent,
} from './lib/attainment/attainment-details/custom-course-unit-attainment-details/custom-course-unit-attainment-details.component';
import {
    CustomModuleAttainmentDetailsComponent,
} from './lib/attainment/attainment-details/custom-module-attainment-details/custom-module-attainment-details.component';
import {
    DegreeProgrammeAttainmentDetailsComponent,
} from './lib/attainment/attainment-details/degree-programme-attainment-details/degree-programme-attainment-details.component';
import {
    ModuleAttainmentDetailsComponent,
} from './lib/attainment/attainment-details/module-attainment-details/module-attainment-details.component';
import {
    AttainmentMisregistrationModalComponent,
} from './lib/attainment/attainment-misregistration-modal/attainment-misregistration-modal.component';
import { AttainmentListForStudyComponent } from './lib/attainment/list/attainment-list-for-study/attainment-list-for-study.component';
import {
    AttainmentMisregistrationRationaleModalComponent,
} from './lib/attainment/list/attainmentMisregistrationRationaleModal-ng.component';
import {
    AttainmentMisregistrationRationaleModalDirective,
} from './lib/attainment/list/attainmentMisregistrationRationaleModal.dir.upgraded';
import { ThesisDetailsComponent } from './lib/attainment/thesis-details/thesis-details.component';
import {
    AddResponsiblePersonsStepComponent,
} from './lib/bulk-edit-wizard/add-responsible-persons-step/add-responsible-persons-step.component';
import {
    SelectResponsiblePersonComponent,
} from './lib/bulk-edit-wizard/select-responsible-person/select-responsible-person.component';
import { CalendarEventTooltipDirective } from './lib/calendar/calendarEventTooltip.component.upgraded';
import { CalendarSettingsModalComponent } from './lib/calendar/dialog/calendarSettingsModal.component';
import { CalendarSettingsModalDirective } from './lib/calendar/dialog/calendarSettingsModal.dir.upgraded';
import { CourseUnitNameAndCodeComponent } from './lib/courseUnit/course-unit-name-and-code/course-unit-name-and-code.component';
import { CourseUnitSearchComponent } from './lib/courseUnit/course-unit-search/course-unit-search.component';
import {
    PriorLearningSubstitutionsContainerComponent,
} from './lib/courseUnit/prior-learning-substitutions/prior-learning-substitutions-container/prior-learning-substitutions-container.component';
import {
    CompletionMethodAssessmentItemInfoComponent,
} from './lib/courseUnitInfoModal/completion-methods/completion-method-assessment-item-info/completion-method-assessment-item-info.component';
import {
    CompletionMethodRepeatsComponent,
} from './lib/courseUnitInfoModal/completion-methods/completion-method-repeats/completion-method-repeats.component';
import { CompletionMethodsComponent } from './lib/courseUnitInfoModal/completion-methods/completion-methods.component';
import {
    CourseUnitInfoModalCourseUnitRealisationComponent,
} from './lib/courseUnitInfoModal/course-unit-info-modal-course-unit-realisation/course-unit-info-modal-course-unit-realisation.component';
import { CourseUnitInfoModalComponent } from './lib/courseUnitInfoModal/courseUnitInfoModal.component';
import { CourseUnitInfoModalDirective } from './lib/courseUnitInfoModal/courseUnitInfoModal.dir.upgraded';
import {
    PlanCourseUnitInfoModalBasicInfoComponent,
} from './lib/courseUnitInfoModal/plan-course-unit-info-modal-basic-info/plan-course-unit-info-modal-basic-info.component';
import {
    ReadOnlyCourseUnitInfoModalComponent,
} from './lib/courseUnitInfoModal/read-only-course-unit-info-modal/read-only-course-unit-info-modal.component';
import {
    SelectableCompletionMethodsComponent,
} from './lib/courseUnitInfoModal/selectable-completion-methods/selectable-completion-methods.component';
import {
    CourseUnitRealisationStructureComponent,
} from './lib/courseUnitRealisation/course-unit-realisation-structure/course-unit-realisation-structure.component';
import {
    StudySubGroupStructureComponent,
} from './lib/courseUnitRealisation/study-sub-group-structure/study-sub-group-structure.component';
import { CurriculumPeriodsCompactDirective } from './lib/curriculumPeriod/curriculumPeriodsCompact.component.upgraded';
import { CustomStudyDraftNameComponent } from './lib/customStudyDraft/custom-study-draft-name/custom-study-draft-name.component';
import { CustomStudyDraftInfoModalComponent } from './lib/customStudyDraft/customStudyDraftInfoModal.component';
import { CustomStudyDraftInfoModalDirective } from './lib/customStudyDraft/customStudyDraftInfoModal.dir.upgraded';
import { LocalDateEditorDirective } from './lib/date/local-date-editor/localDateEditor/localDateEditor.dir.upgraded';
import { SearchAndSelectEducationsComponent } from './lib/education/search-and-select-educations/search-and-select-educations.component';
import { EducationStructurePreviewDirective } from './lib/educationStructurePreview/educationStructurePreview.dir.upgraded';
import {
    EnrolmentQuestionnaireQuestionComponent,
} from './lib/enrolmentQuestionnaire/enrolment-questionnaire-question/enrolment-questionnaire-question.component';
import { EnrolmentQuestionnaireModalComponent } from './lib/enrolmentQuestionnaire/enrolmentQuestionnaireModal-ng.component';
import { EnrolmentQuestionnaireModalDirective } from './lib/enrolmentQuestionnaire/enrolmentQuestionnaireModal.dir.upgraded';
import { ApplicationFilesComponent } from './lib/file-upload/application-files.component';
import { LocationSearchDirective } from './lib/locationSearch/locationSearch.dir.upgraded';
import { MenuButtonDirective } from './lib/menuButton/menuButton.comp.upgraded';
import { SisMessageConversationComponent } from './lib/messenger/sis-message-conversation.component';
import { SisMessageConversationsComponent } from './lib/messenger/sis-message-conversations.component';
import { SuggestAddMessageModalComponent } from './lib/messenger/suggestAddMessage.component';
import { SuggestAddMessageModalDirective } from './lib/messenger/suggestAddMessage.dir.upgraded';
import { massExamSessionModelProvider } from './lib/model/massExamSession.model';
import { ModuleSearchComponent } from './lib/module/module-search/module-search.component';
import {
    OpenUniversityApprovalStatePanelComponent,
} from './lib/open-university/approval-state-panel/open-university-approval-state-panel.component';
import { CurrentOrganisationRoleShareEditorDirective } from './lib/organisation/currentOrganisationRoleShareEditor.component.upgraded';
import { OrganisationRoleShareEditorComponentDirective } from './lib/organisation/organisationRoleShareEditor.component.upgraded';
import { ResponsibilityInfosEditorDirective } from './lib/person/responsibilityInfosEditor.component.upgraded';
import { SearchEmployeeComponent } from './lib/person/search-employee/search-employee.component';
import { SearchEmployeesComponent } from './lib/person/search-employees/search-employees.component';
import { SearchPersonGroupsComponent } from './lib/personGroup/search-person-groups/search-person-groups.component';
import { CreatePlanComponent } from './lib/plan/create-plan/create-plan.component';
import { FreeEditComponent } from './lib/plan/free-edit/free-edit.component';
import { InvalidSelectionsComponent } from './lib/plan/invalid-selections/invalid-selections.component';
import { PlanCourseUnitInfoModalComponent } from './lib/plan/plan-course-unit-info-modal/plan-course-unit-info-modal.component';
import { PlanEditComponent } from './lib/plan/plan-edit/plan-edit.component';
import { PlanModuleDirective } from './lib/plan/planModule.comp.upgraded';
import { LegacyPlanSelectorModalComponent } from './lib/plan/planSelectorModal.component';
import { PlanSelectorModalDirective } from './lib/plan/planSelectorModal.dir.upgraded';
import { AnyCourseUnitRuleComponent } from './lib/plan/rules/any-course-unit-rule/any-course-unit-rule.component';
import { AnyModuleRuleComponent } from './lib/plan/rules/any-module-rule/any-module-rule.component';
import { CompositeRuleComponent } from './lib/plan/rules/composite-rule/composite-rule.component';
import { CourseUnitCountRuleComponent } from './lib/plan/rules/course-unit-count-rule/course-unit-count-rule.component';
import { CourseUnitRuleComponent } from './lib/plan/rules/course-unit-rule/course-unit-rule.component';
import { CreditsRuleComponent } from './lib/plan/rules/credits-rule/credits-rule.component';
import { ModuleRuleComponent } from './lib/plan/rules/module-rule/module-rule.component';
import { RuleSelectComponent } from './lib/plan/rules/rule-select/rule-select.component';
import { PrerequisiteGroupEditModalComponent } from './lib/prerequisites/prerequisiteGroupEditModal.component';
import { PrerequisiteGroupEditModalDirective } from './lib/prerequisites/prerequisiteGroupEditModal.dir.upgraded';
import { PrerequisitesComponent } from './lib/prerequisites/prerequisites/prerequisites.component';
import { ResponsibilityInfosEditorComponent } from './lib/responsibility-infos-editor/responsibility-infos-editor.component';
import { DisplaySelectedParametersComponent } from './lib/search/display-selected-parameters/display-selected-parameters.component';
import { DisplaySelectedParametersDirective } from './lib/search/displaySelectedParameters.component.upgraded';
import { SearchFilterOptionsMenuComponent } from './lib/search/search-filter-options-menu/search-filter-options-menu.component';
import { SearchFilterTagComponent } from './lib/search/search-filter-tag/search-filter-tag.component';
import { SearchResultInfoComponent } from './lib/search/search-result-info/search-result-info.component';
import { SearchFilterOptionsMenuDirective } from './lib/search/searchFilterOptionsMenu.component.upgraded';
import { SearchFilterLegacyDirective } from './lib/search/searchFilters/searchFilter.component.upgraded';
import { SearchFilterMenuItemDirective } from './lib/search/searchFilters/searchFilterMenuItem.component.upgraded';
import { SearchFilterTagDirective } from './lib/search/searchFilters/searchFilterTag.component.upgraded';
import { SearchResultInfoDirective } from './lib/search/searchResultInfo.component.upgraded';
import { DropdownSelectDirective } from './lib/select/dropdownSelect.component.upgraded';
import { LocalizedStringDirective } from './lib/string/localizedString.dir.upgraded';
import { StudyRightExtensionValidityPipe } from './lib/studyRight/study-right-extension-validity.pipe';
import { SisComponentsModule } from './sis-components.module';

/**
 * This module contains hybrid AngularJS/Angular code, e.g. upgraded AngularJS services and components, and Angular components, pipes
 * and directives that have (direct or indirect) AngularJS dependencies. Pure Angular code belongs in {@link SisComponentsModule}.
 */
@NgModule({
    declarations: [
        AnyModuleRuleComponent,
        AddCourseUnitRealisationDialogComponent,
        AddCourseUnitRealisationDialogDirective,
        AnyCourseUnitRuleComponent,
        ApplicationFilesComponent,
        AssessmentItemAttainmentDetailsComponent,
        AttainmentDetailsModalComponent,
        AttainmentListForStudyComponent,
        AttainmentMisregistrationModalComponent,
        AttainmentMisregistrationRationaleModalComponent,
        AttainmentMisregistrationRationaleModalDirective,
        CalendarEventTooltipDirective,
        CalendarSettingsModalComponent,
        CalendarSettingsModalDirective,
        CommonShowModuleContentApplicationModalComponent,
        CommonShowModuleContentApplicationModalDirective,
        CompletionMethodAssessmentItemInfoComponent,
        CompletionMethodRepeatsComponent,
        CompletionMethodsComponent,
        CompositeRuleComponent,
        CourseUnitAttainmentDetailsComponent,
        CourseUnitInfoModalComponent,
        CourseUnitInfoModalCourseUnitRealisationComponent,
        CourseUnitInfoModalDirective,
        CourseUnitCountRuleComponent,
        CourseUnitNameAndCodeComponent,
        CourseUnitRealisationStructureComponent,
        CourseUnitRuleComponent,
        CourseUnitSearchComponent,
        CreatePlanComponent,
        CreditsRuleComponent,
        CurrentOrganisationRoleShareEditorDirective,
        CurriculumPeriodsCompactDirective,
        CustomCourseUnitAttainmentDetailsComponent,
        CustomModuleAttainmentDetailsComponent,
        CustomStudyDraftInfoModalComponent,
        CustomStudyDraftInfoModalDirective,
        CustomStudyDraftNameComponent,
        DegreeProgrammeAttainmentDetailsComponent,
        DisplaySelectedParametersComponent,
        DisplaySelectedParametersDirective,
        DropdownSelectDirective,
        EditWorkflowHandlerModalComponent,
        EducationStructurePreviewDirective,
        EnrolmentQuestionnaireModalComponent,
        EnrolmentQuestionnaireModalDirective,
        EnrolmentQuestionnaireQuestionComponent,
        FreeEditComponent,
        InvalidSelectionsComponent,
        LocalDateEditorDirective,
        LocalizedStringDirective,
        LocationSearchDirective,
        MenuButtonDirective,
        ModuleAttainmentDetailsComponent,
        ModuleRuleComponent,
        ModuleSearchComponent,
        OpenUniversityApprovalStatePanelComponent,
        OrganisationRoleShareEditorComponentDirective,
        PlanCourseUnitInfoModalBasicInfoComponent,
        PlanCourseUnitInfoModalComponent,
        PlanEditComponent,
        PlanModuleDirective,
        LegacyPlanSelectorModalComponent,
        PlanSelectorModalDirective,
        PrerequisiteGroupEditModalComponent,
        PrerequisiteGroupEditModalDirective,
        PrerequisitesComponent,
        PriorLearningSubstitutionsContainerComponent,
        ReadOnlyCourseUnitInfoModalComponent,
        ResponsibilityInfosEditorComponent,
        ResponsibilityInfosEditorDirective,
        RuleSelectComponent,
        SearchAndSelectEducationsComponent,
        SearchEmployeeComponent,
        SearchEmployeesComponent,
        SearchFilterLegacyDirective,
        SearchFilterMenuItemDirective,
        SearchFilterOptionsMenuComponent,
        SearchFilterOptionsMenuDirective,
        SearchFilterTagComponent,
        SearchFilterTagDirective,
        SearchPersonGroupsComponent,
        SearchResultInfoComponent,
        SearchResultInfoDirective,
        SelectableCompletionMethodsComponent,
        SisMessageConversationComponent,
        SisMessageConversationsComponent,
        StudyRightExtensionValidityPipe,
        StudySubGroupStructureComponent,
        SuggestAddMessageModalComponent,
        SuggestAddMessageModalDirective,
        ThesisDetailsComponent,
        SelectResponsiblePersonComponent,
        AddResponsiblePersonsStepComponent,
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        NgxFudisModule,
        ReactiveFormsModule,
        SisCommonModule,
        SisComponentsModule,
        TranslateModule.forChild(),
        UIRouterUpgradeModule.forChild(),
        NgxFudisModule,
    ],
    exports: [
        AnyModuleRuleComponent,
        AttainmentListForStudyComponent,
        AnyCourseUnitRuleComponent,
        ApplicationFilesComponent,
        CalendarEventTooltipDirective,
        CalendarSettingsModalComponent,
        CompletionMethodAssessmentItemInfoComponent,
        CompletionMethodRepeatsComponent,
        CompletionMethodsComponent,
        CompositeRuleComponent,
        CourseUnitCountRuleComponent,
        CourseUnitNameAndCodeComponent,
        CourseUnitRealisationStructureComponent,
        CourseUnitRuleComponent,
        CourseUnitSearchComponent,
        CreatePlanComponent,
        CreditsRuleComponent,
        CurrentOrganisationRoleShareEditorDirective,
        CurriculumPeriodsCompactDirective,
        CustomStudyDraftNameComponent,
        DisplaySelectedParametersComponent,
        DisplaySelectedParametersDirective,
        DropdownSelectDirective,
        EditWorkflowHandlerModalComponent,
        EducationStructurePreviewDirective,
        EnrolmentQuestionnaireModalComponent,
        EnrolmentQuestionnaireQuestionComponent,
        FreeEditComponent,
        InvalidSelectionsComponent,
        LocalDateEditorDirective,
        LocalizedStringDirective,
        LocationSearchDirective,
        MenuButtonDirective,
        ModuleRuleComponent,
        ModuleSearchComponent,
        PlanCourseUnitInfoModalComponent,
        PlanEditComponent,
        PlanModuleDirective,
        PrerequisitesComponent,
        PriorLearningSubstitutionsContainerComponent,
        OpenUniversityApprovalStatePanelComponent,
        OrganisationRoleShareEditorComponentDirective,
        ResponsibilityInfosEditorComponent,
        ResponsibilityInfosEditorDirective,
        RuleSelectComponent,
        SearchAndSelectEducationsComponent,
        SearchEmployeeComponent,
        SearchEmployeesComponent,
        SearchFilterLegacyDirective,
        SearchFilterOptionsMenuComponent,
        SearchFilterOptionsMenuDirective,
        SearchFilterTagComponent,
        SearchFilterTagDirective,
        SearchPersonGroupsComponent,
        SearchResultInfoComponent,
        SearchResultInfoDirective,
        SelectableCompletionMethodsComponent,
        SisComponentsModule,
        SisMessageConversationComponent,
        SisMessageConversationsComponent,
        StudyRightExtensionValidityPipe,
        StudySubGroupStructureComponent,
        ThesisDetailsComponent,
        SelectResponsiblePersonComponent,
        AddResponsiblePersonsStepComponent,
    ],
    providers: [
        authServiceProvider,
        codebookJSDataModelProvider,
        colorServiceProvider,
        commonAttainmentServiceProvider,
        commonEmployeeServiceProvider,
        commonEnrolmentQuestionnaireServiceProvider,
        commonGradeScaleServiceProvider,
        commonMessageServiceProvider,
        commonModuleServiceProvider,
        commonPersonGroupServiceProvider,
        commonPlanSelectionServiceProvider,
        commonPlanServiceProvider,
        commonStudentApplicationServiceProvider,
        commonStudyPeriodServiceProvider,
        commonTermRegistrationPeriodServiceProvider,
        courseUnitInfoModalProvider,
        courseUnitJSDataModelProvider,
        courseUnitRealisationServiceProvider,
        curriculumPeriodServiceProvider,
        customStudyDraftInfoModalServiceProvider,
        degreeProgrammeJSDataModelProvider,
        jsDataCacheHelperServiceProvider,
        jsDataStoreProvider,
        languageJSDataModelProvider,
        massExamSessionModelProvider,
        moduleJSDataModelProvider,
        personRuleServiceProvider,
        planStudyRightServiceProvider,
        planValidationResultHelperProvider,
        publicPersonServiceProvider,
        ruleServiceProvider,
        searchComponentUtilsServiceProvider,
        searchFilterTypesProvider,
        searchParametersProvider,
        searchParameterStorageServiceProvider,
        studyEventServiceProvider,
        studyEventSummaryServiceProvider,
        studyModuleJSDataModelProvider,
        universityServiceProvider,
        validAttainmentFilterServiceProvider,
    ],
})
export class SisComponentsHybridModule {}
