<ng-container *ngIf="variant === 'open-university-cart' && (enableCartObservable | async)">
  @let notificationCount = notificationObservable | async;
  <ng-container *ngTemplateOutlet="openUniversityCartDesktop; context: {notificationCount: notificationCount}"/>
</ng-container>
<ng-container *ngIf="variant === 'messages'">
  @let notificationCount = notificationObservable | async;
  @if (mobileMode) {
    <ng-container *ngTemplateOutlet="messagesMobile; context: {notificationCount: notificationCount}"/>
  }
  @else {
    <ng-container *ngTemplateOutlet="messagesDesktop; context: {notificationCount: notificationCount}"/>
  }
</ng-container>
<ng-container *ngIf="variant === 'notes'">
  <ng-container *ngTemplateOutlet="mobileMode ? notesMobile : notesDesktop"/>
</ng-container>
<ng-container *ngIf="variant === 'help' && !mobileMode">
  <ng-container *ngTemplateOutlet="helpDesktop"/>
</ng-container>
<ng-template #openUniversityCartDesktop let-notificationCount="notificationCount">
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [class.top-navigation-icon__desktop--active]="isActive"
     [attr.aria-current]="isActive && 'page'"
     [attr.aria-label]="name + ', ' +  notificationCount + ' ' + ariaLabel"
     [attr.data-cy]="dataCy"
     [uiSref]="'student.open-university-cart'"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'shopping-cart'" [color]="isActive ? 'white' : 'gray-dark'"/>
    <sis-notification-badge *ngIf="notificationCount"
                            [value]="notificationCount"
                            class="top-navigation-icon__desktop__badge"/>
  </a>
</ng-template>
<ng-template #messagesDesktop let-notificationCount="notificationCount">
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [class.top-navigation-icon__desktop--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [href]="path()"
     [attr.aria-label]="name + ', ' +  notificationCount + ' ' + ariaLabel"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'mail'" [color]="isActive ? 'white' : 'gray-dark'"/>
    <sis-notification-badge *ngIf="notificationCount"
                            [value]="notificationCount"
                            [attr.data-cy]="'message-notifications'"
                            class="top-navigation-icon__desktop__badge"/>
  </a>
</ng-template>
<ng-template #messagesMobile let-notificationCount="notificationCount">
  <a class="top-navigation-icon top-navigation-icon__mobile"
     [class.top-navigation-icon__mobile--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [href]="path()"
     [attr.aria-label]="name + ', ' +  notificationCount + ' ' + ariaLabel"
     (keydown)="keydownPressed.emit($event)"
     (keydown.escape)="blurMobileMenuIcon.emit($event)"
     (blur)="blurMobileMenuIcon.emit($event)">{{ name }}
    <sis-notification-badge *ngIf="notificationCount"
                            [value]="notificationCount"
                            [attr.data-cy]="'message-notifications'"
                            class="top-navigation-icon__mobile__badge"/>
    <fudis-icon *ngIf="isActive" [icon]="'check'" [color]="'primary'" class="float-end top-navigation-icon__mobile__check"/>
  </a>
</ng-template>
<ng-template #notesDesktop>
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [class.top-navigation-icon__desktop--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [uiSref]="'student.logged-in.course-cart'"
     [attr.aria-label]="name"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'notebook'" [color]="isActive ? 'white' : 'gray-dark'"/>
  </a>
</ng-template>
<ng-template #notesMobile>
  <a class="top-navigation-icon top-navigation-icon__mobile"
     [class.top-navigation-icon__mobile--active]="isActive"
     [attr.data-cy]="dataCy"
     [attr.aria-current]="isActive && 'page'"
     [uiSref]="'student.logged-in.course-cart'"
     (keydown)="keydownPressed.emit($event)"
     (keydown.escape)="blurMobileMenuIcon.emit($event)"
     (blur)="blurMobileMenuIcon.emit($event)">{{ name }}
    <fudis-icon *ngIf="isActive" [icon]="'check'" [color]="'primary'" class="float-end top-navigation-icon__mobile__check"/>
  </a>
</ng-template>
<ng-template #helpDesktop>
  <a class="top-navigation-icon top-navigation-icon__desktop"
     [href]="path()"
     target="_blank"
     rel="noopener noreferrer"
     [attr.data-cy]="dataCy"
     [attr.aria-label]="ariaLabel"
     fudisTooltip
     [tooltip]="name">
    <fudis-icon [icon]="'question-mark'"/>
  </a>
</ng-template>
