import { inject, Injectable } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Attainment, GradeRaiseAttempt } from 'common-typescript/types';
import _ from 'lodash';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AttainmentEntityService } from 'sis-components/service/attainment-entity.service';

import {
    getAttainmentDetailsModalOpener,
} from '../../profile/attainment/attainment-details/attainment-details-modal/attainment-details-modal.component';

@Injectable({ providedIn: 'root' })
export class AttainmentStudentModalService {

    private attainmentEntityService = inject(AttainmentEntityService);
    private appErrorHandler: AppErrorHandler = inject(AppErrorHandler);

    private readonly attainmentDetailsModalOpener = getAttainmentDetailsModalOpener();

    openAttainmentDetailsModal(attainment: Attainment,
                               gradeRaiseEnabled: boolean,
                               gradeRaiseInfoEnabled: boolean,
                               gradeRaiseAttempt: GradeRaiseAttempt): Observable<NgbModalRef> {
        const attainments = this.attainmentEntityService.getByPersonId(attainment.personId);

        return attainments
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                map((allAttainments) => {
                    const allMatchingSecondaryAttainments = this.attainmentEntityService.getSecondaryAttainments(attainment, allAttainments);
                    const secondaryAttainments = _.filter(allMatchingSecondaryAttainments, { misregistration: false });
                    const hasValidParentAttainment = this.attainmentEntityService.isAttached(attainment, allAttainments);
                    const isAboutToExpire = this.attainmentEntityService.isAboutToExpire(attainment);

                    return this.attainmentDetailsModalOpener({
                        attainment,
                        secondaryAttainments,
                        hasValidParentAttainment,
                        isAboutToExpire,
                        gradeRaiseEnabled,
                        gradeRaiseInfoEnabled,
                        gradeRaiseAttempt,
                    });
                }),
            );
    }
}
