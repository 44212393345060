(function () {
  initialAppCheck.$inject = ["$log", "selectEducationModal", "$state"];
  angular.module('student.common.service.initialAppCheck', ['student.plan']).factory('initialAppCheck', initialAppCheck);

  /**
   * @ngInject
   */
  function initialAppCheck($log, selectEducationModal, $state) {
    return {
      triggerSelectEducationModalIfNoPlans: function (plans) {
        if (plans.length === 0) {
          return selectEducationModal.open().then(function (result) {
            $state.go('student.logged-in.structure', {
              planId: result.planId
            });
          }, function () {
            $log.debug('selectEducationModal cancelled');
          });
        }
      }
    };
  }
})();