import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { OpenUniversityCart, OpenUniversityCartUpdateRequest } from 'common-typescript/types';
import { catchError, EMPTY, Subject, throwError } from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PaymentSystemService } from 'sis-components/service/payment-system.service';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';

import { OpenUniversityErrorHelperService } from '../service/open-university-error-helper.service';

import { CART_PURCHASE_WIZARD_STEPS, CART_PURCHASE_WIZARD_STEPS_AUTO_ACTIVATE, CartWizardStep, STEP } from './constants';

@Component({
    selector: 'app-open-university-cart',
    templateUrl: './open-university-cart.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityCartComponent implements OnInit, OnDestroy {

    constructor(
        private appErrorHandler: AppErrorHandler,
        private localeService: LocaleService,
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
        private openUniversityErrorHelper: OpenUniversityErrorHelperService,
        private paymentSystemService: PaymentSystemService,
        private stateService: StateService,
        private titleService: Title,
        private translateService: TranslateService,
        private authService: AuthService,
        private privatePersonService: PrivatePersonEntityService,
    ) {}

    showAutoConfrimWizard: boolean;
    wizardSteps: CartWizardStep[];
    wizardProgressSteps: string[];

    @ViewChild('currentStepTitle') currentStepTitle: ElementRef;
    currentStep = STEP.CHECK_SELECTIONS;

    cart: OpenUniversityCart;
    submitClickedSubject: Subject<void> = new Subject<void>();
    requestInProgress = false;
    checkEnrolments$ = new Subject<void>();
    loaded = false;

    get STEP() {
        return STEP;
    }

    ngOnInit(): void {
        this.openUniversityCartCustomerService.getCurrentCart(true)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe({
                next: cart => {
                    this.loaded = true;
                    this.cart = cart;
                },
            });
        this.initWizardConfirmTypeForUser();
    }

    ngOnDestroy() {
        this.titleService.setTitle(this.translateService.instant('PAGE_TITLE_STUDENT'));
    }

    back(): void {
        if (!this.loaded) return;
        if (this.currentStep < 1) {
            this.exit();
        } else {
            this.currentStep -= 1;
            this.currentStepTitle?.nativeElement?.focus();
            this.setTitle();
        }
    }

    private exit() {
        this.stateService.go('student.search.open-university');
    }

    forward(): void {
        if (!this.loaded || !this.cart) return;

        if (this.currentStep === STEP.CHECK_SELECTIONS) {
            this.checkSelectionsForward();
        } else if (this.currentStep === STEP.CONTACT_INFORMATION) {
            this.submitClickedSubject.next(); // child component (<app-cart-contact-information>) listens to this
        }
    }

    nextStep(): void {
        this.currentStep += 1;
        this.currentStepTitle?.nativeElement?.focus();
        this.setTitle();
    }

    checkSelectionsForward(): void {
        this.checkEnrolments$.next();
    }

    continueToPaymentSystem(openUniversityCartUpdateRequest: OpenUniversityCartUpdateRequest): void {
        this.requestInProgress = true;
        this.paymentSystemService.initializePaymentAndNavigateToPaymentSystem(
            this.cart.id,
            this.localeService.getCurrentLanguage(),
            openUniversityCartUpdateRequest,
        )
            .pipe(
                catchError(error => {
                    if (error.status === 422) { return throwError(() => error); }
                    this.openUniversityErrorHelper.showPaymentInitializationFailureAlert();
                    return EMPTY;
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe()
            .add(() => this.requestInProgress = false);
    }

    setTitle(): void {
        const currentStep = this.wizardSteps[this.currentStep];
        this.titleService.setTitle(`${this.translateService.instant(currentStep.pageTitle)}, ${this.translateService.instant(currentStep.wizardProgressStep)} - ${this.translateService.instant('PAGE_TITLE_STUDENT')}`);
    }

    private initWizardSteps(steps: CartWizardStep[]): void {
        this.wizardSteps = steps;
        this.wizardProgressSteps = this.wizardSteps.map(({ wizardProgressStep }) => wizardProgressStep).filter(Boolean);
        this.setTitle();
    }

    private initWizardConfirmTypeForUser() {
        if (this.authService.loggedIn()) {
            this.privatePersonService.getIsValidStudent(this.authService.personId())
                .pipe(this.appErrorHandler.defaultErrorHandler())
                .subscribe({
                    next: (isValid) => {
                        this.initWizardSteps(isValid ? CART_PURCHASE_WIZARD_STEPS_AUTO_ACTIVATE : CART_PURCHASE_WIZARD_STEPS);
                        this.showAutoConfrimWizard = isValid;
                    },
                });
        } else {
            this.initWizardSteps(CART_PURCHASE_WIZARD_STEPS);
            this.showAutoConfrimWizard = false;
        }
    }

}
