<ng-container *transloco="let t">
    <fudis-body-text [variant]="'lg-light'" id="attained-credits-by-study-term">
        {{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.SPENT_STUDY_TERMS_DESCRIPTION') }}
    </fudis-body-text>
    <table class="table styled-table" aria-describedby="attained-credits-by-study-term">
        <thead>
        <tr>
            <th>{{ t('STUDENT_APPLICATIONS.STUDY_TERM') }}</th>
            <th>{{ t('STUDENT_APPLICATIONS.ATTENDANCE') }}</th>
            <th class="text-right">{{ t('STUDENT_APPLICATIONS.ATTAINED_CREDITS') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of this.attainedCredits; last as isLast">
            <td *ngIf="!row.studyTerm.name">{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.ATTAINMENTS_BEFORE_STUDY_RIGHT_START') }}</td>
            <td *ngIf="!row.studyTerm.name">-</td>
            <td *ngIf="row.studyTerm.name">
                <span *ngIf="row.studyTerm.valid">
                    {{row.studyTerm.name | localizedString}} {{this.getStudyTermYear(row.studyTerm)}}
                    <span *ngIf="isLast">{{ t('STUDENT_APPLICATIONS.ONGOING') }}</span>
                </span>
            </td>
            <td *ngIf="row.studyTerm.name">
                <span *ngIf="row.termRegistration">

                    <span>{{ t('PROFILE.REGISTRATION.' + row.termRegistration.termRegistrationType) }}</span>
                    <span *ngIf="row.termRegistration.statutoryAbsence">, {{ t('PROFILE.STATUTORY_ABSENCE') }}</span>

                </span>
                <span *ngIf="!row.termRegistration">{{ t('PROFILE.REGISTRATION.MISSING') }}</span>
            </td>
            <td class="text-right">{{row.credits}} {{ t('CREDITS') }}</td>
        </tr>
        </tbody>
    </table>
    <div class="listing-summary">
        <h4>{{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.TOTAL_CREDITS') }}</h4>
        <span class="credits">
            {{this.totalCredits}} {{ t('CREDITS') }}
        </span>
    </div>
</ng-container>
