<div ngbDropdown #dropdown=ngbDropdown class="login-dropdown">
  <button type="button" ngbDropdownToggle class="non-button login-dropdown__toggle"
          (keyup)="onKeyboardButtonInteraction($event)"
          [attr.aria-haspopup]="'menu'"
          [attr.aria-controls]="'sis-login-dropdown'"
          [attr.data-cy]="'login-button'">
    {{ 'AUTH.LOGIN' | transloco }}
    <fudis-icon [icon]="'chevron'" [rotate]="'cw-90'" [color]="'white'"/>
  </button>
  <div id="sis-login-dropdown" role="menu" class="dropdown-menu-right login-dropdown__menu" ngbDropdownMenu>
    <a *ngFor="let option of options" fudisLink ngbDropdownItem role="menuitem"
       class="login-dropdown__menu__item"
       [href]="option.url"
       [title]="option.name | localizedString"
       (blur)="blurMenu($event)">
    </a>
  </div>
</div>
