import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CustomAttainmentWorkflow, CustomAttainmentWorkflowApplication, OtmId } from 'common-typescript/types';

@Component({
    selector: 'app-custom-attainment-workflow-summary',
    templateUrl: './custom-attainment-workflow-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomAttainmentWorkflowSummaryComponent {
    @Input({ required: true }) studentId!: OtmId;
    @Input({ required: true }) studentWorkflow!: CustomAttainmentWorkflow | CustomAttainmentWorkflowApplication;
}
