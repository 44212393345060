import {
    Component,
    DestroyRef,
    EventEmitter,
    Inject,
    inject,
    OnChanges,
    OnInit,
    Optional,
    Output,
    SimpleChanges, ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CheckboxGroupComponent, FudisDialogService } from '@funidata/ngx-fudis';
import { FudisCheckboxGroupFormGroup } from '@funidata/ngx-fudis/lib/types/forms';
import {
    DisclosureAuthorization,
    DisclosureAuthorizationCategory,
    OtmId,
    PrivatePerson,
} from 'common-typescript/types';
import _ from 'lodash';
import { combineLatest, finalize, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';

import {
    DisclosureAuthorizationForm,
    DisclosureAuthorizationFormInterface,
    PartialDisclosureAuthorization,
} from '../../disclosure-authorization/disclosure-authorization.component';
import { AppErrorHandler } from '../../error-handler/app-error-handler';
import { SisFormBuilder } from '../../form/sis-form-builder.service';
import { DisclosureAuthorizationEntityService } from '../../service/disclosure-authorization-entity.service';

export interface DisclosureAuthorizationsEditModalValues {
    person: PrivatePerson;
    staffEdit: boolean;
    categories: DisclosureAuthorizationCategory[];
    answers: DisclosureAuthorization[];
    reset: () => void;
}

export function disclosureAuthorizationsEditModalOpener(): (
    person: PrivatePerson,
    staffEdit: boolean,
    categories: DisclosureAuthorizationCategory[],
    answers: DisclosureAuthorization[],
    reset: () => void,
) => MatDialogRef<DisclosureAuthorizationsEditModalComponent, { data: object }> {
    const dialogService = inject(FudisDialogService);
    return (
        person: PrivatePerson,
        staffEdit: boolean,
        categories: DisclosureAuthorizationCategory[],
        answers: DisclosureAuthorization[],
        reset: () => void,
    ) => dialogService.open(DisclosureAuthorizationsEditModalComponent, { data: {
        person, staffEdit, categories, answers, reset },
    });
}

@Component({
    selector: 'sis-disclosure-authorizations-edit-modal',
    templateUrl: './disclosure-authorizations-edit-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DisclosureAuthorizationsEditModalComponent implements OnInit, OnChanges {
    @Output() reset = new EventEmitter<void>();

    @ViewChild('checkboxGroup') public checkboxGroup: CheckboxGroupComponent;

    person: PrivatePerson;
    staffEdit: boolean;
    categories: DisclosureAuthorizationCategory[];
    answers: DisclosureAuthorization[];
    answersForm: FormGroup;
    initialValues: { answers: PartialDisclosureAuthorization[] };
    initialControls: FormArray;

    private requestsInProgress: boolean = false;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public values: DisclosureAuthorizationsEditModalValues,
        private fb: SisFormBuilder,
        private disclosureAuthorizationService: DisclosureAuthorizationEntityService,
        private appErrorHandler: AppErrorHandler,
        private authService: AuthService,
        private destroyRef: DestroyRef,
        private dialogService: FudisDialogService,
    ) {}

    ngOnInit() {
        if (this.values) {
            this.person = this.values.person;
            this.staffEdit = this.values.staffEdit;
            this.categories = this.values.categories;
            this.answers = this.values.answers;
            this.reset
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(this.values.reset);
            this.answersForm = this.buildForm();
            this.initialValues = this.answersForm.value;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.categories || changes.answers) {
            this.answersForm = this.buildForm();
            this.initialValues = this.answersForm.value;
        }
    }

    buildForm(): FormGroup<DisclosureAuthorizationForm> {
        const buildedForm = this.fb.group<DisclosureAuthorizationForm>({
            answers: this.fb.array<FormGroup>((this.categories || [])
                .map(category => this.fb.group<DisclosureAuthorizationFormInterface>({
                    authorized: this.fb.control(_.get(this.getAnswer(category.id), 'authorized', false)),
                    description: category.description,
                    id: category.id,
                }))),
        });
        this.initialControls = buildedForm.get('answers') as FormArray;

        return buildedForm;
    }

    getAnswer(disclosureCategoryId: OtmId): DisclosureAuthorization {
        return _.find(this.answers, answer => answer.disclosureCategoryId === disclosureCategoryId);
    }

    save(closeDialog = true): void {
        if (this.checkboxGroup?.formGroup) {
            if (this.checkboxGroup.formGroup.invalid) {
                this.checkboxGroup.formGroup.markAllAsTouched();
            } else if (!this.requestsInProgress) {
                this.requestsInProgress = true;
                const observables: Observable<DisclosureAuthorization>[] = [];
                const updatedAnswers = this.getUpdatedAnswersFromForm(this.checkboxGroup.formGroup);

                _.forEach(updatedAnswers, updatedAnswer => {
                    if (_.get(updatedAnswer, 'metadata')) {
                        observables.push(this.disclosureAuthorizationService.updateAnswer(updatedAnswer));
                    } else if (this.staffEdit) {
                        observables.push(this.disclosureAuthorizationService.createForStudent(this.person.id, updatedAnswer));
                    } else {
                        observables.push(this.disclosureAuthorizationService.createNewAnswer(updatedAnswer));
                    }
                });

                observables.length > 0
                    ? combineLatest(observables)
                        .pipe(
                            take(1),
                            finalize(() => this.requestsInProgress = false),
                            this.appErrorHandler.defaultErrorHandler())
                        .subscribe(() => this.stopEditing(closeDialog))
                    : this.dialogService.close();
            }
        } else {
            this.reset.emit();
        }
    }

    private stopEditing(closeDialog = true): void {
        this.reset.emit();
        if (closeDialog) {
            this.dialogService.close();
        }
    }

    getUpdatedAnswersFromForm(formAnswers: FormGroup<FudisCheckboxGroupFormGroup<object>>): DisclosureAuthorization[] {
        const updatedAnswers: DisclosureAuthorization[] = [];

        Object.keys(formAnswers.controls).forEach(controlId => {
            _.forEach(this.initialValues.answers, initialAnswer => {
                // If staff edit answers only changed answers are pushed to updatedAnswers array.
                if (initialAnswer.id === controlId && !(this.staffEdit && initialAnswer.authorized === formAnswers.controls[controlId].value)) {
                    updatedAnswers.push(this.createAnswer(controlId, formAnswers.controls[controlId].value));
                }
            });
        });
        return updatedAnswers;
    }

    createAnswer(updatedId: string, updatedValue: boolean): DisclosureAuthorization {
        return {
            disclosureCategoryId: updatedId,
            authorized: updatedValue,
            privatePersonId: this.person.id,
            changedByPersonId: this.authService.personId(),
            documentState: _.get(this.getAnswer(updatedId), 'documentState', undefined),
            id: _.get(this.getAnswer(updatedId), 'id', undefined),
            metadata: _.get(this.getAnswer(updatedId), 'metadata', undefined),
        };
    }
}
