<div class="row sis-mb-lg">
  <div class="col-12">
    <education-structure-preview [educationModel]="previewModel" [connectionModel]="connectionModel">
      <left-boxes>
        <article *ngIf="education && studyRight">
          <div class="row sis-label d-inline-block" role="heading" [attr.aria-level]="level">
            {{education.structure.phase1.name | localizedString }}
            <ng-container *ngIf="studyRight.personalizedSelectionPath?.phase1" [ngTemplateOutlet]="personalizedBadge"/>
          </div>
          <ng-container *ngFor="let optionWrapper of leftOptions">
            <ng-container *ngTemplateOutlet="optionTemplate; context: {optionWrapper: optionWrapper, phaseId: 'phase1'}"/>
          </ng-container>
        </article>
      </left-boxes>
      <right-boxes>
        <article *ngIf="education && studyRight">
          <div *ngIf="education.structure.phase2" class="col-row sis-label d-inline-block" role="heading" [attr.aria-level]="level">
            {{ education.structure.phase2.name | localizedString }}
            <ng-container *ngIf="studyRight.personalizedSelectionPath?.phase2" [ngTemplateOutlet]="personalizedBadge"/>
          </div>
        <ng-container *ngFor="let optionWrapper of rightOptions">
          <ng-container *ngTemplateOutlet="optionTemplate; context: {optionWrapper: optionWrapper, phaseId: 'phase2'}"/>
        </ng-container>
        </article>
      </right-boxes>
    </education-structure-preview>
  </div>
  <div class="col-12 sis-my-lg" aria-hidden="true">
    <div class="row">
      <div class="col-md-6">
        <sis-icon icon="arrow-solid" color="primary"/>
        = <span class="sis-guidance sis-ml-xs" translate="PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.SELECTIONS_MATCHING_CURRENT_PLAN"></span>
      </div>
      <div class="col-md-6">
        <sis-icon icon="check" color="primary"/>
        = <span class="sis-guidance sis-ml-xs" translate="PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.CONFIRMED"></span>
      </div>
      <div class="col-md-6">
        <sis-icon icon="arrow-dashed"/>
        = <span class="sis-guidance sis-ml-xs" translate="PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.LEARNING_OPPORTUNITY"></span>
      </div>
      <div class="col-md-6">
        <sis-icon icon="hourglass"/>
        = <span class="sis-guidance sis-ml-xs" translate="PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.ACTIVE_PHASE"></span>
      </div>
      <div class="col-md-6">
        <sis-icon icon="lock"/>
        = <span class="sis-guidance sis-ml-xs" translate="PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.REQUIRES_APPROVAL"></span><br/>
      </div>
      <div class="col-md-6">
        <sis-icon icon="achievement"/>
        = <span class="sis-guidance sis-ml-xs" translate="PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.ATTAINED_DEGREE"></span>
      </div>
    </div>
  </div>
</div>

<ng-template #optionTemplate let-optionWrapper="optionWrapper" let-phaseId="phaseId">
  <div class="studymodule cu-color-{{optionWrapper.option.color}}"
       [class.studymodule-narrow]="optionWrapper.option.size === 'small'"
       [class.dimmed]="!optionWrapper.isInLearningOpportunityAllowedPaths">
    <div class="studymodule-top-row" *ngIf="optionWrapper.option.moduleGroupId|moduleGroup|async as module">
      <div class="title-box">
        <span class="studymodule-title">
          <sis-icon icon="check" color="primary" *ngIf="optionWrapper.option.matchesAcceptedSelectionPath"
                    [attr.aria-label]="'PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.CONFIRMED' | translate"/>
          <sis-icon icon="lock" *ngIf="optionWrapper.option.acceptanceType==='ACCEPTED_BY_TEACHER'"
                    [attr.aria-label]="'PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.REQUIRES_APPROVAL' | translate"/>
          {{module.name|localizedString}}
        </span>
      </div>

      <div *ngIf="optionWrapper.option.size !== 'small'" class="extra-items-holder">
        <div *ngIf="optionWrapper.isActivePhase" class="grade-box">
          <sis-icon icon="hourglass" [attr.aria-label]="'PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.ACTIVE_PHASE' | translate"/>
        </div>
        <div *ngIf="dpAttainmentsByGroupId[optionWrapper.option.moduleGroupId]" class="grade-box">
          <sis-icon icon="achievement" [attr.aria-label]="'PLAN_STUDY_RIGHT_MODAL.EXPLANATIONS.ATTAINED_DEGREE' | translate"/>
        </div>
        <div class="credits-box">
          <span class="credit-amount">
            {{toStudyModule(module).targetCredits|creditRange: 'TEXT' : true}}
          </span>
          <span class="credit-abbreviation" translate="CREDITS"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #personalizedBadge>
  <sis-tiny-badge [variant]="'success'" [attr.data-cy]="'personalized-badge'">
    {{ 'PROFILE.STUDY_PATH.PERSONAL_BADGE' | translate }}
  </sis-tiny-badge>
</ng-template>
