import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaxLength } from 'common-typescript/constants';
import { CustomAttainmentWorkflowApplication, CustomStudyDraft, OtmId, PriorLearningInclusionWorkflowApplication } from 'common-typescript/types';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service';
import { maxLength, required } from 'sis-components/form/form-validators';
import { getLabelState } from 'sis-components/form/formUtils';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

import { SelectedModule } from '../plan-module-selector/plan-module-selector.component';

export interface FormData {
    plannedParentModuleId: OtmId;
    degreeProgrammeId: OtmId;
    degreeProgrammeGroupId: OtmId;
    name: string;
}

@Component({
    selector: 'app-custom-attainment-basic-info-edit',
    templateUrl: './custom-attainment-basic-info-edit.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomAttainmentBasicInfoEditComponent implements OnInit {

    @Input() workflowApplication: Partial<PriorLearningInclusionWorkflowApplication | CustomAttainmentWorkflowApplication>;
    @Input() planId: OtmId;
    @Input() customStudyDraft: CustomStudyDraft;
    @Output() exit = new EventEmitter<void>();
    @Output() continue = new EventEmitter<FormData>();

    form: FormGroup;

    getLabelState = getLabelState;

    constructor(
        private alertsService: AlertsService,
        private fb: SisFormBuilder,
    ) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            plannedParentModuleId: this.fb.control(this.workflowApplication?.plannedParentModuleId || this.customStudyDraft?.parentModuleId, required()),
            degreeProgrammeId: this.fb.control(this.workflowApplication?.degreeProgrammeId),
            degreeProgrammeGroupId: this.fb.control(this.workflowApplication?.degreeProgrammeGroupId),
            name: this.fb.control(this.workflowApplication?.name || this.customStudyDraft?.name, [required(), maxLength(MaxLength.MAX_SHORT_STRING_LENGTH)]),
        });
    }

    onModuleSelect(selection: SelectedModule): void {
        this.form.patchValue({
            plannedParentModuleId: selection?.module?.id,
            degreeProgrammeId: selection?.degreeProgramme?.id,
            degreeProgrammeGroupId: selection?.degreeProgramme?.groupId,
        });
        // Mark the control dirty in order to update the state of the label icon
        this.plannedParentModuleId?.markAsDirty();
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.continue.emit({ ...this.form.value });
        } else {
            this.form.markAllAsTouched();
            this.alertsService.addFormSubmissionFailureAlert();
        }
    }

    get plannedParentModuleId(): FormControl {
        return this.form?.get('plannedParentModuleId') as FormControl;
    }

    get name(): FormControl {
        return this.form?.get('name') as FormControl;
    }
}
