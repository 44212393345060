import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
    CourseUnit,
    EnrolmentRight,
} from 'common-typescript/types';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CourseUnitInfoService } from 'sis-components/courseUnitInfo/course-unit-info.service';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';

@Component({
    selector: 'app-different-version-purchases',
    templateUrl: './different-version-purchases.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DifferentVersionPurchasesComponent implements OnInit, OnDestroy {

    /** Enrolment rights for different versions of the given currentCourseUnit, as grouped by common courseUnit groupId */
    @Input() courseUnitGroupEnrolmentRights: EnrolmentRight[];
    /** Course unit being viewed. This course unit will NOT be listed in the different purchases - only those that differ from it. */
    @Input() currentCourseUnit: CourseUnit;

    private readonly destroyed$ = new Subject<void>();
    private _versionsNames$: Observable<string[]>;

    constructor(
        private appErrorHandler: AppErrorHandler,
        private courseUnitInfoService: CourseUnitInfoService,
    ) { }

    ngOnInit(): void {
        if (this.courseUnitGroupEnrolmentRights?.length > 0 && this.currentCourseUnit) {
            this._versionsNames$ = this.courseUnitInfoService.getSelectableVersionsAndNamesByCu(this.currentCourseUnit).pipe(
                map(([versions, displayNames]) => [
                    ...(new Set(this.courseUnitGroupEnrolmentRights
                        .filter(er => er.courseUnitId !== this.currentCourseUnit.id)
                        .map(er => er.courseUnitId))
                    )].map(courseUnitId => displayNames[courseUnitId])
                    .filter(name => name !== undefined)),
                takeUntil(this.destroyed$),
                this.appErrorHandler.defaultErrorHandler(),
            );
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    get versionNames$(): Observable<string[]> {
        return this._versionsNames$;
    }
}
