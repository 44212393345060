import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ValidatablePlan } from 'common-typescript';
import {
    CourseUnit,
    CourseUnitAttainment,
    CourseUnitSelection, LocalId, OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { take } from 'rxjs/operators';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';

import {
    CompletionMethodSelections,
    SelectCompletionMethodComponent,
} from '../../../plan/select-completion-method/select-completion-method.component';
import { AttainmentStudentModalService } from '../../service/attainment-student-modal-service';

interface DialogActions {
    openSelectCompletionMethodModal: boolean
}

@Component({
    selector: 'app-course-unit-attainment-notification',
    templateUrl: './course-unit-attainment-notification.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitAttainmentNotificationComponent {
    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.courseUnitAttainmentNotification',
        directiveName: 'appCourseUnitAttainmentNotification',
    };

    private attainmentModalService = inject(AttainmentStudentModalService);
    private modalService = inject(ModalService);

    @Input({ required: true }) courseUnitAttainment: CourseUnitAttainment;
    @Input() courseUnitSelection: CourseUnitSelection;
    @Input() courseUnit: CourseUnit;
    @Input() validatablePlan: ValidatablePlan;
    @Output() selectCompletionMethod = new EventEmitter<CompletionMethodSelections>();

    constructor(
        private courseUnitEntityService: CourseUnitEntityService,
        private appErrorHandler: AppErrorHandler,
    ) {}

    openAttainmentDetailsModal(): void {
        this.attainmentModalService.openAttainmentDetailsModal(this.courseUnitAttainment, true, false, this.courseUnitSelection.gradeRaiseAttempt).subscribe(
            {
                next: ref => {
                    ref.closed.subscribe((val: DialogActions) => {
                        if (val?.openSelectCompletionMethodModal) this.openCompletionMethodComponent();
                    });
                },
                error: () => {},
            },
        );
    }

    openCompletionMethodComponent(): void {
        if (this.courseUnitSelection.gradeRaiseAttempt) {
            this.getCourseUnit(this.courseUnitSelection.gradeRaiseAttempt.courseUnitId).subscribe((courseUnit) => {
                this.openCompletionMethodSelection(courseUnit, this.courseUnitSelection.gradeRaiseAttempt.completionMethodId, this.courseUnitSelection.gradeRaiseAttempt.selectedAssessmentItemIds);
            });
        } else {
            this.openCompletionMethodSelection(this.courseUnit, null, _.map(this.validatablePlan.getSelectedAssessmentItems(this.courseUnit), (ai) => ai.id));
        }
    }

    openCompletionMethodSelection(courseUnit: CourseUnit, completionMethodId: LocalId, assessmentItemSelections: OtmId[]) {
        this.modalService.open(SelectCompletionMethodComponent, {
            assessmentItemSelections,
            validatablePlan: this.validatablePlan,
            completionMethodId,
            courseUnit,
            isGradeRaiseAttempt: true,
            gradeRaiseAttempt: this.courseUnitSelection.gradeRaiseAttempt,
        }, { size: 'sm' }).closed
            .subscribe((completionMethodSelections: CompletionMethodSelections) => {
                this.selectCompletionMethod.emit(completionMethodSelections);
            });
    }

    getCourseUnit(courseUnitId: OtmId) {
        return this.courseUnitEntityService.getById(courseUnitId)
            .pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler());
    }
}
