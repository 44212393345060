import {
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { BadgeVariant } from '../badge/tiny-badge/tiny-badge.component';
import { SlideBanner } from '../carousel/carousel.types';
import { DescribedByContentDirective } from '../util/described-by-content.directive';

@Component({
    selector: 'sis-carousel-selection-box-slide',
    templateUrl: './carousel-selection-box-slide.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CarouselSelectionBoxSlideComponent implements OnInit {

    @ViewChild('inputRef') private inputRef: ElementRef<HTMLDivElement>;
    @ContentChildren(DescribedByContentDirective) private describedByContent: QueryList<DescribedByContentDirective>;
    fontSizeClass = '';
    minHeightClass = '';
    ready: boolean;

    @Input() translatedTitle: string;
    @Input() value: any;
    @Input() control: FormControl;
    @Input() id: string;
    /**
     * The name is used to group carousel slide inputs together. Therefore, it should be the same for all slides in the same carousel.
     */
    @Input() name: string;
    /** Badge shown below the title. Accepts a translation key. */
    @Input() titleBadgeText?: string;
    @Input() titleBadgeVariant?: BadgeVariant = 'secondary';
    /**
     * Optional banners to be shown between slide inputs and projected content
     */
    @Input() banners?: SlideBannerWithId[];
    /**
     * Event to be listened if needed, emits the id of the slide when selected
     */
    @Output() selectedIdEvent = new EventEmitter<string>();
    @Output() slideKeyEvent = new EventEmitter<KeyboardEvent>();

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit() {
        this.adjustFontSize();
        this.initBannerIds();
        this.ready = true;
    }

    adjustFontSize() {
        const translatedTitleLength = this.translatedTitle?.length ?? 0;
        const fontAdjustIndex = Math.min(Math.floor(translatedTitleLength / 20), 3);
        this.fontSizeClass = fontAdjustIndex > 0 ? `font-adjust-${fontAdjustIndex}` : '';
    }

    // exists basically just to make mocking in tests a bit easier
    getDescribedByContent() {
        return this.describedByContent?.toArray();
    }

    // called by carousel after (content and) view init
    afterCarouselViewInit(slideComponents: CarouselSelectionBoxSlideComponent[]) {
        // adjust input min height based on all slides max content
        const inputHeights = slideComponents?.map(sc => sc.getInputHeight());
        const heightAdjustIndex = inputHeights?.length > 0 ? Math.min(Math.ceil((Math.max(...inputHeights) - 55) / 25), 4) : 0;
        this.minHeightClass = heightAdjustIndex > 0 ? `height-adjust-${heightAdjustIndex}` : '';
        this.ref.detectChanges();
    }

    selectSlide(allowFocus = true) {
        if (this.control.value === this.value) {
            return; // no need to do anything, if the value hasn't changed
        }
        this.control.setValue(this.value); // set value explicitly because calling inputElement.click() isn't enough for Firefox
        this.selectedIdEvent.emit(this.id);
        if (allowFocus) {
            // to prevent unnecessary jumping when clicking a slide, call input's focus() with option preventScroll: true
            this.inputRef.nativeElement.getElementsByTagName('input')[0]?.focus({ preventScroll: true });
        }
    }

    get ariaDescribedBy() {
        let describedByIds = this.getDescribedByContent()?.map(dbc => dbc.id);
        if (this.banners) {
            describedByIds = Array.of(...this.banners.map(banner => banner.id), ...describedByIds);
        }
        return describedByIds.length > 0 ? describedByIds.join(' ') : undefined;
    }

    getInputHeight(): number {
        return this.inputRef.nativeElement.offsetHeight;
    }

    onSlideKeydown($keyEvent: KeyboardEvent) {
        this.slideKeyEvent.emit($keyEvent);
    }

    private initBannerIds() {
        this.banners?.forEach(banner => banner.id = `${this.id}-banner-${banner.className}`);
    }
}

export interface SlideBannerWithId extends SlideBanner {
    id?: string;
}
