import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
    AssessmentItem,
    CourseUnit,
    CourseUnitRealisation,
    EnrolmentCalculationConfig,
    OtmId,
} from 'common-typescript/types';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { CourseUnitEntityService } from 'sis-components/service/course-unit-entity.service';
import {
    EnrolmentCalculationConfigEntityService,
} from 'sis-components/service/enrolment-calculation-config-entity.service';
import { Tab } from 'sis-components/tabs/tab-content-switch/tab-content-switch.component';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'app-course-unit-realisation-info',
    templateUrl: './course-unit-realisation-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseUnitRealisationInfoComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.courseUnitInfoModal.courseUnitRealisation.courseUnitRealisationInfo',
        directiveName: 'appCourseUnitRealisationInfo',
    };

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() courseUnit: CourseUnit;
    @Input() level = 4;
    @Input() currentTabIndex = 0;

    tabs: Tab[] = [
        { title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.BASIC_INFO' },
        { title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.ENROLMENT_INFO' },
        { title: 'SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.TAB_HEADINGS.STUDY_GROUPS' },
    ];

    enrolmentCalculationConfig$: Observable<EnrolmentCalculationConfig>;
    alternativeCourseUnits$: Observable<CourseUnit[]>;
    assessmentItemsByCourseUnitId$: Observable<Map<OtmId, AssessmentItem[]>>;

    constructor(private enrolmentCalculationConfigService: EnrolmentCalculationConfigEntityService,
                private courseUnitService: CourseUnitEntityService,
                private assessmentItemService: AssessmentItemEntityService,
                private appErrorHandler: AppErrorHandler) {}

    ngOnInit() {
        this.enrolmentCalculationConfig$ = this.enrolmentCalculationConfigService.getEnrolmentCalculationConfigOrNull(this.courseUnitRealisation.id).pipe(
            this.appErrorHandler.defaultErrorHandler(),
        );
        this.getAlternativeCourseUnitsAndAssessmentItems();
    }

    getAlternativeCourseUnitsAndAssessmentItems() {
        const assessmentItemIds = _.get(this.courseUnitRealisation, 'assessmentItemIds');
        this.alternativeCourseUnits$ = this.courseUnitService.getByAssessmentItemIdsStudent(assessmentItemIds).pipe(
            this.appErrorHandler.defaultErrorHandler(),
            map((courseUnits) => _.chain(courseUnits)
                .reject({ id: this.courseUnit.id })
                .uniqBy('id')
                .value()),
            tap((courseUnits) => {
                this.assessmentItemsByCourseUnitId$ = this.assessmentItemService.getByIds(assessmentItemIds).pipe(
                    this.appErrorHandler.defaultErrorHandler(),
                    map((assessmentItems) => {
                        const assessmentItemsByCourseUnitId = new Map<OtmId, AssessmentItem[]>();
                        _.each(courseUnits, (courseUnit) => {
                            assessmentItemsByCourseUnitId.set(courseUnit.id, this.getCourseUnitAssessmentItems(courseUnit, assessmentItems));
                        });
                        return assessmentItemsByCourseUnitId;
                    }),
                );
            }),
        );
    }

    private getCourseUnitAssessmentItems(courseUnit: CourseUnit, assessmentItems: AssessmentItem[]) {
        const assessmentItemIds = _.flattenDeep(_.map(courseUnit.completionMethods, 'assessmentItemIds'));
        return _.filter(assessmentItems, assessmentItem => _.indexOf(assessmentItemIds, assessmentItem.id) > -1);
    }

    hasCoordinatingOrganisations() {
        return _.some(
            this.courseUnitRealisation.organisations,
            { roleUrn: 'urn:code:organisation-role:coordinating-organisation' },
        );
    }
}
