import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxFudisModule } from '@funidata/ngx-fudis';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { SisCommonModule } from 'sis-common/sis-common.module';
import { EnrolmentModule } from 'sis-components/enrolment/enrolment.module';
import { SisComponentsHybridModule } from 'sis-components/sis-components.module.hybrid';

import { courseUnitInfoAsPageStates } from './components/course-unit-info-as-page/course-unit-info-as-page.route';
import { CourseUnitInfoHeaderComponent } from './components/course-unit-info-as-page/course-unit-info-header/course-unit-info-header.component';
import { CourseUnitInfoNavigationComponent } from './components/course-unit-info-as-page/course-unit-info-navigation/course-unit-info-navigation.component';
import { CourseUnitInfoComponent } from './components/course-unit-info-as-page/course-unit-info/course-unit-info.component';
import { CourseUnitInfoModalHeaderInfoListComponent } from './components/course-unit-info-modal/course-unit-info-modal-header/course-unit-info-modal-header-info-list/course-unit-info-modal-header-info-list.component';
import { CourseUnitInfoModalHeaderTabsComponent } from './components/course-unit-info-modal/course-unit-info-modal-header/course-unit-info-modal-header-tabs/course-unit-info-modal-header-tabs.component';
import { CourseUnitInfoModalHeaderVersionSelectorComponent } from './components/course-unit-info-modal/course-unit-info-modal-header/course-unit-info-modal-header-version-selector/course-unit-info-modal-header-version-selector.component';
import { CourseUnitInfoModalHeaderComponent } from './components/course-unit-info-modal/course-unit-info-modal-header/course-unit-info-modal-header.component';
import { CourseUnitInfoModalPriorLearningComponent } from './components/course-unit-info-modal/course-unit-info-modal-substitutions/course-unit-info-modal-prior-learning/course-unit-info-modal-prior-learning.component';
import { CourseUnitInfoModalSubstitutionCorrespondencesComponent } from './components/course-unit-info-modal/course-unit-info-modal-substitutions/course-unit-info-modal-substitution-correspondences/course-unit-info-modal-substitution-correspondences.component';
import { CourseUnitInfoModalSubstitutionsCorrespondencesSelectFormComponent } from './components/course-unit-info-modal/course-unit-info-modal-substitutions/course-unit-info-modal-substitutions-correspondences-select-form/course-unit-info-modal-substitutions-correspondences-select-form.component';
import { CourseUnitInfoModalSubstitutionsComponent } from './components/course-unit-info-modal/course-unit-info-modal-substitutions/course-unit-info-modal-substitutions.component';
import { CourseUnitRealisationInfoComponent } from './components/course-unit-info-modal/course-unit-realisation/course-unit-realisation-info/course-unit-realisation-info.component';
import { StudentCourseUnitRealisationStructureComponent } from './components/course-unit-info-modal/course-unit-realisation/student-course-unit-realisation-structure/student-course-unit-realisation-structure.component';
import { StudentStudyEventExceptionsComponent } from './components/course-unit-info-modal/course-unit-realisation/student-study-event-exceptions/student-study-event-exceptions.component';
import { StudentStudyEventStructureComponent } from './components/course-unit-info-modal/course-unit-realisation/student-study-event-structure/student-study-event-structure.component';
import { StudentStudyEventSummaryComponent } from './components/course-unit-info-modal/course-unit-realisation/student-study-event-summary/student-study-event-summary.component';
import { StudentStudySubGroupStructureComponent } from './components/course-unit-info-modal/course-unit-realisation/student-study-sub-group-structure/student-study-sub-group-structure.component';
import { CourseUnitInfoModalComponent } from './components/course-unit-info-modal/courseUnitInfoModal.component';
import { CourseUnitInfoModalDirective } from './components/course-unit-info-modal/courseUnitInfoModal.dir.upgraded';
import { SelectAssessmentItemModalComponent } from './components/course-unit-info-modal/selectAssessmentItemModal.component';
import { SelectAssessmentItemModalDirective } from './components/course-unit-info-modal/selectAssessmentItemModal.dir.upgraded';
import {
    StudentCourseUnitInfoModalHeaderComponent,
} from './components/course-unit-info-modal/student-course-unit-info-modal/student-course-unit-info-modal-header/student-course-unit-info-modal-header.component';
import { StudentCourseUnitInfoModalComponent } from './components/course-unit-info-modal/student-course-unit-info-modal/student-course-unit-info-modal.component';
import { CompletionMethodsListViewComponent } from './components/course-unit-info/completion-methods-list/completion-methods-list-view/completion-methods-list-view.component';
import { CompletionMethodsListComponent } from './components/course-unit-info/completion-methods-list/completion-methods-list.component';
import { CompletionMethodsNgComponent } from './components/course-unit-info/completion-methods-ng/completion-methods-ng.component';
import {
    CompletionMethodsDirective,
} from './components/course-unit-info/completion-methods-ng/completionMethods.component.upgraded';
import { CourseUnitInfoBasicInfoHeaderComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-basic-info-header/course-unit-info-basic-info-header.component';
import {
    CourseUnitInfoBrochureContainerComponent,
} from './components/course-unit-info/course-unit-info-brochure/course-unit-info-brochure-container/course-unit-info-brochure-container.component';
import { CourseUnitInfoBrochureComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-brochure.component';
import { CourseUnitInfoContentAndGoalsComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-content-and-goals/course-unit-info-content-and-goals.component';
import { CourseUnitInfoLearningMaterialsComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-learning-materials/course-unit-info-learning-materials.component';
import { CourseUnitInfoPrerequisitesComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-prerequisites/course-unit-info-prerequisites.component';
import { CourseUnitInfoResponsibilityTeachersContactInfoComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-responsibility-teachers-contact-info/course-unit-info-responsibility-teachers-contact-info.component';
import { CourseUnitInfoTitleClassificationComponent } from './components/course-unit-info/course-unit-info-brochure/course-unit-info-title-classification/course-unit-info-title-classification.component';
import { CancelOrAbortEnrolmentModalComponent } from './components/course-unit-realisation/enrolment/cancelOrAbortEnrolmentModal-ng.component';
import { CancelOrAbortEnrolmentModalDirective } from './components/course-unit-realisation/enrolment/cancelOrAbortEnrolmentModal.dir.upgraded';
import { ApplyForGraduationModalComponent } from './components/graduation/applyForGraduationModal-ng.component';
import { ApplyForGraduationModalDirective } from './components/graduation/applyForGraduationModal.dir.upgraded';
import { GraduationInstructionsUrlComponent } from './components/graduation/graduation-instructions-url/graduation-instructions-url.component';
import { GraduationSurveyModalComponent } from './components/graduation/graduation-survey-modal/graduation-survey-modal.component';
import { MandatoryGraduationSurveyModalComponent } from './components/graduation/mandatory-graduation-survey-modal/mandatory-graduation-survey-modal.component';
import { LearningEnvironmentsComponent } from './components/learning-environments/learning-environments.component';
import { ModuleInfoBasicInfoComponent } from './components/module-info/module-info-basic-info/module-info-basic-info.component';
import { ModuleInfoClassificationComponent } from './components/module-info/module-info-classification/module-info-classification.component';
import { ModuleInfoDescriptionComponent } from './components/module-info/module-info-description/module-info-description.component';
import {
    StudentModuleInfoModalCreateModuleAttainmentApplicationBlockComponent,
} from './components/module-info/student-module-info-modal/student-module-info-modal-create-module-attainment-application-block/student-module-info-modal-create-module-attainment-application-block.component';
import {
    StudentModuleInfoModalDPAttainmentApplicationBlockComponent,
} from './components/module-info/student-module-info-modal/student-module-info-modal-dp-attainment-application-block/student-module-info-modal-dp-attainment-application-block.component';
import { StudentModuleInfoModalGraduationInfoComponent } from './components/module-info/student-module-info-modal/student-module-info-modal-graduation-info/student-module-info-modal-graduation-info.component';
import { StudentModuleInfoModalHeaderComponent } from './components/module-info/student-module-info-modal/student-module-info-modal-header/student-module-info-modal-header.component';
import {
    StudentModuleInfoModalModuleAttainmentApplicationsBlockComponent,
} from './components/module-info/student-module-info-modal/student-module-info-modal-module-attainment-applications-block/student-module-info-modal-module-attainment-applications-block.component';
import { StudentModuleInfoModalComponent } from './components/module-info/student-module-info-modal/student-module-info-modal.component';
import { PlanSelectComponent } from './components/plan-select/plan-select.component';
import { RipaEnrolmentInformationComponent } from './components/ripa-enrolment-information/ripa-enrolment-information.component';
import { ApplyForModuleContentWorkflowComponent } from './components/show-view-module-content-application-link/apply-for-module-content-workflow/apply-for-module-content-workflow.component';
import { StudentTopNavigationComponent } from './components/student-top-navigation/student-top-navigation.component';
import { StudyRightExtensionRequirementsModalComponent } from './components/study-right-extension-requirements-modal/study-right-extension-requirements-modal.component';
import { CourseUnitRealisationNamePipe } from './pipes/course-unit-realisation-name.pipe';

@NgModule({
    declarations: [
        ApplyForGraduationModalComponent,
        ApplyForGraduationModalDirective,
        CancelOrAbortEnrolmentModalComponent,
        CancelOrAbortEnrolmentModalDirective,
        CourseUnitInfoModalComponent,
        CourseUnitInfoModalDirective,
        CourseUnitRealisationNamePipe,
        PlanSelectComponent,
        SelectAssessmentItemModalComponent,
        SelectAssessmentItemModalDirective,
        StudentCourseUnitRealisationStructureComponent,
        StudentStudyEventStructureComponent,
        StudentStudySubGroupStructureComponent,
        StudentStudyEventSummaryComponent,
        StudentStudyEventExceptionsComponent,
        StudentTopNavigationComponent,
        CourseUnitInfoBrochureComponent,
        CourseUnitInfoBasicInfoHeaderComponent,
        CourseUnitInfoContentAndGoalsComponent,
        CourseUnitInfoPrerequisitesComponent,
        CourseUnitInfoLearningMaterialsComponent,
        CourseUnitInfoResponsibilityTeachersContactInfoComponent,
        CourseUnitInfoTitleClassificationComponent,
        CourseUnitInfoHeaderComponent,
        CourseUnitInfoModalHeaderComponent,
        CourseUnitInfoModalHeaderVersionSelectorComponent,
        CourseUnitInfoModalHeaderInfoListComponent,
        CourseUnitInfoModalHeaderTabsComponent,
        CourseUnitInfoModalSubstitutionsCorrespondencesSelectFormComponent,
        CourseUnitInfoModalPriorLearningComponent,
        CourseUnitInfoComponent,
        CourseUnitInfoNavigationComponent,
        CompletionMethodsListComponent,
        CompletionMethodsNgComponent,
        CompletionMethodsDirective,
        CourseUnitInfoBrochureContainerComponent,
        CourseUnitInfoHeaderComponent,
        GraduationSurveyModalComponent,
        CourseUnitInfoModalSubstitutionsComponent,
        CourseUnitInfoModalSubstitutionCorrespondencesComponent,
        GraduationInstructionsUrlComponent,
        ApplyForModuleContentWorkflowComponent,
        CompletionMethodsListViewComponent,
        LearningEnvironmentsComponent,
        RipaEnrolmentInformationComponent,
        StudyRightExtensionRequirementsModalComponent,
        StudentCourseUnitInfoModalComponent,
        StudentCourseUnitInfoModalHeaderComponent,
        StudentModuleInfoModalComponent,
        StudentModuleInfoModalHeaderComponent,
        ModuleInfoBasicInfoComponent,
        ModuleInfoClassificationComponent,
        ModuleInfoDescriptionComponent,
        StudentModuleInfoModalGraduationInfoComponent,
        StudentModuleInfoModalModuleAttainmentApplicationsBlockComponent,
        StudentModuleInfoModalCreateModuleAttainmentApplicationBlockComponent,
        StudentModuleInfoModalDPAttainmentApplicationBlockComponent,
        MandatoryGraduationSurveyModalComponent,
        CourseUnitRealisationInfoComponent,
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        SisCommonModule,
        SisComponentsHybridModule,
        UIRouterModule.forChild({ states: courseUnitInfoAsPageStates }),
        TranslateModule.forChild(),
        EnrolmentModule,
        NgxFudisModule,
    ],
    exports: [
        CourseUnitInfoBasicInfoHeaderComponent,
        CourseUnitRealisationNamePipe,
        PlanSelectComponent,
        GraduationInstructionsUrlComponent,
        LearningEnvironmentsComponent,
        ModuleInfoBasicInfoComponent,
        ModuleInfoClassificationComponent,
        ModuleInfoDescriptionComponent,
        CourseUnitRealisationInfoComponent,
    ],
})
export class StudentCommonModule {}
