<ng-container *transloco="let t">
    <div class="modal-header">
        <h2 class="modal-title">
            {{ t(values.title) }}
        </h2>
        <button type="button"
                class="modal-close"
                (click)="modalInstance.close()"
                [attr.aria-label]="(t('ARIA_LABEL.CLOSE'))">
            <sis-icon icon="ring-close"></sis-icon>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="values.completePaymentMsgAnchorElement">
            <sis-markup-string-view (click)="modalInstance.close()" [content]="values.completePaymentMsgAnchorElement"/>
        </p>
        <p *ngFor="let description of values.descriptions">
            {{ t(description) }}
        </p>
        <p>
            <sis-markup-string-view *ngIf="values.orderNumberMsg" [content]="values.orderNumberMsg"/>
        </p>
        <div *ngIf="values.contactOrganizer" class="row">
            <span>{{ t(values.contactOrganizer.msg) }}</span>
            <span *ngIf="values.contactOrganizer.link">
              <a fudisLink [href]="values.contactOrganizer.link" [external]="true" [title]="removeMailtoFromName(values.contactOrganizer.link)"></a>
            </span>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg"
                (click)="modalInstance.close()">
            {{ t('BUTTON.CLOSE') }}
        </button>
    </div>
</ng-container>
