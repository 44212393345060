import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/core';
import { StudyRightExtensionWorkflow, StudyRightExtensionWorkflowApplication, StudyRightExtensionWorkflowDecision } from 'common-typescript/types';

import { workflowCancellationDialogOpener } from '../cancel-workflow-dialog/cancel-workflow-dialog.component';

@Component({
    selector: 'app-study-right-extension-workflow',
    templateUrl: './study-right-extension-workflow.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightExtensionWorkflowComponent {

    @Input() studentWorkflow: StudyRightExtensionWorkflow;

    stateService = inject(StateService);

    private readonly openCancelWorkflowDialog = workflowCancellationDialogOpener();

    shouldShowCancelButton() {
        return ['REQUESTED', 'IN_HANDLING'].includes(this.studentWorkflow?.state);
    }

    openCancelDialog() {
        this.openCancelWorkflowDialog({
            workflowId: this.studentWorkflow?.id,
            workflowType: this.studentWorkflow?.type,
            successCallback: () => this.stateService.reload(),
        });
    }

    isWorkflowCancelled() {
        return this.studentWorkflow?.state === 'CANCELLED';
    }

    getApplication() {
        return this.studentWorkflow.application as StudyRightExtensionWorkflowApplication;
    }

    isWaitingForDecision() {
        return ['REQUESTED', 'IN_HANDLING'].includes(this.studentWorkflow?.state);
    }

    getDecision(): StudyRightExtensionWorkflowDecision {
        return this.studentWorkflow.decision as StudyRightExtensionWorkflowDecision;
    }
}
