import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CompletionMethod } from 'common-typescript/types';

import { AggregateProductInfo } from '../types';

@Component({
    selector: 'app-purchased-product-basic-info',
    templateUrl: './purchased-product-basic-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasedProductBasicInfoComponent {

    @Input({ required: true }) product: AggregateProductInfo;
    @Input({ required: true }) completionMethod: CompletionMethod;
}
