@if (identityProviders?.length === 1) {
  <div class="login--{{mobileMode ? 'mobile' : 'desktop'}}">
    <a class="login--{{mobileMode ? 'mobile' : 'desktop'}}__item"
       [href]="identityProviders[0].url"
       (keydown)="keydownPressed.emit($event)"
       (keydown.escape)="blurMobileLogin.emit($event)"
       (blur)="blurMobileLogin.emit($event)">
      {{ 'AUTH.LOGIN' | transloco }}
    </a>
  </div>
} @else if (identityProviders?.length > 1) {
  @if (mobileMode) {
    <div class="login-selection login-selection__mobile" role="group" aria-labelledby="login-selection">
      <div class="login-selection__header" aria-hidden="true" id="login-selection">{{ 'AUTH.LOGIN' | transloco }}</div>
      <a *ngFor="let option of identityProviders" fudisLink
         class="login-selection__item"
         [href]="option.url" role="menuitem"
         [title]="option.name | localizedString"
         (keydown)="keydownPressed.emit($event)"
         (keydown.escape)="blurMobileLogin.emit($event)"
         (blur)="blurMobileLogin.emit($event)">
      </a>
    </div>
  } @else {
    <sis-login-dropdown *ngIf="!mobileMode" [options]="identityProviders"/>
  }
}
