import { inject } from '@angular/core';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { map } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { lazyLoadModuleWithRetries } from 'sis-components/util/utils';

import { AddOwnCalendarEventModalValues } from './add-own-calendar-event-modal.component';

/**
 * Lazy loading opener for the AddOwnCalendarEventModalComponent.
 */
export function addOwnCalendarEventModalLazyOpener() {
    const fudisDialogService = inject(FudisDialogService);
    const appErrorHandler = inject(AppErrorHandler);
    return (modalValues?: AddOwnCalendarEventModalValues) => lazyLoadModuleWithRetries(import('./add-own-calendar-event-modal.component'))
        .pipe(
            appErrorHandler.defaultErrorHandler(),
            map((result) => fudisDialogService.open(
                result.AddOwnCalendarEventModalComponent,
                {
                    data: modalValues,
                })),
        );
}
