import { commonPlanServiceModule } from 'sis-components/service/plan.service';
(function () {
  planSubstitutionService.$inject = ["$log"];
  angular.module('student.common.service.planSubstitutionService', [commonPlanServiceModule]).factory('planSubstitutionService', planSubstitutionService);
  function planSubstitutionService($log) {
    const api = {
      removeSubstitutionSubstituteFor: (substitutedCourseUnitId, substitutingCourseUnitId, plan) => {
        const courseUnitSelectionForSubstitutingCourseUnit = plan.getCourseUnitSelection(substitutingCourseUnitId);
        if (courseUnitSelectionForSubstitutingCourseUnit) {
          // removed old substituteFor value
          _.remove(courseUnitSelectionForSubstitutingCourseUnit.substituteFor, {
            substitutedCourseUnitId: substitutedCourseUnitId
          });
          if (_.isEmpty(courseUnitSelectionForSubstitutingCourseUnit.substituteFor) && (!courseUnitSelectionForSubstitutingCourseUnit.parentModuleId || courseUnitSelectionForSubstitutingCourseUnit.parentModuleId === null)) {
            // removed empty courseUnitSelection
            _.remove(plan.courseUnitSelections, {
              courseUnitId: substitutingCourseUnitId
            });
          }
        } else {
          $log.error('removeSubstitutionSubstituteFor > ' + 'courseUnitSelectionForSubstitutingCourseUnit not found for substitutingCourseUnitId', 'substitutedCourseUnitId', substitutedCourseUnitId, 'substitutingCourseUnitId', substitutingCourseUnitId);
        }
      }
    };
    return api;
  }
})();