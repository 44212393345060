<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.ACTIVE_STUDIES.TITLE')" [subtitle]="t('FRONTPAGE.ACTIVE_STUDIES.SUBTITLE')">
    <ng-container *ngIf="activeStudies$ | async as activeStudies; else loading">
      <app-collapsible-list [limit]="5"
                            [initiallyCollapsed]="initiallyCollapsed"
                            (collapsedChange)="onCollapsedChange($event)">
        @for (study of activeStudies; track study.courseUnitRealisation.id) {
          <ng-template [appCollapsibleListContent]="study.courseUnitRealisation.id">
            <sis-study-realisation-box
              [level]="3"
              [id]="study.courseUnitRealisation.id"
              [realisationName]="study.courseUnitRealisation.name"
              [activityPeriod]="study.courseUnitRealisation.activityPeriod"
              [code]="study.courseUnit?.code"
              [name]="study.courseUnit?.name"
              [credits]="study.assessmentItem?.credits"
              [disableBoxMaxWidth]="true"
              (nameClickAction)="openCourseUnitInfoModal(study)"
              [class.sis-mb-sm]="!$last"
              [showCancelledBadge]="isCurCancelled(study)"
            >
              <sis-study-notification-box *ngIf="!isCurCancelled(study) && !!study.courseUnitRealisation.learningEnvironments?.length"
                                          type="none"
                                          study-box="notifications">
                <app-learning-environments
                  [learningEnvironments]="study.courseUnitRealisation.learningEnvironments"
                  class="d-block sis-pl-xs"
                />
              </sis-study-notification-box>

              <sis-study-notification-box *ngIf="isCurCancelled(study)">
                {{ 'ENROLMENTS.CUR_CANCELLED' | translate  }}
              </sis-study-notification-box>
            </sis-study-realisation-box>
          </ng-template>
        }
      </app-collapsible-list>

      <p *ngIf="!activeStudies?.length && !searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
        {{t('FRONTPAGE.ACTIVE_STUDIES.NO_ITEMS')}}
      </p>
    </ng-container>

    <p *ngIf="searchFailed()" class="app-widget-container__content__info-text sis-mb-sm">
      {{t('FRONTPAGE.ACTIVE_STUDIES.FETCHING_STUDIES_FAILED')}}
    </p>
  </app-widget-container>
</ng-container>

<ng-template #loading>
  <div aria-busy="true" class="d-flex justify-content-center">
    <sis-small-spinner />
  </div>
</ng-template>
