import { Component, DestroyRef, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService, FudisValidators } from '@funidata/ngx-fudis';
import { FudisSelectOption } from '@funidata/ngx-fudis/lib/types/forms';
import { TranslocoService } from '@ngneat/transloco';
import { CurriculumPeriod, Education, ModuleSelection, OtmId, Plan, StudyRight } from 'common-typescript/types';
import moment from 'moment';
import {
    combineLatest,
    combineLatestWith,
    distinctUntilKeyChanged,
    exhaustMap,
    from,
    map,
    Observable, of,
    shareReplay,
    startWith,
    Subject,
    switchMap,
    tap, withLatestFrom,
} from 'rxjs';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { UuidService } from 'sis-common/uuid/uuid.service';
import { COMMON_PLAN_SELECTION_SERVICE } from 'sis-components/ajs-upgraded-modules';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';
import { CurriculumPeriodEntityService } from 'sis-components/service/curriculum-period-entity.service';
import { EducationEntityService } from 'sis-components/service/education-entity.service';
import { PlanEntityService } from 'sis-components/service/plan-entity.service';
import { StudyRightEntityService } from 'sis-components/service/study-right-entity.service';
import { UniversityService } from 'sis-components/service/university.service';

export function createPlanModalOpener(): () => MatDialogRef<CreatePlanModalComponent> {
    const fudisDialogService = inject(FudisDialogService);
    return () => fudisDialogService.open(CreatePlanModalComponent);
}

export interface CreatePlanData {
    studentStudyRights: StudyRight[];
    educationOptions: FudisSelectOption<Education>[];
    curriculumPeriodOptions: FudisSelectOption<CurriculumPeriod>[];
}

interface CreatePlanForm {
    education: FormControl<FudisSelectOption<Education> | null>
    curriculumPeriod: FormControl<FudisSelectOption<CurriculumPeriod> | null>
    learningOpportunityId: FormControl<OtmId | null>,
    name: FormControl<string | null>
}
@Component({
    selector: 'app-create-plan-modal',
    templateUrl: './create-plan-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CreatePlanModalComponent implements OnInit {
    private dialogService = inject(FudisDialogService);
    private fb = inject(SisFormBuilder);
    private translocoService = inject(TranslocoService);
    private studyRightEntityService = inject(StudyRightEntityService);
    private educationEntityService = inject(EducationEntityService);
    private curriculumPeriodEntityService = inject(CurriculumPeriodEntityService);
    private universityService = inject(UniversityService);
    private appErrorHandler = inject(AppErrorHandler);
    private localeService = inject(LocaleService);
    private destroyRef = inject(DestroyRef);
    private authService = inject(AuthService);
    private uuid = inject(UuidService);
    private commonPlanSelectionService = inject(COMMON_PLAN_SELECTION_SERVICE);
    private planEntityService = inject(PlanEntityService);

    form: FormGroup<CreatePlanForm>;

    data$: Observable<CreatePlanData>;
    submitSubject$: Subject<void> = new Subject();

    ngOnInit() {
        this.form = this.buildForm();
        const selectedEducation$ = this.form.controls.education.valueChanges.pipe(
            startWith(this.form.controls.education.value),
            tap(() => {
                if (this.form.controls.education.value !== null) {
                    this.form.controls.curriculumPeriod.setValue(null);
                    this.form.controls.name.setValue(this.getDefaultPlanName());
                    this.form.controls.learningOpportunityId.setValue(null);
                }
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.data$ = this.createDataObservable(selectedEducation$).pipe(shareReplay({ bufferSize: 1, refCount: true }));
        this.createDefaultCurriculumPeriodOptionSetterSubscription(selectedEducation$);
        this.createDefaultLearningOpportunitySetterSubscription(selectedEducation$);
        this.createSubmitSubscription();
        this.onEducationValueChanges();
    }

    /**
     * Check curriculum period and plan name inputs' disabled status according to education control value
     */
    onEducationValueChanges() {
        const educationControl = this.form.controls.education;

        educationControl.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef)).subscribe(value => {
            if (value) {
                this.form.controls.curriculumPeriod.enable();
                this.form.controls.name.enable();
            } else {
                this.form.controls.curriculumPeriod.disable();
                this.form.controls.name.disable();
            }
        });
        educationControl.updateValueAndValidity();
    }

    getDefaultPlanName(): string {
        return `${this.translocoService.translate('STUDY_PLAN_DEFAULT_NAME')} ${moment().format('DD.MM.YYYY')}`;
    }

    /**
     * Creates a subscription that submits the form when the submitSubject emits
     */
    createSubmitSubscription() {
        this.submitSubject$.pipe(
            takeUntilDestroyed(this.destroyRef),
            withLatestFrom(this.data$),
            exhaustMap(([,data]) => this.onSubmit(data).pipe(
                tap((savedPlan) => {
                    if (savedPlan === null) {
                        return;
                    }
                    this.dialogService.close(savedPlan);
                }),
                this.appErrorHandler.defaultErrorHandler(),
            )),
        ).subscribe();
    }

    /**
     * Creates a subscription that sets the default curriculum period option when education selection changes
     *
     * @param selectedEducation$ - Observable of the selected education
     */
    createDefaultCurriculumPeriodOptionSetterSubscription(selectedEducation$: Observable<FudisSelectOption<Education> | null>) {
        selectedEducation$.pipe(
            takeUntilDestroyed(this.destroyRef),
            combineLatestWith(this.data$.pipe(distinctUntilKeyChanged('curriculumPeriodOptions'))),
            tap(([selectedEducation, { curriculumPeriodOptions, studentStudyRights }]) => {
                this.form.controls.curriculumPeriod.setValue(this.getDefaultCurriculumPeriodOption(studentStudyRights, curriculumPeriodOptions, selectedEducation));
            })).subscribe();
    }

    /**
     * Returns the default curriculum period option based on the student's study rights and the selected education.
     * 1. If there are no curriculum period options or the education is not selected, return null.
     * 2. Find an active curriculum period using current date, or if the student has a study right starting in
     *    the future, use the starting date as a reference.
     * 3. If no active curriculum period is found, return the last curriculum period that has ended, or as a fallback the first curriculum period in the list.
     *
     * @param studentStudyRights All student study rights
     * @param curriculumPeriodOptions Selectable curriculum period options for the current education selection
     * @param selectedEducation The currently selected education
     */
    getDefaultCurriculumPeriodOption(studentStudyRights: StudyRight[], curriculumPeriodOptions: FudisSelectOption<CurriculumPeriod>[], selectedEducation: FudisSelectOption<Education>): FudisSelectOption<CurriculumPeriod> | null {
        if (curriculumPeriodOptions.length > 0 && selectedEducation) {
            const matchingStudyRight = studentStudyRights.find(studyRight => studyRight.educationId === selectedEducation.value.id);
            const nowMoment = moment();
            const studyRightStartDate = matchingStudyRight?.valid?.startDate;
            let referenceMoment = nowMoment;
            if (studyRightStartDate && nowMoment.isBefore(studyRightStartDate)) {
                referenceMoment = moment(studyRightStartDate);
            }
            const defaultCurriculumPeriod = curriculumPeriodOptions.find(curriculumPeriodOption => referenceMoment.isBetween(
                curriculumPeriodOption.value.activePeriod.startDate,
                curriculumPeriodOption.value.activePeriod.endDate,
                'days',
                '[)',
            ));
            if (defaultCurriculumPeriod) {
                return defaultCurriculumPeriod;
            }
            const lastCurriculumPeriodInPast = curriculumPeriodOptions.filter(curriculumPeriodOption =>
                referenceMoment.isAfter(curriculumPeriodOption.value.activePeriod.endDate)).pop();
            return lastCurriculumPeriodInPast || curriculumPeriodOptions[0];
        }
        return null;
    }

    /**
     * Creates a subscription that sets the default learning opportunity when education selection changes
     *
     * @param selectedEducation$ - Observable of the selected education
     */
    createDefaultLearningOpportunitySetterSubscription(selectedEducation$: Observable<FudisSelectOption<Education> | null>) {
        selectedEducation$.pipe(
            takeUntilDestroyed(this.destroyRef),
            combineLatestWith(this.data$.pipe(distinctUntilKeyChanged('studentStudyRights'))),
            tap(([selectedEducation, { studentStudyRights }]) => {
                if (selectedEducation === null) {
                    return;
                }
                const matchingStudyRight = studentStudyRights.find(studyRight => studyRight.educationId === selectedEducation.value.id);
                if (matchingStudyRight) {
                    this.form.controls.learningOpportunityId.setValue(matchingStudyRight.learningOpportunityId);
                }
            })).subscribe();
    }

    buildForm(): FormGroup<CreatePlanForm> {
        return this.fb.group({
            education: this.fb.control(null, ([
                FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
            ])),
            curriculumPeriod: this.fb.control(null, ([
                FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
            ])),
            learningOpportunityId: this.fb.control(null, ([
                FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
            ])),
            name: this.fb.control(null, ([
                FudisValidators.required(this.translocoService.translate('SIS_COMPONENTS.COMMON_VALIDATION_ERRORS.REQUIRED')),
            ])),
        });
    }

    createDataObservable(selectedEducation$: Observable<FudisSelectOption<Education> | null>): Observable<CreatePlanData> {
        const studentStudyRights$ = this.studyRightEntityService.getStudyRightsForCurrentUser().pipe(
            map(studyRights => studyRights.filter(studyRight => ['ACTIVE', 'ACTIVE_NONATTENDING', 'NOT_STARTED', 'PASSIVE'].includes(studyRight.state))),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        const studyRightEducations$ = studentStudyRights$.pipe(
            switchMap(studyRights =>
                this.educationEntityService.getByIds(studyRights.map(studyRight => studyRight.educationId))),
        );
        const educationOptions$ = studyRightEducations$.pipe(map((educations) => educations.map(education => ({
            label: this.localeService.localize(education.name),
            value: education,
        } as FudisSelectOption<Education>))));
        const allCurriculumPeriods$ = this.curriculumPeriodEntityService.getByUniversityOrgId(this.universityService.getCurrentUniversityOrgId()).pipe(
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        const curriculumPeriodOptions$ = allCurriculumPeriods$.pipe(
            combineLatestWith(selectedEducation$, studentStudyRights$),
            map(([curriculumPeriods, selectedEducation, studentStudyRights]) => {
                if (selectedEducation === null) {
                    return [];
                }
                const matchingStudyRight = studentStudyRights.find(studyRight => studyRight.educationId === selectedEducation?.value?.id);
                const startMoment = moment(matchingStudyRight?.valid.startDate) ?? moment();
                const endMoment = moment().add(6, 'months');
                return curriculumPeriods.filter(curriculumPeriod => {
                    const curriculumPeriodStartMoment = moment(curriculumPeriod.activePeriod.startDate);
                    const curriculumPeriodEndMoment = moment(curriculumPeriod.activePeriod.endDate);

                    return ((startMoment.isBetween(curriculumPeriodStartMoment, curriculumPeriodEndMoment, 'days', '[)')) ||
                        (curriculumPeriodStartMoment.isBetween(startMoment, endMoment, 'days', '[)')));

                });

            }),
            map((curriculumPeriods) => (curriculumPeriods.map((curriculumPeriod) => ({
                label: this.localeService.localize(curriculumPeriod.name),
                value: curriculumPeriod,
            } as FudisSelectOption<CurriculumPeriod>)))),
        );

        return combineLatest({
            studentStudyRights: studentStudyRights$,
            educationOptions: educationOptions$,
            curriculumPeriodOptions: curriculumPeriodOptions$,
        }).pipe(
            this.appErrorHandler.defaultErrorHandler(),
        );
    }

    onSubmit(data: CreatePlanData): Observable<Plan | null> {
        if (this.form.valid) {
            const educationId = this.form.controls.education.value.value.id;
            const planObject: Partial<Plan> = {
                rootId: educationId,
                curriculumPeriodId: this.form.controls.curriculumPeriod.value.value.id,
                learningOpportunityId: this.form.controls.learningOpportunityId.value,
                name: this.form.controls.name.value,
                userId: this.authService.personId(),
                id: this.uuid.randomOtmId(),
                moduleSelections: [
                    {
                        moduleId: educationId,
                    } as ModuleSelection,
                ],
                courseUnitSelections: [],
                customCourseUnitAttainmentSelections: [],
                customModuleAttainmentSelections: [],
                assessmentItemSelections: [],
                timelineNotes: [],
            };
            const matchingStudyRight = data.studentStudyRights.find(studyRight => studyRight.educationId === educationId);
            return from(this.commonPlanSelectionService.makeAutomaticSelectionsForNewPlan(planObject, false, matchingStudyRight))
                .pipe(
                    switchMap((planWithAutomaticSelections: Plan) => this.planEntityService.createMyPlan(planWithAutomaticSelections)),
                );
        }
        return of(null);
    }

}
