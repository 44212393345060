import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CourseUnit, OtmId } from 'common-typescript/types';
import { trackByEntityId } from 'sis-components/util/utils';

import { AggregateProductInfo } from '../types';

/**
 * Expects a provision of {@link AssessmentItemTeachingInitializationStateService} from a DOM ancestor.
 */
@Component({
    selector: 'app-product-carousel',
    templateUrl: './product-carousel.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ProductCarouselComponent {

    @Input() courseUnit: CourseUnit;
    @Input() products: AggregateProductInfo[];

    /** Id of the product that should be automatically selected when initializing the carousel */
    @Input() initialSelection: OtmId;
    @Input() mobileView: boolean;
    /** Name of the carousel to be used when grouping carousel slide inputs together */
    @Input() name: string;
    /** Optional id of the element containing meaningful header for carousel to be used with aria-labelledby and aria-describedby attributes */
    @Input() carouselHeaderId?: string;
    @Output() selected = new EventEmitter<AggregateProductInfo>();

    selectedProductIndexControl = new FormControl(null);

    readonly entityId = trackByEntityId;

    onProductSelect(productId: OtmId): void {
        const selectedProduct = this.products?.find(product => product?.id === productId);
        if (selectedProduct) {
            this.selected.emit(selectedProduct);
        }
    }
}
