import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmrexResultCode } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

@Component({
    selector: 'app-emrex-result-modal',
    templateUrl: './emrex-result-modal.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmrexResultModalComponent {
    resultCode: EmrexResultCode;

    constructor(
        @Inject(ModalService.injectionToken) private resultCodeValue: EmrexResultCode,
        public modal: NgbActiveModal) {
        this.resultCode = resultCodeValue;
    }

}
