<ng-container *ngIf="education">
  <div class="row">
    <div class="col-12 col-md-6">
      <h5 class="fudis-heading__md" translate>PROFILE.BASIC_INFO</h5>
      <sis-description-list [columnCount]="1">
        <dl>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'PROFILE.VALID' | translate }}</dt>
            <dd>{{ studyRight.valid | localDateRange }}</dd>
          </ng-template>
          <ng-template #listItem *ngIf="studyRight.studyRightExtensions?.length">
            <dt class="sis-label">{{ 'PROFILE.GRANTED_EXTENSIONS' | translate }}</dt>
            <dd>
              <sis-extension-info [extensions]="studyRight.studyRightExtensions"
                                  [showShowDetails]="true"
                                  (showDetails)="openExtensionDetails($event)"/>
            </dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'PROFILE.ORGANISATION' | translate }}</dt>
            <dd>{{ (studyRight.organisationId|organisation|async)?.name | localizedString }}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ 'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.PERSON_INFO.SCHOOL_EDUCATION_LANGUAGE' | translate }}</dt>
            <dd *ngIf="studyRight?.schoolEducationLanguageUrn">
              {{ studyRight?.schoolEducationLanguageUrn | codeLocalizedName | async }}
            </dd>
            <dd *ngIf="isStaffUser && !studyRight?.schoolEducationLanguageUrn">
              {{ 'PROFILE.INFO_NOT_GIVEN_CAN_BE_FILLED' | translate }}
              <a fudisLink [href]="'/tutor/role/staff/student/' + studyRight.studentId + '/basic/study-rights'" [external]="true" [title]="'PROFILE.STUDY_RIGHT_BASIC_INFO' | translate"></a>
            </dd>
          </ng-template>
        </dl>
      </sis-description-list>
      <div class="row sis-mb-lg">
        <div class="col-12 col-md-6">
          <sis-expandable [variant]="'link'" [title]="'PROFILE.SHOW_DETAILS' | translate" [level]="6"
                          [attr.aria-label]="('ARIA_LABEL.SHOW_DETAILS' | translate) + ' ' + (education.name | localizedString)">
            <ng-template sisExpandableContent>
              <sis-description-list [columnCount]="1">
                <dl>
                  <ng-template #listItem>
                    <dt class="sis-label">{{ 'PROFILE.EXPIRATION_RULES' | translate }}</dt>
                    <dd>{{ studyRight.studyRightExpirationRulesUrn | codeLocalizedName | async }}</dd>
                  </ng-template>
                  <ng-template #listItem *ngIf="studyRight.decreeOnUniversityDegreesUrn">
                    <dt class="sis-label">{{ 'PROFILE.DECREE_ON_UNIVERSITY_DEGREES' | translate }}</dt>
                    <dd>{{ studyRight.decreeOnUniversityDegreesUrn | codeLocalizedName | async }}</dd>
                  </ng-template>
                  <ng-template #listItem *ngIf="studyRight.degreeRegulations">
                    <dt class="sis-label">{{ 'PROFILE.DEGREE_REGULATIONS' | translate }}</dt>
                    <dd>{{ studyRight.degreeRegulations }}</dd>
                  </ng-template>
                  <ng-template #listItem>
                    <dt class="sis-label">{{ 'PROFILE.GRANT_DATE' | translate }}</dt>
                    <dd>{{ studyRight.grantDate | localDateFormat }}</dd>
                  </ng-template>
                  <ng-template #listItem  *ngIf="studyRight.studyStartDate">
                    <dt class="sis-label">{{ 'PROFILE.START_DATE' | translate }}</dt>
                    <dd>{{ studyRight.studyStartDate | localDateFormat }}</dd>
                  </ng-template>
                  <ng-template #listItem *ngIf="studyRight.transferOutDate">
                    <dt class="sis-label">{{ 'PROFILE.TRANSFER_OUT_DATE' | translate }}</dt>
                    <dd>{{ studyRight.transferOutDate | localDateFormat }}</dd>
                  </ng-template>
                  <ng-template #listItem *ngIf="education | isAgreementStudy">
                    <dt class="sis-label">{{ 'PROFILE.HOME_ORGANISATION' | translate }}</dt>
                    <dd>{{ studyRight.homeOrganisationUrn | codeLocalizedName | async }}</dd>
                  </ng-template>
                </dl>
              </sis-description-list>
              <sis-custom-code-urns *ngIf="studyRight.codeUrns" [codeUrns]="studyRight.codeUrns" [classificationScope]="'study-right'"/>
            </ng-template>
          </sis-expandable>
        </div>
      </div>

      <div *ngIf="studyRight && hasMobilityPeriodForStudyRight" class="row">
        <div class="col-12">
          <h5 class="fudis-heading__md" translate>{{ 'MOBILITY_PERIOD.' + ((education | isDegreeEducation) ? 'OUT' : 'IN') + 'BOUND_STUDENT.TITLE'}}</h5>
          <sis-mobility-periods [studyRightId]="studyRight.id" [education]="education"/>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="row sis-mb-lg">
        <div class="col-12">
          <sis-study-right-states [studyRightId]="studyRight.id" [studyRightCancellation]="studyRight.studyRightCancellation"/>
        </div>
      </div>
      <ng-container *ngIf="alwaysDisplayDegreeProgrammeAttainments || hasDegreeProgrammeAttainments || isRegistrationRequired">
        <div class="row sis-mb-lg">
          <div class="col-12">
            <h5 class="fudis-heading__md" translate>PROFILE.STUDY_PROGRESS</h5>
            <sis-attained-degrees *ngIf="attainments" [studyRightId]="studyRight.id" [attainments]="attainments"/>
            <sis-semesters-indicator *ngIf="isRegistrationRequired" [studyRightId]="studyRight.id"/>
          </div>
        </div>
        <div class="row sis-mb-lg" *ngIf="isRegistrationRequired">
          <div class="col-12">
            <h5 class="fudis-heading__md" translate>PROFILE.ATTENDANCE</h5>
            <sis-study-years-term-registrations [studyRightId]="studyRight.id"/>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <sis-readonly-tuition-fee-periods [studyRightId]="studyRight.id"/>

  <div class="row">
    <div class="col-12">
      <hr class="sis-hr sis-mb-lg" aria-hidden="true"/>
      <h5 class="fudis-heading__md" translate>PROFILE.STUDY_PATH.TITLE</h5>
      <div class="sis-mb-sm">
       <sis-personalized-selection-notification [education]="education" [studyRight]="studyRight"/>
      </div>
      <div class="row sis-mb-lg">
        <div class="col-12">
          <sis-description-list>
            <dl>
              <ng-template #listItem>
                <dt class="sis-label">{{ 'PROFILE.LEARNING_OPPORTUNITY' | translate }}</dt>
                <dd>{{ studyRight.learningOpportunityId | learningOpportunityName:education | localizedString }}</dd>
              </ng-template>
            </dl>
          </sis-description-list>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-5">
          <sis-basic-grouping-panel [level]="6"
                                    [heading]="('PROFILE.STUDY_PATH.PHASE_1' | translate) + ' ' + (education.structure.phase1.name | localizedString)">
            <sis-description-list>
              <dl>
                <ng-template #listItem *ngIf="education | isDegreeEducation">
                  <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.EDUCATION_CLASSIFICATION' | translate }}</dt>
                  <dd>{{ studyRight.phase1EducationClassificationUrn | codeLocalizedName | async }}</dd>
                </ng-template>
                <ng-template #listItem>
                  <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.EDUCATION_LOCATION' | translate }}</dt>
                  <dd>{{ studyRight.phase1EducationLocationUrn | codeLocalizedName | async }}</dd>
                </ng-template>
              </dl>
            </sis-description-list>
          </sis-basic-grouping-panel>
        </div>
        <div *ngIf="education.structure.phase2" class="col-12 col-md-5 offset-md-1">
          <sis-basic-grouping-panel [level]="6"
                                    [heading]="('PROFILE.STUDY_PATH.PHASE_2' | translate) + ' ' + (education.structure.phase2.name | localizedString)">
            <sis-description-list>
              <dl>
                <ng-template #listItem *ngIf="education | isDegreeEducation">
                  <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.EDUCATION_CLASSIFICATION' | translate }}</dt>
                  <dd>{{ studyRight.phase2EducationClassificationUrn | codeLocalizedName | async }}</dd>
                </ng-template>
                <ng-template #listItem>
                  <dt class="sis-label">{{ 'PROFILE.STUDY_PATH.EDUCATION_LOCATION' | translate }}</dt>
                  <dd>{{ studyRight.phase2EducationLocationUrn | codeLocalizedName | async }}</dd>
                </ng-template>
              </dl>
            </sis-description-list>
          </sis-basic-grouping-panel>
        </div>
      </div>
      <div class="row sis-mb-lg">
        <div class="col-12 col-md-5" *ngIf="studyRight.phase1InternationalContractualDegree">
          <sis-basic-grouping-panel [level]="6"
                                    [heading]="('PROFILE.STUDY_PATH.PHASE_1' | translate) + ' ' + (education.structure.phase1.name | localizedString)">
            <sis-international-contractual-degree [internationalContractualDegree]="studyRight.phase1InternationalContractualDegree"/>
          </sis-basic-grouping-panel>
        </div>
        <div *ngIf="studyRight.phase2InternationalContractualDegree"
             class="col-12 col-md-5"
             [class.offset-md-1]="studyRight.phase1InternationalContractualDegree">
          <sis-basic-grouping-panel [level]="6"
                                    [heading]="('PROFILE.STUDY_PATH.PHASE_2' | translate) + ' ' + (education.structure.phase2.name | localizedString)">
            <sis-international-contractual-degree [internationalContractualDegree]="studyRight.phase2InternationalContractualDegree"/>
          </sis-basic-grouping-panel>
        </div>
      </div>
      <sis-study-right-education-structure-view [education]="education" [studyRight]="studyRight" [attainments]="attainments"/>

      <sis-minor-selections *ngIf="attainmentsByModuleGroupId && (education | isDegreeEducation)"
                            [attainmentsByModuleGroupId]="attainmentsByModuleGroupId"
                            [studyRight]="studyRight" [education]="education"/>

      <hr class="sis-hr sis-mb-lg" aria-hidden="true"
          *ngIf="studyRight.moduleSelections?.length || studyRight.courseUnitSelections?.length">

      <ng-container *ngIf="studyRight.moduleSelections?.length">
        <h5 class="fudis-heading__md sis-pb-xxs" translate>PROFILE.STUDY_RIGHT_SELECTIONS.MODULE_SELECTIONS_TITLE</h5>
        <div class="row d-block sis-mb-lg">
          <div *ngFor="let moduleSelection of studyRight.moduleSelections" class="col-12 col-md-4 sis-mb-xs">
            <sis-module-selection [selection]="moduleSelection"
                                  [attainment]="attainmentsByModuleGroupId[moduleSelection.moduleGroupId]"/>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="studyRight.courseUnitSelections?.length">
        <h5 class="sis-pb-xxs" translate>PROFILE.STUDY_RIGHT_SELECTIONS.COURSE_UNIT_SELECTIONS_TITLE</h5>
        <div class="row d-block sis-mb-lg">
          <div *ngFor="let courseUnitSelection of studyRight.courseUnitSelections" class="col-12 col-md-4 sis-mb-xs">
            <sis-course-unit-selection [selection]="courseUnitSelection"
                                       [attainment]="attainmentsByCourseUnitGroupId[courseUnitSelection.courseUnitGroupId]"/>
          </div>
        </div>
      </ng-container>

      <hr class="sis-hr sis-mb-lg" aria-hidden="true">

      <h5 class="fudis-heading__md">{{ 'PROFILE.STUDY_RIGHT_SELECTIONS.COOPERATION_NETWORK_RIGHTS.TITLE' | transloco }}</h5>
      <div class="row">
        <div class="col-10">
          <table class="table styled-table" *ngIf="studyRight.cooperationNetworkRights?.length; else noRows;">
            <caption>{{ 'PROFILE.STUDY_RIGHT_SELECTIONS.COOPERATION_NETWORK_RIGHTS.SUB_TITLE' | transloco }}</caption>
            <thead>
            <tr>
              <th>{{ 'PROFILE.STUDY_RIGHT_SELECTIONS.COOPERATION_NETWORK_RIGHTS.ABBREVIATION' | transloco }}</th>
              <th>{{ 'PROFILE.STUDY_RIGHT_SELECTIONS.COOPERATION_NETWORK_RIGHTS.NAME' | transloco }}</th>
              <th>{{ 'PROFILE.STUDY_RIGHT_SELECTIONS.COOPERATION_NETWORK_RIGHTS.VALIDITY_PERIOD' | transloco }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let studyRight of studyRight.cooperationNetworkRights">
              <td class="col-3">{{ (studyRight.cooperationNetworkId | cooperationNetwork | async)?.abbreviation }}</td>
              <td
                class="col-3">{{ (studyRight.cooperationNetworkId | cooperationNetwork | async)?.name | localizedString }}</td>
              <td class="col-3">{{ studyRight.validityPeriod | localDateRange }}</td>
            </tr>
            </tbody>
          </table>
          <ng-template #noRows>
            <p class="sis-guidance">
              {{ "PROFILE.STUDY_RIGHT_SELECTIONS.COOPERATION_NETWORK_RIGHTS.NO_NETWORKS" | transloco }}
            </p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
