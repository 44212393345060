<div *ngIf="ready"
     class="selection-box-slide"
     [ngClass]="{'selected-highlight': control.value === value }"
     (click)="selectSlide()"
     (keydown)="onSlideKeydown($event)">
  <div class="slide-input" [ngClass]="[fontSizeClass, minHeightClass]" #inputRef>
    <sis-radio-button [translatedLabel]="translatedTitle"
                      [control]="control"
                      [value]="value"
                      [id]="id"
                      [name]="name"
                      [badgeText]="titleBadgeText"
                      [badgeVariant]="titleBadgeVariant"
                      [ariaDescribedBy]="ariaDescribedBy"
                      data-cy="carousel-selection-radio-button">
    </sis-radio-button>
  </div>
  <ng-container *ngFor="let banner of banners">
    <strong *ngIf="banner" [id]="banner.id" class="banner" [ngClass]="banner.className">
      {{ banner.text }}
    </strong>
  </ng-container>
  <div class="slide-content">
    <ng-content></ng-content>
  </div>
</div>
