import { Component, inject, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Attainment, GradeRaiseAttempt } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings } from 'sis-common/types/angular-hybrid';

export interface AttainmentDetailsModalValues {
    attainment: Attainment;
    secondaryAttainments: Attainment[];
    hasValidParentAttainment: boolean;
    isAboutToExpire: boolean;
    gradeRaiseEnabled: boolean,
    gradeRaiseInfoEnabled: boolean,
    gradeRaiseAttempt: GradeRaiseAttempt,
}

export function getAttainmentDetailsModalOpener(): (values: AttainmentDetailsModalValues) => NgbModalRef {
    const modalService = inject(ModalService);
    return (values: AttainmentDetailsModalValues) => modalService.open(AttainmentDetailsModalComponent, values, { size: 'md' });
}

@Component({
    selector: 'app-attainment-details-modal',
    templateUrl: './attainment-details-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AttainmentDetailsModalComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'student.attainment.attainmentDetailsModal.downgraded',
        directiveName: 'appAttainmentDetailsModal',
    };

    attainment: Attainment;
    secondaryAttainments: Attainment[];
    expirationImminent = false;
    gradeRaiseEnabled = false;
    gradeRaiseInfoEnabled = false;
    gradeRaiseAttempt: GradeRaiseAttempt;

    constructor(@Inject(ModalService.injectionToken) private values: AttainmentDetailsModalValues, public modal: NgbActiveModal) {
        this.attainment = values.attainment;
        this.secondaryAttainments = values.secondaryAttainments;
        this.expirationImminent = values.isAboutToExpire && !values.hasValidParentAttainment;
        this.gradeRaiseEnabled = values.gradeRaiseEnabled;
        this.gradeRaiseInfoEnabled = values.gradeRaiseInfoEnabled;
        this.gradeRaiseAttempt = values.gradeRaiseAttempt;
    }
}
