import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

import { BadgeVariant } from '../badge/tiny-badge/tiny-badge.component';

@Component({
    selector: 'sis-radio-button',
    templateUrl: './radio-button.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent {

    @Input() control: FormControl;
    @Input() disabled?: boolean;
    @Input() required?: boolean;
    @Input() inline?: boolean;
    /**
     * Takes a pre-translated label.
     * Alternative to {@link label}.
     */
    @Input() translatedLabel: string;
    /**
     * Takes a label translation key.
     * Alternative to {@link translatedLabel}.
     */
    @Input() label: string;
    @Input() name?: string;
    @Input() value: any;
    /** Badge shown below the label text. Accepts a translation key. */
    @Input() badgeText?: string;
    @Input() badgeVariant?: BadgeVariant = 'secondary';
    /** Additional info shown below the radio button label. Accepts a translation key. */
    @Input() guidance?: string;
    @Input() id?: string;
    /**
     * Optional `aria-describedby` value to set on the `input` element.
     */
    @Input() ariaDescribedBy?: string;
}
