import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PrivatePerson, SearchResult, StudentWorkflow, Workflow } from 'common-typescript/types';
import { take, tap } from 'rxjs/operators';
import {
    SortAndPaginationEvent,
    WorkflowLinkCreator,
} from 'sis-components/applications/student-workflow-list/student-workflow-list.component';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { STUDENT_WORKFLOW_TYPE } from 'sis-components/model/student-workflow-constants';
import { WorkflowEntityService } from 'sis-components/service/workflow-entity.service';

function workflowTypeToState(workflow: Workflow) {
    switch (workflow?.type) {
        case STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_SUBSTITUTION_WORKFLOW:
        case STUDENT_WORKFLOW_TYPE.PRIOR_LEARNING_INCLUSION_WORKFLOW:
            return 'student.logged-in.profile.applications.prior-learning-application';
        case STUDENT_WORKFLOW_TYPE.MODULE_ATTAINMENT_WORKFLOW:
            return 'student.logged-in.profile.applications.module-attainment-application';
        case STUDENT_WORKFLOW_TYPE.DEGREE_PROGRAMME_ATTAINMENT_WORKFLOW:
            return 'student.logged-in.profile.applications.degree-programme-attainment-workflow';
        case STUDENT_WORKFLOW_TYPE.CUSTOM_ATTAINMENT_WORKFLOW:
            return 'student.logged-in.profile.applications.custom-attainment-workflow';
        case STUDENT_WORKFLOW_TYPE.CUSTOM_MODULE_CONTENT_WORKFLOW:
        case STUDENT_WORKFLOW_TYPE.REQUIRED_MODULE_CONTENT_WORKFLOW:
            return 'student.logged-in.profile.applications.module-content-application';
        case STUDENT_WORKFLOW_TYPE.STUDY_RIGHT_EXTENSION_WORKFLOW:
            return 'student.logged-in.profile.applications.study-right-extension-workflow';
        default:
            return null;
    }
}

@Component({
    selector: 'app-student-applications',
    templateUrl: './student-applications.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StudentApplicationsComponent implements OnInit {

    readonly workflowLinkCreator: WorkflowLinkCreator = {
        state: workflowTypeToState,
        params: workflow => ({ applicationId: workflow?.id }),
        options: { reload: true },
    };

    readonly defaultSortAndPaginationEvent: SortAndPaginationEvent = {
        sortColumn: 'creationTime',
        reverse: true,
        start: 0,
        itemsPerPage: 20,
    };

    constructor(
        private workflowEntityService: WorkflowEntityService,
        protected appErrorHandler: AppErrorHandler,
    ) { }

    @Input() profile: PrivatePerson;

    searchResult: SearchResult<StudentWorkflow>;
    sortAndPaginationEvent: SortAndPaginationEvent;

    ngOnInit(): void {
        this.loadApplications(this.defaultSortAndPaginationEvent);
    }

    loadApplications(event: SortAndPaginationEvent): void {
        const reverse = !!event.reverse;
        const sort = (reverse ? '-' : '') + event.sortColumn;

        const searchParams = {
            limit: event.itemsPerPage,
            studentId: this.profile.id,
            start: event.start,
            sort,
        };

        this.sortAndPaginationEvent = event;

        this.workflowEntityService.search(searchParams)?.pipe(
            take(1),
            tap((result: SearchResult<StudentWorkflow>) => {
                this.searchResult = result;
            }),
            this.appErrorHandler.defaultErrorHandler(),
        ).subscribe();
    }
}
