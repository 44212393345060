import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

/**
 * A component for showing a "disabled" link that:
 * <ol>
 *   <li>has no URL (i.e. no `href` attribute)</li>
 *   <li>shows a tooltip on hover explaining why the link is disabled</li>
 *   <li>is styled like a disabled element</li>
 * </ol>
 *
 * The link content is passed inside the `<sis-disabled-link>` element like you would do for regular `<a>` elements.
 */
@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-disabled-link',
    templateUrl: './disabled-link.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DisabledLinkComponent {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sisComponents.disabledLink',
        directiveName: 'sisDisabledLink',
    };

    /** Text that explains why the link is disabled. Shown as a tooltip. */
    @Input() explanation: string;

    /** If set to true, an "external link" icon is shown next to the link. */
    @Input() isExternal = false;

    /** Additional class attribute values to add to the link element. `disabled` is always added. */
    @Input() linkClass?: string;

}
