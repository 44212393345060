<sis-contextual-notification variant="danger" class="error-box" tabindex="-1" *ngIf="curControl.invalid && showErrors">
  {{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.CUR_VALIDATION_ERROR' | translate }}
</sis-contextual-notification>
<div class="cur-selection-boxes-container" aria-describedby="currentStepName-heading">
  <ng-container *ngFor="let cur of assessmentItem?.courseUnitRealisations; let index = index">
    <sis-selection-box [translatedTitle]="(cur.name | localizedString) + ' ' + (cur.activityPeriod | localDateRange)"
                       [control]="curControl"
                       [value]="cur.id"
                       [name]="'course-unit-realisation-selection'"
                       [type]="'radio'"
                       [id]="'course-unit-realisation-selection-' + index"
                       [required]="true"
                       [disabled]="fullCourseUnitRealisations.includes(cur.id)"
                       [disabledReason]="'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.CUR_FULL'"
                       data-cy="cur-selection-box"/>
  </ng-container>
</div>
<ng-container *ngIf="curControl?.value">
  <section *ngFor="let sgs of selectedCourseUnitRealisation.studyGroupSets; let i = index"
           class="wizard-step-group-container">
    <h3 data-cy="study-sub-group-header">{{ sgs.name | localizedString }}</h3>
    <p class="sis-guidance">{{ sgs | allocationRuleDescription }}</p>
    <sis-contextual-notification variant="danger" class="d-block error-box" tabindex="-1" *ngIf="sgsControlArray(i, sgs)?.invalid && showErrors">
      {{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.STUDY_SUB_GROUP_SELECTIONS_VALIDATION_ERROR' | translate }}
    </sis-contextual-notification>
    <div class="cur-selection-boxes-container-wide">
      <ng-container *ngFor="let ssg of sgs.studySubGroups; let j = index">
        <!-- Automatically selected ssgs -->
        <div *ngIf="sgs.studySubGroups.length === sgs.subGroupRange.min;else selectionView">
          <span *ngIf="ssg.isFull" class="float-end">
            <sis-tiny-badge variant="danger">{{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.GROUP_IS_FULL' | translate }}</sis-tiny-badge>
          </span>
          <h4>{{ ssg.name | localizedString }}</h4>
              <app-study-event-info-list [studyEvents]="ssg.studyEvents" [teacherIds]="ssg.teacherIds"/>
        </div>
        <ng-template #selectionView>
          <sis-selection-box [translatedTitle]="ssg.name | localizedString"
                             [control]="ssgControl(i, sgs, j)"
                             [value]="ssg.id"
                             [name]="sgs.localId"
                             [type]="sgs.subGroupRange.min === 1 && sgs.subGroupRange.max === 1 ? 'radio' : 'checkbox'"
                             [id]="'study-sub-group-selection-sgs-' + i + '-ssg-' + j"
                             [disabled]="ssg.isFull || sgs.subGroupRange.max === 0"
                             [required]="true"
                             [disabledReason]="ssg.isFull ? 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.GROUP_IS_FULL' : null"
                             data-cy="study-event-selection-box">
            <app-study-event-info-list [studyEvents]="ssg.studyEvents" [teacherIds]="ssg.teacherIds" class="sis-ml-lg"/>
          </sis-selection-box>
        </ng-template>
      </ng-container>
    </div>
  </section>
  <section *ngIf="selectedCourseUnitRealisation.enrolmentQuestionnaire?.enrolmentQuestions?.length > 0">
    <h3>{{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.ENROLMENT_QUESTIONAIRE_TITLE' | translate }}</h3>
    <sis-contextual-notification variant="danger" class="d-block error-box" tabindex="-1" *ngIf="enrolmentQuestionControlArray().invalid && showErrors">
      {{ 'OPEN_UNIVERSITY.ENROLMENT_WIZARD.ENROLMENT_QUESTIONAIRE_VALIDATION_ERROR' | translate }}
    </sis-contextual-notification>
    <div *ngFor="let enrolmentQuestion of selectedCourseUnitRealisation.enrolmentQuestionnaire.enrolmentQuestions; let i = index"
         class="enrolment-question-container">
      <sis-enrolment-questionnaire-question [question]="enrolmentQuestion"
                                            [control]="enrolmentQuestionControl(i, enrolmentQuestion.localId)"
                                            [increment]="i + 1"
                                            [courseUnit]="courseUnit"/>
    </div>
  </section>
</ng-container>
