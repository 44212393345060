import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'sis-common/auth/auth-service';

import { navUrls, NavUtilsService } from '../nav-utils/nav-utils.service';

@Component({
    selector: 'sis-user-settings-menu',
    templateUrl: './user-settings-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsMenuComponent implements OnInit {

    activeRoleNameKey: string;
    dropdownMenu: HTMLElement;

    constructor(private navUtilsService: NavUtilsService,
                private authService: AuthService) {}

    /** View child for the component's wrapper div */
    @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

    ngOnInit() {
        this.activeRoleNameKey = this.getActiveRoleNameKey(this.navUtilsService.getSelectedContextPath());
        this.dropdownMenu = document.getElementById('sis-user-settings-menu');
    }

    get displayName(): string {
        return this.authService.displayname();
    }

    private getActiveRoleNameKey(context: string): string {
        switch (context) {
            case (navUrls.ADMIN_URL):
                return 'AUTH.APPROLE.ADMIN';
            case (navUrls.STUDENT_URL):
                return 'AUTH.APPROLE.STUDENT';
            case (navUrls.STAFF_URL):
                return 'AUTH.APPROLE.STAFF';
            case (navUrls.TEACHER_URL):
                return 'AUTH.APPROLE.TEACHER';
            case ('/staff'):
                return 'AUTH.APPROLE.STAFF';
        }
        return null;
    }

    /** Close settings menu when next targeted element is not inside the menu */
    blurMenu(event: any) {
        if (!this.dropdownMenu.contains(event.relatedTarget)) {
            this.dropdown.close();
        }
    }

    /** Implementation to keyboard navigation */
    onKeyboardButtonInteraction(event: KeyboardEvent) {
        if (this.dropdown.isOpen() && event.code !== 'Tab') {
            const listItems = this.getDropdownItems() as HTMLInputElement[];

            if (listItems.length > 0) {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    const focusElement = this.getFocusedElement();
                    const siblingElementsCount = listItems.length - 1;
                    event.preventDefault();

                    const focusIndex = listItems.indexOf(focusElement);

                    let elementToFocus;

                    if (event.key === 'ArrowDown') {
                        elementToFocus = listItems[focusIndex < siblingElementsCount ? focusIndex + 1 : 0];
                    }

                    if (event.key === 'ArrowUp') {
                        elementToFocus = listItems[focusIndex > 0 ? focusIndex - 1 : siblingElementsCount];
                    }

                    elementToFocus.focus();

                } else if (event.code === 'Space') {
                    event.preventDefault();
                    const focusElement = this.getFocusedElement();
                    focusElement.click();
                }
            }
        }
    }

    private getFocusedElement() {
        return this.dropdownMenu.querySelector(':focus') as HTMLInputElement;
    }

    private getDropdownItems() {
        const listItems = this.dropdownMenu.querySelectorAll('a, button');
        return Array.prototype.filter.call(listItems, (item: any) => !item.classList.contains('d-lg-none'));
    }
}
