<div ngbDropdown #dropdown=ngbDropdown class="language-selection">
  <button type="button" ngbDropdownToggle class="non-button language-selection__toggle"
          (keyup)="onKeyboardButtonInteraction($event)"
          [attr.aria-label]="('AUTH.CHANGE_LANGUAGE' | transloco)"
          [attr.aria-haspopup]="'menu'"
          [attr.aria-controls]="'sis-language-selection-menu'"
          [attr.data-cy]="'sis-language-selection-toggle'">
    <span class="d-none d-sm-inline-block">{{currentLanguageName}}</span>
    <span class="d-inline-block d-sm-none">{{currentLanguage.toUpperCase()}}</span>
    <fudis-icon [icon]="'chevron'" [rotate]="'cw-90'"/>
  </button>
  <div *ngIf="languageSelections?.length > 0" ngbDropdownMenu id="sis-language-selection-menu" role="menu" class="dropdown-menu-right language-selection__menu">
    <button type="button"
            ngbDropdownItem
            role="menuitem"
            class="language-selection__menu__item"
            *ngFor="let selection of languageSelections"
            (click)="changeLanguage(selection.language)"
            (blur)="blurMenu($event)"
            [lang]="selection.language"
            [attr.aria-current]="selection.language === currentLanguage"
            [attr.data-cy]="'sis-language-selection-option-'+selection.language">
      {{selection.name}}
      <fudis-icon class="sis-ml-xs" [icon]="'check'" [color]="'primary'" *ngIf="selection.language === currentLanguage"/>
    </button>
  </div>
</div>
