<div *transloco="let t" class="container">
  <ng-container *ngIf="studentWorkflow">
    <fudis-grid [rowGap]="'md'">
      <fudis-grid-item>
        <div class="d-flex flex-column flex-sm-row justify-content-end gap-4">
          <fudis-button
            *ngIf="shouldShowCancelButton()"
            (handleClick)="openCancelDialog()"
            [label]="t('PROFILE.APPLICATIONS.CANCEL_APPLICATION_MODAL.BUTTON.OPEN_CANCEL_APPLICATION_MODAL')"
            [variant]="'primary'" data-cy="srew-cancel-button"/>
        </div>
      </fudis-grid-item>

      <ng-container *ngIf="isWorkflowCancelled()">
        <fudis-notification
          [variant]="'warning'">{{ t('PROFILE.APPLICATIONS.APPLICATION_CANCELLED') }}
        </fudis-notification>
      </ng-container>

      <ng-container *ngIf="isWaitingForDecision()">
        <fudis-notification [variant]="'warning'">{{ t('PROFILE.APPLICATIONS.ATTACHMENT_DELIVERY_REMINDER') }}
        </fudis-notification>
      </ng-container>

      <ng-container *ngIf="isWaitingForDecision()">
        <ng-container *ngIf="getApplication()?.educationId | education | async as education">
          <ng-container *ngIf="education.studyRightExtensionInstructions">
            <fudis-notification data-cy="srew-instructions" [variant]="'info'">{{ education.studyRightExtensionInstructions | localizedString }}</fudis-notification>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!!studentWorkflow?.decision; else onlyApplication">
        <ng-container>
          <fudis-expandable [title]="t('STUDENT_APPLICATIONS.APPLICATION_INFO')">
            <ng-template fudisContent [type]="'expandable'">
              <ng-container *ngTemplateOutlet="applicationInfo"/>
            </ng-template>
          </fudis-expandable>
        </ng-container>
        <ng-container>
          <fudis-expandable [closed]="false" [title]="t('STUDENT_APPLICATIONS.DECISION_INFO')">
            <ng-template fudisContent [type]="'expandable'">
              <ng-container *ngTemplateOutlet="decisionInfo"/>
            </ng-template>
          </fudis-expandable>
        </ng-container>
      </ng-container>
    </fudis-grid>

    <ng-template #onlyApplication>
      <fudis-heading [level]="2">{{ t('STUDENT_APPLICATIONS.APPLICATION_INFO') }}</fudis-heading>
      <ng-container *ngTemplateOutlet="applicationInfo"/>
    </ng-template>

    <ng-template #applicationInfo>
      <app-study-right-extension-workflow-summary [studentWorkflow]="studentWorkflow" />
    </ng-template>

    <ng-template #decisionInfo>
      <app-study-right-extension-workflow-decision-summary [studyRightExtensionWorkflowDecision]="getDecision()"></app-study-right-extension-workflow-decision-summary>
    </ng-template>

  </ng-container>
</div>
