<ng-container *transloco="let t">
    <div *ngIf="this.invalidModulesSorted" >
        <fudis-notification [variant]="'danger'">
            <fudis-body-text>
                {{ t('PLAN.STATE.INVALID_SELECTIONS_IN_PLAN') }}
            </fudis-body-text>
            <ul>
                <li class="fudis-mt-xxs" *ngFor="let item of this.invalidModulesSorted">
                  <a fudisLink
                     [title]="t('PLAN.STATE.INVALID_SELECTIONS_IN_PLAN_MODULE_TEXT', {name: item.name})"
                     [size]="'md'"
                     [href]="'#'"
                     (click)="handleFocusClick($event, item.focusId)"></a>
                </li>
            </ul>
        </fudis-notification>
    </div>
</ng-container>
