<ng-container *ngIf="question.questionType === 'FREE_TEXT'">
    <sis-text-field
      labelHTML="{{ parsedFreeTextQuestion | localizedString }}"
      [control]="control"
      [id]="'enrolment-questionnaire-question-free-text'"
      [textarea]="true" />
</ng-container>

<ng-container *ngIf="question.questionType === 'LANGUAGE_SELECT' && langQuestion">
    <h4 [ngClass]="{'required': question.required, 'valid': control.valid, 'invalid': control.invalid && control.touched }">
        {{ 'ENROLMENT_QUESTIONAIRE_LANG_ANSWER_TITLE' | translate }}
    </h4>
    <div role="radiogroup" [attr.aria-describedby]="'enrolment-questionnaire-question-language-select_errors'">
        <div *ngFor="let langSelect of langQuestion.selections">
            <sis-radio-button
              [translatedLabel]="langSelect.option | localizedString"
              [control]="control"
              [value]="langSelect.value"
              [name]="question.localId" />
        </div>
    </div>
    <sis-validation-errors
      *ngIf="control.invalid && control.touched"
      [validationErrors]="control.errors"
      [formFieldId]="'enrolment-questionnaire-question-language-select'"
      [ariaLive]="false" />
</ng-container>
