<div *ngIf="logoutUrl" class="logout" [class.logout--mobile]="mobileMode">
  <a ngbDropdownItem
     role="menuitem"
     class="logout__item"
     [href]="logoutUrl"
     (click)="logout()"
     (keydown)="keydownPressed.emit($event)"
     (keydown.escape)="blurLogout.emit($event)"
     (blur)="blurLogout.emit($event)">
    {{ 'AUTH.LOGOUT' | transloco }}
  </a>
</div>
