import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, Signal,
    ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { LocalizedString, UniversitySettings } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { UniversityService } from '../../service/university.service';
import { NavUtilsService } from '../nav-utils/nav-utils.service';

interface ExternalLink {
    name: LocalizedString;
    url: string;
}

@Component({
    selector: 'sis-help-and-feedback-menu',
    templateUrl: './help-and-feedback-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpAndFeedbackMenuComponent implements OnInit {

    @Input() mobileMode = false;

    @Output() keydownPressed = new EventEmitter<any>();
    @Output() blurMobileHelpAndFeedbackMenu = new EventEmitter<any>();

    readonly authenticated: Signal<boolean>;
    universityLinks$: Observable<ExternalLink[]>;
    helpUrl$: Observable<string>;

    constructor(authService: AuthService,
                private universityService: UniversityService,
                private localeService: LocaleService,
                private navUtilsService: NavUtilsService) {
        this.authenticated = toSignal(authService.userLoggedIn$);
    }

    ngOnInit() {
        this.universityLinks$ = this.initUniversityLinks();
        this.helpUrl$ = this.getHelpUrl();
    }

    get currentLanguage(): string {
        return this.localeService.getCurrentLanguage();
    }

    private getHelpUrl(): Observable<string> {
        return this.navUtilsService.getHelpUrl();
    }

    private initUniversityLinks(): Observable<ExternalLink[]> {
        return this.universityService.getCurrentUniversitySettings()
            .pipe(
                map((universitySettings: UniversitySettings) => {
                    const universityLinks: ExternalLink[] = [];
                    if (universitySettings.feedbackLink) {
                        universityLinks.push({
                            name: universitySettings.feedbackLink.title,
                            url: universitySettings.feedbackLink.url,
                        });
                    }
                    if (universitySettings.helpdeskLink) {
                        universityLinks.push({
                            name: universitySettings.helpdeskLink.title,
                            url: universitySettings.helpdeskLink.url,
                        });
                    }
                    return universityLinks;
                }),
            );
    }
}
