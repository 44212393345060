<ng-container *transloco="let t">
  <div class="university-top-navigation-logo sis-mx-sm" role="img" [attr.aria-label]="getUniversityName()"></div>
  <ng-container *ngIf="universitySettings$ | async">
    <button type="button"
            *ngIf="this.contentUrl"
            (click)="toggle()"
            [attr.aria-expanded]="(isClosed$ | async) === false"
            [attr.aria-haspopup]="true"
            [attr.aria-controls]="'universityNavIFrame'"
            [attr.aria-label]="getUniversityName() + ', ' + t('SIS_COMPONENTS.NAVIGATION.OTHER_SERVICES')"
            id="open-university-iframe-button"
            class="university-iframe-toggle non-button sis-pl-sm">
      {{ t('SIS_COMPONENTS.NAVIGATION.OTHER_SERVICES') }}
      <fudis-icon [icon]="'chevron'" [rotate]="'cw-90'"/>
    </button>
  </ng-container>
  <sis-language-selection-menu class="float-end"/>
  <div class="only-ie-10-11 float-end sis-mr-sm">
    <sis-contextual-notification variant="danger">
      {{ t('SIS_COMPONENTS.UNSUPPORTED_BROWSER.IE_10_11') }}
    </sis-contextual-notification>
  </div>
  <!--Present university navigation iFrame as a banner modal with captured focus trap-->
  <div #dialogElement aria-modal="true" role="dialog" [ngClass]="{'d-none': isClosed$ | async}">
    <div #closeButton id="close-university-iframe">
      <button id="close-university-iframe-button" type="button" class="btn btn-lg btn-secondary" (click)="toggle()"
              [attr.aria-label]="t('BUTTON.CLOSE') + ' ' + t('SIS_COMPONENTS.NAVIGATION.OTHER_SERVICES')">
        {{ t('BUTTON.CLOSE') }}
      </button>
    </div>
    <iframe #iframeElement id="universityNavIFrame" tabindex="0"
            title="{{ getUniversityName() }}, {{ t('SIS_COMPONENTS.NAVIGATION.OTHER_SERVICES') }}"></iframe>
    <a href="#" class="hidden-focus-trap-guide" aria-hidden="true"
       (focus)="moveFocusToElement('close-university-iframe-button')"></a>
    <div id="university-iframe-backshroud"></div>
  </div>
</ng-container>
