<div class="row" *ngIf="product">
    <div class="col-12 col-md-4 sis-mb-md" *transloco="let t">
        <fudis-dl [variant]="'compact'">
            <fudis-dl-item>
                <fudis-dt [contentText]="t('OPEN_UNIVERSITY.STUDY_OFFERING.ENROLMENT_RIGHT_VALIDITY')"></fudis-dt>
                <fudis-dd>{{product.enrolmentRight.validityPeriod | localDateRange}}</fudis-dd>
            </fudis-dl-item>
            <fudis-dl-item>
                <fudis-dt [contentText]="t('OPEN_UNIVERSITY.STUDY_OFFERING.CREDITS_LABEL')"></fudis-dt>
                <fudis-dd>{{product.credits | decimalNumber}} {{t('OPEN_UNIVERSITY.STUDY_OFFERING.CREDITS_LONG')}}</fudis-dd>
            </fudis-dl-item>
        </fudis-dl>
    </div>
    <div class="col-12 col-md-8" *transloco="let t">
        <fudis-dl [classes]="'fudis-mb-md'">
            <fudis-dl-item *ngIf="product.description as description">
                <fudis-dt [contentText]="t('OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_DESCRIPTION')"></fudis-dt>
                <fudis-dd><sis-localized-markup-string-view [content]="description"></sis-localized-markup-string-view></fudis-dd>
            </fudis-dl-item>
            <fudis-dl-item *ngIf="completionMethod?.prerequisites | localizedString as prerequisites">
                <fudis-dt [contentText]="t('FIELD_NAMES.prerequisites')"></fudis-dt>
                <fudis-dd>{{prerequisites}}</fudis-dd>
            </fudis-dl-item>
            <fudis-dl-item *ngIf="completionMethod?.evaluationCriteria | localizedString as evaluationCriteria">
                <fudis-dt [contentText]="t('FIELD_NAMES.grading')"></fudis-dt>
                <fudis-dd>{{evaluationCriteria}}</fudis-dd>
            </fudis-dl-item>
        </fudis-dl>
    </div>
</div>
