import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    BatchOperationResult,
    CooperationNetworkDetails,
    CooperationNetworkShare,
    CourseUnit,
    CourseUnitResultItem,
    CourseUnitSearch,
    CourseUnitsToCooperationNetworkRequest,
    DocumentState,
    ModuleResponsibilityInfoType,
    OtmId,
    PersonWithModuleResponsibilityInfoType,
    ResponsibilityInfo,
    ResponsiblePersonDeleteRequest,
    ResponsiblePersonsAddRequest,
    ResponsiblePersonValidityPeriodEndDates,
    ResponsiblePersonValidityPeriodsEndRequest,
    SearchResult,
} from 'common-typescript/types';
import * as _ from 'lodash';
import { combineLatest, Observable, of, tap, throwError } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { searchRequestToQueryParams } from '../search-ng/search-utils';
import { UniversityService } from '../service/university.service';

import { EntityService } from './entity.service';
import { SisuDataLoader } from './SisuDataLoader';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        addToCooperationNetworkBatch() {
            return `${this.backend}/course-units/add-to-cooperation-network/batch`;
        },
        addResponsiblePersons() {
            return `${this.backend}/course-units/add-responsible-persons/batch`;
        },
        endResponsiblePersonValidityPeriod() {
            return `${this.backend}/course-units/end-responsible-person-validity-period/batch`;
        },
        deleteResponsiblePerson() {
            return `${this.backend}/course-units/delete-responsible-person/batch`;
        },
        get search() {
            return `${this.backend}/authenticated/course-unit-search`;
        },
        get byGroupId() {
            return `${this.backend}/course-units/by-group-id`;
        },
        get findBy() {
            return `${this.backend}/course-units`;
        },
        get findByAuthenticated() {
            return `${this.backend}/authenticated/course-units`;
        },
        get byGroupIdsCurriculumPeriodIdDocumentState() {
            return `${this.backend}/course-units/for-curriculum-period`;
        },
        cancelApproval(courseUnitId: OtmId) {
            return `${this.backend}/course-units/cancel-approval/${courseUnitId}`;
        },
        undoDelete(courseUnitId: OtmId) {
            return `${this.backend}/course-units/undo-delete/${courseUnitId}`;
        },
        approve(courseUnitId: OtmId) {
            return `${this.backend}/course-units/approve/${courseUnitId}`;
        },
        addCooperationNetworkDetailsToCourseUnit(courseUnitId: OtmId) {
            return `${this.backend}/course-units/${courseUnitId}/cooperation-network-details`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'course-units',
})
export class CourseUnitEntityService extends EntityService<CourseUnitState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.courseUnitEntityService',
        serviceName: 'courseUnitEntityService',
    };

    constructor(private universityService: UniversityService) {
        super(CourseUnitStore, CourseUnitQuery);
        this.groupIdDataloader = new SisuDataLoader<OtmId, CourseUnit, CourseUnit>(
            {
                getByIdsCall: groupIds => this.createGetByGroupIdsCall(groupIds),
                successEntitiesCallback: () => {},
                resultExtractor: (groupId, entities) => entities.find(entity => entity.groupId === groupId),
                bufferSize: 50,
                bufferTime: 20,
            });
    }

    public readonly groupIdDataloader: SisuDataLoader<OtmId, CourseUnit, CourseUnit>;

    findByAuthenticated(groupId: OtmId, documentStates?: DocumentState[]): Observable<CourseUnit[]> {
        return this.getHttp().get<CourseUnit[]>(
            CONFIG.ENDPOINTS.findByAuthenticated,
            {
                params: {
                    groupId,
                    documentState: documentStates ? documentStates : [],
                },
            },
        );
    }

    search(searchParams: Partial<CourseUnitSearch>): Observable<SearchResult<CourseUnitResultItem>> {
        return this.getHttp().get<SearchResult<CourseUnitResultItem>>(CONFIG.ENDPOINTS.search, { params: this.toQueryParams(searchParams) });
    }

    private toQueryParams(searchRequest: Partial<CourseUnitSearch>): { [key: string]: string | string[] } {
        if (_.isEmpty(searchRequest)) {
            return {};
        }

        return _.omitBy(
            {
                ...searchRequestToQueryParams(searchRequest),
                curriculumPeriodId: searchRequest.curriculumPeriodIds,
                orgRootId: searchRequest.organisationRootIds,
                orgId: searchRequest.organisationIds,
                universityOrgId: searchRequest.universityOrgIds,
                validity: searchRequest.validity,
            },
            _.isEmpty,
        );
    }

    /**
     * Optional parameters (curriculumPeriodId, documentStates, preferByState) in corresponding KORI endpoint are not supported by this
     * method. Also universityId is hard coded to be current university. Optional parameters would make batching quite complex as the
     * batching should be done per distinct parameter set. "Simplest solution" that came to my mind was:
     *  1. Check if there exists Dataloader in Map<parameters,Dataloader> for these parameters. If yes skip to 3.
     *  2. Create new Dataloader and insert it with parameters into Map<parameters,Dataloader>.
     *  3. Get Dataloader from Map<parameters,Dataloader> with parameters.
     *  4. Call Dataloader.load.
     *
     * @param groupId groupId of the course unit
     */
    getByGroupId(groupId: OtmId): Observable<CourseUnit> {
        if (!groupId) {
            return throwError(() => new Error('The group id was missing!'));
        }
        return this.groupIdDataloader.load(groupId);
    }

    getByAssessmentItemIdsStudent(assessmentItemIds: OtmId[]): Observable<CourseUnit[]> {
        return this.getByAssessmentItemIds(assessmentItemIds, false);
    }

    getByAssessmentItemIdsStaff(assessmentItemIds: OtmId[]): Observable<CourseUnit[]> {
        return this.getByAssessmentItemIds(assessmentItemIds, true);
    }

    getByAssessmentItemIds(assessmentItemIds: OtmId[], authenticated: boolean): Observable<CourseUnit[]> {
        if (!assessmentItemIds || assessmentItemIds.length === 0) {
            return throwError(() => new Error('The assessment item ids were missing!'));
        }
        return this.getHttp().get<CourseUnit[]>(
            authenticated ? CONFIG.ENDPOINTS.findByAuthenticated : CONFIG.ENDPOINTS.findBy,
            { params: { assessmentItemId: assessmentItemIds.toString() } },
        );
    }

    getByGroupIdsCurriculumPeriodIdDocumentState(groupIds: OtmId[], curriculumPeriodId: OtmId, documentStates: DocumentState[]): Observable<CourseUnit[]> {
        const params: Record<string, any> = {
            groupId: groupIds.toString(),
            curriculumPeriodId,
            documentState: documentStates.toString(),
        };
        return this.getHttp().get<CourseUnit[]>(
            CONFIG.ENDPOINTS.byGroupIdsCurriculumPeriodIdDocumentState,
            { params },
        );
    }

    /**
     * This is a wrapper to getByGroupId. You probably should be using it directly. Usage of this method is usually for centralized
     * relation loading which is unnecessary with dataloader.
     */
    getByGroupIds(groupIds: OtmId[], curriculumPeriodId?: OtmId): Observable<CourseUnit[]> {
        if (groupIds?.length === 0) {
            return of([]);
        }
        const uniqIds = _.uniq((groupIds || []).filter(Boolean));
        // Skip dataloader when using optional parameters for now
        if (curriculumPeriodId) {
            return this.createGetByGroupIdsCall(uniqIds, curriculumPeriodId);
        }
        return combineLatest(uniqIds.map(groupId => this.getByGroupId(groupId)));
    }

    private createGetByGroupIdsCall(groupIds: OtmId[], curriculumPeriodId?: OtmId): Observable<CourseUnit[]> {
        let params: Record<string, any> = {
            groupId: groupIds.toString(),
            universityId: this.universityService.getCurrentUniversityOrgId(),
        };
        if (curriculumPeriodId) {
            params = { ...params, curriculumPeriodId };
        }
        return this.getHttp().get<CourseUnit[]>(
            CONFIG.ENDPOINTS.byGroupId,
            { params },
        );
    }

    addToCooperationNetworkBatch(courseUnitIds: OtmId[], cooperationNetworkShare: CooperationNetworkShare, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.addToCooperationNetworkBatch(),
            { courseUnitIds, cooperationNetworkId: cooperationNetworkShare.cooperationNetworkId, validityPeriod: cooperationNetworkShare.validityPeriod } as CourseUnitsToCooperationNetworkRequest,
            { params: { dryRun } },
        );
    }

    addResponsiblePersonsBatch(courseUnitIds: OtmId[], responsibilityInfos: ResponsibilityInfo[], dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.addResponsiblePersons(),
            { ids: courseUnitIds, responsibilityInfos } as ResponsiblePersonsAddRequest<PersonWithModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    endResponsiblePersonValidityPeriodBatch(courseUnitIds: OtmId[], responsiblePersonId: OtmId, endDates: ResponsiblePersonValidityPeriodEndDates, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        const roleValidityPeriodEndDates = [
            { roleUrn: 'urn:code:module-responsibility-info-type:responsible-teacher', endDate: endDates.responsibleTeacherRoleEndDate },
            { roleUrn: 'urn:code:module-responsibility-info-type:administrative-person', endDate: endDates.adminRoleEndDate },
            { roleUrn: 'urn:code:module-responsibility-info-type:contact-info', endDate: endDates.contactInfoRoleEndDate },
        ];
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.endResponsiblePersonValidityPeriod(),
            { ids: courseUnitIds, responsiblePersonId, roleValidityPeriodEndDates } as ResponsiblePersonValidityPeriodsEndRequest<ModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    deleteResponsiblePersonBatch(courseUnitIds: OtmId[], responsiblePersonId: OtmId, rolesToDelete: Set<ModuleResponsibilityInfoType>, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.deleteResponsiblePerson(),
            { ids: courseUnitIds, responsiblePersonId, rolesToDelete: [...rolesToDelete] } as ResponsiblePersonDeleteRequest<ModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    undoApproval(id: OtmId): Observable<CourseUnit> {
        return this.getHttp().put<CourseUnit>(
            CONFIG.ENDPOINTS.cancelApproval(id),
            {},
        ).pipe(tap((courseUnit) => this.store.upsert(courseUnit.id, courseUnit)));
    }

    undoDelete(id: OtmId, curriculumPeriodIds: OtmId[]): Observable<CourseUnit> {
        return this.getHttp().put<CourseUnit>(
            CONFIG.ENDPOINTS.undoDelete(id),
            curriculumPeriodIds,
        ).pipe(tap((courseUnit) => this.store.upsert(courseUnit.id, courseUnit)));
    }

    approve(id: OtmId): Observable<CourseUnit> {
        return this.getHttp().put<CourseUnit>(
            CONFIG.ENDPOINTS.approve(id),
            {},
        ).pipe(tap((courseUnit) => this.store.upsert(courseUnit.id, courseUnit)));
    }

    updateAndStore(courseUnit: CourseUnit): Observable<CourseUnit> {
        return super.update<CourseUnit>(courseUnit.id, courseUnit, { skipWrite: true })
            .pipe(tap((cu) => this.store.upsert(cu.id, cu)));
    }

    storeUpsert(courseUnit: CourseUnit) {
        this.store.upsert(courseUnit.id, courseUnit);
    }

    updateCooperationNetworkDetails(id: OtmId, details: CooperationNetworkDetails) {
        return this.getHttp().put<CourseUnit>(
            CONFIG.ENDPOINTS.addCooperationNetworkDetailsToCourseUnit(id),
            details,
        ).pipe(tap((courseUnit) => this.store.upsert(courseUnit.id, courseUnit)));
    }
}

type CourseUnitState = EntityState<CourseUnit, OtmId>;

@StoreConfig({ name: 'course-units' })
class CourseUnitStore extends EntityStore<CourseUnitState> {}

class CourseUnitQuery extends QueryEntity<CourseUnitState> {
    constructor(protected store: CourseUnitStore) {
        super(store);
    }
}
