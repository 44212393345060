<ng-container *transloco="let t">
  <sis-page-title [title]="t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP2.TITLE')"
                  [dataCy]="'activate-wizard-title-step-2'"/>
  <sis-wizard-progress [stepKeys]="wizardProgressStepKeys" [currentStep]="1"></sis-wizard-progress>

  <div *ngIf="isPersonInfoInputRequiredView" class="mt-4 mb-4">
    <p>{{ t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP2.DESC1') }}</p>
    <p>{{ t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP2.DESC2') }}</p>
  </div>

  <div class="row">
    <div class="col-6">
      <sis-description-list [fillByColumn]="false">
        <dl>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('PROFILE.FIRST_NAMES') }}</dt>
            <dd data-cy="activate-wizard-person-first-names">
              {{ isSuomiFiPath ? suomiFiUserBasicDetails.firstNames : person?.firstNames}}
            </dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('PROFILE.LAST_NAME') }}</dt>
            <dd data-cy="activate-wizard-person-last-name">
              {{ isSuomiFiPath ? suomiFiUserBasicDetails.lastName : person?.lastName }}
            </dd>
          </ng-template>
        </dl>
      </sis-description-list>
    </div>
    <div *ngIf="isPersonInfoInputRequiredView" class="col-6 required-field-icon sis-guidance" aria-hidden="true">
      <sis-icon [icon]="'required'"/>
      {{ t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.SUOMI_FI.REQUIRED_FIELD') }}
    </div>
  </div>

  <ng-container *ngIf="isStaffUser && form">
    <label [ngClass]="getLabelState(citizenshipUrns) || 'required'">
      {{ t('PROFILE.CITIZENSHIP') }}
    </label>
    <div class="row code-select-editor" *ngFor="let control of citizenshipUrns.controls; let i = index">
      <div class="col-7 col-xl-3">
        <sis-code-select-editor [codebookUrn]="countryCodeBookUrn"
                                [selectedUrn]="control.value"
                                [excludeCodes]="citizenshipUrns.value"
                                [required]="true"
                                (onSelectCode)="onSelectCitizenshipUrn(control, $event.selectedUrn)"
                                data-cy="activate-wizard-citizenship-urn-select"
                                [ngClass]="{'cart-error-class': control?.invalid}"
                                class="error-box"
                                [id]="'citizenshipUrn'"
                                [attr.tabindex]="-1">
        </sis-code-select-editor>
        <sis-validation-errors *ngIf="(citizenshipUrns.touched || citizenshipUrns.dirty) && control.invalid"
                               [validationErrors]="control.errors"
                               [formFieldId]="'citizenshipUrn'"
                               [ariaLive]="false" />
      </div>
      <div class="col-2">
        <sis-button *ngIf="citizenshipUrns.length > 1"
                    (clicked)="citizenshipUrns.removeAt(i)"
                    naked="true">
          <sis-icon icon="delete"></sis-icon>
        </sis-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 sis-mb-xl">
        <sis-add-button *ngIf="canAddCitizenship()"
                        [ngClass]="{'cart-error-class': citizenshipUrns?.invalid}"
                        data-cy="activate-wizard-add-citizenship"
                        [label]="t('PROFILE.ADD_CITIZENSHIP')"
                        [attr.tabindex]="-1"
                        (clicked)="addCitizenship()"></sis-add-button>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-7 col-xl-3">
        <sis-text-field
          [ngClass]="{'cart-error-class': personalIdentityCode?.invalid}"
          [control]="personalIdentityCode"
          [id]="'personalIdentityCode'"
          [label]="t('PROFILE.PERSONAL_IDENTITY_CODE')"
          [showLabelIcon]="isFinnishCitizen()"
          [attr.tabindex]="-1">
        </sis-text-field>
      </div>
      <div class="form-group col-7 col-xl-3 offset-xl-1">
        <sis-local-date-editor
          [ngClass]="{'cart-error-class': dateOfBirth?.invalid}"
          [control]="dateOfBirth"
          [label]="t('PROFILE.BIRTH_DATE')"
          [id]="'dateOfBirth'"
          [name]="'date-of-birth-input'"
          [attr.tabindex]="-1">
        </sis-local-date-editor>
      </div>
    </div>
  </ng-container>

  <div class="row" *ngIf="!isPersonInfoInputRequiredView">
    <div class="col-6">
      <sis-description-list [fillByColumn]="false">
        <dl>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('PROFILE.EMAIL') }}</dt>
            <dd>{{person?.primaryEmail}}</dd>
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{ t('PROFILE.PHONE_NUMBER') }}</dt>
            <dd>{{person?.phoneNumber}}</dd>
          </ng-template>
        </dl>
      </sis-description-list>
    </div>
  </div>

  <div class="row" *ngIf="isPersonInfoInputRequiredView">
    <div class="form-group col-7 col-xl-3">
      <sis-text-field class="error-box"
                      [ngClass]="{'cart-error-class': email?.invalid}"
                      [control]="email"
                      [id]="'email'"
                      [label]="t('PROFILE.EMAIL')"
                      [autocomplete]="'email'"
                      [attr.tabindex]="-1">
      </sis-text-field>
    </div>
    <div class="form-group col-7 col-xl-3 offset-xl-1">
      <sis-text-field [ngClass]="{'cart-error-class': phone?.invalid}"
                      class="error-box"
                      [control]="phone"
                      [id]="'phone'"
                      [label]="t('PROFILE.PHONE_NUMBER')"
                      [autocomplete]="'tel'"
                      helpBlock="OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.SUOMI_FI.PHONE_GUIDANCE"
                      [attr.tabindex]="-1">
      </sis-text-field>
    </div>
  </div>

  <ng-container *ngIf="isNewSuomiFiSignin || isStaffUser">
    <div class="row">
      <div *ngIf="isNewSuomiFiSignin" class="form-group col-7 col-xl-3">
        <sis-code-select codeBookName="country"
                         [ngClass]="{'cart-error-class': citizenshipUrn?.invalid}"
                         label="FIELD_NAMES.citizenshipUrns"
                         data-cy="activate-wizard-add-citizenship-student"
                         [control]="citizenshipUrn"
                         [attr.tabindex]="-1">
        </sis-code-select>
      </div>
      <div class="form-group col-7 col-xl-3" [class.offset-xl-1]="isNewSuomiFiSignin">
        <sis-code-select codeBookName="municipality"
                         [ngClass]="{'cart-error-class': municipalityUrn?.invalid}"
                         label="FIELD_NAMES.municipalityUrn"
                         [control]="municipalityUrn"
                         data-cy="activate-wizard-municipality-urn-select"
                         [attr.tabindex]="-1">
        </sis-code-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-7 col-xl-3">
        <sis-code-select codeBookName="language"
                         [ngClass]="{'cart-error-class': motherTongueUrn?.invalid}"
                         label="FIELD_NAMES.motherTongueUrn"
                         [control]="motherTongueUrn"
                         data-cy="activate-wizard-mother-tong-urn-select"
                         [attr.tabindex]="-1">
        </sis-code-select>
      </div>
      <div class="form-group col-7 col-xl-3 offset-xl-1">
        <sis-code-select codeBookName="gender"
                         [ngClass]="{'cart-error-class': genderUrn?.invalid}"
                         label="FIELD_NAMES.genderUrn"
                         [control]="genderUrn"
                         data-cy="activate-wizard-gender-urn-select"
                         [attr.tabindex]="-1">
        </sis-code-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-7 col-xl-3">
        <sis-code-select codeBookName="preferred-language"
                         [ngClass]="{'cart-error-class': preferredLanguageUrn?.invalid}"
                         label="FIELD_NAMES.preferredLanguageUrn"
                         [control]="preferredLanguageUrn"
                         data-cy="activate-wizard-preferred-lang-urn-select"
                         [attr.tabindex]="-1">
        </sis-code-select>
      </div>
    </div>
  </ng-container>

  <sis-contextual-notification *ngIf="!isPersonInfoInputRequiredView" variant="light" class="row col-12">
    <div>{{ t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP2.INFO_TEXT') }}</div>
  </sis-contextual-notification>

  <sis-wizard-step-buttons class="mt-5 mb-4"
                           [forwardButtonTitle]="t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_WIZARD.STEP2.CONFIRM_BUTTON')"
                           [backButtonDisplay]="false"
                           [forwardButtonDisabled]="onGoingRedeemProcess"
                           (forward)="startRedeemProcess()">
  </sis-wizard-step-buttons>
</ng-container>
