import { ChangeDetectionStrategy, Component, inject, signal, ViewEncapsulation } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { SessionStorageService } from 'sis-common/storage/session-storage.service';

import { StudentCourseUnitInfoModalService } from '../../../common/service/student-course-unit-info-modal.service';

import { ActiveStudiesService, StudyData } from './active-studies.service';

const COLLAPSED_KEY = 'student.frontpage.activeStudies.collapsed';

@Component({
    selector: 'app-active-studies',
    templateUrl: './active-studies.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveStudiesComponent {

    private readonly activeStudiesService = inject(ActiveStudiesService);
    private readonly sessionStorageService = inject(SessionStorageService);
    private readonly studentCourseUnitInfoModalService = inject(StudentCourseUnitInfoModalService);

    readonly activeStudies$: Observable<StudyData[]> = this.activeStudiesService.getActiveStudies()
        .pipe(
            catchError(() => {
                this.searchFailed.set(true);
                return of([]);
            }),
        );

    readonly initiallyCollapsed = this.sessionStorageService.getValueOrDefault(COLLAPSED_KEY, true);
    readonly searchFailed = signal(false);

    openCourseUnitInfoModal(study: StudyData): void {
        this.studentCourseUnitInfoModalService.openCourseUnitModalForEnrolment(
            // opening the dialog will probably crash, if the course unit ID is not provided
            study.courseUnit?.id,
            study.courseUnitRealisation,
            study.enrolment,
        );
    }

    isCurCancelled(data: StudyData): boolean {
        return data.courseUnitRealisation?.flowState === 'CANCELLED';
    }

    onCollapsedChange(collapsed: boolean) {
        this.sessionStorageService.setValue(COLLAPSED_KEY, collapsed);
    }
}
