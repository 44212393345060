<div class="edit-mode editing" *transloco="let t">
  <div class="header-edit-mode-with-title">
    <div class="row">
      <div class="title">
        <fudis-heading [level]="2" [variant]="'lg'">
          {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.TITLE') }}
        </fudis-heading>
      </div>
      <div class="edit-toggle-holder">
        <button [disabled]="!form.valid" type="button" class="btn btn-primary float-end btn-lg" (click)="save()">
          {{ t('SIS_COMPONENTS.BUTTON.SAVE') }}
        </button>
        <button type="button" class="btn btn-primary btn-hollow float-end btn-lg" (click)="cancel()">
          {{ t('SIS_COMPONENTS.BUTTON.CANCEL') }}
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="container-content">
      <div class="row">
        <div class="col-md-6 form-group">
          <sis-text-field
            id="phoneNumber"
            [control]="phoneNumber"
            [type]="'tel'"
            [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.PHONE_NUMBER')"/>
        </div>
        <div class="col-md-6 sis-mb-md">
          <fudis-dl>
            <fudis-dl-item>
              <fudis-dt
                [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.EMAIL')"/>
              <fudis-dd [contentText]="person.primaryEmail"/>
            </fudis-dl-item>
          </fudis-dl>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <sis-text-field
            id="secondaryEmail"
            [control]="secondaryEmail"
            [type]="'email'"
            [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_EMAIL')"/>
        </div>
      </div>
      <sis-basic-grouping-panel
        [level]="3"
        heading="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.PRIMARY_ADDRESS"
        guidance="STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.PRIMARY_ADDRESS_GUIDANCE">
        <div class="form-group">
          <fudis-dl [columns]="{ sm: 1, md: 2 }">
            <fudis-dl-item>
              <fudis-dt
                [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.STREET_ADDRESS')"/>
              <fudis-dd [contentText]="person.primaryAddress?.streetAddress"/>
            </fudis-dl-item>
            <fudis-dl-item>
              <fudis-dt
                [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.POSTAL_CODE')"/>
              <fudis-dd [contentText]="person.primaryAddress?.postalCode"/>
            </fudis-dl-item>
            <fudis-dl-item>
              <fudis-dt
                [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.CITY')"/>
              <fudis-dd [contentText]="person.primaryAddress?.city"/>
            </fudis-dl-item>
            <fudis-dl-item>
              <fudis-dt
                [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.COUNTRY')"/>
              <fudis-dd [contentText]="person.primaryAddress?.countryUrn | codeLocalizedName | async"/>
            </fudis-dl-item>
          </fudis-dl>
        </div>
      </sis-basic-grouping-panel>

      <sis-basic-grouping-panel
        [heading]="'STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_ADDRESS'"
        [level]="3"
        [hideGroupingPanel]="!isSecondaryAddress">
        <ng-container basic-grouping-panel="headerButton">
          <fudis-button *ngIf="isSecondaryAddress"
                        [label]="t('ARIA_LABEL.DELETE') + ' ' + t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_ADDRESS')"
                        [size]="'icon-only'"
                        [icon]="'delete'"
                        [labelHidden]="true"
                        [variant]="'tertiary'"
                        (handleClick)="removeSecondaryAddress()"/>
          <sis-add-button *ngIf="!isSecondaryAddress"
                          [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.ADD_SECONDARY_ADDRESS')"
                          (clicked)="addSecondaryAddress()"/>
        </ng-container>
        <ng-container *ngIf="isSecondaryAddress">
          <div class="form-group">
            <sis-radio-button [value]="true"
                              name="isSecondaryAddressInFinland"
                              [control]="isSecondaryAddressInFinland"
                              (click)="secondaryAddressInFinlandUpdated(true)"
                              [translatedLabel]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.ADDRESS_IN_FINLAND')"/>
            <sis-radio-button
              name="isSecondaryAddressInFinland"
              [control]="isSecondaryAddressInFinland"
              [value]="false"
              (click)="secondaryAddressInFinlandUpdated(false)"
              [translatedLabel]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.ADDRESS_NOT_IN_FINLAND')"/>
          </div>
          <div class="row" *ngIf="isSecondaryAddress && isSecondaryAddressInFinland.value">
            <div class="col-12 col-md-6 form-group">
              <sis-text-field
                id="secondaryAddressStreetAddress"
                [control]="secondaryAddressStreetAddressFinland"
                [type]="'text'"
                [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.STREET_ADDRESS')"/>
            </div>
            <div class="col-12 col-md-6 form-group">
              <sis-text-field
                id="secondaryAddressPostalCode"
                [control]="secondaryAddressPostalCode"
                [type]="'text'"
                [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.POSTAL_CODE')"/>
            </div>
            <div class="col-12 col-md-6 form-group">
              <sis-text-field
                id="secondaryAddressCity"
                [control]="secondaryAddressCity"
                [type]="'text'"
                [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.CITY')"/>
            </div>
            <div class="col-12 col-md-6 form-group">
              <fudis-dl>
                <fudis-dl-item>
                  <fudis-dt
                    [contentText]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.COUNTRY')"/>
                  <fudis-dd [contentText]="countryUrnFI | codeLocalizedName | async"/>
                </fudis-dl-item>
              </fudis-dl>
            </div>
          </div>
          <div class="row" *ngIf="isSecondaryAddress && !isSecondaryAddressInFinland.value">
            <div class="col-12 form-group">
              <sis-text-field
                [control]="secondaryAddressStreetAddressAbroad"
                [textarea]="true"
                [helpBlock]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.SECONDARY_ADDRESS_GUIDANCE')"
                [label]="t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.ADDRESS_INFO')"/>
            </div>
            <div class="col-12 form-group">
              <label [ngClass]="getLabelState(secondaryAddressCountryUrn)" for="secondaryAddressCountryUrn">
                {{ t('STUDENT_APPLICATIONS.DEGREE_PROGRAMME_ATTAINMENT_APPLICATION.CONTACT_INFO.COUNTRY') }}
              </label>
              <sis-code-select-editor [codebookUrn]="codebookUrns.country"
                                      (onSelectCode)="setSecondaryAddressCountryUrn($event.selectedUrn)"
                                      name="secondaryAddressCountryUrn"
                                      id="secondaryAddressCountryUrn"
                                      [required]="!isSecondaryAddressInFinland.value"
                                      [selectedUrn]="secondaryAddressCountryUrn.value"
                                      [excludeCodes]="[countryUrnFI, countryUrnAX]"/>
            </div>
          </div>
        </ng-container>
      </sis-basic-grouping-panel>
    </div>
  </form>
</div>
