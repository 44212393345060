<div *transloco="let t">
  <fudis-grid>
    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <fudis-dl-item data-cy="srewd-application-decision">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DECISION')"/>
        <fudis-dd [contentText]="t('STUDENT_APPLICATIONS.STATE.' + studyRightExtensionWorkflowDecision.state)"/>
      </fudis-dl-item>
      <fudis-dl-item data-cy="srewd-resolution-rationale">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.RESOLUTION_RATIONALE')"/>
        <fudis-dd>
          <sis-text-with-default-missing-value [value]="studyRightExtensionWorkflowDecision.resolutionRationale"/>
        </fudis-dd>
      </fudis-dl-item>

      <ng-container *ngIf="studyRightExtensionWorkflowDecision.state === 'ACCEPTED'">
        <fudis-dl-item data-cy="srewd-granted-terms">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.GRANTED_EXTENSIONS')"/>
          <fudis-dd>{{ studyRightExtensionWorkflowDecision.grantedTerms }} {{ t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.' + (studyRightExtensionWorkflowDecision.grantedTerms === 1 ? 'SEMESTER' : 'SEMESTERS')) }}</fudis-dd>
        </fudis-dl-item>
        <fudis-dl-item data-cy="srewd-extension-validity" *ngIf="extensionValidity$ | async as extensionValidity">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.EXTENSION_VALIDITY')"/>
          <fudis-dd [contentText]="extensionValidity | localDateRange"/>
        </fudis-dl-item>
        <fudis-dl-item data-cy="srewd-extension-info">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.EXTENSION_APPLICATION.ADDITIONAL_INFO')"/>
          <fudis-dd>
            <sis-text-with-default-missing-value [value]="studyRightExtensionWorkflowDecision.extensionInfo" />
          </fudis-dd>
        </fudis-dl-item>
      </ng-container>

      <fudis-dl-item data-cy="srewd-decision-approver">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.DECISION_APPROVER')"/>
        <fudis-dd>{{ studyRightExtensionWorkflowDecision.approvedBy | publicPerson | async | personFullName }}, {{ studyRightExtensionWorkflowDecision.approverTitle | localizedString }}</fudis-dd>
      </fudis-dl-item>
    </fudis-dl>

    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <ng-container *ngIf="!!studyRightExtensionWorkflowDecision.proposal">
        <fudis-dl-item data-cy="srewd-proposal-result">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PROPOSAL')"/>
          <fudis-dd [contentText]="t('STUDENT_APPLICATIONS.PROPOSAL_RESULT.' + studyRightExtensionWorkflowDecision.proposal.result)" />
        </fudis-dl-item>
        <fudis-dl-item data-cy="srewd-proposal-presenter">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PRESENTER')"/>
          <fudis-dd>{{ studyRightExtensionWorkflowDecision.proposal.presenterId | publicPerson | async | personFullName }}, {{ studyRightExtensionWorkflowDecision.proposal.presenterTitle | localizedString }}</fudis-dd>
        </fudis-dl-item>
        <fudis-dl-item data-cy="srewd-proposal-rationale">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.PROPOSAL_RATIONALE')"/>
          <fudis-dd>
            <sis-text-with-default-missing-value [value]="studyRightExtensionWorkflowDecision.proposal.rationale" />
          </fudis-dd>
        </fudis-dl-item>
      </ng-container>
    </fudis-dl>

    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <ng-container *ngIf="!!studyRightExtensionWorkflowDecision.administrativeReview">
        <fudis-dl-item data-cy="srewd-administrative-review-result">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.ADMINISTRATIVE_REVIEW')"/>
          <fudis-dd [contentText]="t('STUDENT_APPLICATIONS.ADMINISTRATIVE_REVIEW_RESULT.' + studyRightExtensionWorkflowDecision.administrativeReview.result)" />
        </fudis-dl-item>
        <fudis-dl-item data-cy="srewd-administrative-review-reviewer">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.ADMINISTRATIVE_REVIEWER')"/>
          <fudis-dd>{{ studyRightExtensionWorkflowDecision.administrativeReview.reviewerId | publicPerson | async | personFullName }}, {{ studyRightExtensionWorkflowDecision.administrativeReview.reviewerTitle | localizedString }}</fudis-dd>
        </fudis-dl-item>
        <fudis-dl-item data-cy="srewd-administrative-review-notes" *ngIf="hasAdministrativeReview()">
          <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.ADMINISTRATIVE_REVIEW_NOTES')"/>
          <fudis-dd>
            <sis-text-with-default-missing-value [value]="studyRightExtensionWorkflowDecision.administrativeReview.notes" />
          </fudis-dd>
        </fudis-dl-item>
      </ng-container>
    </fudis-dl>

    <fudis-dl [columns]="{ sm: 1, md: 2 }">
      <fudis-dl-item data-cy="srewd-approval-date">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.APPROVAL_DATE')"/>
        <fudis-dd [contentText]="studyRightExtensionWorkflowDecision.approvalDate | localDateFormat" />
      </fudis-dl-item>
      <fudis-dl-item data-cy="srewd-appeal-instructions">
        <fudis-dt [contentText]="t('STUDENT_APPLICATIONS.APPEAL_INSTRUCTIONS')"/>
        <fudis-dd><sis-markup-string-view [content]="studyRightExtensionWorkflowDecision.appealInstructions" /></fudis-dd>
      </fudis-dl-item>
    </fudis-dl>
  </fudis-grid>
</div>
