<app-cart-container
        *ngIf="wizardSteps"
        [pageTitle]="wizardSteps[currentStep].pageTitle"
        [wizardStepKeys]="wizardProgressSteps"
        [currentStep]="currentStep"
>
  <div class="row" *ngIf="loaded">
    <ng-container *ngIf="cart?.items?.length > 0; else emptyCart">
      <div class="col-12 col-lg-10">
        <h2 tabindex="-1" data-cy="cart-wizard-step-title" #currentStepTitle>
          {{ wizardSteps[currentStep].wizardProgressStep | translate }}
        </h2>

        <app-cart-check-selections
          [hidden]="currentStep !== STEP.CHECK_SELECTIONS"
          [cart]="cart"
          [checkEnrolments$]="checkEnrolments$"
          (hasValidEnrolments)="nextStep()"
        ></app-cart-check-selections>

        <app-cart-contact-information
          [hidden]="currentStep !== STEP.CONTACT_INFORMATION"
          [cart]="cart"
          [submitClicked]="submitClickedSubject.asObservable()"
          (formSubmitted)="continueToPaymentSystem($event)"
          [autoConfirmText]="showAutoConfrimWizard"
        ></app-cart-contact-information>
      </div>

      <div class="col-12 col-lg-10 wizard-step-button-container">
        <sis-wizard-step-buttons
          [backButtonTitle]="wizardSteps[currentStep].backButtonTitle | translate"
          [backButtonDisabled]="false"
          [backButtonDisplay]="true"
          [forwardButtonTitle]="wizardSteps[currentStep].forwardButtonTitle | translate"
          [forwardButtonDisabled]="requestInProgress"
          [forwardButtonDisabledTooltip]="'OPEN_UNIVERSITY_CART.WIZARD.CONTACT_INFORMATION.WAITING_GUIDANCE' | translate"
          [forwardButtonDisplay]="true"
          (back)="back()"
          (forward)="forward()"
        >
        </sis-wizard-step-buttons>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!loaded" class="spinner-border" role="status">
    <span class="visually-hidden">{{'OPEN_UNIVERSITY_CART.WIZARD.LOADING' | translate}}</span>
  </div>
</app-cart-container>

<ng-template #emptyCart>
  <div class="col-12 col-lg-10">
    <app-empty-cart/>
  </div>
</ng-template>
