import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
// @ts-ignore
import Glider from 'glider-js';
import { isNumber } from 'lodash';
import { Subscription } from 'rxjs';

import {
    CarouselSelectionBoxSlideComponent,
} from '../carousel-selection-box-slide/carousel-selection-box-slide.component';
import { arrowKeys, CarouselConfig, CommonConfig, prevKeys, SlideBanner, SlideWidth } from '../carousel/carousel.types';

@Component({
    selector: 'sis-carousel',
    templateUrl: './carousel.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {

    mergedConfig: CarouselConfig;
    containerWidthClass: string;
    glider: Glider;

    @ViewChild('gliderRef') private gliderRef: ElementRef<HTMLDivElement>;
    @ViewChild('prevRef') private prevRef: ElementRef<HTMLButtonElement>;
    @ViewChild('nextRef') private nextRef: ElementRef<HTMLButtonElement>;
    // CarouselSelectionBoxSlideComponent has to be generalised e.g. by creating a base class if there is a need to create a new kind of slide component
    @ContentChildren(CarouselSelectionBoxSlideComponent) private slideComponents: QueryList<CarouselSelectionBoxSlideComponent>;

    @Input() control: FormControl;
    /**
     * Optional role attribute to be used for the carousel content, e.g. radiogroup
     */
    @Input() contentRole?: string;
    /**
     * Optional values to use for overriding the default configuration
     */
    @Input() config?: Partial<CarouselConfig>;
    /**
     * Optional custom translation key or text to be used as the aria-label of the previous-button
     */
    @Input() prevAriaLabel? = 'ARIA_LABEL.PREVIOUS';
    /**
     * Optional custom translation key or text to be used as the aria-label of the next-button
     */
    @Input() nextAriaLabel? = 'ARIA_LABEL.NEXT';
    /**
     * Optional id of the slide that should be automatically selected
     */
    @Input() selectedSlideId?: string;
    /**
     * Optional translation key or text to be shown between navigation arrows
     */
    @Input() mobileViewDescription?: string;
    /**
     * Optional overriding value for when to use mobile view in case the default behavior is not enough
     */
    @Input() mobileView?: boolean;
    /**
     * Optional value to be used with aria-labelledby and aria-describedby attributes.
     */
    @Input() carouselHeaderId?: string;

    slideKeyEventSubscriptions: Array<Subscription>;
    visibleSlideIndex = 0;

    ngOnInit() {
        this.mergedConfig = { ...new CarouselConfig(), ...this.config };
        this.updateContainerWidthClass();
    }

    @HostListener('window:resize')
    updateContainerWidthClass() {
        if (this.isMobileView()) {
            this.containerWidthClass = 'mobile';
            return;
        }

        const responsiveConfig = this.getCurrentResponsiveConfig();
        const itemWidth = responsiveConfig?.itemWidth ?? this.mergedConfig.itemWidth;
        const slidesToShow = responsiveConfig?.slidesToShow ?? this.mergedConfig.slidesToShow;
        const values = [itemWidth, slidesToShow];

        // if either value is 'auto', the container width should not be limited by setting a class
        if (values.find(value => value === 'auto')) {
            if (this.containerWidthClass) {
                this.containerWidthClass = undefined;
            }
            return;
        }

        if (values.filter(value => !isNumber(value)).length > 0) {
            throw new Error(`Carousel config values "itemWidth" and "slidesToShow" should be either 'auto' or number – got itemWidth: ${itemWidth}, slidesToShow: ${slidesToShow}`);
        }

        this.containerWidthClass = `${SlideWidth[itemWidth]}-${slidesToShow}`;
    }

    isMobileView(): boolean {
        return this.mobileView ?? this.isMobileViewDefault();
    }

    private isMobileViewDefault(): boolean {
        const relevantBreakpoints = this.mergedConfig?.responsive?.map(r => r.breakpoint).filter(b => b > 0);
        const mobileBreakpoint = relevantBreakpoints?.length > 0 ? Math.min(...relevantBreakpoints) : 768;
        return window.innerWidth < mobileBreakpoint;
    }

    private getCurrentResponsiveConfig(): CommonConfig {
        const relevantBreakpoints = this.mergedConfig.responsive?.map(r => r.breakpoint).filter(b => b <= window.innerWidth);
        return relevantBreakpoints?.length > 0
            ? this.mergedConfig.responsive.find(r => r.breakpoint === Math.max(...relevantBreakpoints))?.settings
            : null;
    }

    ngAfterViewInit() {
        this.glider = new Glider(this.gliderRef.nativeElement, this.mergedConfig);

        this.gliderRef.nativeElement.addEventListener('glider-slide-visible', this.handleSlideChange.bind(this));

        if (this.selectedSlideId) {
            const selectedSlideIndex = this.getSlideComponents().find(sc => sc.id === this.selectedSlideId)?.value;
            if (isNumber(selectedSlideIndex)) {
                this.visibleSlideIndex = selectedSlideIndex;
                this.selectSlide(selectedSlideIndex);
                this.glider.scrollItem(selectedSlideIndex);
            }
        }
        this.slideKeyEventSubscriptions = this.getSlideComponents()
            .map(sc => {
                sc.afterCarouselViewInit(this.getSlideComponents());
                return sc.slideKeyEvent.subscribe(($slideKeyEvent: KeyboardEvent) => this.stepIfNecessary($slideKeyEvent));
            });

        // this is a hacky way to fix the change detection issue described in OTM-32408
        setTimeout(() => {
            this.glider.refresh(true);
        }, 0);
    }

    handleSlideChange($event: CustomEvent) {
        if (this.isMobileView() && this.visibleSlideIndex !== $event.detail.slide) {
            this.visibleSlideIndex = $event.detail.slide;
            // prevent slide focus in case browse arrow is active, so that browsing isn't interrupted
            this.selectSlide(this.visibleSlideIndex, !this.isBrowseArrowActive());
        }
    }

    private isBrowseArrowActive() {
        return document.activeElement
            && [this.prevElementClassName, this.nextElementClassName].includes(document.activeElement.className);
    }

    selectSlide(slideIndex: number, allowFocus = true) {
        this.getSlideComponents()[slideIndex]?.selectSlide(allowFocus);
    }

    // exists basically just to make mocking in tests a bit easier
    getSlideComponents(): CarouselSelectionBoxSlideComponent[] {
        return this.slideComponents?.toArray();
    }

    private stepIfNecessary($slideKeyEvent: KeyboardEvent) {
        if (this.isMobileView() && arrowKeys.includes($slideKeyEvent.key)) {
            $slideKeyEvent.preventDefault();
            const fromIndex = this.control.value ?? 0;
            const lastIndex = (this.getSlideComponents()?.length ?? 1) - 1;
            let toIndex;
            if (prevKeys.includes($slideKeyEvent.key)) {
                toIndex = fromIndex !== 0 ? fromIndex - 1 : lastIndex;
            } else {
                toIndex = fromIndex !== lastIndex ? fromIndex + 1 : 0;
            }
            this.glider.scrollItem(toIndex);
        }
    }

    getCurrentSlideCount(): string {
        return `${this.visibleSlideIndex + 1} / ${this.getSlideComponents()?.length ?? 0}`;
    }

    hideControls(): boolean {
        const slidesToShow = this.getCurrentResponsiveConfig()?.slidesToShow ?? this.mergedConfig.slidesToShow;
        return isNumber(slidesToShow) ? this.getSlideComponents()?.length <= slidesToShow : false;
    }

    ngOnDestroy() {
        this.slideKeyEventSubscriptions?.forEach(subscription => subscription.unsubscribe());
        this.glider?.destroy();
    }

    get prevElementClassName(): string {
        return this.prevRef.nativeElement.className;
    }

    get nextElementClassName(): string {
        return this.nextRef.nativeElement.className;
    }

    // storybook requires some dummy implementation here. if you know how to get rid of this, please tell me also
    getBanner(_index: number): SlideBanner {
        return null;
    }
}
