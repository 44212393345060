import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { LocalizedString } from 'common-typescript/types';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';

interface IdentityProvider {
    name: LocalizedString;
    url: string;
}

@Component({
    selector: 'sis-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

    identityProviders: IdentityProvider[] = [];

    @Input() mobileMode = false;

    @Output() keydownPressed = new EventEmitter<any>();
    @Output() blurMobileLogin = new EventEmitter<any>();

    constructor(private configService: ConfigService,
                private authService: AuthService) {}

    ngOnInit() {
        const identityProviderLogins = this.configService.get()?.identityProviderLogins || [];
        this.identityProviders = (identityProviderLogins).map(login => ({
            name: login.name,
            url: this.authService.getAuthUrl(login.shibbolethUrl, window.location.href),
        }));
    }
}
