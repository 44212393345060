<ng-container *transloco="let t">
  <div class="container sis-pt-md">
    <sis-page-title [title]="t('OPEN_UNIVERSITY.STUDIES_ACTIVATE_ERROR.TITLE')"/>
    <div>
      <transloco key="OPEN_UNIVERSITY.STUDIES_ACTIVATE_ERROR.DESCRIPTION" /><span *ngIf="!contactLinkObject">.</span>
      <a *ngIf="contactLinkObject?.isEmail; else notEmailLink" [href]="'mailto:' + contactLinkObject?.link">
        {{ contactLinkObject?.link }}</a>
      <ng-template #notEmailLink>
        <a *ngIf="contactLinkObject?.contactLinkText" [href]="contactLinkObject?.link">
          {{ contactLinkObject?.contactLinkText }}</a>
      </ng-template>
    </div>
  </div>
</ng-container>
