import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { AuthService } from 'sis-common/auth/auth-service';
import { ConfigService } from 'sis-common/config/config.service';

import { navUrls, NavUtilsService } from '../nav-utils/nav-utils.service';

interface UserRoleSelection {
    active: boolean;
    nameKey: string;
    url: string;
}

@Component({
    selector: 'sis-user-role-menu',
    templateUrl: './user-role-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleMenuComponent implements OnInit {

    @Input() mobileMode = false;

    @Output() keydownPressed = new EventEmitter<any>();
    @Output() blurMobileUserRoleMenu = new EventEmitter<any>();

    userRoleSelections: UserRoleSelection[] = [];

    activeRoleNameKey: string;
    currentUniversityOrgId: OtmId;

    constructor(private configService: ConfigService,
                private navUtilsService: NavUtilsService,
                private authService: AuthService) {}

    ngOnInit() {
        this.userRoleSelections = this.getUserRoleSelections(this.navUtilsService.getSelectedContextPath());
        this.activeRoleNameKey = this.getActiveRoleNameKey(this.navUtilsService.getSelectedContextPath());
    }

    updateActiveRole(role: UserRoleSelection): void {
        // If role is changed but context isn't, update active role to match context
        if (role.url === this.navUtilsService.getContextPath()) {
            this.activeRoleNameKey = this.getActiveRoleNameKey(this.navUtilsService.getContextPath());
            this.userRoleSelections = this.getUserRoleSelections(this.navUtilsService.getContextPath());
            // Special case for staff url, since navUrls.STAFF_URL contains full path of the front page and will
            // therefore not match this.navUtilsService.getContextPath() which returns only /staff
        } else if (role.url === navUrls.STAFF_URL) {
            this.activeRoleNameKey = this.getActiveRoleNameKey(navUrls.STAFF_URL);
            this.userRoleSelections = this.getUserRoleSelections(navUrls.STAFF_URL);
        }
    }

    private getUserRoleSelections(context: string): UserRoleSelection[] {
        const roles: UserRoleSelection[] = [];
        const universityConfig = this.configService.get();
        if (this.authService.hasScope('student') || this.authService.hasScope('staff')) {
            roles.push({
                active: context === navUrls.STUDENT_URL,
                nameKey: 'AUTH.APPROLE.STUDENT',
                url: !universityConfig.studentFrontpageRedirectUrl ?
                    navUrls.STUDENT_URL : navUrls.STUDENT_PLAN_URL,
            });
        }
        if (this.authService.hasScope('staff')) {
            roles.push({
                active: context === navUrls.TEACHER_URL,
                nameKey: 'AUTH.APPROLE.TEACHER',
                url: navUrls.TEACHER_URL,
            });
            roles.push({
                active: (context === navUrls.STAFF_URL || context === '/staff'),
                nameKey: 'AUTH.APPROLE.STAFF',
                url: navUrls.STAFF_URL,
            });
        }
        if (this.authService.hasScope(['admin', 'admin-ui'])) {
            roles.push({
                active: context === navUrls.ADMIN_URL,
                nameKey: 'AUTH.APPROLE.ADMIN',
                url: navUrls.ADMIN_URL,
            });
        }

        return roles;
    }

    private getActiveRoleNameKey(context: string): string {
        switch (context) {
            case (navUrls.ADMIN_URL):
                return 'AUTH.APPROLE.ADMIN';
            case (navUrls.STUDENT_URL):
                return 'AUTH.APPROLE.STUDENT';
            case (navUrls.STAFF_URL):
                return 'AUTH.APPROLE.STAFF';
            case (navUrls.TEACHER_URL):
                return 'AUTH.APPROLE.TEACHER';
            case ('/staff'):
                return 'AUTH.APPROLE.STAFF';
        }
        return null;
    }
}
