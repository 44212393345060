import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Plan, ValidatablePlan } from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

import { PlanSelectorConfig } from './planSelectorConfig';

export interface PlanSelectorValues {
    description: string;
    plan: Plan;
    planSelectorConfig: PlanSelectorConfig;
    title: string;
    validatablePlan: ValidatablePlan;
}

@Component({
    selector: 'sis-legacy-plan-selector-modal',
    template: '<plan-selector-modal (close)="activeModal.close($event.$value)" (dismiss)="activeModal.dismiss()" [resolve]="values"></plan-selector-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class LegacyPlanSelectorModalComponent {
    constructor(@Inject(ModalService.injectionToken) public values: PlanSelectorValues,
                public activeModal: NgbActiveModal) {}
}
