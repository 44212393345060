<div role="article" class="row">
  <div class="col-12" [ngClass]="{'col-md-10': loggedIn }">
    <div class="row course-unit-heading">
      <div class="results-name col-12">
        <div class="title-box" data-cy="course-unit-info-title">
          <a [uiSref]="courseUnitTitleLink" [uiParams]="{courseUnitId: result.id}"
             data-cy="course-unit-info-link">
            <h3 class="courseunit-title">
              <sis-mark-string [target]="result.name" [query]="term"/> ({{result.credits | creditRange:'TEXT'  }})
            </h3>
          </a>
          <span class="courseunit-code">
            <sis-mark-string [target]="result.code" [query]="term"/>
          </span>
        </div>
        <div *ngIf="matchingOtherLang" class="language-match">
          <span class="language-match-title" translate>SEARCH_LANGUAGE_MATCH</span>
          <span>{{' ' + result.nameMatch}}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 tweet-text" *ngIf="result.tweetText">
        <p>{{result.tweetText}}</p>
      </div>
      <div class="col-12 col-md-5">
        <div class="tiny-static-form-group">
          <span class="label colon" translate>SEARCH_RESULT.ATTAINMENT_LANGUAGES</span>
          <span *ngIf="!result.attainmentLanguageUrns || result.attainmentLanguageUrns.length === 0" class="value"
                translate>SEARCH_RESULT.NO_INFO</span>
          <ng-container *ngFor="let languageUrn of result.attainmentLanguageUrns; index as i">
                        <span class="value">
                            {{ languageUrn | codeLocalizedName | async }}<span
                          *ngIf="result.attainmentLanguageUrns.length -1 !== i">,</span>
                        </span>
          </ng-container>
        </div>
        <div class="tiny-static-form-group">
          <span class="label colon" translate>SEARCH_RESULT.TEACHING_LANGUAGES</span>
          <span *ngIf="curLangCodeUrns.length === 0" class="value" translate>SEARCH_RESULT.NO_INFO</span>
          <ng-container *ngFor="let urn of curLangCodeUrns; index as j">
                        <span class="value">
                            {{ urn | codeLocalizedName | async }}<span *ngIf="curLangCodeUrns.length -1 !== j">,</span>
                        </span>
          </ng-container>
        </div>
        <div class="tiny-static-form-group">
          <span class="label colon" translate>SEARCH_RESULT.ACTIVITY_PERIODS</span>
          <span class="value">{{ renderedActivityPeriods }}</span>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="tiny-static-form-group">
          <span class="label colon" translate>SEARCH_RESULT.CURRICULUM_PERIOD</span>
          <span class="value">{{ renderedCurriculumPeriods }}</span>
        </div>
        <div class="tiny-static-form-group course-unit-search-result-organisations">
          <span class="label colon" translate>SEARCH_RESULT.ORGANIZATION</span>
          <sis-show-more-text
            [text]="organisationNames"
            [maxCharsBeforeOverFlow]="80"
            [showMoreButtonText]="'ARIA_LABEL.SHOW_MORE'"
            [showLessButtonText]="'ARIA_LABEL.SHOW_LESS'">
          </sis-show-more-text>
        </div>
        <div class="tiny-static-form-group">
          <span class="label colon" translate>SEARCH_RESULT.UNIVERSITY_ORG_IDS</span>
          <ng-container *ngFor="let universityOrgId of result.universityOrgIds; index as j">
                        <span class="value">
                            {{(universityOrgId|organisation|async)?.name | localizedString}}
                          <span *ngIf="result.universityOrgIds.length -1 !== j">,</span>
                        </span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-2" *ngIf="loggedIn">
    <div class="course-cart-actions float-md-right" aria-live="polite">
      <sis-button naked="true" size="sm" *ngIf="!isInCart"
                  (clicked)="addToCart()"
                  dataCy="add-to-course-cart"
                  [ariaLabel]="('ARIA_LABEL.ADD_TO_CART' | translate) + ' ' + (result.name | plainText)">
        <sis-icon icon="list-add"></sis-icon>
        <span translate>ADD_TO_CART</span>
      </sis-button>
      <sis-button naked="true" size="sm" variant="secondary" *ngIf="isInCart"
                  (clicked)="removeFromCart()"
                  dataCy="remove-from-course-cart"
                  [ariaLabel]="('ARIA_LABEL.REMOVE_FROM_CART' | translate) + ' ' + (result.name | plainText)">
        <sis-icon icon="list-minus"></sis-icon>
        <span translate>REMOVE_FROM_CART</span>
      </sis-button>
    </div>
  </div>
</div>
