import angular from 'angular';
import _ from 'lodash';
import '@uirouter/angular-hybrid';
import { hybridAppBootstrapModule } from 'sis-common/angular-hybrid/hybrid-app-bootstrap.dir';
import { ServiceBootstrapComponent } from 'sis-common/angular-hybrid/service-bootstrap.component.ts';
import { AlertsComponent } from 'sis-components/alerts/alerts.component.ts';
import { AlertsService } from 'sis-components/alerts/alerts-ng.service.ts';
import { FooterComponent } from 'sis-components/footer/footer.component.ts';
import { pageHeaderModule } from 'sis-components/header/pageHeader.component.ts';
import { SisMessageConversationComponent } from 'sis-components/messenger/sis-message-conversation.component.ts';
import { SkipLinkComponent } from 'sis-components/skip-link/skip-link.component.ts';
import { ServiceBreakService } from 'sis-components/service/service-break.service.ts';
import { BehaviorSubject } from 'rxjs';
import { OpenUniversityCartTimerAlertService } from 'sis-components/alerts/open-university-cart-timer-alert.service.ts';
import { editModeServiceModule } from 'sis-components/edit/editMode.service.ts';
import { errorHandlerModule } from 'sis-components/error-handler/legacy/errorhandler.module';
import { AboutComponent } from 'sis-components/about/about.component.ts';
import { environmentNgjsServiceModule } from 'sis-common/environmentService/environmentNgjs.service';
import { frontendInfoNgjsInterceptorModule } from 'sis-common/http-interceptors/frontend-info/frontendInfoNgjs.interceptor';
import { TranspileComponent } from 'sis-components/transpile/transpile.component.ts';
import { MarkupStringViewComponent } from 'sis-components/markup-string-view/markup-string-view.component.ts';
import { LocalizedMarkupStringViewComponent } from 'sis-components/localized-markup-string-view/localized-markup-string-view.component.ts';
import { NotificationsService } from 'sis-components/service/notifications/notifications.service.ts';
import { configModule } from 'sis-common/config/config.module.ts';
import { logModule } from 'sis-components/error-handler/log.module.ts';
import { UniversityIframeComponent } from 'sis-components/university-iframe/university-iframe.component.ts';
import { ngxClipboardServiceModule } from 'sis-common/angular-hybrid/hybrid.ts';
import { environment } from '../environments/environment.ts';
import { StudentTopNavigationComponent } from './common/components/student-top-navigation/student-top-navigation.component.ts';
import { CourseCartComponent } from './course-cart/course-cart.component.ts';
import { studentRoutes } from './auth/student-routes.ts';
export const studentModule = 'student';
export const transitionChangeSubject = new BehaviorSubject(null);
export function initAppModule(downgradedAngularModules) {
  getSelectedPlan.$inject = ["$q", "$log", "$transition$", "$state", "$rootScope", "$filter", "plans", "planSeeker", "errorService"];
  findPlansForUser.$inject = ["commonPlanService", "AuthService"];
  init.$inject = ["$uiRouter", "$transitions", "$window", "environmentNgjsService"];
  NgServiceCtrl.$inject = ["$rootScope", "sisAlertsService", "serviceBreakService", "sisOpenUniversityCartTimerAlertService"];
  AppCtrl.$inject = ["$rootScope", "$transitions", "$translate"];
  animation.$inject = ["$animateProvider"];
  routes.$inject = ["$stateProvider", "$locationProvider"];
  configureLogs.$inject = ["$logProvider", "$compileProvider", "$provide"];
  /* globals universityConfig */
  return angular.module(studentModule, ['ngSanitize', 'ngAnimate', 'ngMessages', 'ui.router', 'ui.router.upgrade', 'sis-components.util.edit-mode', 'sis-common.auth', 'sis-common.l10n.localeService', errorHandlerModule, 'sis-common.university', 'sis-components.date.localDateFormatFilter', logModule, configModule, pageHeaderModule, 'sis-components.messenger.messages', 'sis-components.printPlan', 'sis-components.string.sortByLocalizedValueFilter', 'sis-components.string.textareaLength', 'sis-components.edit.editMode', 'sis-components.model.attainment', 'sis-components.model.courseUnit', 'sis-components.service.validAttainmentFilterService', 'student.common.service.courseUnitService', 'tmh.dynamicLocale', 'student.common.utils.previewMode', 'student.common.components.planSeeker', 'student.tutoring.route', 'student.frontpage', 'student.plan', 'student.calendar.route', 'student.timing.route', 'student.profile', 'student.messages.studentMessageConversationsList', AboutComponent.downgrade.moduleName, AlertsService.downgrade.moduleName, ServiceBreakService.downgrade.moduleName, AlertsComponent.downgrade.moduleName, NotificationsService.downgrade.moduleName,
  // Actually this is only needed by 'student.common.components.courseUnitInfoModal',
  // but for some reason tests can't find the clipboard module,
  // if it's only required by 'student.common.components.courseUnitInfoModal'.
  ngxClipboardServiceModule, hybridAppBootstrapModule, editModeServiceModule, environmentNgjsServiceModule, frontendInfoNgjsInterceptorModule, ServiceBootstrapComponent.downgrade.moduleName, SisMessageConversationComponent.downgrade.moduleName, SkipLinkComponent.downgrade.moduleName, OpenUniversityCartTimerAlertService.downgrade.moduleName, CourseCartComponent.downgrade.moduleName, TranspileComponent.downgrade.moduleName, MarkupStringViewComponent.downgrade.moduleName, LocalizedMarkupStringViewComponent.downgrade.moduleName, UniversityIframeComponent.downgrade.moduleName, ...downgradedAngularModules]).config(configureLogs)
  // Delay initial UI-Router URL sync until all bootstrapping is complete
  .config(["$urlServiceProvider", $urlServiceProvider => {
    $urlServiceProvider.deferIntercept();
    $urlServiceProvider.config.strictMode(false);
  }]).config(routes).config(animation).config(["DSProvider", DSProvider => {
    angular.extend(DSProvider.defaults, {
      csp: true
    });
  }]).constant('studentDefault', {
    view: 'student.logged-in.frontpage'
  }).controller('AppCtrl', AppCtrl).controller('NgServiceCtrl', NgServiceCtrl).run(init);

  /**
   * @ngInject
   */
  function init($uiRouter, $transitions, $window, environmentNgjsService) {
    // NOSONAR
    _.forEach(universityConfig.uiRouterTraceCategories, category => {
      $uiRouter.trace.enable(category);
    });
    $transitions.onStart({}, trans => {
      const toState = trans.$to();
      if (toState.external) {
        $window.location.href = toState.url;
        return false;
      }
      if (toState.redirectTo) {
        return {
          state: toState.redirectTo,
          params: toState.params
        };
      }
      return undefined;
    });
    environmentNgjsService.init(environment);
  }
  function isInsideContainer(state) {
    return _.some(['student.about', 'student.search', 'student.logged-in.enrolments', 'student.logged-in.profile'], stateName => _.includes(state.name, stateName));
  }
  function NgServiceCtrl($rootScope, sisAlertsService, serviceBreakService, sisOpenUniversityCartTimerAlertService) {
    if (!serviceBreakService) {
      console.warn('Problem initializing serviceBreakService for AngularJS use');
    }
    sisOpenUniversityCartTimerAlertService.subscribeToCurrentOpenUniversityCartChanges();
    $rootScope.$on('alert', (e, alert) => sisAlertsService.addAlert({
      type: alert.type,
      message: alert.msg,
      identifier: alert.identifier,
      dismiss: alert.dismiss,
      onClickCallback: alert.onClickCallback
    }));
    $rootScope.$on('dismiss-all-alerts', () => sisAlertsService.dismissAllAlerts());
    $rootScope.$on('dismiss-all-matching-alerts', (e, data) => sisAlertsService.dismissAllMatchingAlerts(_.get(data, 'pattern')));
    $rootScope.$on('dismiss-alert-if-exists', (e, data) => sisAlertsService.dismissAlertIfExists(_.get(data, 'identifier')));

    // Dirty fix for subscribing transition changes from init.
    // Can be refactored to onSuccess again when project is not initiated as AngularJS
    transitionChangeSubject.subscribe(transition => {
      if (transition) {
        $rootScope.browserTabTitle = transition.to().data && transition.to().data.browserTabTitle ? transition.to().data.browserTabTitle : '';
      }
    });
    $rootScope.$broadcast('NgAppInitialized');
    $rootScope.NgAppInitialized = true;
  }

  /**
   * @ngInject
   */
  function AppCtrl($rootScope, $transitions, $translate) {
    const ctrl = this;
    ctrl.stateData = {};
    ctrl.isReady = false;
    $transitions.onSuccess({}, trans => {
      ctrl.stateData = trans.$to().data;
      ctrl.pageTitle = 'PAGE_TITLE.STUDENT';
      $rootScope.isInsideContainer = isInsideContainer(trans.$to());
    });

    // State data must come from the current route when transition errors
    $transitions.onError({}, trans => {
      ctrl.stateData = trans.$from().data;
    });
    $transitions.onSuccess({}, transition => {
      transitionChangeSubject.next(transition);
    }, {
      priority: 999
    });
    $translate.onReady().then(() => {
      // Ensure async translations are loaded before page content is rendered
      ctrl.isReady = true;
    });
  }
  function findPlansForUser(commonPlanService, AuthService) {
    return commonPlanService.findAllByUserId(AuthService.personId(), true, true);
  }
  function getSelectedPlan($q, $log, $transition$, $state, $rootScope, $filter,
  // NOSONAR
  plans, planSeeker, errorService) {
    const params = _.cloneDeep($transition$.params());
    if (params.planId) {
      const selectedPlan = _.find(plans, {
        id: params.planId
      });
      if (selectedPlan) {
        return selectedPlan;
      }
      $log.warn(`Plan with id ${params.planId} not found!`, 'plans:', plans, 'params:', params);
      errorService.showTranslatedError({
        titleKey: 'ERROR.PLAN_WITH_GIVEN_ID_NOT_FOUND'
      });
      $state.go(studentRoutes.defaultView);
      // Should use redirectTo instead of $state.go. Can't refactor now because of riliisihässäkkä.
      return undefined;
    }
    return planSeeker.find().then(planId => {
      const toState = $transition$.$to().name;
      params.planId = planId;
      $log.info('Redirect to default plan', toState, params);
      $state.go(toState, params);
    }).catch(error => {
      $log.warn('Error finding default plan', error, 'plans:', plans, 'params:', params);
      $state.go(studentRoutes.defaultView);
    });
  }

  /**
   * @ngInject
   */
  function routes($stateProvider, $locationProvider) {
    $stateProvider.state('student', {
      url: '',
      data: {},
      redirectTo: studentRoutes.defaultView,
      headerParams: {
        skipBreadcrumb: true
      },
      views: {
        'university-iframe@': {
          component: UniversityIframeComponent
        },
        topNavigation: {
          component: StudentTopNavigationComponent
        },
        '': {
          template: '<ui-view></ui-view>'
        },
        footer: {
          component: FooterComponent
        }
      },
      resolve: {
        // For FooterComponent
        aboutHref: () => '/student/about'
      },
      onEnter: function () {
        document.body.classList.add('page-student');
      } // /onExit: ...
    }).state('student.logged-in', {
      url: '',
      abstract: true,
      headerParams: {
        skipBreadcrumb: true
      },
      params: {
        receivedMessageTypeFilter: {
          value: 'STUDENT'
        }
      }
    }).state('student.about', {
      url: '/about',
      data: {
        showHeader: true
      },
      headerParams: {
        displayNameKey: 'ABOUT.TITLE'
      },
      component: AboutComponent,
      resolve: {
        version: () => ({
          name: 'student',
          version: BUILD_VERSION,
          build: BUILD_TAG
        })
      }
    }).state('student.logged-in.course-cart', {
      url: '/course-cart',
      template: '<app-course-cart></app-course-cart>',
      data: {
        browserTabTitle: 'APPLICATION_COURSE_CART',
        showHome: true,
        hideLastNode: false
      },
      headerParams: {
        displayNameKey: 'APPLICATION_COURSE_CART'
      }
    }).state('student.logged-in.plan', {
      url: '/plan/:planId',
      params: {
        planId: ''
      },
      abstract: true,
      resolve: {
        plans: findPlansForUser,
        selectedPlan: getSelectedPlan
      },
      template: '<ui-view></ui-view>',
      headerParams: {
        skipBreadcrumb: true
      }
    }).state('student.logged-in.messages', {
      abstract: true,
      url: '/messages',
      template: '<ui-view></ui-view>'
    }).state('student.logged-in.messages.list', {
      url: '',
      template: '<messages class="container" message-filter-type="\'STUDENT\'" remove-sender-student-number="true"></messages>',
      data: {
        messageSnippetLength: 170,
        fixedHeader: true,
        headerWithoutBreadcrumbs: true,
        hasSecondaryBackground: true,
        showMessagesHeading: true,
        showHeader: true
      }
    }).state('student.logged-in.messages.message', {
      url: '/:messageId?returnRoute',
      template: '<message message-id="messageId" return-route="returnRoute" remove-sender-student-number="true"></message>',
      controller: ["$scope", "$stateParams", function ($scope, $stateParams) {
        $scope.messageId = $stateParams.messageId;
        $scope.returnRoute = $stateParams.returnRoute;
      }],
      data: {
        openInPlanStructureHref: '/student/structure/:planId?openUnplanned',
        openInPlanTutoringRoute: 'student.logged-in.plan.tutoring.messages',
        openPriorLearningApplicationHref: '/student/profile/applications/prior-learning/:applicationId',
        openModuleAttainmentMessageHref: '/student/profile/applications/module-attainment/:applicationId',
        openDegreeProgrammeAttainmentMessageHref: '/student/profile/applications/degree-programme/:applicationId',
        openCustomAttainmentMessageHref: '/student/profile/applications/custom-attainment/:applicationId',
        openStudyRightExtensionApplicationHref: '/student/profile/applications/study-right-extension/:applicationId',
        openModuleContentApplicationHref: '/student/profile/applications/module-content-application/:applicationId',
        hasSecondaryBackground: true
      }
    }).state('student.logged-in.message-conversations', {
      abstract: true,
      url: '/message-conversations',
      data: {
        hideLastNode: false,
        showHome: true,
        browserTabTitle: 'COMMON.MESSAGES'
      },
      headerParams: {
        skipBreadcrumb: false,
        displayNameKey: 'STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.TITLE'
      },
      redirectTo: 'student.logged-in.message-conversations.list.all'
    }).state('student.logged-in.message-conversations.list', {
      abstract: true,
      url: '',
      headerParams: {
        skipBreadcrumb: true
      },
      data: {
        hasSecondaryBackground: false
      },
      redirectTo: 'student.logged-in.message-conversations.list.all'
    }).state('student.logged-in.message-conversations.list.all', {
      url: '/all',
      headerParams: {
        skipBreadcrumb: true,
        displayNameKey: 'STUDENT_MESSAGES.MESSAGE_CONVERSATIONS_LIST_VIEW.RECEIVED'
      },
      template: '<student-message-conversations-list [recipient-id]="personId" [filter-type]="\'STUDENT\'" [filter]="filter" [return-state-params]="returnStateParams"></student-message-conversations-list>',
      params: {
        returnStateParams: null
      },
      controller: ["$scope", "$stateParams", "AuthService", function ($scope, $stateParams, AuthService) {
        $scope.personId = AuthService.personId();
        $scope.filter = 'ALL';
        $scope.returnStateParams = _.get($stateParams, 'returnStateParams');
      }]
    }).state('student.logged-in.message-conversations.message-conversation', {
      url: '/:messageConversationId?returnRoute',
      template: '<sis-message-conversation [recipient-id]="personId" [message-conversation-id]="messageConversationId" [filter-type]="receivedMessageTypeFilter" [return-route]="returnRoute" [return-params]="returnParams"></sis-message-conversation>',
      controller: ["$scope", "$stateParams", "AuthService", function ($scope, $stateParams, AuthService) {
        $scope.messageConversationId = $stateParams.messageConversationId;
        $scope.returnRoute = $stateParams.returnRoute;
        $scope.personId = AuthService.personId();
        $scope.returnParams = $stateParams.returnParams;
        $scope.receivedMessageTypeFilter = $stateParams.receivedMessageTypeFilter;
      }],
      params: {
        returnParams: {}
      },
      data: {
        openInPlanStructureHref: '/student/structure/:planId?openUnplanned',
        openInPlanTutoringRoute: 'student.logged-in.plan.tutoring.messages',
        openPriorLearningApplicationHref: '/student/profile/applications/prior-learning/:applicationId',
        openModuleAttainmentMessageHref: '/student/profile/applications/module-attainment/:applicationId',
        openDegreeProgrammeAttainmentMessageHref: '/student/profile/applications/degree-programme/:applicationId',
        openCustomAttainmentMessageHref: '/student/profile/applications/custom-attainment/:applicationId',
        openStudyRightExtensionApplicationHref: '/student/profile/applications/study-right-extension/:applicationId',
        openModuleContentApplicationHref: '/student/profile/applications/module-content-application/:applicationId',
        hasSecondaryBackground: false,
        hideSenderStudentNumber: true
      }
    }).state('otherwise', {
      url: '*path',
      redirectTo: studentRoutes.defaultView
    });
    $locationProvider.html5Mode(true);
  }

  /**
   * ngAnimations enabled for elements with class name "angular-animate"
   * @ngInject
   */
  function animation($animateProvider) {
    $animateProvider.classNameFilter(/angular-animate/);
  }

  /**
   * @ngInject
   */
  function configureLogs($logProvider, $compileProvider, $provide) {
    $compileProvider.debugInfoEnabled(universityConfig.isDebugInfoEnabled);
    $logProvider.debugEnabled(universityConfig.isDebugEnabled);
    if (!angular.isUndefined(universityConfig.isInfoEnabled) && !universityConfig.isInfoEnabled) {
      $provide.decorator('$log', ["$delegate", $delegate => {
        $delegate.info = function () {};
        return $delegate;
      }]);
    }
  }
}