<div id="student-application" class="container student-application custom-attainment-application">
  <div class="row">
    <div class="col-md-10 col-lg-8">
      <div class="form-group-mimic">
        <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep"></sis-wizard-progress>
      </div>

      <app-custom-attainment-basic-info-edit [hidden]="!isFirstStep()"
                                             [planId]="plan?.id"
                                             [customStudyDraft]="customStudyDraft"
                                             (exit)="exit()"
                                             (continue)="continue($event)">
      </app-custom-attainment-basic-info-edit>
      <app-caa-attainment-info [hidden]="currentStep !== 1"
                               [customStudyDraft]="customStudyDraft"
                               (attachmentsChange)="onAttachmentsChange($event)"
                               (exit)="exit()"
                               (previous)="previous()"
                               (continue)="continue($event)">
      </app-caa-attainment-info>
      <app-caa-confirmation [hidden]="!isLastStep()"
                            [application]="workflowApplication"
                            [attachments]="attachments"
                            [disableButtons]="uploading"
                            (exit)="exit()"
                            (previous)="previous()"
                            (submit)="submit($event)">
      </app-caa-confirmation>

    </div>
  </div>
</div>
