import { Injectable } from '@angular/core';
import { OtmId } from 'common-typescript/types';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CourseUnitRealisationExpansionStatusService {
    // CURs are initially closed, so let's maintain "expanded" statuses instead of "closed" statuses
    private readonly _idsOfExpandedCourseUnitRealisations$: BehaviorSubject<ReadonlySet<OtmId>>
        = new BehaviorSubject<ReadonlySet<OtmId>>(new Set<OtmId>());

    private readonly _closedStatusStreamPerCourseUnitRealisationId: Map<OtmId, Observable<boolean>>
        = new Map<OtmId, Observable<boolean>>();

    /**
     * Returns a stream of "closed" statuses of the defined CUR.
     * Sequential duplicates are removed,
     * so this can be safely used with e.g. {@link ExpandableComponent} without emission loops.
     * Always returns the same instance per {@link courseUnitRealisationId}.
     */
    isClosed(courseUnitRealisationId: OtmId): Observable<boolean> {
        // Always return the same Observable instance per CUR to avoid change detection loops,
        // i.e. don't "pipe" here directly from the subject.
        let stream: Observable<boolean> | undefined = this._closedStatusStreamPerCourseUnitRealisationId.get(courseUnitRealisationId);
        if (!stream) {
            stream = this._idsOfExpandedCourseUnitRealisations$.pipe(
                map(idsOfExpandedCourseUnitRealisations => !idsOfExpandedCourseUnitRealisations.has(courseUnitRealisationId)),
                distinctUntilChanged(),
            );
            this._closedStatusStreamPerCourseUnitRealisationId.set(courseUnitRealisationId, stream);
        }
        return stream;
    }

    /**
     * Sets the "closed" status of the defined CUR.
     */
    setClosedStatus(courseUnitRealisationId: OtmId, closed: boolean): void {
        const willBeExpanded: boolean = !closed;

        const idsOfExpandedCourseUnitRealisations: ReadonlySet<OtmId> = this._idsOfExpandedCourseUnitRealisations$.value;
        const isNowExpanded: boolean = idsOfExpandedCourseUnitRealisations.has(courseUnitRealisationId);

        if (willBeExpanded === isNowExpanded) {
            return;
        }

        const newIdsOfExpandedCourseUnitRealisations = new Set<OtmId>([...idsOfExpandedCourseUnitRealisations]);
        if (willBeExpanded) {
            newIdsOfExpandedCourseUnitRealisations.add(courseUnitRealisationId);
        } else {
            newIdsOfExpandedCourseUnitRealisations.delete(courseUnitRealisationId);
        }

        this._idsOfExpandedCourseUnitRealisations$.next(newIdsOfExpandedCourseUnitRealisations);
    }
}
