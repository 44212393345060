import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ExternalAttainedStudy, LocalDateString, PriorStudies, Urn } from 'common-typescript/types';
import { Observable, of } from 'rxjs';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { AppErrorHandler } from './../../error-handler/app-error-handler';
import { ExternalAttainedStudyService } from './../../service/external-attained-study.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-prior-studies-summary',
    templateUrl: './prior-studies-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorStudiesSummaryComponent implements OnInit {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.lib.applications.prior-studies-summary',
        directiveName: 'sisPriorStudiesSummary',
    };

    @Input() priorStudies: PriorStudies;
    @Input() externalAttainedStudy?: ExternalAttainedStudy;

    emrexPriorStudies$: Observable<Partial<PriorStudies>>;

    constructor(private externalAttainedStudyService: ExternalAttainedStudyService, private appErrorHandler: AppErrorHandler) {}

    ngOnInit() {
        this.emrexPriorStudies$ = this.externalAttainedStudy ?
            this.externalAttainedStudyService.getExternalAttainedStudyAsPriorStudies(this.externalAttainedStudy)
                .pipe(
                    this.appErrorHandler.defaultErrorHandler(),
                ) : of({});
    }

    showEmrexStudentEntryBadge = (emrexValue: String | Urn | LocalDateString, priorStudiesValue: String | Urn | LocalDateString) => {
        if (emrexValue === undefined) return false;
        return emrexValue === null && priorStudiesValue;
    };
}
