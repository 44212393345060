import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { numberUtils } from 'common-typescript';
import {
    ApplicationAttachments,
    Attachment,
    ExternalAttainedStudyAttachments, OtmId,
    StudentApplication,
} from 'common-typescript/types';
import _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { DEFAULT_PROMISE_HANDLER } from 'sis-common/ajs-upgraded-modules';
import { ModalService } from 'sis-common/modal/modal.service';
import { ComponentDowngradeMappings, DowngradedComponent, StaticMembers } from 'sis-common/types/angular-hybrid';

import { COMMON_STUDENT_APPLICATION_SERVICE } from '../ajs-upgraded-modules';
import { AlertsService, AlertType } from '../alerts/alerts-ng.service';
import { AppErrorHandler } from '../error-handler/app-error-handler';
import { ApplicationAttachmentUploadService } from '../file-upload/application-attachment-upload.service';
import { FileItem, FileUploadComponent } from '../file-upload/file-upload.component';
import { ApplicationAttachmentEntityService } from '../service/application-attachment-entity.service';

@StaticMembers<DowngradedComponent>()
@Component({
    selector: 'sis-application-files',
    templateUrl: './application-files.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationFilesComponent implements OnInit, OnDestroy {

    static downgrade: ComponentDowngradeMappings = {
        moduleName: 'sis-components.applicationFiles.downgraded',
        directiveName: 'sisApplicationFiles',
    };

    @Input() applicationId: OtmId;
    @Input() studentId: OtmId;
    @Input() editable: boolean;
    @Input() editAllFiles?: boolean;
    @Input() staffEditMode?: boolean;
    @Input() externalAttainedStudyIds: OtmId[] = [];

    metadata: any;
    applicationAttachmentEntityServiceSubscription: Subscription;
    existingAttachmentsChanged = false;
    previouslyAddedFiles: FileItem[];
    application: StudentApplication | null;
    currentExternalAttachments: ExternalAttainedStudyAttachments[] = [];
    attachments: WritableSignal<Attachment[]> = signal([]);
    sendingFiles = signal(false);
    editing = signal(false);

    constructor(private appErrorHandler: AppErrorHandler,
                private translateService: TranslateService,
                private state: StateService,
                private uiRouterGlobals: UIRouterGlobals,
                private applicationAttachmentEntityService: ApplicationAttachmentEntityService,
                private modalService: ModalService,
                private applicationAttachmentUploadService: ApplicationAttachmentUploadService,
                private alertsService: AlertsService,
                @Inject(COMMON_STUDENT_APPLICATION_SERVICE) private commonStudentApplicationService: any,
                @Inject(DEFAULT_PROMISE_HANDLER) private defaultPromiseHandler: any) { }

    ngOnInit(): void {
        this.subscribeToApplicationAttachmentEntityService();
    }

    ngOnDestroy() {
        this.applicationAttachmentEntityServiceSubscription?.unsubscribe();
    }

    subscribeToApplicationAttachmentEntityService() {
        if (!!this.applicationAttachmentEntityServiceSubscription) {
            this.applicationAttachmentEntityServiceSubscription.unsubscribe();
        }
        this.applicationAttachmentEntityServiceSubscription = this.applicationAttachmentEntityService.getAttachmentsByApplicationId(this.applicationId)
            .pipe(this.appErrorHandler.defaultErrorHandler())
            .subscribe((res: ApplicationAttachments) => {
                this.metadata = res.metadata;
                this.attachments.set(res.attachments);
            });
    }

    downloadZip() {
        this.applicationAttachmentEntityService.downloadZip(this.applicationId);
    }

    addAttachments() {
        const componentRef = this.modalService.open(FileUploadComponent, {
            backdrop: 'static',
            size: 'sm',
        });
        const fileItems = this.getFileItemsFromAttachments(this.attachments());

        componentRef.componentInstance.previouslyAddedFiles = fileItems ? fileItems : [];
        componentRef.componentInstance.acceptedFilesTypes = ['application/pdf', 'text/plain', 'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpeg'];
        componentRef.componentInstance.isRemovable = false;
        componentRef.componentInstance.showPreviousFilesListButton = true;
        componentRef.componentInstance.modalMode = true;

        componentRef.result
            .then((updatedFileItems: FileItem[]) => {
                this.uploadAttachments(updatedFileItems);
            })
            .catch(() => {}); // The modal was dismissed => do nothing
    }

    editAttachments() {
        const fileItems = this.getFileItemsFromAttachments(this.attachments());
        this.previouslyAddedFiles = fileItems ? fileItems : [];
        this.setEditMode(true);
    }

    setEditMode(editMode: boolean) {
        this.editing.set(editMode);
    }

    uploadAttachments(updatedFileItems: FileItem[]) {
        const oldAttachments = this.getOldAttachments(this.attachments(), updatedFileItems);
        const newAddedFileItems = this.getNewAddedFileItems(this.attachments(), updatedFileItems);
        this.commonStudentApplicationService.findById(this.applicationId, { bypassCache: true }).then((application: any) => { // get the newest application state to ensure state has not changed during student add files
            if (this.staffEditMode || !!application && _.includes(['REQUESTED'], application.state)) {
                if (newAddedFileItems.length > 0 || this.existingAttachmentsChanged || oldAttachments.length !== this.attachments().length) { // Checks if something is changed and new application attachments upload is needed.
                    this.alertsService.addAlert(
                        {
                            type: AlertType.WARNING,
                            message: this.translateService.instant('FILE_UPLOAD.SENDING_FILES'),
                            identifier: this.applicationId,
                        });
                    this.sendingFiles.set(true);
                    this.applicationAttachmentUploadService.uploadApplicationAttachments(this.applicationId, newAddedFileItems, this.studentId, this.metadata, oldAttachments).then(() => {
                        this.alertsService.dismissAlert(this.applicationId);
                        this.alertsService.addAlert({
                            type: AlertType.SUCCESS,
                            message: this.translateService.instant('FILE_UPLOAD.FILES_UPLOADED'),
                        });
                        this.sendingFiles.set(false);
                        this.existingAttachmentsChanged = false; // initialise change
                        this.subscribeToApplicationAttachmentEntityService();
                    }).catch(() => {
                        this.sendingFiles.set(false);
                    });
                }
            } else {
                this.alertsService.addAlert(
                    {
                        type: AlertType.DANGER,
                        message: this.translateService.instant('FILE_UPLOAD.APPLICATION_STATE_CHANGED'),
                    });
                this.state.go(this.uiRouterGlobals.current.name, { applicationId: application.id, showSubmitSuccess: false }); // refresh page
            }
        }).catch(this.defaultPromiseHandler.loggingRejectedPromiseHandler);
    }

    getOldAttachments(attachments: Attachment[], updatedFileItems: FileItem[]): Attachment[] {
        return _.filter(attachments, (attachment) => // Filters old changed attachments based on their localId. Changes attachment comment if that is altered.
            _.some(updatedFileItems, (fileItem) => {
                if (fileItem.localId === attachment.localId) {
                    if (attachment.comment !== fileItem.explanation) {
                        this.existingAttachmentsChanged = true;
                        attachment.comment = fileItem.explanation;
                    }
                    return true;
                }
                return false;
            }),
        );
    }

    getNewAddedFileItems(attachments: Attachment[], updatedFileItems: FileItem[]): FileItem[] {
        return _.filter(updatedFileItems, (fileItem) => // Filters new added files if they are added compared to previous loaded attachment list.
            !_.some(attachments, (attachment) => attachment.localId === fileItem.localId),
        );
    }

    getFileItemsFromAttachments(attachments: Attachment[]): FileItem[] {
        const fileItems: FileItem[] = [];
        _.each(attachments, (attachment) => {
            const file = new File([new ArrayBuffer(attachment.size)], attachment.name, { type: attachment.fileType });
            fileItems.push({
                file,
                explanation: attachment.comment,
                preSignedGetUrl: attachment.preSignedGetUrl,
                localId: attachment.localId,
                name: file.name.normalize() });
        });
        return fileItems;
    }

    readableFileSizeString(number: any) {
        return numberUtils.readableFileSizeString(number);
    }

    getExternalAttainedStudyIdsAsObservable(): Observable<string[]> {
        return of(this.externalAttainedStudyIds);
    }

    onExternalAttachmentsChange($event: ExternalAttainedStudyAttachments[]) {
        this.currentExternalAttachments = $event;
    }
}
