<div *ngIf="universitySelections?.length > 1" aria-labelledby="change-university" role="group" class="university-menu"
     [class.university-menu--mobile]="mobileMode">
  <div class="university-menu__header" aria-hidden="true" id="change-university">{{'AUTH.UNIVERSITY' | translate}}</div>
  <button type="button"
          ngbDropdownItem
          role="menuitem"
          *ngFor="let university of universitySelections"
          (click)="changeUniversity(university.id)"
          (blur)="blurMobileUniversityMenu.emit($event)"
          (keydown.escape)="blurMobileUniversityMenu.emit($event)"
          (keydown)="keydownPressed.emit($event)"
          [attr.aria-current]="university.id === currentUniversityOrgId"
          class="university-menu__item">
    {{university.name}}
    <fudis-icon [icon]="'check'" *ngIf="university.id === currentUniversityOrgId" [color]="'primary'"/>
  </button>
</div>
