import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LocalDateRange, StudyRightExtensionWorkflowDecision } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { extensionValidityCalculator } from 'sis-components/workflow/WorkflowUtils';

@Component({
    selector: 'app-study-right-extension-workflow-decision-summary',
    templateUrl: './study-right-extension-workflow-decision-summary.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudyRightExtensionWorkflowDecisionSummaryComponent implements OnInit {

    @Input({ required: true }) studyRightExtensionWorkflowDecision!: StudyRightExtensionWorkflowDecision;

    private _extensionValidityCalculator = extensionValidityCalculator();

    extensionValidity$: Observable<LocalDateRange>;

    ngOnInit() {
        this.extensionValidity$ = this._extensionValidityCalculator(
            this.studyRightExtensionWorkflowDecision.extensionStartDate,
            this.studyRightExtensionWorkflowDecision.grantedTerms,
        );
    }

    hasAdministrativeReview() {
        return this.studyRightExtensionWorkflowDecision.administrativeReview?.result !== 'REVIEW_NOT_PERFORMED';
    }
}
