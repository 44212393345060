<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.RECENT_ATTAINMENTS.TITLE')" [subtitle]="t('FRONTPAGE.RECENT_ATTAINMENTS.SUBTITLE')">
    <ng-container *ngIf="recentAttainments$ | async as attainments; else loading">
      <ul *ngIf="attainments?.length">
        <li *ngFor="let attainment of attainments">
          <app-attainment-info [attainment]="attainment" (clicked)="onAttainmentClick($event)"/>
          <hr aria-hidden="true"/>
        </li>
      </ul>
      <p *ngIf="!attainments?.length && !searchFailed()" class="app-widget-container__content__info-text sis-mb-zero">
        {{t('FRONTPAGE.RECENT_ATTAINMENTS.NO_ITEMS')}}
      </p>
    </ng-container>

    <p *ngIf="searchFailed()" class="app-widget-container__content__info-text sis-mb-zero">
      {{t('FRONTPAGE.RECENT_ATTAINMENTS.FETCHING_ATTAINMENTS_FAILED')}}
    </p>
    <a fudisLink [href]="profileAttainmentsHref" [external]="true" class="fudis-mt-xxs" [title]="t('FRONTPAGE.RECENT_ATTAINMENTS.SHOW_ALL_ATTAINMENTS')"></a>
  </app-widget-container>
</ng-container>

<ng-template #loading>
  <div aria-busy="true" class="d-flex justify-content-center">
    <sis-small-spinner />
  </div>
</ng-template>
