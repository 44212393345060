<ng-container *transloco="let t">
  <fudis-dialog [size]="'md'">
    <fudis-heading fudisDialogTitle [level]="1">
      {{t(data.title)}}
    </fudis-heading>
    <fudis-dialog-content>
      <fudis-grid [rowGap]="'sm'">
        @if (data.description) {
          <fudis-body-text>{{t(data.description)}}</fudis-body-text>
        }
        @if (selectFailed && !selectedStudy) {
          <fudis-notification [variant]="'danger'" #errorNotification tabindex="-1">
            <fudis-body-text>{{t('SIS_COMPONENTS.PLAN_SELECTOR_MODAL.CHOOSE_STUDY')}}</fudis-body-text>
          </fudis-notification>
        }
        <sis-radiobutton-tree [rootNode]="rootNode" [(selected)]="selectedStudy"/>
      </fudis-grid>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button fudisDialogClose [variant]="'secondary'" [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')"/>
      <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SELECT')" (handleClick)="onSelect()"/>
    </fudis-dialog-actions>
  </fudis-dialog>
</ng-container>
