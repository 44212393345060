import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import {
    CeeposPayment,
    CeeposPaymentConfirmation,
    PaymentSystemLog,
} from 'common-typescript/types';
import { catchError, retry, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { PaymentSystemLogEntityService } from 'sis-components/service/payment-system-log-entity.service';
import { PaymentSystemService } from 'sis-components/service/payment-system.service';

@Component({
    selector: 'app-ceepos-payment-complete',
    templateUrl: './ceepos-payment-complete.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CeeposPaymentCompleteComponent implements OnInit {

    constructor(
        private paymentSystemLogEntityService: PaymentSystemLogEntityService,
        private paymentSystemService: PaymentSystemService,
        private stateService: StateService,
        private uiRouterGlobals: UIRouterGlobals,
        private appErrorHandler: AppErrorHandler,
    ) {}

    ngOnInit(): void {
        const ceeposPaymentConfirmation: CeeposPaymentConfirmation = this.readCeeposResponseFromQueryParams();

        this.logCeeposPaymentConfirmation(ceeposPaymentConfirmation);
        this.paymentSystemService.completePayment(undefined, ceeposPaymentConfirmation)
            .pipe(
                take(1),
                retry({ count: 3, delay: 10000 }), // if error, wait 10 seconds and try the request again (max 3 times)
                catchError((err) => this.redirectToPaymentProcessingPage(err)),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe({
                next: (payment) => {
                    if (payment?.status === 'PAID') {
                        this.stateService.go('student.open-university-cart.payment-successful', { paymentId: payment.id });
                    } else if (payment?.status === 'CANCELLED') {
                        this.stateService.go('student.open-university-cart.payment-failed');
                    } else {
                        this.stateService.go('student.open-university-cart.payment-processing');
                    }
                },
            });
    }

    private redirectToPaymentProcessingPage(err: any) {
        this.stateService.go('student.open-university-cart.payment-processing');
        return throwError(() => err);
    }

    private convertQueryParamToInteger(param: string): number {
        // params are sent as string so convert them to integer
        const num = Number.parseInt(param, 10);
        return Number.isNaN(num) ? undefined : num;
    }

    private logCeeposPaymentConfirmation(ceeposPaymentConfirmation: CeeposPaymentConfirmation) {
        const paymentSystemLog: Partial<PaymentSystemLog> = {
            jsonLog: JSON.stringify(ceeposPaymentConfirmation),
            paymentId: ceeposPaymentConfirmation.Id,
            paymentSystem: 'CEEPOS',
            paymentSystemLogMsgDirection: 'FRONTEND_INBOUND',
        };
        this.paymentSystemLogEntityService.create(paymentSystemLog);
    }

    private readCeeposResponseFromQueryParams(): CeeposPaymentConfirmation {
        return {
            Id: this.uiRouterGlobals.params?.Id,
            Status: this.convertQueryParamToInteger(this.uiRouterGlobals.params?.Status),
            Reference: this.uiRouterGlobals.params?.Reference,
            Payments: this.parsePayments(),
            Hash: this.uiRouterGlobals.params?.Hash,
        } as CeeposPaymentConfirmation;
    }

    private parsePayments(): CeeposPayment[] {
        let payments: CeeposPayment[] = [];
        if (this.uiRouterGlobals.params?.PaymentMethod instanceof Array) {
            for (let i = 0; i < this.uiRouterGlobals.params.PaymentMethod.length; i = i + 1) {
                const payment: CeeposPayment = {
                    PaymentMethod: this.convertQueryParamToInteger(this.uiRouterGlobals.params.PaymentMethod[i]),
                    PaymentSum: this.convertQueryParamToInteger(this.uiRouterGlobals.params.PaymentSum[i]),
                    Timestamp: this.uiRouterGlobals.params.Timestamp[i],
                    PaymentDescription: this.uiRouterGlobals.params.PaymentDescription[i],
                };
                payments.push(payment);
            }
        } else {
            const payment: CeeposPayment = {
                PaymentMethod: this.convertQueryParamToInteger(this.uiRouterGlobals.params?.PaymentMethod),
                PaymentSum: this.convertQueryParamToInteger(this.uiRouterGlobals.params?.PaymentSum),
                Timestamp: this.uiRouterGlobals.params?.Timestamp,
                PaymentDescription: this.uiRouterGlobals.params?.PaymentDescription,
            };
            payments = Object.values(payment).every(el => el === undefined) ? [] : [payment];
        }
        return payments;
    }
}
