<ng-container *transloco="let t">
  <fudis-notification
    *ngFor="let expirationInfo of expirationInfos$ | async; trackBy: studyRightId"
    variant="warning"
    class="d-block alert-top-spacing"
  >
    <fudis-body-text class="sis-mt-xxs">
      <fudis-body-text>
        {{
          t('STUDY_RIGHT_EXPIRATION.EXPIRING_INFO_WITH_EDUCATION', {
            educationName: expirationInfo.studyRight.educationId | education | async | localizeProperty: 'name',
            endDate: expirationInfo.inclusiveEndDate | localDateFormat
          })
        }}
      </fudis-body-text>
    </fudis-body-text>
    <fudis-body-text>
      {{ t('STUDY_RIGHT_EXPIRATION.EXTENSION_INFO_EXPIRING') }}
    </fudis-body-text>
    <sis-button
      *ngIf="extensionApplicationEnabled() && !expirationInfo.pendingExtensionApplication"
      [label]="t('STUDY_RIGHT_EXPIRATION.EXTENSION_APPLY')"
      [link]="true"
      (clicked)="applyForExtension(expirationInfo.studyRight)"
      class="d-inline-block sis-m-xxs"
      data-cy="study-right-expiration-info-apply-for-extension"
    />
    <a
      *ngIf="expirationInfo.pendingExtensionApplication"
      fudisLink
      uiSref="student.logged-in.profile.applications.study-right-extension-workflow"
      [uiParams]="{applicationId: expirationInfo.pendingExtensionApplication.id}"
      [classes]="['d-inline-block', 'sis-m-xxs']"
      data-cy="study-right-expiration-info-show-application"
    >{{ t('STUDY_RIGHT_EXPIRATION.EXTENSION_SHOW_APPLICATION') }}</a>
  </fudis-notification>
</ng-container>
