import { AfterViewChecked, Component, Inject, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Attainment,
    CompletionMethod,
    CourseUnit,
    CourseUnitRealisation,
    Module,
    OtmId,
    Plan,
    ValidatablePlan,
} from 'common-typescript/types';
import { ModalService } from 'sis-common/modal/modal.service';

import { OpenUniversityOfferingPreselection } from '../../../open-university/study-offering/open-university-offering/open-university-offering.component';

export class CourseUnitInfoData {
    attainments: Attainment[];
    color?: string;
    courseUnit: CourseUnit;
    completionMethods: CompletionMethod[];
    courseUnitRealisation?: CourseUnitRealisation;
    forceEnableCalendarAdd?: boolean;
    module?: Module;
    openTab?: 'BASIC' | 'COMPLETION_METHODS' | 'SUBSTITUTIONS' | 'OPEN_UNIVERSITY_OFFERING';
    currentUniversityOrgId?: OtmId;
    plan?: Plan;
    unplannedCourseUnit?: CourseUnit;
    validatablePlan: ValidatablePlan;
    openUniversityOfferingPreselectedPurchase?: OpenUniversityOfferingPreselection;
}

export interface CourseUnitInfoValues {
    readonly data: CourseUnitInfoData;
}

@Component({
    selector: 'app-course-unit-info-modal',
    template: '<course-unit-info-modal (dismiss)="activeModal.dismiss()" [resolve]="values"></course-unit-info-modal>',
    encapsulation: ViewEncapsulation.None,
})
export class CourseUnitInfoModalComponent implements AfterViewChecked {

    mainHeadingHasBeenFocused = false;
    curHeadingHasBeenFocused = false;

    constructor(@Inject(ModalService.injectionToken) public values: CourseUnitInfoValues,
                public activeModal: NgbActiveModal) {}

    ngAfterViewChecked() {
        if (this.isCompletionMethodsTab() && !this.curHeadingHasBeenFocused) {
            this.setFocusToCurHeading();
        } else if (!this.curHeadingHasBeenFocused && !this.mainHeadingHasBeenFocused && document.getElementById('student-course-unit-modal-heading')) {
            this.setFocusToMainHeading();
        }
    }

    setFocusToMainHeading() {
        this.mainHeadingHasBeenFocused = true;
        const headingContainer = document.getElementById('student-course-unit-modal-heading');
        headingContainer.focus();
    }

    isCompletionMethodsTab() {
        return this.values?.data?.openTab === 'COMPLETION_METHODS';
    }

    getCurHeadingIdForAssessmentItem() {
        if (this.values?.data?.courseUnitRealisation?.assessmentItemIds &&
            this.values.data.courseUnitRealisation.assessmentItemIds.length > 0) {
            return `realisation-heading-${this.values.data.courseUnitRealisation.assessmentItemIds[0]}`;
        }
        return null;
    }

    setFocusToCurHeading() {
        const curHeading = document.getElementById(this.getCurHeadingIdForAssessmentItem());
        if (curHeading) {
            this.curHeadingHasBeenFocused = true;
            curHeading.focus();
        }
    }
}
