<div data-cy="open-university-assessment-items-and-course-unit-realisations">
  <p *ngIf="showCourseUnitRealisationInfoText" class="sis-guidance sis-guidance__primary sis-pb-sm">
    {{ 'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.GUIDANCE' | translate }}
  </p>

  <div role="heading" class="fudis-heading__xs" [attr.aria-level]="7">
    {{ 'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.MAX_NUMBER_OF_ENROLMENTS' | transloco }}
  </div>

  <p *ngIf="hasNoEnrolmentLimit()">
    {{ 'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.NO_LIMIT' | transloco }}
  </p>

  <sis-description-list-compact *ngIf="!hasNoEnrolmentLimit()" [columnCount]="1">
    <dl>
      <ng-template #compactListItem>
        <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.TOTAL' | transloco}}</dt>
        <dd class="sis-value">{{ remainingEnrolmentCounts?.total }}</dd>
      </ng-template>
      <ng-template #compactListItem>
        <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.USED' | transloco}}</dt>
        <dd class="sis-value">{{ remainingEnrolmentCounts?.used }}</dd>
      </ng-template>
      <ng-template #compactListItem>
        <dt class="sis-label colon">{{ 'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.REMAIN' | transloco}}</dt>
        <dd class="sis-value">{{ remainingEnrolmentCounts?.remain() }}</dd>
      </ng-template>
    </dl>
  </sis-description-list-compact>
  <br>

  <p *ngIf="!courseUnitRealisations?.length" class="guidance">
    {{'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.NO_CURS' | translate}}
  </p>

  <ul class="no-list-style-type">
    <li *ngFor="let courseUnitRealisation of courseUnitRealisations | slice:0:showLimit; trackBy: entityId"
        class="sis-mb-xs" data-cy="open-university-course-unit-realisation">
      <a fudisLink *ngIf="componentType === 'STAFF'" [href]="'/teacher/role/staff/teaching/course-unit-realisations/view/' + courseUnitRealisation.id + '/information/basicinfo'" [external]="true" class="course-unit-realisation-title" [title]="courseUnitRealisation | courseUnitRealisationFullName | localizedString"></a>
      <ng-container *ngIf="componentType === 'STUDENT'">{{ courseUnitRealisation | courseUnitRealisationFullName | localizedString }}</ng-container>
      <hr class="sis-hr" aria-hidden="true"/>
    </li>
  </ul>

  <div class="show-all">
    <sis-button *ngIf="courseUnitRealisations?.length > showLimit"
                [link]="true"
                (clicked)="onClickShowAll()"
                data-cy="show-all-course-unit-realisations-button">
      {{'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.N_OTHERS' | translate: {others: courseUnitRealisations.length - showLimit} }}
    </sis-button>
    <sis-button *ngIf="!showLimit && courseUnitRealisations?.length > DEFAULT_SHOW_LIMIT"
                [link]="true"
                (clicked)="onClickShowLess()"
                data-cy="show-less-course-unit-realisations-button">
      {{'OPENUNIVERSITY.STUDY_RIGHTS.ASSESSMENT_ITEM.SHOW_LESS' | translate}}
    </sis-button>
  </div>

</div>
