<ng-container *transloco="let t">
  <ng-container *ngIf="data$ | async as data">
    <app-plan-structure-free-edit
      [modalValues]="this.modalValues"
      *ngIf="this.showFreeEditMode">
    </app-plan-structure-free-edit>
  </ng-container>
  <fudis-dialog *ngIf="!this.showFreeEditMode">
    <fudis-heading [level]="2">{{ this.modalValues.module.name | localizedString }}</fudis-heading>
    <fudis-dialog-content>
      <ng-container *ngIf="hasShownErrors$()">
        <div class="sis-mb-sm">
          <sis-plan-structure-rule-error-summary #errorSummary
                                                 [tabIndex]="-1"
                                                 [ruleErrorStates]="ruleErrorStateService.shownErrorStates$()"
                                                 (errorClick)="focusRule($event)">
          </sis-plan-structure-rule-error-summary>
        </div>
      </ng-container>
      <ng-container *ngIf="data$ | async as data">
        <fudis-grid [classes]="'modal-body'"
                    [columnGap]="'none'"
                    [columns]="{sm:'auto min-content'}">
          <sis-menu-button [id]="'planMenuOptionsButton'"
                           [color]="'primary'"
                           [options]="menuOptions"
                           fudisGridItem
                           [alignSelfX]="'end'">
            <sis-icon [icon]="'three-dots'"></sis-icon>
          </sis-menu-button>
          <sis-plan-structure-select-rule [parentModule]="data.selectedModule"
                                          [headingLevel]="3"
                                          [rule]="data.selectedModule.rule"
                                          [planData]="data.planData"
                                          [planRuleData]="data.planRuleData"
                                          [planStateObject]="data.planStateObject"
                                          [planValidationResult]="data.planValidationResult"
                                          [validatablePlan]="data.validatablePlan"
                                          [selectionUIState]="'ACTIVE'"
                                          [groupPrefix]="''"
                                          [ruleDepthLevel]="1">
          </sis-plan-structure-select-rule>
        </fudis-grid>
      </ng-container>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button fudisDialogClose [label]="t('SIS_COMPONENTS.BUTTON.CANCEL')" (handleClick)="dismiss()"
                    [variant]="'secondary'"/>
      <fudis-button [label]="t('SIS_COMPONENTS.BUTTON.SAVE')" (handleClick)="submitClick$.next()"/>
    </fudis-dialog-actions>
  </fudis-dialog>
</ng-container>

