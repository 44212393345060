<ng-container *ngIf="assessmentItem">
  <h5 class="sis-mt-sm">{{assessmentItem.name | localizedString}} ({{assessmentItem.credits | creditRange:'SYMBOLS'}})</h5>
  <p *ngIf="!courseUnitRealisations?.length" class="sis-guidance">
    {{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.NO_CURS' | translate}}
  </p>
  <p *ngIf="courseUnitRealisations?.length > 1 && !hideGuidance" class="sis-guidance">
    {{'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.SEVERAL_CURS' | translate}}
  </p>
  <sis-expandable *ngFor="let courseUnitRealisation of courseUnitRealisations; trackBy: entityId"
                  (closedChange)="closedChange(courseUnitRealisation, $event)"
                  [title]="(courseUnitRealisation.name | localizedString) + ' ' + (courseUnitRealisation.activityPeriod  | localDateRange)"
                  [variant]="'naked-dotted'"
                  [level]="6"
                  [closed]="isClosed(courseUnitRealisation) | async"
                  [elementId]="getCourseUnitRealisationElementId(courseUnitRealisation)">

    <ng-container *ngIf="seatCounts">
      <ng-template sisExpandableCustomTitle *ngIf="curIsFull(courseUnitRealisation)">
        <sis-tiny-badge variant="danger">{{ 'OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEM.CUR_FULL' | translate }}</sis-tiny-badge>
      </ng-template>
    </ng-container>
    <ng-template sisExpandableContent>
      <app-open-university-course-unit-realisation-info [courseUnitRealisation]="courseUnitRealisation" [hideGuidance]="hideGuidance"></app-open-university-course-unit-realisation-info>
    </ng-template>
  </sis-expandable>
</ng-container>
