<fudis-footer *transloco="let t" class="d-flex flex-column">
  <ng-template fudisFooterContentLeft>
    <a fudisLink
       [title]="t('SIS_COMPONENTS.NAVIGATION.JOIN_FUNILAB')"
       [external]="true"
       [href]="'https://digitalist.leanlab.co/cgi/view?sid=20b01d167963a06a'"></a>
    <a fudisLink
      *ngIf="featureToggleAllowed"
      [title]="t('SIS_COMPONENTS.NAVIGATION.FEATURE_TOGGLE_URL')"
      [href]="featureToggleUrl"></a>
  </ng-template>
  <ng-template fudisFooterContentRight>
    <a fudisLink
       *ngIf="registryDescriptionUrl()"
       [title]="t('SIS_COMPONENTS.NAVIGATION.REGISTRY_DESCRIPTION_URL')"
       [external]="true"
       [href]="registryDescriptionUrl() + '?lang=' + currentLanguage()"></a>
    <a fudisLink
      [title]="t('SIS_COMPONENTS.NAVIGATION.ACCESSIBILITY_STATEMENT_URL')"
      [external]="true"
      [href]="'/accessibility-statement/' + currentLanguage()"></a>
    <a fudisLink
      *ngIf="aboutHref"
      [title]="t('ABOUT.TITLE')"
      [href]="aboutHref"></a>
  </ng-template>
</fudis-footer>
