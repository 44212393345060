import angular from 'angular';
import _ from 'lodash';
import { authServiceModule } from 'sis-common/auth/auth.service';
import { commonPlanServiceModule } from 'sis-components/service/plan.service';
import { enrolmentServiceModule } from '../common/service/enrolment.service';
import { calendarEnrolmentsModule } from './right-panel/calendarEnrolments.component';
import { courseUnitsNotInCalendarModule } from './right-panel/courseUnitsNotInCalendar.component';
(function () {
  angular.module('student.calendar.route', ['student.calendar', 'student.common.components.planSeeker', 'sis-components.model.enrolment', 'sis-components.plan.planLoader', commonPlanServiceModule, 'sis-components.service.courseUnitService', calendarEnrolmentsModule, courseUnitsNotInCalendarModule, enrolmentServiceModule, authServiceModule]).config(["$stateProvider", $stateProvider => {
    $stateProvider.state('student.logged-in.calendar', {
      url: '/calendar',
      component: 'calendar',
      resolve: {
        enrolments: ["enrolmentService", function (enrolmentService) {
          return enrolmentService.findMyEnrolments(true, false)
          // return only enrolments that are in calendar and load relations only for those
          .then(enrolments => _.filter(enrolments, 'isInCalendar')).then(enrolments => enrolmentService.loadMinimumDataForCourseUnitRealisationComponent(enrolments));
        }],
        getValidatablePlan: ["$q", "planSeeker", "PlanLoader", function ($q, planSeeker, PlanLoader) {
          return _.once(() => planSeeker.find().then(planId => planId ? PlanLoader.createValidatablePlan(planId) : $q.resolve(null)));
        }]
      },
      data: {
        hideFooter: true,
        hideLastNode: false,
        browserTabTitle: 'APPLICATION_SCHEDULE'
      },
      headerParams: {
        displayNameKey: 'APPLICATION_SCHEDULE',
        skipBreadcrumb: false
      },
      redirectTo: 'student.logged-in.calendar.enrolments'
    }).state('student.logged-in.calendar.enrolments', {
      url: '/enrolments',
      headerParams: {
        displayNameKey: 'COURSE_UNIT_REALISATIONS'
      },
      component: 'calendarEnrolments',
      params: {
        courseUnitRealisationId: undefined
      },
      resolve: {
        showFilters: () => true,
        showFinishedAndAborted: () => false,
        showNotStartedAndActive: () => true
      }
    }).state('student.logged-in.calendar.aborted-and-finished-enrolments', {
      url: '/aborted-and-finished-enrolments',
      headerParams: {
        displayNameKey: 'FINISHED_AND_ABORTED_ENROLMENTS'
      },
      component: 'calendarEnrolments',
      params: {
        courseUnitRealisationId: undefined
      },
      resolve: {
        showFilters: () => false,
        showFinishedAndAborted: () => true,
        showNotStartedAndActive: () => false
      }
    }).state('student.logged-in.calendar.not-enrolled', {
      url: '/not-enrolled',
      headerParams: {
        displayNameKey: 'UNSCHEDULED_COURSE_UNITS'
      },
      component: 'courseUnitsNotInCalendar',
      resolve: {
        plans: ["commonPlanService", "AuthService", (commonPlanService, AuthService) => commonPlanService.findAllByUserId(AuthService.personId(), true, true)],
        validatablePlan: ["getValidatablePlan", getValidatablePlan => getValidatablePlan()],
        notScheduledCourseUnits: ["commonPlanService", "validatablePlan", "enrolments", function (commonPlanService, validatablePlan, enrolments) {
          if (!validatablePlan) {
            return [];
          }
          const enrolmentsInCalendar = _.filter(enrolments, 'isInCalendar');
          return commonPlanService.findNotScheduledCourseUnits(validatablePlan, enrolmentsInCalendar);
        }]
      }
    })
    // DEPRECATED! These states are here for backwards compatibility. The study calendar URL changed in v2.13,
    // and these routes allow any old bookmarks, shared links etc. to work. Can be removed in some later release.
    .state('student.logged-in.plan.calendar', {
      url: '/calendar',
      redirectTo: 'student.logged-in.calendar'
    }).state('student.logged-in.plan.calendar.children', {
      url: '/*ignore',
      redirectTo: 'student.logged-in.calendar'
    });
  }]);
})();