<app-cart-container *ngIf="cart && showAutoConfirmWizard !== undefined"
                    [pageTitle]="'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.PAGE_TITLE'"
                    [wizardStepKeys]="wizardStepNames"
                    [currentStep]="currentStep">
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <div class="thank-you-texts">
          <transloco *ngIf="!showAutoConfirmWizard || cart.state !== 'ACTIVATED'" data-cy="thank-you-text" key="OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.THANK_YOU_TEXT" [params]="{orderNumber: cart.orderNumber, buyerEmail: cart.buyerEmail}"></transloco>
          <transloco *ngIf="showAutoConfirmWizard && cart.state === 'ACTIVATED'" data-cy="thank-you-text" key="OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.THANK_YOU_TEXT_AUTO_CONFIRM" [params]="{orderNumber: cart.orderNumber, buyerEmail: cart.buyerEmail}"></transloco>
      </div>
        <sis-contextual-notification variant="warning" *ngIf="cart.state !== 'ACTIVATED' && showAutoConfirmWizard">
            <div>
                <transloco key="OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.AUTO_CONFIRMATION_FAILED" [params]="{orderNumber: cart?.orderNumber}"></transloco>
                <ng-container *ngIf="contactInfo$ | async as contactInfo">
                    <a *ngIf="contactInfo.isEmail; else notEmailLink" [href]="'mailto:' + contactInfo.link">
                        {{ contactInfo.link }}.</a>
                    <ng-template #notEmailLink>
                        <a *ngIf="contactInfo.contactLinkText" [href]="contactInfo.link">
                            {{ contactInfo.contactLinkText }}.</a>
                    </ng-template>
                </ng-container>
            </div>
        </sis-contextual-notification>
      <div class="confirm-enrolments" *ngIf="!showAutoConfirmWizard">
        <h2 tabindex="-1" #currentStepNameFocus>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_ENROLMENT_TITLE' | translate }}</h2>
        <div class="row">
          <div class="col-xl-8">
            <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_TEXT_1' | translate }}</p>
            <p data-cy="confirm-text-2">{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_TEXT_2' | translate:{buyerEmail: cart.buyerEmail} }}</p>
            <p>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_TEXT_3' | translate }}</p>
          </div>
          <div class="confirm-button-div col-xl-4">
            <sis-button type="button"
                        variant="primary"
                        (clicked)="redirectToStudiesActivateWizard()"
                        data-cy="confirm-enrolment-button">
              {{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CONFIRM_ENROLMENT_BUTTON' | translate }}
            </sis-button>
          </div>
        </div>
        <div class="row guidance-div">
          <div class="notification-icon"><sis-icon [icon]="'notification'" [color]="'primary'"></sis-icon></div>
          <div class="guidance-text">
            <transloco class="guidance"
                       key="OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.GUIDANCE"
                       [params]="{activationCode: cart.activationCode}"
                       data-cy="activation-code-guidance">
            </transloco>
          </div>
        </div>
      </div>

      <h2>{{ 'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.COURSE_UNIT_TITLE' | translate }}</h2>
      <app-cart-accordions [cart]="cart" [isEditable]="false" [hideEnrolmentQuestonnaire]="!showAutoConfirmWizard"></app-cart-accordions>
      <div class="row price">
        <div class="col-sm-7"></div>
        <div class="row col-sm-5">
          <app-cart-price [cart]="cart" [showOnlySummary]="true" [alignRight]="true"></app-cart-price>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-10" *ngIf="showAutoConfirmWizard">
      <sis-wizard-step-buttons
              [forwardButtonTitle]="'OPEN_UNIVERSITY_CART.WIZARD.CONFIRMATION.CLOSE' | translate"
              [backButtonDisplay]="false"
              (forward)="goToFrontPage()"/>
    </div>
  </div>
</app-cart-container>
