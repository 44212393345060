import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { OtmId, UniversityOrganisation } from 'common-typescript/types';
import { ConfigService } from 'sis-common/config/config.service';

import { UniversityService } from '../../service/university.service';

@Component({
    selector: 'sis-university-menu',
    templateUrl: './university-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversityMenuComponent implements OnInit {

    @Input() mobileMode = false;

    @Output() keydownPressed = new EventEmitter<any>();
    @Output() blurMobileUniversityMenu = new EventEmitter<any>();

    universitySelections: UniversityOrganisation[] = [];

    currentUniversityOrgId: OtmId;

    constructor(private configService: ConfigService,
                private universityService: UniversityService) {}

    ngOnInit() {
        this.universitySelections = this.getUniversitySelections();
        this.currentUniversityOrgId = this.universityService.getCurrentUniversityOrgId();
    }

    changeUniversity(universityOrgId: OtmId): void {
        this.universityService.setCurrentUniversityOrgId(universityOrgId);
        window.location.reload();
    }

    private getUniversitySelections(): UniversityOrganisation[] {
        return this.configService.get().homeOrganisations || [];
    }
}
