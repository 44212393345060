<div *ngFor="let priorLearning of priorLearnings; index as i" class="form-group-mimic application-attributes">
  @let externalAttainedStudy = getExternalAttainedObservable(priorLearning?.externalAttainedStudyId) | async;
  <sis-expandable *ngIf="showAsExpandable; else defaultTemplate" [level]="level" [variant]="'naked-dotted'">
    <ng-template sisExpandableCustomTitle>
      <ng-container [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{priorLearning, externalAttainedStudy, i}"></ng-container>
    </ng-template>
    <ng-template sisExpandableContent>
      <ng-container [ngTemplateOutlet]="priorLearningContent" [ngTemplateOutletContext]="{priorLearning, externalAttainedStudy}"></ng-container>
    </ng-template>
  </sis-expandable>
  <ng-template #defaultTemplate>
    <div role="heading" class="fudis-heading__lg" [attr.aria-level]="level"><ng-container [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{priorLearning, externalAttainedStudy, i}"></ng-container></div>
    <ng-container [ngTemplateOutlet]="priorLearningContent" [ngTemplateOutletContext]="{priorLearning, externalAttainedStudy}"></ng-container>
  </ng-template>
</div>

<ng-template #priorLearningContent let-priorLearning="priorLearning" let-externalAttainedStudy="externalAttainedStudy">
  @if (externalAttainedStudy) {
    <sis-prior-studies-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.STUDIES" [priorStudies]="priorLearning" [externalAttainedStudy]="externalAttainedStudy"></sis-prior-studies-summary>
  } @else {
    <sis-prior-studies-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.STUDIES" [priorStudies]="priorLearning"></sis-prior-studies-summary>
  }
  <sis-prior-competence-summary *ngIf="priorLearning.type === PRIOR_LEARNING_TYPE.COMPETENCE" [priorCompetence]="priorLearning"></sis-prior-competence-summary>
</ng-template>

<ng-template #title let-priorLearning="priorLearning" let-externalAttainedStudy="externalAttainedStudy" let-i="i">
  <div>
    {{priorLearning | priorLearningName:applicationType:(priorLearnings.length > 1 ? i : null)}}
    <ng-container *ngIf="externalAttainedStudy">
      <fudis-badge class="sis-ml-xs" variant="primary">{{'STUDENT_APPLICATIONS.PRIOR_LEARNING.PRIOR_LEARNING_EMREX_BADGE' | transloco}}</fudis-badge>
      <fudis-body-text class="sis-mt-sm" [variant]="'md-light'">{{ 'STUDENT_APPLICATIONS.PRIOR_LEARNING.SELECTED_EMREX_ATTAINMENT_GUIDANCE' | transloco }}</fudis-body-text>
      <fudis-button class="sis-mt-xs"
                    [size]="'small'"
                    [variant]="'tertiary'"
                    [label]="'STUDENT_APPLICATIONS.PRIOR_LEARNING.VIEW_EMREX_ATTAINMENT' | transloco"
                    (handleClick)="this.openEmrexAttainmentDialog(externalAttainedStudy)" />
    </ng-container>
  </div>
</ng-template>
