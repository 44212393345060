<ng-container *ngIf="courseUnitRealisation">
  <div class="mb-5 mb-sm-3">
    <sis-tab-content-switch [(currentIndex)]="currentTabIndex"
                            type="pills"
                            [tabs]="[
                              { title: 'OPEN_UNIVERSITY.STUDY_OFFERING.CUR_INFO.STUDY_GROUPS_AND_EVENTS' },
                              { title: 'OPEN_UNIVERSITY.STUDY_OFFERING.CUR_INFO.BASIC_INFO' },
                            ]">
    </sis-tab-content-switch>
  </div>

  <div [hidden]="currentTabIndex !== 0">
    <div *ngFor="let studyGroupSet of courseUnitRealisation.studyGroupSets; trackBy: entityId" class="row">
      <div class="col-12">
        <div class="fudis-heading__xs" role="heading" [attr.aria-level]="7">{{studyGroupSet.name | localizedString}}</div>
        <p *ngIf="!hideGuidance" class="sis-guidance">{{studyGroupSet | allocationRuleDescription: 'OPEN_UNIVERSITY.STUDY_OFFERING.SELECTION_RULE'}}</p>
        <sis-description-list [columnCount]="1">
          <dl>
            <ng-template #listItem *ngFor="let studySubGroup of studyGroupSet.studySubGroups; trackBy: entityId">
              <ng-container *ngIf="!studySubGroup.cancelled">
                <dt class="sis-label">{{studySubGroup.name | localizedString}}</dt>
                <dd>
                  <app-study-event-info-list [studyEvents]="studySubGroup.studyEventIds | studyEvents | async"
                                             [teacherIds]="studySubGroup.teacherIds"></app-study-event-info-list>
                </dd>
              </ng-container>
            </ng-template>
          </dl>
        </sis-description-list>
      </div>
    </div>
  </div>

  <sis-description-list [hidden]="currentTabIndex !== 1" [fillByColumn]="false" [columnCount]="3">
    <dl>
      <ng-template #listItem *ngIf="courseUnitRealisation.learningMaterial as learningMaterial">
        <dt class="sis-label">{{'FIELD_NAMES.learningMaterial' | translate}}</dt>
        <dd><sis-localized-markup-string-view [content]="learningMaterial"></sis-localized-markup-string-view></dd>
      </ng-template>
      <ng-template #listItem *ngIf="courseUnitRealisation.literature?.length > 0">
        <dt class="sis-label">{{'FIELD_NAMES.literature' | translate}}</dt>
        <dd>
          <sis-literature *ngFor="let literature of courseUnitRealisation.literature" [literature]="literature"></sis-literature>
        </dd>
      </ng-template>
      <ng-template #listItem *ngIf="courseUnitRealisation.learningEnvironmentDescription | localizedString as learningEnvironmentDescription">
        <dt class="sis-label">{{'FIELD_NAMES.learningEnvironmentDescription' | translate}}</dt>
        <dd>{{learningEnvironmentDescription}}</dd>
      </ng-template>
      <ng-template #listItem *ngIf="courseUnitRealisation.additionalInfo as additionalInfo">
        <dt class="sis-label">{{'FIELD_NAMES.additionalInfo' | translate}}</dt>
        <dd><sis-localized-markup-string-view [content]="additionalInfo"></sis-localized-markup-string-view></dd>
      </ng-template>
      <ng-template #listItem *ngIf="courseUnitRealisation.teachingLanguageUrn | codeLocalizedName | async as teachingLanguage">
        <dt class="sis-label">{{'FIELD_NAMES.teachingLanguageUrn' | translate}}</dt>
        <dd>{{teachingLanguage}}</dd>
      </ng-template>
      <ng-template #listItem>
        <dt class="sis-label">{{'FIELD_NAMES.responsibilityInfos' | translate}}</dt>
        <dd>
          <sis-responsibility-infos [responsibilityInfos]="courseUnitRealisation.responsibilityInfos"
                                    [roleVisibilityAndOrder]="[
                                    'urn:code:course-unit-realisation-responsibility-info-type:responsible-teacher',
                                    'urn:code:course-unit-realisation-responsibility-info-type:teacher',
                                    'urn:code:course-unit-realisation-responsibility-info-type:contact-info'
                                  ]">
          </sis-responsibility-infos>
        </dd>
      </ng-template>
    </dl>
  </sis-description-list>
</ng-container>
