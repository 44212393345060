<ng-container *transloco="let t">
  <app-widget-container [title]="t('FRONTPAGE.STUDY_PROGRESS.TITLE')" [subtitle]="t('FRONTPAGE.STUDY_PROGRESS.SUBTITLE')">
      <app-select-plan-dropdown
        [widget]="'app-study-progress-graph'"
        (selectionChange)="onDropdownSelectionChange($event)"
      ></app-select-plan-dropdown>

      <ng-container *ngIf="studyProgressGraphResults$ | async as studyProgressGraphResults">
        <div *ngIf="studyProgressGraphResults" class="col-12 sis-mt-xl sis-mb-lg plan-container">
          <section class="col-6 plan-section separator">
            <fudis-heading [level]="3"
                           [variant]="'xxs'">
              {{t('FRONTPAGE.STUDY_PROGRESS.PLANNED')}}
            </fudis-heading>
            <p class="big-font sis-mt-xs sis-mb-sm" data-cy="planned-percentage">{{ studyProgressGraphResults.planned.percentage }}%</p>
            <p class="sis-mb-zero">{{ studyProgressGraphResults.planned.from }}/{{ studyProgressGraphResults.planned.to }} {{t('CREDITS')}}</p>
          </section>
          <section class="col-6 plan-section">
            <fudis-heading [level]="3"
                           [variant]="'xxs'">
              {{t('FRONTPAGE.STUDY_PROGRESS.PASSED')}}
            </fudis-heading>
            <p class="big-font sis-mt-xs sis-mb-sm" data-cy="completed-percentage">{{ studyProgressGraphResults.attained.percentage }}%</p>
            <p class="sis-mb-zero">{{ studyProgressGraphResults.attained.from }}/{{ studyProgressGraphResults.attained.to }} {{t('CREDITS')}}</p>
          </section>
        </div>
        <div class="sis-pl-md sis-pr-md sis-mb-sm">
          <sis-contextual-notification *ngIf="!studyProgressGraphResults" variant="warning">
            <div> {{t('FRONTPAGE.STUDY_PROGRESS.FETCHING_PLANS_FAILED')}}</div>
          </sis-contextual-notification>
          <!-- state: PlanValidationState -->
          <sis-contextual-notification *ngIf="studyProgressGraphResults.moduleValidationResult?.state === 'INVALID'" variant="warning">
            <div> {{ t('FRONTPAGE.STUDY_PROGRESS.PLAN_IS_INVALID') }}</div>
          </sis-contextual-notification>

          <sis-contextual-notification *ngIf="studyProgressGraphResults.moduleValidationResult?.state === 'INCOMPLETE' && studyProgressGraphResults?.planned.percentage >= 100" variant="warning">
            <div> {{ t('FRONTPAGE.STUDY_PROGRESS.PLAN_IS_DEFICIENT') }}</div>
          </sis-contextual-notification>
        </div>
      </ng-container>

      <div *ngIf="loading()" aria-busy="true" class="d-flex justify-content-center pt-3">
        <sis-small-spinner />
      </div>
  </app-widget-container>
</ng-container>
