<ng-container *transloco="let t">
  <div class="row mb-sm-4">
    <div class="col-12 header-container" data-cy="product-details-header">
      <app-add-to-cart-button *ngIf="isOnSale &&
                                    (!product?.isFull || (product?.isFull && product?.isInCart)) &&
                                    !product?.isCurrentOrgIdDifferentThanProductOrgId"
                              [productId]="product?.id"
                              [courseUnitId]="courseUnit?.id"
                              [isProductInCart]="product?.isInCart"
                              [class.mb-5]="mobileView"
                              [class.float-end]="!mobileView">
      </app-add-to-cart-button>

      <!-- In mobile view we never show the "Purchased" badge in product details -->
      <div *ngIf="mobileView && isFutureProduct" class="mb-5">
        <ng-container *ngTemplateOutlet="futureProductBadge"></ng-container>
      </div>

      <fudis-heading [level]="4">
        {{ t('OPEN_UNIVERSITY.STUDY_OFFERING.PRODUCT_DETAILS', {productName: product?.name | localizedString}) }}
        <ng-container *ngIf="!mobileView">
          <ng-container *ngIf="product.isPurchased">
            <sis-tiny-badge variant="success">{{ t('OPEN_UNIVERSITY.STUDY_OFFERING.PURCHASED') }}</sis-tiny-badge>
          </ng-container>

          <ng-container *ngIf="isFutureProduct">
            <ng-container *ngTemplateOutlet="futureProductBadge"></ng-container>
          </ng-container>
        </ng-container>
      </fudis-heading>

      <div *ngIf="product?.isCurrentOrgIdDifferentThanProductOrgId" class="mt-3 mb-3">
        <sis-contextual-notification variant="warning">
          <span>{{
              t(
                'OPEN_UNIVERSITY.STUDY_OFFERING.UNIVERSITY_ORG_ORGANIZER', {
                  productUniversityOrg: (product?.organisationId | organisation | async)?.name | localizedString
                }
              )
            }}
          </span>
          <span>{{
              t(
                'OPEN_UNIVERSITY.STUDY_OFFERING.UNIVERSITY_ORG_ID_MISS_MATCH', {
                  currentUniversityOrg: (product?.currentOrgId | organisation | async)?.name | localizedString
                })
            }}
          </span>
        </sis-contextual-notification>
      </div>
    </div>
  </div>
  <app-product-basic-info [product]="product" [completionMethod]="completionMethod" *ngIf="isOnSale || isFutureProduct"></app-product-basic-info>
  <app-purchased-product-basic-info [product]="product" [completionMethod]="completionMethod" *ngIf="isPurchased"></app-purchased-product-basic-info>
  <div class="row">
    <div class="col-12">
      <fudis-heading [level]="4">{{ t('OPEN_UNIVERSITY.STUDY_OFFERING.ASSESSMENT_ITEMS') }}</fudis-heading>
      <ng-container *ngIf="isPurchased">
        <fudis-body-text [variant]="'md-light'">
          {{ t('OPEN_UNIVERSITY.STUDY_OFFERING.PURCHASED_PROD_GUIDANCE.DESC_FRAGMENT_1') }}
          <a fudisLink [title]="t('OPEN_UNIVERSITY.STUDY_OFFERING.PURCHASED_PROD_GUIDANCE.DESC_FRAGMENT_2')" [external]="true" [href]="'/student/calendar'"></a>
          {{ t('OPEN_UNIVERSITY.STUDY_OFFERING.PURCHASED_PROD_GUIDANCE.DESC_FRAGMENT_3') }}
          <a fudisLink [title]="t('OPEN_UNIVERSITY.STUDY_OFFERING.PURCHASED_PROD_GUIDANCE.DESC_FRAGMENT_4')" [external]="true" [href]="'/student/enrolments'"></a>
        </fudis-body-text>
        <app-assessment-item-teaching *ngFor="let assessmentItem of assessmentItems$ | async; trackBy: entityId"
                                      [assessmentItem]="assessmentItem"
                                      [courseUnitRealisations]="cursForPurchasedProductByAssItemId.get(assessmentItem.id)"
                                      [hideGuidance]="true">
        </app-assessment-item-teaching>
      </ng-container>

      <ng-container *ngIf="!isPurchased">
        <app-assessment-item-teaching *ngFor="let assessmentItem of assessmentItems$ | async; trackBy: entityId"
                                      [assessmentItem]="assessmentItem"
                                      [courseUnitRealisations]="this.product?.teachingOnSale?.[assessmentItem.id]"
                                      [seatCounts]="product.curAvailableSeats">
        </app-assessment-item-teaching>
      </ng-container>
    </div>
  </div>

  <ng-template #futureProductBadge>
    <sis-tiny-badge variant="secondary">{{
        t('OPEN_UNIVERSITY.STUDY_OFFERING.SALES_PERIOD_STARTS_AT', {
          date: product?.salesPeriod?.startDateTime | localDateFormat,
          time: product?.salesPeriod?.startDateTime | localTimeFormat
        })
      }}
    </sis-tiny-badge>
  </ng-template>
</ng-container>
