import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { StateService } from '@uirouter/angular';
import {
    ApplicationAttachments,
    Attachment,
    CustomAttainmentWorkflow,
    CustomAttainmentWorkflowApplication,
} from 'common-typescript/types';
import _ from 'lodash';
import { catchError, finalize, map, Observable, of, take, tap, throwError } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { FileItem } from 'sis-components/file-upload/file-upload.component';
import { ApplicationAttachmentEntityService } from 'sis-components/service/application-attachment-entity.service';
import { WorkflowDataChangeService } from 'sis-components/service/workflow-data-change.service';

import { ApplicationCreationService } from '../../../../common/service/application-creation.service';

@Component({
    selector: 'app-custom-attainment-application-supplement-wizard',
    templateUrl: './custom-attainment-application-supplement-wizard.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CustomAttainmentApplicationSupplementWizardComponent implements OnInit {

    @Input() workflow: CustomAttainmentWorkflow;

    readonly wizardStepKeys = [
        'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.SUPPLEMENT.PHASE_1',
        'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.SUPPLEMENT.PHASE_2',
        'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.SUPPLEMENT.PHASE_3',
    ];

    currentStep = 0;

    application: Partial<CustomAttainmentWorkflowApplication>;
    attachments: FileItem[] = [];
    applicationAttachments: ApplicationAttachments;
    uploading = false;

    initialAttachments$: Observable<FileItem[]>;
    supplementRequestRationale$: Observable<string>;

    constructor(
        private applicationCreationService: ApplicationCreationService,
        private appErrorHandler: AppErrorHandler,
        private applicationAttachmentEntityService: ApplicationAttachmentEntityService,
        private workflowDataChangeService: WorkflowDataChangeService,
        private state: StateService,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnInit(): void {
        this.initialAttachments$ = this.applicationAttachmentEntityService.getAttachmentsByApplicationId(this.workflow.id).pipe(
            catchError(err => {
                // Expect 404 if the workflow has no attachments, pass other errors to default handler
                if (err.status === 404) {
                    return of(undefined);
                }
                return throwError(() => err);
            }),
            this.appErrorHandler.defaultErrorHandler(),
            map(((applicationAttachments: ApplicationAttachments) => {
                this.applicationAttachments = applicationAttachments;
                if (applicationAttachments?.attachments) {
                    return this.getFileItemsFromAttachments(applicationAttachments.attachments);
                }
                return [];
            })),
            tap(fileItems => this.attachments = fileItems),
        );
        this.application = { ...this.workflow.application as CustomAttainmentWorkflowApplication };
        this.supplementRequestRationale$ = this.workflowDataChangeService.getActiveSupplementRequestDescription(this.workflow.id)
            .pipe(this.appErrorHandler.defaultErrorHandler());
    }

    getFileItemsFromAttachments(attachments: Attachment[]): FileItem[] {
        const fileItems: FileItem[] = [];
        _.each(attachments, (attachment) => {
            const file = new File([new ArrayBuffer(attachment.size)], attachment.name, { type: attachment.fileType });
            fileItems.push({
                file,
                explanation: attachment.comment,
                preSignedGetUrl: attachment.preSignedGetUrl,
                localId: attachment.localId,
                name: file.name.normalize() });
        });
        return fileItems;
    }

    onAttachmentsChange(attachments: FileItem[]): void {
        this.attachments = attachments ?? [];
    }

    onFormValueChange(changes: Partial<CustomAttainmentWorkflowApplication>): void {
        if (changes) {
            this.application = { ...this.application, ...changes };
        }
    }

    isFirstStep(): boolean {
        return this.currentStep === 0;
    }

    isLastStep(): boolean {
        return this.currentStep === this.wizardStepKeys.length - 1;
    }

    exit(): void {
        this.state.go('^'); // go to parent state, which is the application inspection view
    }

    previous(): void {
        if (!this.isFirstStep()) {
            this.currentStep -= 1;
        }
    }

    continue(changes: Partial<CustomAttainmentWorkflowApplication>): void {
        this.onFormValueChange(changes);
        if (!this.isLastStep()) {
            this.currentStep += 1;
        }
    }

    submit(changes: Partial<CustomAttainmentWorkflowApplication>) {
        if (!this.uploading) {
            this.onFormValueChange(changes);
            this.uploading = true;
            this.applicationCreationService.supplementWorkflowApplication(
                this.workflow.id,
                this.workflow.studentId,
                this.application,
                this.attachments,
                this.applicationAttachments?.attachments,
                this.applicationAttachments?.metadata,
            ).pipe(
                take(1),
                this.appErrorHandler.defaultErrorHandler(),
                finalize(() => this.uploading = false),
            ).subscribe(
                {
                    next: () => {
                        this.document.defaultView?.scrollTo(0, 0);
                        this.state.go('^', {}, { custom: { skipConfirmationDialog: true }, reload: true });
                    },
                },
            );
        }
    }
}
