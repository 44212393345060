@if (rootNode) {
  <div class="sis-radiobutton-tree">
    <ng-template
      [ngTemplateOutlet]="radiobuttonTree"
      [ngTemplateOutletContext]="{ node: rootNode }"
    />
  </div>
}

<ng-template #radiobuttonTree let-node="node">
  @if (node.id) {
    <div class="sis-radiobutton-tree__node" [attr.data-cy]="'radiobutton-tree-node-' + node.id">
      <div class="sis-radiobutton-tree__node__button-container">
        @if (node.children?.length) {
          <button
            type="button"
            class="non-button"
            (click)="toggleCollapsed(node.id)"
            [attr.aria-label]="'RADIOBUTTON_TREE.EXPAND_BUTTON_ARIA_LABEL' | transloco: { title: node.label }"
            [attr.aria-expanded]="!isCollapsed(node.id)"
            [attr.aria-controls]="'node-children-' + node.id"
            [attr.data-cy]="'radiobutton-tree-expander-' + node.id"
          >
            <fudis-icon icon="chevron" [rotate]="isCollapsed(node.id) ? 'none' : 'cw-90'"/>
          </button>
        }
      </div>

      <div
        class="sis-radiobutton-tree__node__selection"
        [class.sis-radiobutton-tree__node__selection--unselectable]="node.unselectable"
      >
        @if (node.unselectable) {
          <fudis-body-text>{{ node.label }}</fudis-body-text>
        } @else {
          <label class="sis-radiobutton-tree__node__selection__label" [for]="'radiobutton-tree-' + node.id">
            <fudis-body-text>{{ node.label }}</fudis-body-text>
            <div class="sis-radiobutton-tree__node__selection__radio control control-radio">
              <input
                type="radio"
                name="radiobutton-tree"
                [id]="'radiobutton-tree-' + node.id"
                [value]="node.value"
                [formControl]="control"
              >
              <span class="control-indicator"></span>
            </div>
          </label>
        }
      </div>
    </div>

    @if (node.children?.length) {
      <ul
        [id]="'node-children-' + node.id"
        class="sis-radiobutton-tree__children"
        [class.sis-radiobutton-tree__children--collapsed]="isCollapsed(node.id)"
      >
        @for (child of node.children; track child.id) {
          <li>
            <ng-template
              [ngTemplateOutlet]="radiobuttonTree"
              [ngTemplateOutletContext]="{ node: child }"
            />
          </li>
        }
      </ul>
    }
  }
</ng-template>
