<div *ngIf="modalMode" class="modal-header">
  <h2 class="modal-title" tabindex="0" translate>FILE_UPLOAD.ADD_FILES</h2>
</div>
<div [class.modal-body]="modalMode">
    <div class="previous-files" *ngIf="this.showPreviousFilesListButton && this.previouslyAddedFiles.length > 0">
      <sis-expandable [variant]="'link'" [title]="('FILE_UPLOAD.PREVIOUS_FILES' | translate)">
        <ng-template sisExpandableContent>
          <div class="previous-files-list">
            <div *ngFor="let item of previouslyAddedFiles" class="file-preview">
              <a [href]="item.preSignedGetUrl" target="_blank" class="file-name">
                {{item.file.name}} ({{readableFileSizeString(item.file.size)}})
                <span class="visually-hidden" translate>ARIA_LABEL.OPENS_TO_A_NEW_TAB</span>
              </a>
              <p class="file-comment">{{item.explanation}}</p>
            </div>
          </div>
        </ng-template>
      </sis-expandable>
    </div>
    <h4 *ngIf="!modalMode" translate>FILE_UPLOAD.ADD_FILES</h4>
    <div class="guidance">
        <span *ngIf="description" translate>FILE_UPLOAD.DESCRIPTION</span>
        <span> {{ 'FILE_UPLOAD.CONDITIONS' | translate: {x: acceptedFileTypesText, size: maxFileSizeString, n: this.maxFiles} }}</span>
    </div>
    <label>
      <input type="file" id="selectedFile" [multiple]="true" #fileInput (change)="onSelect($event.target.files)" data-cy="file-input"/>
    </label>
    <button type="button"
           class="btn btn-primary btn-hollow btn-sm choose-files"
            (click)="fileInput.click()" translate="FILE_UPLOAD.CHOOSE_FILES"></button>
    <ngx-dropzone
            class="custom-dropzone"
            (change)="onSelect($event.addedFiles)"
            [disabled]="isDisabled">
        <ngx-dropzone-label>
          <sis-icon icon="place"></sis-icon>
          <span translate="FILE_UPLOAD.ADD_DOCUMENT"></span>
        </ngx-dropzone-label>
    </ngx-dropzone>
    <table *ngIf="editableFiles.length > 0 || externalAttachments.length > 0" class="table styled-table">
        <caption class="visually-hidden">{{ 'FILE_UPLOAD.FILE_TITLE' | translate }}</caption>
        <thead>
            <tr class="d-flex">
                <th class="col-9" translate="FILE_UPLOAD.ATTACHMENT_COLUMN"></th>
                <th class="col-2 text-right" translate="FILE_UPLOAD.FILE_SIZE"></th>
                <th class="col-1"></th>
            </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let attachments of externalAttachments">
          <ng-container *ngFor="let document of  attachments.documents">
            <ng-container *ngFor="let lang of getAttachmentLanguages(document)">
              <tr *ngIf="document.attachments[lang] as attachment" class="d-flex">
                <td class="col-9 file-preview">
                  <p class="file-name">{{(document.title | localizedString: lang) +  ' (EMREX:' +  document.externalIdentifier + ')'}}</p>
                  <p>{{!!document.description ? document.description[lang] : ''}}</p>
                </td>
                <td class="col-2 text-right">
                  <p>{{readableFileSizeString(attachment.size)}}</p>
                </td>
                <td class="col-1"></td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr *ngFor="let item of staffEditMode ? editableFiles.slice().reverse() : editableFiles; let index = index" class="d-flex">
            <td class="col-9 file-preview">
                <p class="file-name">{{item.file.name}}</p>
                <label translate="FILE_UPLOAD.FILE_COMMENT" class="file-comment-field">
                  <textarea class="form-control"
                            [ngClass]="{invalid: item.explanation && item.explanation.length >= this.explanationMaxLength}"
                            maxlength="{{this.explanationMaxLength}}"
                            name="explanation"
                            id="{{index}}"
                            (change)="addExplanation($event.target.value, item)"
                            [(ngModel)]="item.explanation"></textarea>
                </label>
                <p *ngIf="!invalidExplanationTextLength(item)" class="help-block" translate="FILE_UPLOAD.FILE_COMMENT_GUIDE"></p>
                <ul class="error-list" *ngIf="invalidExplanationTextLength(item)">
                  <li [translate]="'FILE_UPLOAD.EXPLANATION_TEXT_LIMIT'"
                      [translateParams]="{max: this.explanationMaxLength}"></li>
                </ul>
            </td>
            <td class="col-2 text-right">
                <p>{{readableFileSizeString(item.file.size)}}</p>
            </td>
            <td class="col-1 text-right">
              <sis-button class="remove-file" link="true" (clicked)="onRemove(item)" [ariaLabel]="'ARIA_LABEL.REMOVE_FILE' | translate">
                <sis-icon icon="delete"></sis-icon>
              </sis-button>
            </td>
        </tr>
        </tbody>
    </table>
    <p *ngIf="!this.previousFilesEditable && this.showPreviousFilesListButton"
       class="guidance files-not-editable" translate>FILE_UPLOAD.WARNING_FILES_NOT_EDITABLE
    </p>
</div>
<div *ngIf="modalMode" class="modal-footer">
    <button type="button" (click)="cancel()" class="btn btn-primary btn-hollow btn-lg">
        {{'BUTTON.CANCEL' | translate}}
    </button>
    <button type="button" (click)="confirm()" class="btn btn-primary btn-lg">
        {{'BUTTON.SAVE' | translate}}
    </button>
</div>
