<ng-container *transloco="let t">
  <ng-container *ngIf="data$ | async as data">
    <app-module-info-header [module]="$any(data.module)"
                            [versions]="$any(data.moduleVersions)"
                            [attainment]="data.selectedModuleAttainment"
                            (closeModal)="activeModal.close()"
                            (setModuleInfoVersionChange)="versionChangeSubject$.next($event)">
      <!-- Currently viewed version differs from the initial (selected in plan) version -->
      <ng-template *ngIf="this.values.moduleId !== data.module.id" #headerNotification>
        <sis-contextual-notification [variant]="'light'">
          <span *ngIf="!data.canUseCurrentVersion">
            <ng-container *ngIf="data.module.type === 'DegreeProgramme'">
              {{t('MODULE_INFO.DEGREE_PROGRAMME_VERSION_NOT_COMPATIBLE')}}
            </ng-container>
            <ng-container *ngIf="data.module.type === 'StudyModule'">
              {{t('MODULE_INFO.STUDY_MODULE_VERSION_NOT_COMPATIBLE')}}
            </ng-container>
          </span>
          <span *ngIf="data.canUseCurrentVersion">
            <ng-container *ngIf="data.module.type === 'DegreeProgramme'">
              {{t('MODULE_INFO.DEGREE_PROGRAMME_VERSION_IS_COMPATIBLE')}}
            </ng-container>
            <ng-container *ngIf="data.module.type === 'StudyModule'">
              {{t('MODULE_INFO.STUDY_MODULE_VERSION_IS_COMPATIBLE')}}
            </ng-container>
            <sis-button [type]="'button'"
                        [link]="true"
                        (clicked)="openVersionChangeWarningModal(this.values.moduleId, data.module.id)">
              {{t('MODULE_INFO.VERSION_USE_BTN')}}
            </sis-button>
          </span>
        </sis-contextual-notification>
      </ng-template>
    </app-module-info-header>
    <ng-container *ngIf="data.showModuleAttainmentApplicationBlock">
      <div class="module-attainment-application-block">
        <div class="row">
          <div class="col-12 col-md-8">
            <p>
              <ng-container [ngSwitch]="values.moduleAttainmentApplicationWrapper.mode">
                <span *ngSwitchCase="'FLAT'">
                  {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.FLAT_MODULE_INFO')}}
                </span>
                <span *ngSwitchCase="'RECURSIVE'">
                  {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.DEEP_MODULE_INFO')}}
                </span>
                <span *ngSwitchCase="'APPLICATION_IS_SENT'">
                  {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.APPLICATION_IS_SENT_INFO')}}
                </span>
                <span *ngSwitchCase="'STUDY_RIGHT_NOT_FOUND'">
                  {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.STUDY_RIGHT_NOT_FOUND_INFO')}}
                </span>
                <span class="warning-guidance">
                  {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.MODULE_APPLICATION_NOTIFICATION')}}
                </span>
              </ng-container>
            </p>
          </div>
          <div class="col-12 col-md-4 button-holder">
            <button *ngIf="!!values.moduleAttainmentApplicationWrapper.applyForModuleAttainment
            && !values.moduleAttainmentApplicationWrapper?.applications?.length > 0"
                    class="btn btn-white btn-lg"
                    data-cy="apply-for-module-attainment-button"
                    [disabled]="attainmentApplicationSendPending"
                    (click)="applyForModuleAttainmentApplication(); $event.stopPropagation()">
              {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.APPLY')}}
            </button>
            <button data-cy="show-module-attainment-application-button"
                    #showModuleAttainmentApplicationButton
                    class="btn btn-white btn-lg"
                    *ngIf="values.moduleAttainmentApplicationWrapper?.applications?.length > 0"
                    (click)="navigateToModuleAttainmentApplication(values.moduleAttainmentApplicationWrapper?.applications ?? []);
                        $event.stopPropagation()">
              {{t('MODULE_INFO.MODULE_ATTAINMENT_APPLICATION.SHOW_APPLICATION')}}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.showDegreeProgrammeWorkflowRequestedBlock">
      <div class="degree-programme-application-requested-block">
        <div class="row ">
          <div class="col-12">
            <span>{{t('MODULE_INFO.GRADUATION.GRADUATION_REQUESTED',
              {degreeProgrammeName: data.module.name | localizedString})}}</span>
            <a id="navigate-to-dpa-application"
               [uiSref]="'student.logged-in.profile.applications.degree-programme-attainment-workflow'"
               [uiParams]="{applicationId: data.degreeProgrammeAttainmentWorkflow.id}"
               (click)="activeModal.close()">
              {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_DPA_APPLICATION')}}
            </a>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_BASIC')"
                          [level]="3"
                          [closed]="!values?.sectionExpandableOpenSettings?.basics ?? true">
            <ng-template sisExpandableContent>
              <app-module-info-basic-info [module]="$any(data.module)"></app-module-info-basic-info>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_DESCRIPTIONS')"
                          [level]="3"
                          [closed]="!values?.sectionExpandableOpenSettings?.description ?? true">
            <ng-template sisExpandableContent>
              <app-module-info-description [module]="$any(data.module)"></app-module-info-description>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_CLASSIFICATION')"
                          [level]="3"
                          [closed]="!values?.sectionExpandableOpenSettings?.classification ?? true">
            <ng-template sisExpandableContent>
              <app-module-info-classification [module]="$any(data.module)"></app-module-info-classification>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
      <div class="row" *ngIf="data.showGraduationSection">
        <div class="col-12">
          <sis-expandable [title]="t('MODULE_INFO.TITLE_GRADUATION')"
                          [level]="3"
                          [closed]="!values?.sectionExpandableOpenSettings?.graduation ?? true">
            <ng-template sisExpandableContent>
              <div class="sis-mb-xs">
                <sis-contextual-notification [variant]="'light'" *ngIf="!data.selectedModuleAttainment
              && values.isPrimaryPlan
              && !!data.degreeProgrammeAttainmentWorkflow">
                  <span>{{t('MODULE_INFO.GRADUATION.GRADUATION_REQUESTED',
                    {degreeProgrammeName: data.module.name | localizedString})}}</span>
                  <a id="navigate-to-dpa-application-graduation-section"
                     [uiSref]="'student.logged-in.profile.applications.degree-programme-attainment-workflow'"
                     [uiParams]="{applicationId: data.degreeProgrammeAttainmentWorkflow.id}"
                     (click)="activeModal.close()">
                    {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_DPA_APPLICATION')}}
                  </a>
                </sis-contextual-notification>
              </div>
              <ng-container *ngIf="!data.selectedModuleAttainment">
                @if (!mandatoryGraduationModalValues) {
                  <app-graduation-instructions-url [organisationId]="values.organisationId">
                  </app-graduation-instructions-url>
                  <p class="guidance">{{t('MODULE_INFO.GRADUATION.GUIDANCE')}}</p>
                  <sis-button *ngIf="values.isPrimaryPlan && !data.degreeProgrammeAttainmentWorkflow"
                              [size]="'lg'"
                              [variant]="'primary'"
                              [id]="'navigate-to-dpa-application-creation'"
                              (clicked)="navigateToDegreeProgrammeAttainmentWorkflowCreation(data.module)"
                              [type]="'button'">
                    {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_APPLICATION_CREATION')}}
                  </sis-button>
                } @else {
                  <fudis-body-text class="sis-mb-md" variant="lg-light">{{t('MODULE_INFO.MANDATORY_GRADUATION_SURVEY_GUIDANCE')}}</fudis-body-text>
                  <sis-button *ngIf="values.isPrimaryPlan && !data.degreeProgrammeAttainmentWorkflow"
                              [size]="'lg'"
                              [variant]="'primary'"
                              [id]="'navigate-to-dpa-application-creation'"
                              (clicked)="openMandatoryGraduationSurveyModal()"
                              [type]="'button'">
                    {{t('MODULE_INFO.GRADUATION.NAVIGATE_TO_APPLICATION_CREATION')}}
                  </sis-button>
                }
              </ng-container>
            </ng-template>
          </sis-expandable>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>





