import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import {
    BatchOperationResult,
    DegreeProgramme,
    ModuleResponsibilityInfoType,
    OtmId,
    PersonWithModuleResponsibilityInfoType,
    ResponsibilityInfo,
    ResponsiblePersonDeleteRequest,
    ResponsiblePersonsAddRequest,
    ResponsiblePersonValidityPeriodEndDates,
    ResponsiblePersonValidityPeriodsEndRequest,
} from 'common-typescript/types';
import { Observable, tap } from 'rxjs';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';

import { EntityService } from './entity.service';

const CONFIG = {
    ENDPOINTS: {
        backend: '/kori/api',
        cancelApproval(degreeProgrammeId: OtmId) {
            return `${this.backend}/degree-programmes/cancel-approval/${degreeProgrammeId}`;
        },
        undoDelete(degreeProgrammeId: OtmId) {
            return `${this.backend}/degree-programmes/undo-delete/${degreeProgrammeId}`;
        },
        approve(degreeProgrammeId: OtmId) {
            return `${this.backend}/degree-programmes/approve/${degreeProgrammeId}`;
        },
        create() {
            return `${this.backend}/degree-programmes`;
        },
        addResponsiblePersons() {
            return `${this.backend}/degree-programmes/add-responsible-persons/batch`;
        },
        endResponsiblePersonValidityPeriod() {
            return `${this.backend}/degree-programmes/end-responsible-person-validity-period/batch`;
        },
        deleteResponsiblePerson() {
            return `${this.backend}/degree-programmes/delete-responsible-person/batch`;
        },
    },
};

@StaticMembers<DowngradedService>()
@Injectable({
    providedIn: 'root',
})
@NgEntityServiceConfig({
    baseUrl: CONFIG.ENDPOINTS.backend,
    resourceName: 'degree-programmes',
})
export class DegreeProgrammeEntityService extends EntityService<DegreeProgrammeState> {

    static downgrade: ServiceDowngradeMappings = {
        dependencies: [],
        moduleName: 'sis-components.service.degreeProgrammeEntityService',
        serviceName: 'degreeProgrammeEntityService',
    };

    constructor() {
        super(DegreeProgrammeStore, DegreeProgrammeQuery);
    }

    undoApproval(id: OtmId): Observable<DegreeProgramme> {
        return this.getHttp().put<DegreeProgramme>(
            CONFIG.ENDPOINTS.cancelApproval(id),
            {},
        ).pipe(tap((degreeProgramme) => this.store.upsert(degreeProgramme.id, degreeProgramme)));
    }

    undoDelete(id: OtmId, curriculumPeriodIds: OtmId[]): Observable<DegreeProgramme> {
        return this.getHttp().put<DegreeProgramme>(
            CONFIG.ENDPOINTS.undoDelete(id),
            curriculumPeriodIds,
        ).pipe(tap((degreeProgramme) => this.store.upsert(degreeProgramme.id, degreeProgramme)));
    }

    approve(id: OtmId): Observable<DegreeProgramme> {
        return this.getHttp().put<DegreeProgramme>(
            CONFIG.ENDPOINTS.approve(id),
            {},
        ).pipe(tap((degreeProgramme) => this.store.upsert(degreeProgramme.id, degreeProgramme)));
    }

    updateAndStore(degreeProgramme: DegreeProgramme): Observable<DegreeProgramme> {
        return super.update<DegreeProgramme>(degreeProgramme.id, degreeProgramme, { skipWrite: true })
            .pipe(tap((dp) => this.store.upsert(dp.id, dp)));
    }

    storeUpsert(degreeProgramme: DegreeProgramme) {
        this.store.upsert(degreeProgramme.id, degreeProgramme);
    }

    create(degreeProgramme: DegreeProgramme): Observable<DegreeProgramme> {
        return this.getHttp().post<DegreeProgramme>(CONFIG.ENDPOINTS.create(), { ...degreeProgramme, approvalState: 'urn:code:approval-state-type:not-ready' })
            .pipe(this.upsertAndSwitchToStoreObservable());
    }

    addResponsiblePersonsBatch(
        degreeProgrammeIds: OtmId[],
        responsibilityInfos: ResponsibilityInfo[],
        dryRun: boolean = false,
    ): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.addResponsiblePersons(),
            { ids: degreeProgrammeIds, responsibilityInfos } as ResponsiblePersonsAddRequest<PersonWithModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    endResponsiblePersonValidityPeriodBatch(
        degreeProgrammeIds: OtmId[],
        responsiblePersonId: OtmId,
        endDates: ResponsiblePersonValidityPeriodEndDates,
        dryRun: boolean = false,
    ): Observable<{ [key: string]: BatchOperationResult }> {
        const roleValidityPeriodEndDates = [
            { roleUrn: 'urn:code:module-responsibility-info-type:responsible-teacher', endDate: endDates.responsibleTeacherRoleEndDate },
            { roleUrn: 'urn:code:module-responsibility-info-type:administrative-person', endDate: endDates.adminRoleEndDate },
            { roleUrn: 'urn:code:module-responsibility-info-type:contact-info', endDate: endDates.contactInfoRoleEndDate },
        ];
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.endResponsiblePersonValidityPeriod(),
            { ids: degreeProgrammeIds, responsiblePersonId, roleValidityPeriodEndDates } as ResponsiblePersonValidityPeriodsEndRequest<ModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }

    deleteResponsiblePersonBatch(degreeProgrammeIds: OtmId[], responsiblePersonId: OtmId, rolesToDelete: Set<ModuleResponsibilityInfoType>, dryRun: boolean = false): Observable<{ [key: string]: BatchOperationResult }> {
        return this.getHttp().post<{ [key: string]: BatchOperationResult }>(
            CONFIG.ENDPOINTS.deleteResponsiblePerson(),
            { ids: degreeProgrammeIds, responsiblePersonId, rolesToDelete: [...rolesToDelete] } as ResponsiblePersonDeleteRequest<ModuleResponsibilityInfoType>,
            { params: { dryRun } },
        );
    }
}

type DegreeProgrammeState = EntityState<DegreeProgramme, OtmId>;

@StoreConfig({ name: 'degree-programmes' })
class DegreeProgrammeStore extends EntityStore<DegreeProgrammeState> {}

class DegreeProgrammeQuery extends QueryEntity<DegreeProgrammeState> {
    constructor(protected store: DegreeProgrammeStore) {
        super(store);
    }
}
