import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { FullCalendarCustomEvent } from 'common-typescript/types';
import { switchMap, tap } from 'rxjs';
import { ModalService } from 'sis-common/modal/modal.service';

import {
    addOwnCalendarEventModalLazyOpener,
} from '../../../dialog/add-own-calendar-event-modal/add-own-calendar-event-modal-lazy-opener';

export interface OwnCalendarEventSummaryModalComponentValues {
    event: FullCalendarCustomEvent
}

@Component({
    selector: 'app-own-calendar-event-summary-modal',
    templateUrl: './own-calendar-event-summary-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OwnCalendarEventSummaryModalComponent implements OnInit {
    constructor(
        public activeModal: NgbActiveModal,
        private state: StateService,
        @Inject(ModalService.injectionToken) public values: OwnCalendarEventSummaryModalComponentValues,
    ) {}

    event: FullCalendarCustomEvent;

    addOwnCalendarEventModalLazyOpener = addOwnCalendarEventModalLazyOpener();

    ngOnInit() {
        this.event = this.values.event;
    }

    openOwnCalendarEventModal() {
        this.addOwnCalendarEventModalLazyOpener({ existingCalendarEvent: this.event.extendedProps.eventObject })
            .pipe(
                switchMap((ref) => ref.afterClosed().pipe(
                    tap((result) => {
                        if (result) {
                            this.state.reload();
                        }
                        this.activeModal.dismiss();
                    }),
                )),
            )
            .subscribe();
    }
}
