import { HttpErrorResponse } from '@angular/common/http';
import { TrackByFunction } from '@angular/core';
import { LocalId, OtmId } from 'common-typescript/types';
import { EMPTY, from, identity, MonoTypeOperatorFunction, Observable, OperatorFunction, retry, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';

interface EntityWithId {
    id?: OtmId;
    localId?: LocalId;
}

/** A custom `TrackByFunction` implementation for identifying SISU entities in an `*ngFor` directive */
export function trackByEntityId<T extends EntityWithId>(index: number, entity: T): string | number | T {
    return entity?.id ?? entity?.localId ?? entity ?? index;
}

/**
 *  Similar to {@link trackByEntityId} for situations where the entity to track by is in a nested property.
 *
 *  @param key The name of the property containing the entity to track by.
 */
export function trackByNestedEntityId<T>(key: keyof T): TrackByFunction<T> {
    return (index: number, entity: T) => trackByEntityId(index, entity?.[key]);
}

/**
 * Similar to `distinctUntilKeyChanged`, but with two key differences:
 *
 * 1. Works with any number of keys.
 * 2. Handles null and undefined values in the stream without exceptions.
 *
 * Note that if the source emits null or undefined values, the operator treats the values of the defined properties
 * as if they were undefined. So if the source first emits a null/undefined value, and then a non-null value where
 * the defined properties are missing or undefined, the property values are considered equal, and no value is emitted.
 *
 * If no keys are given, falls back to the behavior of `distinctUntilChanged`.
 */
export function distinctUntilAnyKeyChanged<T, K extends keyof T>(...keys: K[]): MonoTypeOperatorFunction<T> {
    return keys?.length > 0 ? distinctUntilChanged((a: T, b: T) => keys.every((key) => a?.[key] === b?.[key])) : distinctUntilChanged();
}

/**
 * Returns an RxJS operator function that can be used to ignore HTTP errors with specific status codes. If one of the specified
 * errors occurs, the observable will simply complete immediately. All other errors are propagated as usual.
 *
 * @param statuses The HTTP error status codes to ignore
 */
export function ignoreHttpErrorsByStatus<T>(...statuses: number[]): OperatorFunction<T, T> {
    if (!statuses?.length) {
        return identity;
    }
    return catchError(e => e instanceof HttpErrorResponse && statuses.includes(e.status) ? EMPTY : throwError(() => e));
}

/**
 * Converts an AngularJS promise to a native JavaScript promise which will force execution inside NgZone. This is
 * a workaround for AngularJS promises causing issues with change detection when called from Angular code.
 * The problems exist at least when converting AngularJS promises to Observables using `from`-operator, that for
 * some reason causes the whole observable to run outside NgZone during page reloads (but only sometimes).
 * The issue seems to occur most frequently on Safari.
 *
 * @param promise AngularJS promise to convert to a native JavaScript promise.
 */
export function convertAJSPromiseToNative<T>(promise: PromiseLike<T>): Promise<T> {
    return new Promise((resolve, reject) => {
        promise.then((result: T) => resolve(result), (error: any) => reject(error));
    });
}

/**
 * Utility function for module lazy loading retries.
 * Note that the module must be loaded using the import() function where the path
 * is known at compile time. Otherwise, the chunk will not be generated and the
 * lazy loading will not work.
 *
 * @example Correct usage:
 * ```typescript
 * // Lazy loaded chunk is generated automatically and loaded with retries
 * lazyLoadModuleWithRetries(import('./path/to/module'));
 * ```
 * @example Incorrect usage:
 * ```typescript
 * // This will not work, because the path is not known at compile time
 * const path = './path/to/module';
 * lazyLoadModuleWithRetries(import(path));
 * ```
 *
 * @param importFn The chunk import function.
 * @param retries The number of retries (Default: 3).
 * @param retryDelay The delay between retries (ms) (Default: 1000).
 * @returns An observable that emits the loaded module.
 */
export function lazyLoadModuleWithRetries<T>(importFn: Promise<T>, retries = 3, retryDelay = 1000): Observable<T> {
    return from(importFn).pipe(
        retry({ count: retries, delay: retryDelay }),
    );
}
