<fudis-section *transloco="let t" [title]="t('FILE_UPLOAD.FILE_TITLE')" [hidden]="editing()"
               [level]="3" [width]="'md'" [classes]="'fudis-my-xl'">
  <ng-template fudisActions [type]="'section'">
    <fudis-button *ngIf="attachments().length > 0 || currentExternalAttachments.length > 0"
                  [variant]="'tertiary'"
                  [size]="'small'"
                  [icon]="'place'"
                  [iconRotate]="'flip-180'"
                  [label]="t('FILE_UPLOAD.DOWNLOAD_ALL')"
                  (handleClick)="downloadZip()"/>
    <fudis-button *ngIf="editable"
                  [variant]="'secondary'"
                  [size]="'small'"
                  [label]="t('FILE_UPLOAD.ADD_MORE_FILES')"
                  [disabled]="sendingFiles() || editing()"
                  (handleClick)="addAttachments()"/>
    <fudis-button *ngIf="editAllFiles"
                  [size]="'small'"
                  [label]="t('BUTTON.EDIT')"
                  [disabled]="sendingFiles() || editing()"
                  (handleClick)="editAttachments()"/>
  </ng-template>
  <ng-template fudisContent [type]="'section'">
    <fudis-body-text *ngIf="attachments().length === 0 && currentExternalAttachments.length === 0">{{ t('FILE_UPLOAD.ZERO_FILES') }}</fudis-body-text>
    <sis-emrex-attachments [showLink]="true" [externalAttainedStudyIds$]="getExternalAttainedStudyIdsAsObservable()" (fetchedExternalAttachments)="onExternalAttachmentsChange($event)"></sis-emrex-attachments>
    <div class="sis-mt-sm"
         *ngFor="let attachment of staffEditMode && attachments() ? attachments().slice().reverse() : attachments()">
      <a fudisLink
         [title]="attachment.name + readableFileSizeString(attachment.size)"
         [href]="attachment.preSignedGetUrl"
         [external]="true"
         data-cy="attachment"></a>
      <fudis-body-text>{{ attachment.comment }}</fudis-body-text>
    </div>
  </ng-template>
</fudis-section>
<div class="row" *ngIf="editing()">
  <div class="col-12 col-md-8">
    <sis-edit-application-attachments (newFiles)="uploadAttachments($event)"
                                      [previouslyAddedFiles]="previouslyAddedFiles" (editMode)="setEditMode($event)"/>
  </div>
</div>
