<div class="container">
    <div class="row">
      <div class="offset-md-1 col-md-10 offset-lg-2 col-lg-8">
        <div class="page-title-container fake-crumbs">
          <h1 id="skip-link-target" tabindex="-1" data-cy="page-title">
            {{ ('STUDENT_APPLICATIONS.TYPE.' + workflow.type) | translate }}: {{ application.name }}
            <app-workflow-state-badge [state]="workflow.state"></app-workflow-state-badge>
          </h1>
        </div>
        <sis-code-panel [code]="workflow.code"></sis-code-panel>
        <sis-contextual-notification *ngIf="supplementRequestRationale$ | async as notification" variant="warning">
          {{'PROFILE.APPLICATIONS.SUPPLEMENT_REQUESTED_RATIONALE' | translate}}<br aria-hidden="true" />{{notification}}
        </sis-contextual-notification>
        <div class="form-group-mimic">
          <sis-wizard-progress [stepKeys]="wizardStepKeys" [currentStep]="currentStep"></sis-wizard-progress>
        </div>
        <app-custom-attainment-basic-info-edit [hidden]="!isFirstStep()"
                                               [planId]="workflow.planId"
                                               [workflowApplication]="application"
                                               (exit)="exit()"
                                               (continue)="continue($event)">
        </app-custom-attainment-basic-info-edit>
        <app-caa-attainment-info *ngIf="initialAttachments$ | async as initialAttachments"
                                 [hidden]="currentStep !== 1"
                                 [workflowApplication]="application"
                                 [initialAttachments]="initialAttachments"
                                 [workflowId]="workflow.id"
                                 (attachmentsChange)="onAttachmentsChange($event)"
                                 (exit)="exit()"
                                 (previous)="previous()"
                                 (continue)="continue($event)">
        </app-caa-attainment-info>
        <app-caa-confirmation [hidden]="!isLastStep()"
                              [application]="application"
                              [attachments]="attachments"
                              [disableButtons]="uploading"
                              (exit)="exit()"
                              (previous)="previous()"
                              (submit)="submit($event)">
        </app-caa-confirmation>
      </div>
    </div>
</div>
