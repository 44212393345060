<div class="row">
  <div class="col-12">
    <span class="guidance">{{'PROFILE.APPLICATIONS.CUSTOM_ATTAINMENT.PHASE_3_INFO' | translate}}</span>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <h2>{{'STUDENT_APPLICATIONS.APPLICATION_INFO' | translate}}</h2>
  </div>
</div>
<div class="row">
  <div class="col-12 form-group">
    <app-custom-attainment-workflow-summary [studentWorkflow]="application" [studentId]="studentId"/>
  </div>
</div>
<div class="row">
  <div class="col-12 form-group file-preview">
    <app-attachments-preview [attachments]="attachments"></app-attachments-preview>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <h3>{{'STUDENT_APPLICATIONS.GRADING_AND_DECISION' | translate}}</h3>
    <div class="form-group checkbox-group">
      <h4 [class]="getLabelState(mustPrintDecision)">{{'PROFILE.APPLICATIONS.DECISION_AND_DELIVERY' | translate}}</h4>
      <p>{{'PROFILE.APPLICATIONS.DECISION_AND_DELIVERY_INFO' | translate}}</p>
      <sis-radio-button [control]="mustPrintDecision"
                        [value]="false"
                        [label]="'PROFILE.APPLICATIONS.DECISION_DELIVERY_IN_SISU'">
      </sis-radio-button>
      <sis-radio-button [control]="mustPrintDecision"
                        [value]="true"
                        [label]="'PROFILE.APPLICATIONS.DECISION_DELIVERY_BY_MAIL'">
      </sis-radio-button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <sis-button class="float-end"
                [disabled]="disableButtons"
                (clicked)="onSubmit()">
      {{'STUDENT_APPLICATIONS.SEND' | translate}}
    </sis-button>
    <sis-button class="float-end"
                [hollow]="true"
                [disabled]="disableButtons"
                (clicked)="previous.emit()">
      {{'SIS_COMPONENTS.BUTTON.PREVIOUS' | translate}}
    </sis-button>
    <sis-button class="float-end"
                [naked]="true"
                [disabled]="disableButtons"
                (clicked)="exit.emit()">
      <sis-icon icon="back"></sis-icon>{{'SIS_COMPONENTS.BUTTON.EXIT' | translate}}
    </sis-button>
  </div>
</div>
