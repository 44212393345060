import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CourseUnitRealisation } from 'common-typescript/types';
import { trackByEntityId } from 'sis-components/util/utils';

@Component({
    selector: 'app-open-university-course-unit-realisation-info',
    templateUrl: './open-university-course-unit-realisation-info.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class OpenUniversityCourseUnitRealisationInfoComponent {

    @Input() courseUnitRealisation: CourseUnitRealisation;
    @Input() hideGuidance?: boolean;

    currentTabIndex = 0;

    readonly entityId = trackByEntityId;
}
