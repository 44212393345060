<ng-container *transloco="let t">
  <div class="container sis-pt-md">
    <sis-breadcrumbs></sis-breadcrumbs>
    <sis-page-title [title]="t('COURSE_CART.COURSE_CART_PAGE_TITLE')" [showCrumbs]="true"/>
    <div class="row" *ngIf="courseCartCourseUnits$ | async as courseIdsCart">
      <ng-container *ngIf="courseIdsCart && courseIdsCart.length > 0; else noCourseUnitsInCart">
        <div class="col-md-12">
          <table class="sis-table styled-table">
            <caption>{{ t('COURSE_CART.COURSE_CART_PAGE_CAPTION') }}</caption>
            <thead>
            <tr>
              <th class="col-2">{{ t('COURSE_UNIT_CODE')}}</th>
              <th class="col-4">{{ t('COURSE_UNIT_NAME')}}</th>
              <th class="col-2 col-lg-1">{{ t('COURSE_UNIT_CREDITS')}}</th>
              <th class="col-2">{{ t('COURSE_UNIT_ORGANIZATION')}}</th>
              <th class="col-2 col-lg-3 sis-table__header__last">{{t('COURSE_CART.REMOVE_ACTION_HEADER')}}</th><!-- remove button header -->
            </tr>
            </thead>
            <tbody class="sis-table__body" data-cy="course-cart-course-units">
            <tr *ngFor="let courseUnitId of courseIdsCart" class="sis-table__body__row"
                data-cy="course-cart-course-unit">
              <ng-container *ngIf="courseUnitId | courseUnit | async as courseUnit">
                <td class="col-2">
                  <sis-button [link]="true"
                              (clicked)="openCourseUnitInfo(courseUnitId)"
                              [ariaLabel]="courseUnit.code + ' ' + (courseUnit.name | localizedString) + ' ' + t('ARIA_LABEL.OPEN_INFO')">
                    <sis-icon icon="brochure"></sis-icon>{{courseUnit.code}}
                  </sis-button>
                </td>
                <td class="col-4">
                  <span>{{courseUnit?.name | localizedString }}</span>
                </td>
                <td class="col-2 col-lg-1">
                  <span>{{courseUnit?.credits | creditRange}}</span>
                </td>
                <td class="col-2">
              <span *ngFor="let org of courseUnit?.organisations; last as isLast">
                {{(org?.organisationId | organisation | async)?.name | localizedString}}<span
                *ngIf="!isLast">,</span> </span>
                </td>
                <td class="col-2 col-lg-3">
                  <sis-button [type]="'button'"
                              [variant]="'secondary'"
                              [naked]="true"
                              [size]="'sm'"
                              class="sis-table__body__row__button"
                              data-cy="remove-from-course-cart"
                              *ngIf="isLoggedIn"
                              (clicked)="removeCourseUnitFromCourseCart(courseUnit?.id)"
                              aria-label="{{t('ARIA_LABEL.REMOVE_FROM_CART')}} {{courseUnit?.name | localizedString }}">
                    <sis-icon icon="list-minus"></sis-icon>
                    <span class="sis-table__body__row__button__text sis-table__body__row__button__text__long">{{t('REMOVE_FROM_CART')}}</span>
                    <span class="sis-table__body__row__button__text sis-table__body__row__button__text__short">{{t('REMOVE_FROM_CART_SHORT')}}</span>
                  </sis-button>
                </td>
              </ng-container>
            </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-template #noCourseUnitsInCart>
    <p>{{t('COURSE_CART.COURSE_CART_IS_EMPTY')}}</p>
    <span class="guidance">{{t('COURSE_CART.ADD_TO_EMPTY_COURSE_CART')}}</span>
  </ng-template>
</ng-container>



