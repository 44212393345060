import { Injectable } from '@angular/core';
import { OpenUniversityCart, PaymentId } from 'common-typescript/types';
import { catchError, Observable, of, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'sis-common/auth/auth-service';
import { OpenUniversityCartCustomerService } from 'sis-components/service/open-university-cart-customer.service';
import { PrivatePersonEntityService } from 'sis-components/service/private-person-entity.service';

@Injectable({ providedIn: 'root' })
export class OpenUniversityCartUtilsService {

    constructor(
        private privatePersonEntityService: PrivatePersonEntityService,
        private authService: AuthService,
        private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
    ) {}

    /**
     * If the provided cart has not already been activated and the student is valid, tries to activate the cart.
     * Emits only one value: on success the activated cart, otherwise the provided cart.
     */
    activateCartForStudentIfIsValidStudent(cart: OpenUniversityCart): Observable<OpenUniversityCart> {
        if (!this.authService.loggedIn() || cart.state === 'ACTIVATED') {
            return of(cart);
        }

        return this.privatePersonEntityService.getIsValidStudent(this.authService.personId())
            .pipe(
                take(1),
                switchMap((isValidStudent) => isValidStudent
                    ? this.openUniversityCartCustomerService
                        .activateOpenUniversityCartWithActivationCode(cart.id, cart.activationCode)
                    : of(cart),
                ),
                catchError(_ => of(cart)),
            );
    }
}
