import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { AssessmentItem } from 'common-typescript/types';
import { Observable } from 'rxjs';
import { AppErrorHandler } from 'sis-components/error-handler/app-error-handler';
import { AssessmentItemEntityService } from 'sis-components/service/assessment-item-entity.service';
import { trackByEntityId } from 'sis-components/util/utils';

import { AggregateProductInfo, AggregateProductInfoType } from '../types';

@Component({
    selector: 'app-carousel-product-info',
    templateUrl: './carousel-product-info.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselProductInfoComponent {

    assessmentItems$: Observable<AssessmentItem[]>;
    private _product: AggregateProductInfo | undefined;

    constructor(
        private readonly _assessmentItemService: AssessmentItemEntityService,
        private readonly _appErrorHandler: AppErrorHandler,
    ) {}

    @Input({ required: true }) set product(product: AggregateProductInfo) {
        this._product = product;
        this.assessmentItems$ = this._assessmentItemService.findForOpenUniversityProduct(product).pipe(
            this._appErrorHandler.defaultErrorHandler(),
        );
    }

    get product(): AggregateProductInfo | undefined {
        return this._product;
    }

    readonly entityId = trackByEntityId;

    get isOnSale(): boolean {
        return this._product?.type === AggregateProductInfoType.Current;
    }

    get isFutureProduct(): boolean {
        return this._product?.type === AggregateProductInfoType.Future;
    }
}
