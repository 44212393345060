import { ChangeDetectionStrategy, Component, Inject, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { ExternalAttainedStudy, OtmId } from 'common-typescript/types';
import _ from 'lodash';
import { LocaleService } from 'sis-common/l10n/locale.service';
import { SisFormBuilder } from 'sis-components/form/sis-form-builder.service';

export interface SelectEmrexAttainmentDialogValues {
    externalAttainedStudies: ExternalAttainedStudy[]
    selection: ExternalAttainedStudy
}

interface SelectEmrexAttainmentForm {
    externalAttainedStudy: FormControl<ExternalAttainedStudy>
}

export interface ExternalAttainedStudyLabel {
    id: OtmId,
    translatedLabel: string,
    translatedTitle: string,
    code: string,
}

@Component({
    selector: 'app-select-emrex-attainment-dialog',
    templateUrl: './select-emrex-attainment-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEmrexAttainmentDialogComponent implements OnInit {
    private dialogService = inject(FudisDialogService);
    private fb = inject(SisFormBuilder);

    form: FormGroup<SelectEmrexAttainmentForm>;
    sortedExternalAttainedStudies: ExternalAttainedStudyLabel[];

    constructor(
        private localeService: LocaleService,
        @Inject(MAT_DIALOG_DATA) public data: SelectEmrexAttainmentDialogValues,
    ) {
    }

    ngOnInit() {
        const viewModels = this.data.externalAttainedStudies.map(eas => this.mapViewModel(eas));
        this.sortedExternalAttainedStudies = _.sortBy(viewModels, [(o: ExternalAttainedStudyLabel) => (!o.translatedTitle ? 1 : 0), 'translatedTitle', 'code']);
        this.form = this.fb.group({
            externalAttainedStudy: this.fb.sisFormControl(this.data.selection || null),
        });
    }

    getExternalAttainedStudyById(id: OtmId) {
        return _.find(this.data.externalAttainedStudies, { id });
    }

    mapViewModel(externalAttainedStudy: ExternalAttainedStudy): ExternalAttainedStudyLabel {
        const viewModel: ExternalAttainedStudyLabel = {
            id: externalAttainedStudy.id,
            code: externalAttainedStudy.learningOpportunitySpecification?.iscedCode,
            translatedTitle: externalAttainedStudy.learningOpportunitySpecification?.title ?
                this.localeService.localize(externalAttainedStudy.learningOpportunitySpecification?.title) : null,
            translatedLabel: null,
        };

        if (viewModel.translatedTitle && viewModel.code) {
            viewModel.translatedLabel = `${externalAttainedStudy.learningOpportunitySpecification.iscedCode}, ${viewModel.translatedTitle}`;
        } else if (!viewModel.translatedTitle) {
            viewModel.translatedLabel = viewModel.code;
        } else {
            viewModel.translatedLabel = viewModel.translatedTitle;
        }

        return viewModel;
    }

    submit() {
        this.dialogService.close({
            selection: this.form?.controls?.externalAttainedStudy?.value,
        });
    }
}
